import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { error: Boolean };

  connect() {
    if (!window.notyf) {
      window.notyf = new Notyf({
        duration: 5000,
        position: { x: "center", y: "bottom" },
      });
    }

    if (this.element.innerHTML.trim().length === 0) return;

    const notyfData = {
      message: this.element.innerHTML,
      dismissible: true,
    };

    notyfData.type = this.errorValue ? "error" : "success";

    if (this.#notificationIsDuplicate(notyfData)) return;

    window.notyf.open(notyfData);
  }

  disconnect() {
    window.notyf = null;
  }

  #notificationIsDuplicate(notyfData) {
    if (!window.notyf) return false;

    const duplicate = window.notyf.notifications.notifications.find(
      (notification) =>
        notification.options.message === notyfData.message && notification.options.type === notyfData.type
    );

    if (duplicate) {
      return true;
    }

    return false;
  }
}
