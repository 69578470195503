import { Controller } from "stimulus";
import consumer from "~/channels/consumer";

export default class extends Controller {
  static targets = ["progressBar"];
  static values = { id: String };

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "DocGenDependentsUpdateProgressChannel",
        id: this.idValue,
      },
      {
        received: this.received.bind(this),
      }
    );
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  received(data) {
    this.showOrHideProgressBar(data);
  }

  showOrHideProgressBar(data) {
    if (data.status === "in-progress") {
      this.inProgress(data);
    } else if (data.status === "completed") {
      this.completed();
    }
  }

  inProgress({ updated_count, total_count }) {
    this.showProgressBar();
    this.disableSaveButton();
    this.setProgressBarWidth(this.getWidth(updated_count, total_count));
  }

  completed() {
    this.hideProgressBar();
    this.enableSaveButton();
    window.notyf.success("Dependents update completed");
  }

  showProgressBar() {
    this.element.classList.remove("hidden");
  }

  hideProgressBar() {
    this.element.classList.add("hidden");
  }

  setProgressBarWidth(width) {
    this.progressBarTarget.style.width = `${width}%`;
  }

  getWidth(updated_count, total_count) {
    return (updated_count / total_count) * 100;
  }

  disableSaveButton() {
    this.saveButton()?.setAttribute("disabled", "disabled");
  }

  enableSaveButton() {
    this.saveButton()?.removeAttribute("disabled");
  }

  saveButton() {
    return document.getElementById("questionnaire-save-button");
  }
}
