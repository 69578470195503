import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["searchInput"];
  static values = { folderId: String, folderType: String };

  searchInputTargetConnected(element) {
    this.debouncedHandleSearchInputChange = debounce(this.handleSearchInputChange, 300).bind(this);
    element.addEventListener("input", this.debouncedHandleSearchInputChange);
  }

  handleSearchInputChange(event) {
    const searchValue = event.target.value;

    this.updateFolderFiles({
      searchQuery: searchValue,
      folderId: this.folderIdValue,
      folderType: this.folderTypeValue,
    });
  }

  updateFolderFiles(payload) {
    let url = new URL("data_room/folders", window.location.origin);
    let params = new URLSearchParams({ search_query: payload.searchQuery });
    if (payload.folderId) {
      params.append("filtered_folder_id", payload.folderId);
    } else if (payload.folderType) {
      params.append("filtered_file_folder_type", payload.folderType);
    }
    url.search = params.toString();
    url = url.toString();

    get(url, {
      responseKind: "turbo-stream",
    });
  }
}
