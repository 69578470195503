import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["virtualToggle", "physicalFieldsContainer", "eventNameField", "eventWhereField"];

  connect() {
    this.virtualToggleTarget.addEventListener("change", this.renderLocationForms.bind(this));
    this.renderLocationForms();
  }

  renderLocationForms() {
    if (this.virtualToggleTarget.value === "1") {
      this.physicalFieldsContainerTarget.classList.add("hidden");
      this.eventNameFieldTarget.value = "";
      this.eventWhereFieldTarget.value = "";
    } else {
      this.physicalFieldsContainerTarget.classList.remove("hidden");
    }
  }
}
