import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "subjectableTypeSelect",
    "subjectableSubTypeSelect",
    "subjectableSubTypeContainer",
    "aggregateFundDetailOptionsContainer",
  ];

  connect() {
    this.subjectableTypeSelectTarget.addEventListener("change", () => {
      this.toggleSubTypeSelect();
      this.toggleInjectSubjectableOptions();
    });
    this.subjectableTypeSelectTarget.dispatchEvent(new Event("change"));
  }

  toggleSubTypeSelect() {
    if (this.subjectableTypeSelectTarget.value === "PipelineProspect") {
      this.subjectableSubTypeContainerTarget.classList.remove("hidden");
    } else {
      this.subjectableSubTypeContainerTarget.classList.add("hidden");
    }
  }

  toggleInjectSubjectableOptions() {
    console.log(this.subjectableTypeSelectTarget.value);

    if (this.subjectableTypeSelectTarget.value === "FirmAdmin::FundDetail") {
      this.aggregateFundDetailOptionsContainerTarget.classList.remove("hidden");
    } else {
      this.aggregateFundDetailOptionsContainerTarget.classList.add("hidden");
    }
  }
}
