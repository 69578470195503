import { Controller } from "stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = {
    ahoyDocumentPreviewName: String,
    ahoyHyperlinkClickedName: String,
    ahoyFolderFileId: Number,
    ahoyPage: Number,
    ahoyPersonId: Number,
    ahoyAccountId: Number,
    webTrackingUserId: Number,
  };

  static targets = ["timer", "modal"];

  connect() {
    // page loaded event
    this.trackPageLoad();

    this.onVisibilityChange = () => this.tabChangeEventListener();
    this.onPdfPageChange = (event) => this.changePdfPageValue(event);

    // active or inactive tab listener
    document.addEventListener("visibilitychange", this.onVisibilityChange);
    document.addEventListener("pdf-page-change", this.onPdfPageChange);
    document.addEventListener("pdf-hyperlink-open", (event) => this.trackHyperlinkClick(event));

    // Reset timer on page activity
    document.addEventListener("mousemove", () => this.resetTimer());
    document.addEventListener("click", () => this.resetTimer());
    document.addEventListener("scroll", () => this.resetTimer());
    document.addEventListener("keydown", () => this.resetTimer());

    // page leave listener
    window.onbeforeunload = (e) => {
      if (e.target?.activeElement?.id == "download_link") return;

      document.removeEventListener("visibilitychange", this.onVisibilityChange);
      this.trackPageExit();
    };

    this.startTimer();
  }

  disconnect() {
    window.onbeforeunload = () => {};
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
    clearInterval(this.timer);

    this.trackPageExit();
  }

  tabChangeEventListener() {
    if (document.visibilityState == "visible") {
      this.startTimer();
      this.trackPageActive();
    } else {
      this.stopTimer();
      this.trackPageInactive();
    }
  }

  startTimer() {
    this.time = 270;

    this.timer = setInterval(() => {
      if (this.time === 30) {
        this.modalTarget.classList.remove("hidden");
      }

      if (this.time === 0) {
        clearInterval(this.timer);
        this.trackPageRedirect();
        window.location.href = "/";
      }

      const date = new Date(0);
      date.setSeconds(this.time);

      this.timerTarget.innerHTML = `You will be redirected in <span class="font-bold text-primary-500">${date
        .toISOString()
        .substr(14, 5)}</span>`;

      this.time--;
    }, 1000);
  }

  resetTimer() {
    this.stopTimer();
    this.startTimer();
  }

  stopTimer() {
    clearInterval(this.timer);
    this.modalTarget.classList.add("hidden");
  }

  trackPageLoad() {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyDocumentPreviewNameValue, {
      page: this.ahoyPageValue,
      page_loaded: true,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "page_loaded",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      null
    );
  }

  trackPageExit() {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyDocumentPreviewNameValue, {
      page: this.ahoyPageValue,
      page_left: true,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "page_left",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      null
    );
  }

  trackPageActive() {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyDocumentPreviewNameValue, {
      page: this.ahoyPageValue,
      page_active: true,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "page_active",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      null
    );
  }

  trackPageInactive() {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyDocumentPreviewNameValue, {
      page: this.ahoyPageValue,
      page_active: false,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "page_inactive",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      null
    );
  }

  trackPageRedirect() {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyDocumentPreviewNameValue, {
      page: this.ahoyPageValue,
      inactive_redirected: true,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "inactive_redirected",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      null
    );
  }

  changePdfPageValue(event) {
    this.trackPageExit();

    this.ahoyPageValue = event.detail.pageNumber;

    setTimeout(() => {
      this.trackPageLoad();
    }, 5);
  }

  trackHyperlinkClick(event) {
    const current_datetime = new Date().toISOString();
    ahoy.track(this.ahoyHyperlinkClickedNameValue, {
      page: event.detail.pageNumber,
      url: event.detail.url,
      person_id: parseInt(this.ahoyPersonIdValue),
      folder_file_id: this.ahoyFolderFileIdValue,
      account_id: this.ahoyAccountIdValue,
      time: current_datetime,
    });
    this.trackWebTrackingEvent(
      "hyperlink_clicked",
      current_datetime,
      this.ahoyPageValue,
      this.ahoyAccountIdValue,
      this.ahoyFolderFileIdValue,
      this.webTrackingUserIdValue,
      event.detail.url
    );
  }

  trackWebTrackingEvent(
    eventType,
    occurredAt,
    pageNumber,
    accountId,
    dataRoomFolderFileId,
    userId,
    hyperlinkClickedUrl
  ) {
    fetch("/web_tracking/document_previews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        document_preview: {
          event_type: eventType,
          occurred_at: occurredAt,
          page_number: pageNumber,
          account_id: accountId,
          data_room_folder_file_id: dataRoomFolderFileId,
          user_id: userId ? userId : null,
          clicked_url: hyperlinkClickedUrl ? hyperlinkClickedUrl : null,
          person_id: parseInt(this.ahoyPersonIdValue),
          ahoy_visit_token: ahoy.getVisitId(),
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Event logged successfully");
        } else {
          console.error("Error logging event:", data.errors);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  }
}
