import { Controller } from "stimulus";

export default class extends Controller {
  static values = { step: Number };

  connect() {
    this.element.addEventListener("keydown", this.customStep);
  }

  disconnect(){
    this.element.removeEventListener("keydown", this.customStep);
  }

  customStep = (event) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.element.value = (parseFloat(this.element.value) + this.stepValue).toFixed(2);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.element.value = (parseFloat(this.element.value) - this.stepValue).toFixed(2);
    }
  }
}
