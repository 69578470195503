import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "button"];

  connect() {
    this.buttonTarget.disabled = true;
    this.initialValue = this.fieldTarget.value;
  }

  checkUpdate() {
    this.buttonTarget.disabled = this.initialValue === this.fieldTarget.value;
  }
}
