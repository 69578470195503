import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.loadSelection();
  }

  loadSelection() {
    const savedPipelineId = localStorage.getItem('chromeExtensionPipelineId');
    if (savedPipelineId && this.hasSelectTarget) {
      this.selectTarget.value = savedPipelineId;
    }
  }

  saveSelection() {
    const selectedId = this.selectTarget.value;
    localStorage.setItem('chromeExtensionPipelineId', selectedId);
  }
}
