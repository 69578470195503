import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.trixEditor = this.element.querySelector("trix-editor");
    this.trixEditor.addEventListener("trix-initialize", this.initializeEditor.bind(this));
  }

  disconnect() {
    this.trixEditor.removeEventListener("paste", this.pasteHandler.bind(this));
  }

  initializeEditor() {
    this.editor = this.trixEditor.editor;
    this.trixEditor.addEventListener("paste", this.pasteHandler.bind(this));
  }

  pasteHandler(event) {
    const pastedText = event.clipboardData?.getData?.("Text");
    if (
      !!pastedText &&
      !!pastedText.match(/^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/gi)
    ) {
      event.preventDefault();
      this.pasteUrl(pastedText);
    }
  }

  pasteUrl(pastedText) {
    const editor = this.editor;
    let currentText = editor.getDocument().toString();
    let currentSelection = editor.getSelectedRange();
    let textWeAreInterestedIn = currentText.substring(0, currentSelection[0]);
    let startOfPastedText = textWeAreInterestedIn.lastIndexOf(pastedText);
    editor.recordUndoEntry("Auto Link Paste");
    editor.setSelectedRange([startOfPastedText, currentSelection[0]]);
    editor.activateAttribute("href", pastedText);
    editor.activateAttribute("target", "_blank"); // Add this line
    editor.setSelectedRange(currentSelection);
  }
}
