import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "input", "button", "buttonsContainer"];

  static values = {
    showForm: Boolean,
  };

  connect() {
    this.buttonTargets.forEach((button) => {
      button.addEventListener("click", this.toggle.bind(this));
    });

    this.showFormState = this.showFormValue || false;

    if (this.showFormState) {
      this.inputTarget.classList.add("hidden");
      this.textTarget.classList.remove("hidden");
    }
  }

  disconnect() {
    this.buttonTargets.forEach((button) => {
      button.removeEventListener("click", this.toggle.bind(this));
    });
  }

  hideButtons() {
    this.buttonTargets.forEach((button) => {
      if (button.dataset.keepVisible != "true") {
        button.classList.add("hidden");
      }
    });

    if (this.hasButtonsContainerTarget) {
      this.buttonsContainerTarget.classList.add("hidden");
    }
  }

  showButtons() {
    this.buttonTargets.forEach((button) => {
      button.classList.remove("hidden");
    });

    if (this.hasButtonsContainerTarget) {
      this.buttonsContainerTarget.classList.remove("hidden");
    }
  }

  toggleText(show) {
    if (this.hasTextTarget) {
      show
        ? this.textTarget.classList.remove("hidden")
        : this.textTarget.classList.add("hidden");
    }
  }

  toggleInput(show) {
    if (this.hasInputTarget) {
      show
        ? this.inputTarget.classList.remove("hidden")
        : this.inputTarget.classList.add("hidden");
    }
  }

  toggle() {
    if (this.showFormState) {
      this.toggleInput(false);
      this.toggleText(true);

      this.showButtons();

      this.showFormState = false;
    } else {
      this.toggleInput(true);
      this.toggleText(false);

      this.hideButtons();

      this.showFormState = true;
    }
  }
}
