import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actions"];

  toggleActions(e) {
    e.stopPropagation();
    this.actionsTarget.classList.toggle("hidden");
  }

  hideActions() {
    this.actionsTarget.classList.add("hidden");
  }
}
