import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "investorTypeSelect",
    "organizationContainer",
    "personContainer",
    "organizationLabel",
    "organizationInput",
    "personLabel",
    "personInput",
    "personIdField",
    "organizationIdField",
    "resetPersonButton",
    "investmentContainer",
    "investmentSelect",
    "amountInvestedInput",
    "amountInvestedContainer",
  ];

  static values = { entity: String };

  connect() {
    this.childIndex = this.element.dataset.childIndex;

    this.setSharesInputChildIndex();

    this.investorTypeSelectTarget.addEventListener("change", this.chooseInvestorType.bind(this));
    this.organizationInputTarget.addEventListener("change", this.clearPersonInput.bind(this));
    this.organizationIdFieldTarget.addEventListener("change", this.setOrganizationToSearch.bind(this));
    this.investmentSelectTarget.addEventListener("change", this.setSharesInput.bind(this));
    this.investmentSelectTarget.dispatchEvent(new Event("change"));
  }

  setSharesInputChildIndex() {
    const id = "shares-input";
    const sharesInput = document.getElementById(id);
    sharesInput.id = `${id}-${this.childIndex}`;
  }

  setSharesInput(e) {
    if (this.investorTypeSelectTarget.value !== "current_firm") return;

    const id = e.target.value || null;
    const url = `/firm_admin/entities/${this.entityValue}/portfolio_company_investments/${id}/set_shares_input/?child_index=${this.childIndex}`;

    get(url, { responseKind: "turbo-stream" });
  }

  chooseInvestorType(e) {
    const chosenType = e.target.value;
    switch (chosenType) {
      case "angel":
        this.hideOrganization();
        this.setPersonLabel("Name of Angel");
        this.showPerson();
        this.resetOrganizationToSearch();
        this.hideInvestment();
        break;
      case "venture_capital":
        this.setPersonLabel("Name of lead investor");
        this.setOrganizationLabel("Name of VC Firm");
        this.showPerson();
        this.showOrganization();
        this.hideInvestment();
        break;
      case "private_equity":
        this.setPersonLabel("Name of lead investor");
        this.setOrganizationLabel("Name of PE firm");
        this.showPerson();
        this.showOrganization();
        this.hideInvestment();
        break;
      case "bank":
        this.setPersonLabel("Name of lead banker");
        this.setOrganizationLabel("Name of bank");
        this.showPerson();
        this.showOrganization();
        this.hideInvestment();
        break;
      case "current_firm":
        this.hidePerson();
        this.hideOrganization();
        this.showInvestment();
        break;
      default:
        this.resetOrganizationToSearch();
        this.hidePerson();
        this.hideOrganization();
        this.hideInvestment();
    }
  }

  setOrganizationToSearch(e) {
    this.personInputTarget.dataset.organizationId = e.target.value;
  }
  resetOrganizationToSearch() {
    delete this.personInputTarget.dataset.organizationId;
  }
  clearPersonInput(e) {
    this.resetPersonButtonTarget.click();
  }
  showOrganization() {
    this.organizationContainerTarget.classList.remove("hidden");
  }
  hideOrganization() {
    this.organizationContainerTarget.classList.add("hidden");
  }
  showPerson() {
    this.personContainerTarget.classList.remove("hidden");
  }
  hidePerson() {
    this.personContainerTarget.classList.add("hidden");
  }
  setPersonLabel(label) {
    this.personLabelTarget.innerHTML = label;
  }
  setOrganizationLabel(label) {
    this.organizationLabelTarget.innerHTML = label;
  }
  showInvestment() {
    this.investmentContainerTarget.classList.remove("hidden");
    this.investmentSelectTarget.disabled = false;
    this.amountInvestedInputTarget.disabled = true;
    this.amountInvestedInputTarget.classList.add("hidden");
    this.amountInvestedContainerTarget.classList.add("hidden");
    this.investmentSelectTarget.dispatchEvent(new Event("change"));
  }
  hideInvestment() {
    this.investmentContainerTarget.classList.add("hidden");
    this.investmentSelectTarget.disabled = true;
    this.amountInvestedInputTarget.disabled = false;
    this.amountInvestedInputTarget.classList.remove("hidden");
    this.amountInvestedContainerTarget.classList.remove("hidden");
  }

  hideConditionalSecurities() {
    this.hideValuationType();
    this.hideValuationCapDiscount();
    this.hidePreMoneyPrice();
    this.hidePostMoneyValuation();
  }
}
