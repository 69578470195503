import { Controller } from "stimulus";
import "./prism/prism.js";
import tippy from "tippy.js";
import "./prism/prism.css";
import "./prism/highlighter.css";
import "tippy.js/dist/tippy.css";

export default class extends Controller {
  static targets = ["input"];
  static values = { validIdentifiers: Array, validIdentifiersDescription: Object };

  initialize() {
    Prism.languages["conditionals_expression"] = {
      number: {
        pattern: /\b(?:\d+|\d+\.\d+)(?:[Ee][+-]?\d+)?/,
        alias: "builtin",
      },
      string: {
        pattern: /"(?:[^"\\]|\\.)*"/,
        greedy: true,
        alias: "string",
      },
      boolean: {
        pattern: /(?:true|false)/,
        alias: "keyword",
      },
      identifier: {
        pattern: new RegExp("\\b(?:" + this.validIdentifiersValue.join("|").replace(/\./g, "\\$&") + ")\\b"),
        alias: "variable",
      },
      keyword: {
        // and or in not in && ||
        pattern: /\b(?:and|or|in|not_in|&&|\|\|)\b/,
        alias: "keyword",
      },
      operator: {
        pattern: /(?:==|!=|<=|>=|<|>|\+|-|\*|\/)/,
        alias: "operator",
      },
      parenthesis: {
        pattern: /[\(\)]/,
        alias: "punctuation",
      },
      space: {
        pattern: /\s+/,
        alias: "punctuation", // Or any preferred alias for whitespace
      },
      unknown_identifier: {
        // not found on the list of identifiers
        pattern: /\b[a-zA-Z_]\w*\b/,
        alias: "invalid_identifier",
      },
    };
  }

  highlight(event) {
    this.#updateCodeElementContent(event.target.value);
  }

  connect() {
    const tippyPlaceholder = document.createElement("div");
    tippyPlaceholder.style.cursor = "pointer";

    const content = this.validIdentifiersValue
      .map(function (identifier) {
        const namespacedIdentifier = identifier.split(".");
        const item = this.validIdentifiersDescriptionValue[namespacedIdentifier[0]].find(
          (item) => item.path === namespacedIdentifier[1]
        );
        return `<li><div class='variable-name'>${identifier}</div> <div class='variable-description'>${item.description}</div></li>`;
      }, this)
      .join("");

    // SVG for question mark icon
    tippyPlaceholder.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-question" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#87bd4d" fill="none" stroke-linecap="round" stroke-linejoin="round"> <circle cx="12" cy="12" r="10" /> <line x1="12" y1="16" x2="12" y2="12" /> <line x1="12" y1="8" x2="12.01" y2="8" /> </svg>`;
    this.element.insertAdjacentElement("afterend", tippyPlaceholder);
    this.tippyElem = tippy(tippyPlaceholder, {
      content: `<div class="tippy-tooltip-white"><div class="tooltip-heading"><h3>Variables</h3><div class="tooltip-close" id="closeTooltip"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.5 3.5L3.5 12.5" stroke="#D4D4D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 12.5L3.5 3.5" stroke="#D4D4D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></div></div><div class="tooltip-body"><ul class="variable-list">${content}</ul></div></div>`,
      placement: "right",
      allowHTML: true,
      arrow: true,
      boundary: "viewport",
      maxWidth: "500px",
      trigger: "click",
      hideOnClick: false,
      interactive: "true",
      theme: "custom",
    });

    this.tippyElem.popper.querySelector("#closeTooltip").addEventListener(
      "click",
      function () {
        this.tippyElem.hide();
      }.bind(this)
    );

    this.tippyElem.popper.querySelectorAll(".variable-name").forEach((element) => {
      element.addEventListener(
        "click",
        function (event) {
          const currentInputValue = this.inputTarget.value;
          const identifier = event.target.textContent;
          if (currentInputValue.length > 0 && !/\s+$/.test(currentInputValue)) {
            this.inputTarget.value = this.inputTarget.value + " ";
          }
          this.inputTarget.value = this.inputTarget.value + identifier;
          this.inputTarget.focus();
          this.#updateCodeElementContent(this.inputTarget.value);
        }.bind(this)
      );
    });
  }

  disconnect() {
    this.tippy?.destroy();
    this.tippyElem?.destroy();
    this.tippyElem = null;
  }

  inputTargetConnected(element) {
    this.#updateCodeElementContent(element.value);
  }

  #updateCodeElementContent(expression) {
    const html = Prism.highlight(expression, Prism.languages.conditionals_expression, "conditionals_expression");
    const codeTemplate = `<pre class="language-conditionals_expression match-braces rainbow-braces no-brace-hover"><code>${html}</code></pre>`;
    let divElement = this.element.querySelector("div");
    if (divElement) {
      divElement.remove();
    }
    if (expression !== "" && expression !== null) {
      divElement = document.createElement("div");
      divElement.addEventListener(
        "scroll",
        function (event) {
          this.#syncInputScroll(event);
        }.bind(this)
      );

      // add id to div
      divElement.id = "highlighting";
      this.inputTarget.id = "editing";

      divElement.innerHTML = codeTemplate;
      this.element.appendChild(divElement);
      // Force Prism to run complete hook, this runs only the first time
      // this is a workaround to run the complete hook after the code is rendered, so the match-braces plugin can work on expression updates
      Prism.hooks.run("complete", { element: this.element.querySelector("code"), language: "conditionals_expression" });

      const rect = this.inputTarget.getBoundingClientRect();
      this.element.style = `position: relative; height: ${rect.height}px`;
      this.#syncHighlightingScroll();
    }
  }

  #syncInputScroll(event) {
    const highlight = this.element.querySelector("#highlighting");

    this.inputTarget.scrollTop = highlight.scrollTop;
    this.inputTarget.scrollLeft = highlight.scrollLeft;
  }

  #syncHighlightingScroll() {
    const highlight = this.element.querySelector("#highlighting");
    highlight.scrollTop = this.inputTarget.scrollTop;
    highlight.scrollLeft = this.inputTarget.scrollLeft;
  }
}
