import { Controller } from "stimulus";
import { get, put } from "@rails/request.js";
import tippy from "tippy.js";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["closeDate"];
  static values = { entity: String };

  connect() {
    this.setupTooltip();
  }

  setupTooltip() {
    this.tippyInstance = tippy(this.closeDateTarget, {
      content:
        "<div class='bg-white p-4 shadow-dropdown rounded-sm flex items-center gap-1'><svg class='loading-spin h-4 w-4 text-primary-400' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'><circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokewidth='4'></circle><path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path></svg>Loading...</div>",
      interactive: true,
      trigger: "mouseenter",
      onShow: debounce(this.fetchTooltipContent.bind(this), 300),
      allowHTML: true,
      theme: "custom",
      placement: "top",
    });
  }

  async fetchTooltipContent() {
    const closeDate = this.closeDateTarget.dataset.closeDate;
    try {
      const response = await get(
        `/firm_admin/entities/${this.entityValue}/fund_close_dates/${closeDate}/close_date_info_tooltip`
      );
      const html = await response.text;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      const tooltipContent = tempElement.querySelector(".tooltip-content").outerHTML;
      this.tippyInstance.setContent(tooltipContent);
    } catch (error) {
      console.error("Error fetching tooltip content:", error);
      this.tippyInstance.setContent("Error loading tooltip content");
    }
  }
}
