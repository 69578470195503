import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String };

  connect() {
    if (this.urlValue) {
      window.location.replace(this.urlValue);
    } else {
      window.location.reload();
    }
  }
}
