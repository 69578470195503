import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const form = this.element;
    form.addEventListener("submit", function (event) {
      event.preventDefault();

      setTimeout(function () {
        form.submit();
      }, 300);
    });
  }
}
