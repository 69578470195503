import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    removeQueryParameters: Boolean,
  };
  static targets = ["writeHistoryLinks"];

  connect() {
    if (this.removeQueryParametersValue) {
      this.removeQueryParameters();
    }
    
    this.writeHistoryLinks();
  }

  removeQueryParameters() {
    const url = window.location.href;
    const cleanUrl = url.split("?")[0];
    window.history.pushState({ url: url }, document.title, cleanUrl);
  }

  writeHistoryLinks() {
    this.writeHistoryLinksTargets.forEach((link) => {
      link.addEventListener("click", (event) => {
        const url = link.href;
        const title = link.title;
        window.history.pushState({ url: url }, title, url);
      });
    });
  }
}
