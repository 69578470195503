import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["text", "image", "openInTabButton"]; // Added "openInTabButton" target
  observer = null;

  connect() {
    this.initObserver();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  initObserver() {
    this.observer = new MutationObserver(() => {
      this.generateQRCode();
    });
    this.observer.observe(this.textTarget, {
      characterData: true,
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  openInTab(event) {
    const imgElement = this.imageTarget.querySelector("img");
    const currentImageUrl = imgElement.src;

    if (currentImageUrl.startsWith("data:")) {
      const blob = this.dataURLToBlob(currentImageUrl);
      const blobUrl = URL.createObjectURL(blob);

      let a = document.createElement("a");
      a.href = blobUrl;
      a.download = "QRCode.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } else {
      window.open(currentImageUrl, "_blank");
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  async generateQRCode() {
    const rawText = this.textTarget.textContent;
    const sanitizedText = this.sanitizeText(rawText);

    try {
      const response = await this.fetchQRCode(sanitizedText);

      if (response.ok) {
        const jsonData = await response.response.json();
        this.updateImageSrc(jsonData.qr_code);
      } else {
        console.error("Error generating QR code:", response);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }

  sanitizeText(text) {
    let sanitized = text.trim().replace(/\s\s+/g, " ");
    return sanitized.replace(/\s*&\s*/g, "&");
  }

  async fetchQRCode(text) {
    return await post("/qr_codes/generate", {
      contentType: "application/json",
      body: JSON.stringify({ text }),
    });
  }

  updateImageSrc(base64Data) {
    const imgElement = this.imageTarget.querySelector("img");
    let newSrc;
    if (base64Data.startsWith("data:image/png;base64,")) {
      newSrc = base64Data;
    } else {
      newSrc = `data:image/png;base64,${base64Data}`;
    }
    imgElement.src = newSrc;
  }
}
