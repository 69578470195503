import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("img").forEach((img) => {
      img.classList.add("glightbox");

      if (img.src.includes("/representations/redirect/")) {
        img.src = img.src.replace("/representations/redirect/", "/blobs/redirect/");
      }
    });
  }
}
