import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "result"];

  connect() {
    this.text = this.textTarget.innerText.trim();
    this.resultTarget.innerHTML = "<span data-text-content></span><span class='blinking-cursor'>|</span>";
    this.textContent = this.resultTarget.querySelector("[data-text-content]");
    this.type();
  }

  type() {
    this.index = 0;
    this.updateChar();
  }

  updateChar() {
    setTimeout(() => {
      if (this.index < this.text.length) {
        this.textContent.innerHTML += this.text.charAt(this.index);
        this.index++;
        this.updateChar();
      }
    }, this.speed());
  }

  speed() {
    return Math.floor(Math.random() * 40) + 20;
  }
}
