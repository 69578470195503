import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "enabledCheckBox",
    "toneSelect",
    "languageSelect",
    "directiveTextArea",
    "customizationContainer",
    "directiveTextAreaContainer",
    "directiveButtonsContainer",
  ];
  static values = {
    showPromptUrl: String,
  };
  primaryPerson = null;

  connect() {
    this.toggleInputFields();

    this.enabledCheckBoxTarget.addEventListener("change", (e) => {
      this.toggleInputFields();

      // If enabled is unchecked, return to the default template
      if (document.querySelector("[data-controller='quick-emails']") && !this.enabledCheckBoxTarget.checked) {
        this.quickEmailsController().updateTemplate(e);
      } else if (this.directiveTextAreaTarget.value.length > 0) {
        this.invalidateEmailBody();
        this.quickEmailsController().updateTemplate(e, true);
      }
    });

    if (this.hasToneSelectTarget) {
      this.toneSelectTarget.addEventListener("change", (e) => {
        if (this.directiveTextAreaTarget.value.length > 0) {
          this.invalidateEmailBody();
          this.quickEmailsController().updateTemplate(e, true);
        }
      });
    }

    if (this.hasLanguageSelectTarget) {
      this.languageSelectTarget.addEventListener("change", (e) => {
        if (this.directiveTextAreaTarget.value.length > 0) {
          this.invalidateEmailBody();
          this.quickEmailsController().updateTemplate(e, true);
        }
      });
    }
  }

  quickEmailsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='quick-emails']"),
      "quick-emails"
    );
  }

  invalidateEmailBody() {
    document.querySelector("#generate-email-body-cover")?.classList.remove("hidden");
  }

  async showPrompt() {
    const form = this.element.closest("form");
    const formData = new FormData(form);

    await post(this.showPromptUrlValue, {
      body: formData,
      responseKind: "turbo-stream",
    });
  }

  toggleInputFields() {
    if (this.enabledCheckBoxTarget.checked) {
      this.customizationContainerTarget.classList.remove("disabled");
      this.directiveTextAreaContainerTarget.classList.remove("disabled");
      this.directiveButtonsContainerTarget.classList.remove("disabled");
    } else {
      this.customizationContainerTarget.classList.add("disabled");
      this.directiveTextAreaContainerTarget.classList.add("disabled");
      this.directiveButtonsContainerTarget.classList.add("disabled");
    }
  }
}
