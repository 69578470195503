import { Controller } from "stimulus";
import { post } from "@rails/request.js";
import { throttle } from "~/utils/lodashish";
export default class extends Controller {
  static targets = ["handle", "cell"];

  static values = {
    submitUrl: String,
    defaultWidth: Number,
    currentWidth: Number,
  };

  connect() {
    this.cellId = this.setCellId();
    this.resizeDelay = 10;
    this.maxWidthAllowed = 2000;
    this.addTransparentDragImage();
    this.throttleResize = throttle(this.resize.bind(this), this.resizeDelay).bind(this);
    this.handleTarget.addEventListener("dragstart", this.dragStart.bind(this));
    this.handleTarget.addEventListener("drag", this.throttleResize.bind(this));
    this.handleTarget.addEventListener("dragend", this.dragEnd.bind(this));
    this.handleTarget.addEventListener("mouseenter", this.mouseEnterHandle.bind(this));
    this.handleTarget.addEventListener("mouseleave", this.mouseLeaveHandle.bind(this));
  }

  disconnect() {
    this.handleTarget.removeEventListener("dragstart", this.dragStart.bind(this));
    this.handleTarget.removeEventListener("drag", this.throttleResize.bind(this));
    this.handleTarget.removeEventListener("dragend", this.dragEnd.bind(this));
    this.handleTarget.removeEventListener("mouseenter", this.mouseEnterHandle.bind(this));
    this.handleTarget.removeEventListener("mouseleave", this.mouseLeaveHandle.bind(this));
  }

  mouseEnterHandle() {
    this.toggleCellResizeIndicators(true);
  }

  mouseLeaveHandle() {
    if (!this.dragging) this.toggleCellResizeIndicators(false);
  }

  dragStart(event) {
    this.dragging = true;
    this.toggleCellResizeIndicators(true);
    this.setTransparentDragImage(event);
  }

  dragEnd(event) {
    this.dragging = false;
    this.toggleCellResizeIndicators(false);
    this.submitResize(event);
  }

  toggleCellResizeIndicators(activate = false) {
    const cells = document.querySelectorAll(`[data-cell-id='${this.cellId}']`);
    let indicators = [];
    cells.forEach((cell) => indicators.push(cell.querySelector(".tr-resize-indicator")));
    indicators = indicators.filter((indicator) => !!indicator);
    indicators.forEach((indicator) => {
      activate ? indicator.classList.remove("hidden") : indicator.classList.add("hidden");
    });
  }

  addTransparentDragImage() {
    const dragIcon = document.createElement("img");
    dragIcon.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
    const div = document.createElement("div");
    div.appendChild(dragIcon);
    div.style.position = "absolute";
    document.querySelector("body").appendChild(div);
    this.transparentImageDiv = div;
  }

  setTransparentDragImage(event) {
    event.dataTransfer.setDragImage(this.transparentImageDiv, 0, 0);
  }

  setCellId() {
    return this.cellTarget.getAttribute("data-pipelines--pipeline-column-id-value");
  }

  getWidth(event) {
    const { clientX } = event;
    const { left } = this.cellTarget.getBoundingClientRect();
    return clientX - left;
  }

  resize = (event) => {
    const newWidth = this.getWidth(event);

    if (newWidth >= this.defaultWidthValue) this.setWidths(newWidth);
  };

  setWidths(width) {
    this.cellTarget.style.width = `${width}px`;
    this.allCells().forEach((cell) => {
      cell.style.width = `${width}px`;
    });
  }

  allCells() {
    return document.querySelectorAll(`[data-cell-id="${this.cellId}"`);
  }

  submitResize(event) {
    const newWidth = this.getWidth(event);

    post(this.submitUrlValue, {
      body: { cell_id: this.cellId, cell_width: this.sanitizeWidth(newWidth) },
    });
  }

  sanitizeWidth(width) {
    if (width < this.defaultWidthValue) width = this.defaultWidthValue;
    if (width > this.maxWidthAllowed) width = this.maxWidthAllowed;

    return width;
  }
}
