import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    requestUrl: String,
  };

  connect() {
    this.selectTarget.addEventListener("change", this.selectRequest.bind(this));
  }

  selectRequest() {
    const selectedOption = this.selectTarget.selectedOptions[0].value;
    get(`${this.requestUrlValue}?selected_option=${encodeURIComponent(selectedOption)}`, {
      responseKind: "turbo-stream",
    });
  }
}
