import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { epoch: Number };

  connect() {
    this.convertTime();
  }

  convertTime() {
    const date = new Date(this.epochValue * 1000);

    if (date.toDateString() === new Date().toDateString()) {
      // Today
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // 24-hour format
      });

      this.element.textContent = `Today, ${time}`;
    } else if (date.toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
      // Yesterday
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // 24-hour format
      });

      this.element.textContent = `Yesterday, ${time}`;
    } else {
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        month: "long",
        day: "2-digit",
        hour12: true, // 24-hour format
      });

      this.element.textContent = time;
    }
  }
}
