import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["staticNavTrigger", "staticNav"];

  connect() {
    this.staticNavTriggerTarget.addEventListener(
      "click",
      this.toggleStaticNav.bind(this)
    );
  }

  toggleStaticNav() {
    this.staticNavTarget.classList.toggle("active");
    this.staticNavTriggerTarget.classList.toggle("active");
  }
}
