import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, loadOnConnect: Boolean };
  static targets = ["container"];

  connect() {
    if (this.loadOnConnectValue) this.load();
  }

  load() {
    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.containerTarget.innerHTML = html;
        this.runScriptLoadedContent(html);
      });
  }

  runScriptLoadedContent(html) {
    const ele = document.createElement("div");
    ele.innerHTML = html;
    ele.querySelectorAll("script.execute-after-load").forEach((scriptEle) => {
      eval(scriptEle.innerHTML);
    });
  }
}
