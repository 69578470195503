import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    urlPath: String
  }

  download() {
    Rails.ajax({
      type: "POST",
      url: this.urlPathValue,
    });
  }
}
