import { Controller } from "stimulus";
export default class extends Controller {
  goBack(event) {
    const element = event.currentTarget;
    const url = element.dataset.backUrl;

    if (window.history.length > 2) {
      window.history.back();
    } else {
      if (url) {
        window.location = url;
      }
    }
  }
}
