import { Controller } from "stimulus";
import { Swiper } from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      watchOverflow: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: ".base-swiper-next",
        prevEl: ".base-swiper-prev",
      },
      allowSlideNext: true,
      allowSlidePrev: true,
      autoplay: {
        delay: 2000,
        pauseOnMouseEnter: true,
      },
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        2000: {
          slidesPerView: 5,
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
