import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "redirectToggle",
    "redirectField",
    "thankYouContainer",
    "redirectContainer",
  ];

  connect() {
    this.showHideContainers();

    this.redirectToggleTarget.addEventListener(
      "click",
      this.showHideContainers.bind(this)
    );
  }

  showHideContainers() {
    const redirectEnabled = this.redirectFieldTarget.checked;

    if (redirectEnabled) {
      this.thankYouContainerTarget.classList.add("hidden");
      this.redirectContainerTarget.classList.remove("hidden");
    } else {
      this.thankYouContainerTarget.classList.remove("hidden");
      this.redirectContainerTarget.classList.add("hidden");
    }
  }
}
