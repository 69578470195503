import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "periodType",
    "customStartDate",
    "customEndDate",
    "capitalAccountId",
    "exportFormat",
    "saveContainer",
  ];

  connect() {
    this.formatChanged();
  }

  save(e) {
    const target = e.currentTarget;
    this.replaceFields(target);
  }

  download(e) {
    const target = e.currentTarget;
    this.replaceFields(target);

    setTimeout(() => {
      target.disabled = false;
    }, 100);
  }

  replaceFields(target) {
    const formFields = target.parentElement.querySelector("[data-fields]");

    const periodTypeInput = this.createHiddenInput("period_type", this.periodTypeTarget.value);
    const customStartDateInput = this.createHiddenInput("custom_start_date", this.customStartDateTarget.value);
    const customEndDateInput = this.createHiddenInput("custom_end_date", this.customEndDateTarget.value);
    const capitalAccountIdInput = this.createHiddenInput("capital_account_id", this.capitalAccountIdTarget.value);
    const exportFormatInput = this.createHiddenInput("export_format", this.exportFormatTarget.value);

    const div = document.createElement("div");
    div.appendChild(periodTypeInput);
    div.appendChild(customStartDateInput);
    div.appendChild(customEndDateInput);
    div.appendChild(capitalAccountIdInput);
    div.appendChild(exportFormatInput);

    formFields.innerHTML = div.innerHTML;
  }

  formatChanged() {
    if (this.exportFormatTarget.value === "pdf") {
      this.saveContainerTarget.classList.remove("hidden");
    } else {
      this.saveContainerTarget.classList.add("hidden");
    }
  }

  createHiddenInput(name, value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  }
}
