import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.editorElement = this.element.querySelector("trix-editor");
    this.addToBody = this.data.get("add-to-body-value");

    this.editorElement.addEventListener("trix-initialize", () => this.initializeTrix());

    // after 4 seconds, set up the listener for the trix-change event, to avoid tripping over events during connection (variable replacement etc)
    setTimeout(() => {
      this.editorElement.addEventListener("trix-change", () => this.dispatchDocumentEmailBodyChangeEvent());
    }, 4000);
  }

  initializeTrix() {
    if (this.addToBody) {
      this.insertTextToEditor(this.addToBody);
    }
  }

  insertTextToEditor(text) {
    const editor = this.editorElement.editor;
    const anchorTag = `<a href="${text}">${text}</a>`;

    editor.setSelectedRange([editor.getDocument().getLength(), editor.getDocument().getLength()]);

    editor.insertHTML(anchorTag);
  }

  dispatchDocumentEmailBodyChangeEvent() {
    const event = new CustomEvent("quick-email-body-change", {
      detail: {},
      bubbles: true,
    });

    document.dispatchEvent(event);
  }
}
