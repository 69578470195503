import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit", "checkbox"];

  static values = {
    howManyRequired: Number,
  };

  connect() {
    this.howManyRequired = this.howManyRequiredValue || 1;

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        this.checkAllCheckboxes();
      });
    });
  }

  disconnect() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.removeEventListener("change", () => {
        this.checkAllCheckboxes();
      });
    });
  }

  checkAllCheckboxes() {
    const checked = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;

    if (checked >= this.howManyRequired) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
