import { Controller } from "stimulus";
import "@yaireo/tagify/dist/tagify.css";
import Tagify from "@yaireo/tagify";
import { delay } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["cell", "rowCheckbox"];
  static values = {
    id: String,
  };

  connect() {
    delay(this.verifyCorrectGroup.bind(this), 1200);

    if (this.hasRowCheckboxTarget) {
      const allGroupChecked =
        this.groupController().hasGroupCheckboxTarget && this.groupController().groupCheckboxTarget.checked;
      const allTableChecked = document.getElementById("select-all-shown")?.checked;

      this.rowCheckboxTarget.checked = allGroupChecked || allTableChecked;
    }
  }

  viewController() {
    const viewElement = this.element.closest("[data-controller='pipelines--pipeline-view']");
    return this.application.getControllerForElementAndIdentifier(viewElement, "pipelines--pipeline-view");
  }

  groupController() {
    const groupElement = this.element.closest("[data-controller='pipelines--pipeline-group']");
    return this.application.getControllerForElementAndIdentifier(groupElement, "pipelines--pipeline-group");
  }

  cellForId(id) {
    return this.cellTargets.find((cell) => {
      return cell.dataset.cellId == id;
    });
  }

  sortingValueForColumn(column) {
    return this.cellForId(column.idValue)?.dataset.sortingValue;
  }

  hide() {
    this.element.classList.add("!hidden");
  }

  show() {
    this.element.classList.remove("!hidden");
  }

  async verifyCorrectGroup() {
    const groupController = this.groupController();
    const groupByColumn = this.viewController()
      ?.columnControllers()
      .find((controller) => {
        return controller.groupbyValue;
      });

    if (!groupByColumn || !groupController) {
      return;
    }

    const groupName = this.sortingValueForColumn(groupByColumn);

    if (groupName !== groupController.groupnameValue && groupName !== groupController.groupnameusValue) {
      this.viewController()?.scheduleGroupUpdate(groupName);
      this.element.remove();
    }
  }

  showInput(event) {
    const cellTarget = event.target.closest("[data-pipelines--pipeline-row-target='cell']");
    const inputContainer = cellTarget.querySelector("[data-cell-target='inputContainer']");
    const inputElement = cellTarget.querySelector("[data-cell-target='inputElement']");
    const tagifyElement = cellTarget.querySelector("[data-cell-target='tagifyElement']");
    const displayContainer = cellTarget.querySelector("[data-cell-target='displayContainer']");

    if (inputContainer) {
      displayContainer.classList.add("hidden");
      inputContainer.classList.remove("hidden");
    }

    if (inputElement) {
      inputElement.focus();
      inputElement.select();
    }

    if (tagifyElement) {
      this.tagifyInput(tagifyElement);
    }
  }

  showDisplay(event) {
    const cellTarget = event.target.closest("[data-pipelines--pipeline-row-target='cell']");
    const inputContainer = cellTarget.querySelector("[data-cell-target='inputContainer']");
    const displayContainer = cellTarget.querySelector("[data-cell-target='displayContainer']");

    inputContainer.classList.add("hidden");
    displayContainer.classList.remove("hidden");
  }

  submitInput(event) {
    const input = event.target;

    const cellTarget = input.closest("[data-pipelines--pipeline-row-target='cell']");
    const inputContainer = cellTarget.querySelector("[data-cell-target='inputContainer']");
    const displayContainer = cellTarget.querySelector("[data-cell-target='displayContainer']");
    const inputElement = cellTarget.querySelector("[data-cell-target='inputElement']");
    const tagifyElement = cellTarget.querySelector("[data-cell-target='tagifyElement']");

    const newValue = inputElement?.value || tagifyElement?.value || "";
    const oldValue = (cellTarget.dataset.sortingValue || "").replace("[Empty]", "");

    if (input.validity.valid) {
      if (newValue != "" && newValue != oldValue) {
        const form = input.closest("form");
        if (displayContainer) displayContainer.innerHTML = inputElement?.value;
        if (event.sourceCapabilities !== null) Rails.fire(form, "submit");
      }
    } else {
      event.preventDefault();
      window.notyf.error(input.validationMessage);
      input.value = oldValue;
    }

    if (displayContainer) {
      inputContainer.classList.add("hidden");
      displayContainer.classList.remove("hidden");
    }
  }

  tagifyInput(inputElement) {
    const tagify = new Tagify(inputElement, {
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(","),
      duplicates: true,
    });
    tagify.on("keydown", (e) => {
      if (e.detail.event.key == "Enter" && !tagify.state.inputText && !tagify.state.editing) {
        setTimeout(() => Rails.fire(inputElement.closest("form"), "submit"));
      }
    });
    tagify.on("remove", (e) => {
      setTimeout(() => Rails.fire(inputElement.closest("form"), "submit"));
    });
    tagify.on("blur", (e) => {
      setTimeout(() => Rails.fire(inputElement.closest("form"), "submit"));
    });
  }
}
