import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "otherTextFieldInput"];
  static values = { required: Boolean };

  connect(){
    this.change();
    this.addEventListeners();
  }

  disconnect(){
    this.removeEventListeners();
  }

  addEventListeners(){
    this.selectTarget.addEventListener("change", this.change.bind(this));
  }

  removeEventListeners(){
    this.selectTarget.removeEventListener("change", this.change.bind(this));
  }

  change() {
    if (!this.hasOtherTextFieldInputTarget) return;

    if (this.selectTarget.value === "other") {
      this.otherTextFieldInputTarget.classList.remove("hidden");
      this.otherTextFieldInputTarget.disabled = false;
      this.otherTextFieldInputTarget.required = this.requiredValue;
    } else {
      this.otherTextFieldInputTarget.classList.add("hidden");
      this.otherTextFieldInputTarget.disabled = true;
      this.otherTextFieldInputTarget.required = false;
    }
  }
}
