import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export default class extends Controller {
  connect() {
    this.prepareDOM();
    this.initIntlTelInput();
  }

  prepareDOM() {
    this.displayElement = document.createElement("input");
    this.displayElement.type = "text";
    this.displayElement.value = this.element.value;
    this.displayElement.classList = this.element.classList;
    this.displayElement.required = this.element.required;
    this.element.insertAdjacentElement("beforeBegin", this.displayElement);
    this.element.classList.add("hidden");
    this.displayElement.addEventListener("blur", this.triggerBlur);
  }

  disconnect() {
    this.displayElement.removeEventListener("blur", this.triggerBlur);
  }

  initIntlTelInput() {
    this.intlTelInput = intlTelInput(this.displayElement, {
      separateDialCode: true,
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
    });

    this.displayElement.addEventListener("countrychange", this.update.bind(this));
    this.displayElement.addEventListener("keyup", this.update.bind(this));
  }

  update() {
    this.element.value = this.intlTelInput.getNumber();
    this.element.dispatchEvent(new Event("input"));
  }

  triggerBlur = () => {
    this.element.dispatchEvent(new Event("blur"));
  };
}
