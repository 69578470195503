import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    periodStart: String,
    periodEnd: String,
    entity: String,
  };
  static targets = ["viewAccounts", "selectAll", "select"];

  connect() {
    this.selectedAccounts = this.selectedAccounts || [];

    this.selectAllTarget.addEventListener("click", this.selectAllTargetClick.bind(this));

    this.selectTargets.forEach((target) => {
      target.addEventListener("click", this.selectTargetClick.bind(this));

      if (target.checked) {
        this.addAccount(target.dataset.accountId);
      }
    });

    this.viewAccountsTarget.addEventListener("click", this.viewAccountsTargetClick.bind(this));

    this.toggleActionButtons();
  }

  selectAllTargetClick(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  selectTargetClick(event) {
    const isChecked = event.target.checked;
    const accountId = event.target.dataset.accountId;

    if (isChecked) {
      this.addAccount(accountId);
    } else {
      this.removeAccount(accountId);
    }

    this.selectAllTarget.checked = this.selectedAccounts.length == this.selectTargets.length;
    this.toggleActionButtons();
    this.toggleCheckboxParentClass(event.target);
  }

  viewAccountsTargetClick(event) {
    event.preventDefault();
    document.location = `/firm_admin/entities/${
      this.entityValue
    }/accounting_transactions?accounting_account_ids=${this.selectedAccounts.join(",")}&period_start=${
      this.periodStartValue
    }&period_end=${this.periodEndValue}`;
  }

  selectAll() {
    this.selectTargets.forEach((target) => {
      target.checked = true;
      this.addAccount(target.dataset.accountId);
      this.toggleCheckboxParentClass(target);
    });

    this.toggleActionButtons();
  }

  deselectAll() {
    this.selectTargets.forEach((target) => {
      target.checked = false;
      this.removeAccount(target.value);
      this.toggleCheckboxParentClass(target);
    });

    this.toggleActionButtons();
  }

  addAccount(accountId) {
    const index = this.selectedAccounts.indexOf(accountId);

    if (index < 0) {
      this.selectedAccounts.push(accountId);
    }
  }

  removeAccount(accountId) {
    const index = this.selectedAccounts.indexOf(accountId);
    this.selectedAccounts.splice(index, 1);
  }

  toggleActionButtons() {
    if (this.selectedAccounts.length < 1) {
      this.viewAccountsTarget.classList.add("hidden");
    } else {
      this.viewAccountsTarget.classList.remove("hidden");
    }
  }

  toggleCheckboxParentClass = (checkbox) => {
    const parentTr = checkbox.closest("tr");

    if (checkbox.checked) {
      parentTr.classList.add("selected");
    } else {
      parentTr.classList.remove("selected");
    }
  };
}
