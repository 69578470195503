import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.initialState = this.containerTarget.innerHTML;
  }

  clearFormInputs() {
    this.containerTarget.innerHTML = this.initialState;
  }
}
