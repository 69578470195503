import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["form"];

  async handleSubmit(event) {
    event.preventDefault();
    const form = this.formTarget;
    const url = form.action;
    const formData = new FormData(form);
    const transactionId = form.getAttribute("data-transaction-id");

    try {
      const response = await post(url, {
        body: formData,
        responseKind: "json",
      });

      if (response.ok) {
        const data = await response.json;
        const transactionId = document.getElementById("counterpartyTypeForm").getAttribute("data-transaction-id");
        this.addOption(data, transactionId);

        const newModalController = document.querySelector('[data-controller="new-modal"]');
        if (newModalController) {
          const newModalInstance = this.application.getControllerForElementAndIdentifier(
            newModalController,
            "new-modal"
          );
          if (newModalInstance && typeof newModalInstance.close_modal === "function") {
            newModalInstance.close_modal();
          }
        }
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  addOption(data, transactionId) {
    if (data.new_option) {
      const whoSelectSelector = `[data-transaction-id="${transactionId}"] [data-firm-admin--accounting-transactions--reconcile-target="whoSelect"]`;
      const whoSelect = document.querySelector(whoSelectSelector);

      if (whoSelect && whoSelect.tomSelect) {
        whoSelect.tomSelect.addOption(data.new_option);
        whoSelect.tomSelect.setValue(data.new_option.value);
        whoSelect.dispatchEvent(new Event("change"));

        // Set the counterparty_type and counterparty_id fields
        const counterpartyTypeSelector = `[data-transaction-id="${transactionId}"] [data-firm-admin--accounting-transactions--reconcile-target="counterpartyType"]`;
        const counterpartyIdSelector = `[data-transaction-id="${transactionId}"] [data-firm-admin--accounting-transactions--reconcile-target="counterpartyId"]`;
        const counterpartyTypeField = document.querySelector(counterpartyTypeSelector);
        const counterpartyIdField = document.querySelector(counterpartyIdSelector);

        if (counterpartyTypeField && counterpartyIdField) {
          counterpartyTypeField.value = data.counterparty_type;
          counterpartyIdField.value = data.id;
        } else {
        }

        const event = new CustomEvent("newEntityCreated", { detail: { ...data, transactionId } });
        document.dispatchEvent(event);
      }

      const modalElement = document.getElementById("counterpartyTypeModal");
      if (modalElement) {
        modalElement.classList.add("hidden");
      }

      if (data.flash && data.flash.notice) {
        window.notyf.success(data.flash.notice);
      }
    }
  }
}
