import { Controller } from "stimulus";

export default class extends Controller {
  static values = { defaultBottom: Boolean };

  connect() {
    const pathName = document.location.pathname;
    const scrollPosition = sessionStorage["scrollPosition_" + pathName];

    if (scrollPosition != undefined) {
      window.scrollTo(0, parseInt(sessionStorage.getItem("scrollPosition_" + pathName)));
    }

    this.intervalRef = setInterval(() => {
      var scrollPosition = window.scrollY;
      sessionStorage.setItem("scrollPosition_" + pathName, scrollPosition);
    }, 1000);
  }

  disconnect() {
    clearInterval(this.intervalRef);
  }
}
