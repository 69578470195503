import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { handle: String };

  connect() {
    this.prepareSortable();

    if (this.element.dataset.updatePositionInputs) this.setSortingPositionOffsetValue();
  }

  prepareSortable() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      dataIdAttr: "data-id",
      ghostClass: "bg-gray-300",
      handle: this.handleValue || ".sortable__handle",
      filter: ".sortable__filtered",
      group: undefined,
      onSort: this.onSort.bind(this)
    });
  }

  onSort() {
    if (this.element.dataset.updatePositionInputs) this.updatePositionInputs();
    this.dispatchSortEvent();
  }

  updatePositionInputs(e) {
    for(let i = 0; i < this.sortableElements().length; i++) {
      const element = this.sortableElements()[i];
      const positionInputName = element.dataset.positionInputName;
      const positionInput = element.querySelector(`[name="${positionInputName}"]`)
      positionInput.value = i + this.sortingPositionOffsetValue;
    }
  }

  dispatchSortEvent() {
    this.element.dispatchEvent(
      new CustomEvent("sortable:sorted", {
        bubbles: true,
        detail: {
          ids: this.sortable.toArray(),
          element: this.element,
        },
      })
    );
  }

  sortingPositionOffset() {
    const firstChild = this.sortableElements()[0];

    if (!firstChild) return;

    const positionInputName = firstChild.dataset.positionInputName;

    if (!positionInputName) return;

    const positionInput = firstChild.querySelector(`[name="${positionInputName}"]`);
    return parseInt(positionInput.value);
  }

  sortableElements() {
    return this.element.querySelectorAll("[data-sortable-element-container]");
  }

  setSortingPositionOffsetValue() {
    this.sortingPositionOffsetValue = this.sortingPositionOffset();
  }
}
