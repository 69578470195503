import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["variableName", "variableValue"];

  connect() {
    setTimeout(() => {
      let subject = document.getElementById("pipeline_email_subject");
      subject.value = subject.value.replace(
        this.variableNameTarget.value,
        this.variableValueTarget.value
      );

      let body = document.getElementById("pipeline_email_body");
      body.value = body.value.replace(
        this.variableNameTarget.value,
        this.variableValueTarget.value
      );

      this.triggerKeyupEvent(body);
    }, 100);
  }

  triggerKeyupEvent(element) {
    const keyupEvent = new KeyboardEvent("keyup", {
      bubbles: true,
      cancelable: true,
      keyCode: 13,
    });
    element.dispatchEvent(keyupEvent);
  }
}
