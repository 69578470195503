import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.markChannelRead = this.markChannelRead.bind(this);
    window.addEventListener("beforeunload", this.markChannelRead);
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.markChannelRead);
  }

  async markChannelRead() {
    await post(this.urlValue, {});
  }
}
