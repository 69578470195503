import { Controller } from "stimulus";

// This script just toggle 2 classes, everytinhg else should be done via css
export default class extends Controller {
  static targets = ["toggle", "select"];

  connect() {
    this.collapsed = true;

    if (this.hasSelectTarget) {
      this.toggleHideShowBasedOnValue(this.selectTarget.value);
    }
  }

  toggleContent(event) {
    event.preventDefault();
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.element.classList.remove("toggle-expanded");
      this.element.classList.add("toggle-collapsed");
    } else {
      this.element.classList.remove("toggle-collapsed");
      this.element.classList.add("toggle-expanded");
    }
  }

  toggleHideShow(event) {
    this.toggleHideShowBasedOnValue(event.currentTarget.value);
  }

  toggleHideShowBasedOnValue(value) {
    if (value === "") {
      this.toggleTarget.classList.add("hidden");
    } else {
      this.toggleTarget.classList.remove("hidden");
    }
  }
}
