import { Controller } from "stimulus";
import { debounce, delay } from "~/utils/lodashish";

export default class extends Controller {
  static targets = [
    "groupHead",
    "groupBody",
    "groupToggle",
    "row",
    "prospectCount",
    "overdueCount",
    "allGroupsToggle",
    "groupCheckbox",
    "totalInvestment",
    "groupEmpty",
  ];
  static values = {
    name: String,
    opened: Boolean,
    groupname: String,
    groupnameus: String,
    pipelineid: Number,
    hideifempty: Boolean,
  };

  initialize() {
    this.updateInProgress = false;
    this.groupUpdateInProgress = function () {
      this.updateInProgress = true;
    };
    this.groupUpdateFinished = function () {
      this.updateInProgress = false;
    };

    this.debounceRefreshGroupHeadline = debounce(this.refreshGroupHeadline, 300).bind(this);
  }

  connect() {
    this.startMutationObserver();

    if (this.hasGroupToggleTarget) {
      this.groupToggleTarget.addEventListener("click", this.toggleGroup.bind(this));
    }

    delay(this.toggleAllGroups.bind(this), 1000);
  }

  disconnect() {
    if (this.hasGroupToggleTarget) {
      this.groupToggleTarget.removeEventListener("click", this.toggleGroup.bind(this));
    }

    this.stopMutationObserver();
  }

  viewController() {
    const viewElement = this.element.closest("[data-controller='pipelines--pipeline-view']");
    return this.application.getControllerForElementAndIdentifier(viewElement, "pipelines--pipeline-view");
  }

  rowControllers() {
    return this.rowTargets.map((rowTarget) => {
      return this.application.getControllerForElementAndIdentifier(rowTarget, "pipelines--pipeline-row");
    });
  }

  startMutationObserver() {
    if (!this.hasGroupBodyTarget) return;

    this._mutationObserver = this._mutationObserver || new MutationObserver(this.observeMutations.bind(this));

    this._mutationObserver.observe(this.groupBodyTarget, {
      attributes: true,
      childList: true,
    });
  }

  stopMutationObserver() {
    if (!this._mutationObserver) return;

    this._mutationObserver.disconnect();
  }

  observeMutations(mutations) {
    const nodesAddedOrRemoved = mutations.filter((mutation) => {
      return mutation.type == "childList" && (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0);
    });

    if (nodesAddedOrRemoved.length == 0 || this.updateInProgress === true) {
      return;
    }

    this.debounceRefreshGroupHeadline();
  }

  async refreshGroupHeadline() {
    if (!this.hasGroupBodyTarget) {
      return;
    }

    const groupTotalsElement = this.groupBodyTarget.querySelector(".group-totals");

    if (!groupTotalsElement) {
      return;
    }

    const hideGroup =
      (this.hideifemptyValue || this.viewController()?.filterColumnControllers()?.length > 0) &&
      this.rowTargets.length < 1;

    if (hideGroup) {
      this.element.classList.add("hidden");
    } else {
      this.element.classList.remove("hidden");
    }

    if (this.rowTargets.length < 1) {
      this.groupEmptyTargets.forEach((groupEmptyTarget) => {
        this.groupEmptyTarget.classList.remove("hidden");
      });
    } else {
      this.groupEmptyTargets.forEach((groupEmptyTarget) => {
        this.groupEmptyTarget.classList.add("hidden");
      });
    }

    if (this.hasGroupCheckboxTarget) {
      this.groupCheckboxTarget.classList.remove("hidden");
    }

    this.updateProspectCount(groupTotalsElement);
    this.updateOverdueCount(groupTotalsElement);
    this.updateTotalInvestment(groupTotalsElement);

    if (this.opened()) {
      this.openGroup();
    } else {
      this.closeGroup();
    }
  }

  toggleAllGroups() {
    const { allGroupsToggleController, groupsControllers } = this.getAllControllers();

    if (this.allGroupsOpen(groupsControllers)) {
      if (allGroupsToggleController) allGroupsToggleController.openGroup();
    } else {
      if (allGroupsToggleController) allGroupsToggleController.closeGroup();
    }
  }

  allGroupsToggleClick() {
    const { allControllers, allGroupsToggleController, groupsControllers } = this.getAllControllers();

    if (this.allGroupsOpen(groupsControllers)) {
      if (allGroupsToggleController) allGroupsToggleController.closeGroup();

      allControllers.forEach((group) => group?.closeGroup());
    } else {
      if (allGroupsToggleController) allGroupsToggleController.openGroup();

      allControllers.forEach((group) => group?.openGroup());
    }
  }

  getAllControllers() {
    const allGroupsToggle = document.querySelector(
      "[data-controller='pipelines--pipeline-group'][data-all-toggle='true']"
    );
    const allGroupsToggleController = this.application.getControllerForElementAndIdentifier(
      allGroupsToggle,
      "pipelines--pipeline-group"
    );

    const groups = Array.from(
      document.querySelectorAll("[data-controller='pipelines--pipeline-group']:not([data-all-toggle='true'])")
    );
    const groupsControllers = groups.map((element) =>
      this.application.getControllerForElementAndIdentifier(element, "pipelines--pipeline-group")
    );
    const allControllers = [allGroupsToggleController, ...groupsControllers].filter((controller) => controller);

    return {
      allGroupsToggleController,
      groupsControllers,
      allControllers,
    };
  }

  allGroupsOpen(groups) {
    return groups.every((group) => group?.opened());
  }

  opened() {
    if (this.groupnameValue === "") {
      return true;
    }

    const storageValue = localStorage.getItem(`${this.pipelineidValue}_${this.groupnameValue}_opened`) || "true";

    return storageValue === "true";
  }

  setOpened(value) {
    localStorage.setItem(`${this.pipelineidValue}_${this.groupnameValue}_opened`, value);
  }

  openGroup() {
    this.setOpened("true");

    this.hasGroupBodyTarget && this.groupBodyTarget.classList.remove("collapsed");
    this.hasGroupToggleTarget && this.setButtonArrow(this.groupToggleTarget, true);
    this.hasAllGroupsToggleTarget && this.setButtonArrow(this.allGroupsToggleTarget, true);
  }

  closeGroup() {
    this.setOpened("false");

    this.hasGroupBodyTarget && this.groupBodyTarget.classList.add("collapsed");
    this.hasGroupToggleTarget && this.setButtonArrow(this.groupToggleTarget, false);
    this.hasAllGroupsToggleTarget && this.setButtonArrow(this.allGroupsToggleTarget, false);
  }

  setButtonArrow(button, opened) {
    if (opened) {
      button.querySelector("i").classList.remove("ph-caret-right-bold");
      button.querySelector("i").classList.add("ph-caret-down-bold");
    } else {
      button.querySelector("i").classList.add("ph-caret-right-bold");
      button.querySelector("i").classList.remove("ph-caret-down-bold");
    }
  }

  toggleGroup() {
    this.opened() ? this.closeGroup() : this.openGroup();
    this.toggleAllGroups();
  }

  removeRow(prospect_id) {
    let removedARow = false;
    this.rowControllers().forEach((row) => {
      if (row.idValue == prospect_id) {
        row.element.remove();
        removedARow = true;
      }
    });

    return removedARow;
  }

  addRow(rowController) {
    this.groupBodyTarget.appendChild(rowController.element);
  }

  updateProspectCount(groupTotalsElement) {
    if (!this.hasProspectCountTarget) {
      return;
    }

    this.prospectCountTarget.innerText = groupTotalsElement.dataset.totalProspects;
  }

  updateOverdueCount(groupTotalsElement) {
    if (!this.hasOverdueCountTarget) {
      return;
    }

    const overdueCount = parseInt(groupTotalsElement.dataset.totalOverdue);

    if (overdueCount > 0) {
      this.overdueCountTarget.classList.remove("hidden");
      this.overdueCountTarget.lastElementChild.innerText = overdueCount;
    } else {
      this.overdueCountTarget.classList.add("hidden");
    }
  }

  updateTotalInvestment(groupTotalsElement) {
    if (!this.hasTotalInvestmentTarget) {
      return;
    }

    const largeCurrencyColumnController = this.viewController()?.largeCurrencyColumnController();

    if (!largeCurrencyColumnController) {
      this.totalInvestmentTarget.classList.add("hidden");
      return;
    }

    this.totalInvestmentTarget.classList.remove("hidden");
    const totalInvestment = parseFloat(groupTotalsElement.dataset.totalCurrency);

    this.totalInvestmentTarget.querySelector(".investment-span").innerText = this.formatInvestment(totalInvestment);
  }

  clearAllGroupOpenValues() {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((cookie) => {
      if (cookie.startsWith("group_value_")) {
        Cookies.remove(cookie);
      }
    });
  }

  formatInvestment(value) {
    const num = Number(value ? value.toString().replace(/[^\d]/g, "") : "");
    const units = ["K", "M", "B"];
    const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
    const remainder = unit % 3;
    const displayNumber = Math.abs(Number(num)) / Number("1.0e+" + (unit - remainder)).toFixed(2);
    const displayText = displayNumber.toFixed(2).replace(/[.,]00$/, "") + " " + (units[Math.floor(unit / 3) - 1] || "");

    return displayText;
  }

  checkedRowsCount() {
    if (this.hasGroupCheckboxTarget && this.groupCheckboxTarget.checked) {
      const groupTotalsElement = this.groupBodyTarget.querySelector(".group-totals");
      const count = parseInt(groupTotalsElement.dataset.totalProspects);

      if (count > 0) {
        return count;
      }
    }

    return this.rowControllers().filter((row) => row.hasRowCheckboxTarget && row.rowCheckboxTarget.checked).length;
  }

  totalIsEmpty() {
    return this.rowControllers().length > 0 && this.prospectCountTarget.innerText == "0";
  }
}
