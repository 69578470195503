import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["splitAmount", "totalAmount", "submitButton"];

  connect() {
    this.validateSplits();
  }

  validateSplits() {
    const totalAmount = parseFloat(this.totalAmountTarget.value);
    let splitSum = 0;
    let allFieldsFilled = true;

    this.splitAmountTargets.forEach((field) => {
      const value = parseFloat(field.value);
      if (isNaN(value) || value <= 0) {
        allFieldsFilled = false;
      } else {
        splitSum += value;
      }
    });

    const isValid = allFieldsFilled && Math.abs(totalAmount - splitSum) < 0.01;
    this.submitButtonTarget.disabled = !isValid;

    if (isValid) {
      this.submitButtonTarget.classList.remove("btn-disabled");
    } else {
      this.submitButtonTarget.classList.add("btn-disabled");
    }
  }

  splitAdded() {
    this.validateSplits();
  }
}
