import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pipelineType: String };
  static targets = ["input", "reset", "loader", "menu", "addText"];

  connect() {
    this.hideLoaderSetReset();

    document.addEventListener("network-request:start", this.showLoaderHideReset.bind(this));
    document.addEventListener("network-request:end", this.hideLoaderSetReset.bind(this));
    document.addEventListener("network-request:end", this.showMenu.bind(this));
  }

  disconnect() {
    document.removeEventListener("network-request:start", this.showLoaderHideReset);
    document.removeEventListener("network-request:end", this.hideLoaderSetReset);
    document.removeEventListener("network-request:end", this.showMenu);
  }

  showLoaderHideReset() {
    this.loaderTarget.classList.remove("hidden");
    this.resetTarget.classList.add("hidden");
  }

  hideLoaderSetReset() {
    this.loaderTarget.classList.add("hidden");
    this.inputTarget.value.length
      ? this.resetTarget.classList.remove("hidden")
      : this.resetTarget.classList.add("hidden");
  }

  resetInput() {
    this.inputTarget.value = "";
    this.inputTarget.dispatchEvent(new Event("keyup", { bubbles: true }));
  }

  showMenu() {
    if (!this.inputTarget.value.length) {
      return this.hideMenu();
    }

    // Certain types of pipelines cannot add prospects
    if (this.pipelineTypeValue === "closing" || this.pipelineTypeValue === "capital_call") {
      return this.hideMenu();
    }

    // If there are prospects in the search results, don't show the add button
    const rowTargets = document.querySelectorAll(`[data-controller="pipelines--pipeline-row"]`);
    if (rowTargets.length > 0) {
      return this.hideMenu();
    }

    this.addTextTarget.innerHTML = `<i class="ph-plus-circle text-primary-400"></i> Add "${this.inputTarget.value}" as a new prospect`;
    this.addTextTarget.href = this.addTextTarget.href.split("&")[0] + `&search_name=${this.inputTarget.value}`;
    this.menuTarget.classList.remove("hidden");
  }

  hideMenu(e) {
    if (e?.target === this.inputTarget) return;

    this.menuTarget.classList.add("hidden");
  }
}
