import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "activeItem",
    "inactiveItem",
    "filterInactiveAccountCheckbox"
  ];

  connect() {
    document.querySelectorAll("[data-firm-admin--capital-accounts--list-target='filterInactiveAccountCheckbox']").forEach((checkbox) => {
      this.toggleDisplayForInactiveItems(checkbox);

      checkbox.addEventListener("change", (event) => {
        this.toggleDisplayForInactiveItems(event.target);
      });
    });
  }

  toggleDisplayForInactiveItems(checkbox) {
    if (checkbox.checked) {
      this.inactiveItemTargets.forEach((item) => { item.classList.add("hidden") })
    } else {
      this.inactiveItemTargets.forEach((item) => { item.classList.remove("hidden") })
    }
  }
}
