import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["ratingsField", "categoryInput", "descriptionInput", "categoriesContainer"];

  connect() {
    this.sortable = new Sortable(this.categoriesContainerTarget, { // Use `categoriesContainerTarget` instead of `this.element`
      animation: 150,
      dataIdAttr: "data-id",
      ghostClass: "bg-gray-300",
      handle: ".sortable__handle",
      onEnd: this.updateRatings.bind(this),
    });
  }

  updateRatings() {
    const newRatings = this.collectRatings();
    this.ratingsFieldTarget.value = JSON.stringify(newRatings);
  }

  addCategory() {
    const categoryText = this.categoryInputTarget.value;
    const descriptionText = this.descriptionInputTarget.value;

    if (!categoryText.trim()) return;

    // Create the new category object
    const newItem = {
      id: new Date().getTime(), // Temporary unique ID
      text: categoryText,
      description: descriptionText
    };

    // Append to the list visually
    const container = this.categoriesContainerTarget;
    container.insertAdjacentHTML('beforeend', this.renderCategory(newItem));

    // Update the hidden field
    const currentData = JSON.parse(this.ratingsFieldTarget.value);
    currentData.push(newItem);
    this.ratingsFieldTarget.value = JSON.stringify(currentData);

    // Clear the input fields
    this.categoryInputTarget.value = '';
    this.descriptionInputTarget.value = '';
  }

  removeCategory(event) {
    event.preventDefault();
    const categoryElement = event.target.closest('.sortable__item');
    categoryElement.remove();
    this.updateRatings();
  }

  collectRatings() {
    const items = Array.from(this.element.querySelectorAll(".sortable__item"));
    return items.map(item => ({
      id: item.dataset.id,
      text: item.querySelector(".rating-text").textContent.trim(),
      description: item.querySelector(".rating-description").textContent.trim()
    }));
  }

  renderCategory(item) {
    return `
      <div class="sortable__item flex gap-2 items-center border-b border-dark-200 py-2" data-id="${item.id}">
        <div class="sortable__handle w-6 h-6 flex items-center justify-center text-md text-dark-400 cursor-move">
          <i class="ph-dots-six-vertical"></i>
        </div>
        <div>
          <h3 class="rating-text text-dark-700 font-bold text-base">${item.text}</h3>
          <p class="rating-description text-sm">${item.description}</p>
        </div>
        <button class="text-dark-300 text-md hover:text-red-400 flex items-center justify-center flex-shrink-0 ml-auto" data-action="deal-memo-config-rating#removeCategory">
          <i class="ph-trash"></i>
        </button>
      </div>
    `;
  }
}
