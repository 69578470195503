import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sendEmailFooterButton"];

  disableSendEmailFooterButton() {
    this.sendEmailFooterButtonTargets.forEach((el) => {
      el.classList.add("disabled-link");
      el.removeAttribute("href");
      el.removeEventListener("click", this.clickEvent);
    });
  }
}
