// The CrmBulkSelectorController can be used for adding bulk operations to CRM pipeline.
// TODO: Remove this file
import BulkSelectorController from "~/controllers/bulk_selector_controller";

export default class extends BulkSelectorController {
  static targets = ["input", "button", "reset"];

  static values = {
    url: String,
  };

  connect() {
    this.inputTarget.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.search();
      }
    });
  }

  setResultWindow(message) {
    document.getElementById(
      "folders_files_table"
    ).innerHTML = `<div class='h-80 border border-dashed border-dark-300 flex items-center justify-center rounded text-lg'>${message}</div>`;
  }

  search() {
    if (this.inputTarget.value.length) {
      this.resetTarget.classList.remove("hidden");
    } else {
      this.resetTarget.classList.add("hidden");
    }

    let searchInput = this.inputTarget.value;

    this.setResultWindow("Searching...");

    Rails.ajax({
      url: this.urlValue,
      data: `search=${searchInput}`,
      type: "GET",
      error: () =>
        this.setResultWindow(
          "Something went wrong. Please refresh the page or report this problem to the administrator."
        ),
    });
  }

  reset() {
    this.setResultWindow("Resetting...");

    Rails.ajax({
      url: this.urlValue,
      data: "search=",
      type: "GET",
      success: () => {
        this.resetTarget.classList.add("hidden");
        this.inputTarget.value = "";
      },
      error: () =>
        this.setResultWindow(
          "Something went wrong. Please refresh the page or report this problem to the administrator."
        ),
    });
  }
}
