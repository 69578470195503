import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pipelineId: String };

  connect() {
    this.element.addEventListener("sortable:sorted", this.handleSortableSorted.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("sortable:sorted", this.handleSortableSorted.bind(this));
  }

  handleSortableSorted(event) {
    const {
      detail: { ids, element },
    } = event;

    const payload = {
      pipeline_id: this.pipelineIdValue,
      pipeline_user_display_template_ids: ids,
    };
    fetch(`/pipeline_user_display_templates/reorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: JSON.stringify(payload),
    });
  }
}
