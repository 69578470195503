// app/javascript/controllers/video_url_validation_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "error"];

  validate() {
    let url = this.fieldTarget.value;

    if (
      !url.includes("youtube") &&
      !url.includes("youtu.be")
      // FIXME Add back other validations &&
      // !url.includes("vimeo.com") &&
      // !url.includes("loom.com")
    ) {
      this.showErrorMessage("Please enter a valid YouTube URL.");
      return;
    }

    if (url.includes("youtube") || url.includes("youtu.be")) {
      url = this.convertNonEmbedYoutubeURLToEmbedURL(url);
      this.fieldTarget.value = url;

      if (!this.isValidYoutubeURL(url)) {
        this.showErrorMessage("Please enter a valid YouTube URL.");
      } else {
        this.hideErrorMessage();
      }
    }

    // FIXME Add back other validations
    // if (url.includes("vimeo")) {
    //   url = this.convertNonEmbedVimeoURLToEmbedURL(url);
    //   this.fieldTarget.value = url;

    //   if (!this.isValidVimeoURL(url)) {
    //     this.showErrorMessage("Please enter a valid Vimeo URL.");
    //   } else {
    //     this.hideErrorMessage();
    //   }
    // }

    // if (url.includes("loom")) {
    //   url = this.convertNonEmbedLoomURLToEmbedURL(url);
    //   this.fieldTarget.value = url;

    //   if (!this.isValidLoomURL(url)) {
    //     this.showErrorMessage("Please enter a valid Loom URL.");
    //   } else {
    //     this.hideErrorMessage();
    //   }
    // }
  }

  isValidYoutubeURL(url) {
    const youtubeRegex = /^https:\/\/(?:www\.)?youtube\.com\/embed\/\w+/;
    return youtubeRegex.test(url);
  }

  isValidLoomURL(url) {
    const loomRegex = /^https:\/\/(www\.)?loom\.com\/embed\/[a-zA-Z0-9]+$/;
    return loomRegex.test(url);
  }

  isValidVimeoURL(url) {
    const vimeoRegex = /^https:\/\/player\.vimeo\.com\/video\/[0-9]+$/;
    return vimeoRegex.test(url);
  }

  convertNonEmbedYoutubeURLToEmbedURL(url) {
    const id = this.youtubeIdFromURL(url);
    if (id) {
      return `https://www.youtube.com/embed/${id}`;
    }
    return url;
  }

  convertNonEmbedLoomURLToEmbedURL(url) {
    const videoIdPattern = /loom\.com\/share\/([a-zA-Z0-9]+)/;
    const match = url.match(videoIdPattern);
    if (match && match[1]) {
      const videoId = match[1];
      return `https://www.loom.com/embed/${videoId}`;
    }
    return null;
  }

  convertNonEmbedVimeoURLToEmbedURL(url) {
    const vimeoRegex = /^https:\/\/(www\.)?vimeo\.com\/([0-9]+)$/;
    const match = url.match(vimeoRegex);
    if (match) {
      const videoId = match[2];
      return `https://player.vimeo.com/video/${videoId}`;
    }
    return null;
  }

  youtubeIdFromURL(url) {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    }
    return undefined;
  }

  showErrorMessage(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.style.display = "block";
  }

  hideErrorMessage() {
    this.errorTarget.textContent = "";
    this.errorTarget.style.display = "none";
  }
}
