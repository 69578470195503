import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };
  static targets = ["search", "loader", "results", "hiddenField", "selectedResult", "selectedResultName", "close"];

  connect() {
    this.searchField = this.searchTarget;
    this.boundSearchEvent = this.search.bind(this);
    this.searchTarget.addEventListener("keyup", this.boundSearchEvent);
  }

  disconnect() {
    this.searchTarget.removeEventListener("keyup", this.boundSearchEvent);
  }

  search(e) {
    if (e.key == "Enter") {
      window.notyf.error("Please choose an option from the dropdown to continue");
      return;
    }

    const keyModifier = e.keyCode === 18;
    const searchString = this.searchField.value.trim();
    const data =
      this.searchField.dataset.queryParam &&
      `search=${this.searchField.dataset.queryParam}&${this.searchField.dataset.queryParam}=${searchString}`;

    if (!keyModifier) {
      // show loading
      this.loaderTarget.classList.remove("hidden");
      Rails.ajax({
        type: "GET",
        url: this.searchTarget.dataset.dataRoomFileSearchUrlValue,
        data: data,
        error: this.showEmptySearchResults.bind(this),
        success: this.showSearchResults.bind(this),
      });
    }
  }

  loadDefaultOptions() {
    this.search({});
  }

  showSearchResults(results) {
    this.loaderTarget.classList.add("hidden");

    this.updateSearchResults(results);
  }

  updateSearchResults(results) {
    this.searchResults = results;

    this.resultsTarget.innerHTML = "";

    results.forEach((result) => {
      this.resultsTarget.insertAdjacentElement("beforeend", this.createSearchResult(result));
    });
  }

  createSearchResult(result) {
    const resultElement = document.createElement("a");
    resultElement.classList.add(
      "flex",
      "items-center",
      "gap-2",
      "px-3",
      "py-2",
      "hover:bg-dark-50",
      "rounded",
      "cursor-pointer"
    );
    const icon = "ph-file-bold";
    resultElement.innerHTML = `<div class="w-7 h-7 border-2 border-dark-200 rounded-full flex items-center justify-center text-primary-400"><i class="${icon}"></i></div><span>${result.name}</span>`;
    resultElement.dataset.id = result.id;
    resultElement.classList.add("hover:bg-dark-50", "rounded");
    resultElement.addEventListener("click", this.selectSearchResultOnClick.bind(this));

    return resultElement;
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find((result) => result.id == ev.currentTarget.dataset.id);

    this.selectSearchResult(selectedResult);
  }

  selectSearchResult(selectedResult) {
    this.searchTarget.value = selectedResult.name;
    if (selectedResult?.id) this.hiddenFieldTarget.value = selectedResult.id;
    this.selectedResultTarget.classList.remove("hidden");
    this.selectedResultNameTarget.innerHTML = selectedResult.name;
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  reset() {
    this.searchTarget.value = "";
    this.hiddenFieldTarget.value = "";
    this.selectedResultTarget.classList.add("hidden");
  }
}
