import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'submit'];

  connect() {
    setTimeout(() => this.checkTitle(), 100);
  }

  checkTitle() {
    const titleValue = this.titleTarget.value;
    if (titleValue === null || titleValue.length === 0) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }
}
