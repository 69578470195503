import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["up", "down"];
  static values = { channelId: Number };

  connect() {
    this.loadState();
  }

  toggle(event) {
    if (event.target.tagName === "A" && this.downTarget.classList.contains("hidden")) return;
    const childrenMenu = document.getElementById(`children_${this.channelIdValue}`);
    this.upTarget.classList.toggle("hidden");
    this.downTarget.classList.toggle("hidden");
    childrenMenu.classList.toggle("hidden");
    this.saveState();
  }

  saveState() {
    const isExpanded = this.downTarget.classList.contains("hidden");
    this.element.setAttribute('aria-expanded', isExpanded.toString());
    window.localStorage.setItem(`children_expanded_${this.channelIdValue}`, isExpanded);
  }

  loadState() {
    const isExpanded = window.localStorage.getItem(`children_expanded_${this.channelIdValue}`) === "true";
    if (isExpanded) {
      this.upTarget.classList.remove("hidden");
      this.downTarget.classList.add("hidden");
      document.getElementById(`children_${this.channelIdValue}`).classList.remove("hidden");
    } else {
      this.upTarget.classList.add("hidden");
      this.downTarget.classList.remove("hidden");
      document.getElementById(`children_${this.channelIdValue}`).classList.add("hidden");
    }
    this.element.setAttribute('aria-expanded', isExpanded.toString());
  }
}
