import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    selected: String,
    colors: Array
  }

  connect() {
    this.toggleDropdown(false);
    this.displaySelectedColor();
  }

  toggleDropdown(show) {
    const dropdown = this.element.querySelector(".dropdown");
    dropdown.style.display = show ? "block" : "none";
  }

  changeColor(event) {
    event.stopImmediatePropagation();  // Stop the event from bubbling up
    this.selectedValue = event.currentTarget.dataset.color;
    this.displaySelectedColor();
    this.updateHiddenInput();
    this.toggleDropdown(false);
  }

  displaySelectedColor() {
    const displayElement = this.element.querySelector('.color-display');
    displayElement.style.backgroundColor = this.selectedValue;
  }

  updateHiddenInput() {
    const input = this.element.querySelector('input[type="hidden"]');
    input.value = this.selectedValue;
  }

  toggleDisplayDropdown(event) {
    event.preventDefault();  // Prevent the form from submitting
    event.stopImmediatePropagation();  // Stop the event from bubbling up
    const dropdown = this.element.querySelector(".dropdown");
    this.toggleDropdown(dropdown.style.display === "none");
  }
}
