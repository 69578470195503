import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "toggleButton", "submitButton"]

  toggle() {
    const isDisabled = this.fieldTarget.disabled;

    this.fieldTarget.disabled = !isDisabled;

    if (this.fieldTarget.disabled) {
      this.fieldTarget.classList.add('disabled');
    } else {
      this.fieldTarget.classList.remove('disabled');
      this.fieldTarget.focus();
    }

    if (this.hasSubmitButtonTarget && this.submitButtonTarget.classList.contains('hidden')) {
      this.toggleButtonTarget.classList.add('hidden');
      this.submitButtonTarget.classList.remove('hidden');
    }
  }
}
