import { Controller } from "stimulus";
import { post } from "@rails/request.js";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["linkButton", "syncButton", "searchInput", "relinkButton"];

  initialize() {
    this.debouncedImportTransactions = debounce(this.importTransactions, 1000).bind(this);
    this.handleSyncClick = this.handleSyncClick.bind(this);
  }

  connect() {
    if (this.hasLinkButtonTarget) {
      this.linkButtonTarget.addEventListener("click", this.createLinkToken.bind(this));
    }
    this.syncButtonTargets.forEach((button) => {
      button.addEventListener("click", this.handleSyncClick);
    });
    this.relinkButtonTargets.forEach((button) => {
      button.addEventListener("click", this.handleRelinkClick.bind(this));
    });
  }

  disconnect() {
    if (this.hasLinkButtonTarget) {
      this.linkButtonTarget.removeEventListener("click", this.createLinkToken.bind(this));
    }
    this.syncButtonTargets.forEach((button) => {
      button.removeEventListener("click", this.handleSyncClick);
    });
    this.relinkButtonTargets.forEach((button) => {
      button.removeEventListener("click", this.handleRelinkClick.bind(this));
    });
  }

  handleSyncClick(event) {
    event.preventDefault();
    const button = event.currentTarget;
    this.debouncedImportTransactions(button);
  }

  async linkAccount(event) {
    event.preventDefault();
    const institutionId = event.currentTarget.dataset.institutionId;
    const accountOrganizationId = event.currentTarget.dataset.accountOrganizationId;
    this.createLinkToken(event, institutionId, accountOrganizationId);
  }

  async createLinkToken(event, institutionId = null, accountOrganizationId = null) {
    event.preventDefault();
    try {
      if (!accountOrganizationId) {
        accountOrganizationId = this.linkButtonTarget.dataset.accountOrganizationId;
      }
      const body = {
        account_organization_id: accountOrganizationId,
      };
      if (institutionId) {
        body.institution_id = institutionId;
      }
      const response = await post(`/firm_admin/entities/${accountOrganizationId}/bank_accounts/create_link_token`, {
        responseKind: "json",
        contentType: "application/json",
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json;
        if (data.link_token) {
          this.openPlaidLink(data.link_token, accountOrganizationId);
        } else {
          console.error("Unexpected response from create_link_token:", data);
        }
      } else {
        console.error("Unexpected response from create_link_token:", response);
      }
    } catch (error) {
      console.error("Error creating link token:", error);
    }
  }

  async handleRelinkClick(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const bankAccountId = button.dataset.bankAccountId;
    const accountOrganizationId = button.dataset.accountOrganizationId;

    try {
      const response = await post(
        `/firm_admin/entities/${accountOrganizationId}/bank_accounts/${bankAccountId}/relink`,
        {
          responseKind: "json",
        }
      );

      if (response.ok) {
        const data = await response.json;
        if (data.link_token) {
          this.openPlaidLink(data.link_token, accountOrganizationId, bankAccountId, true);
        } else {
          console.error("Unexpected response from relink:", data);
        }
      } else {
        console.error("Unexpected response from relink:", response);
      }
    } catch (error) {
      console.error("Error relinking account:", error);
    }
  }

  openPlaidLink(linkToken, accountOrganizationId, bankAccountId, isRelink = false) {
    const config = {
      token: linkToken,
      onSuccess: (public_token, metadata) => {
        if (isRelink) {
          this.handleRelinkSuccess(accountOrganizationId, bankAccountId);
        } else {
          this.exchangePublicToken(public_token, accountOrganizationId, metadata.accounts);
        }
      },
      onExit: (err, metadata) => {
        if (err != null) {
          console.error("Plaid Link error:", err);
        }
      },
      onEvent: (eventName, metadata) => {},
    };

    const linkHandler = Plaid.create(config);
    linkHandler.open();
  }

  async handleRelinkSuccess(accountOrganizationId, bankAccountId) {
    try {
      const response = await post(
        `/firm_admin/entities/${accountOrganizationId}/bank_accounts/${bankAccountId}/update_link`,
        {
          responseKind: "turbo-stream",
        }
      );

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Error updating link status");
      }
    } catch (error) {
      console.error("Error updating link status:", error);
    }
  }

  async exchangePublicToken(publicToken, accountOrganizationId, selectedAccounts) {
    try {
      const response = await post(`/firm_admin/entities/${accountOrganizationId}/bank_accounts/exchange_public_token`, {
        responseKind: "turbo-stream",
        contentType: "application/json",
        body: JSON.stringify({
          public_token: publicToken,
          account_organization_id: accountOrganizationId,
          selected_accounts: selectedAccounts,
        }),
      });

      if (!response.ok) {
        console.error("Unexpected response from exchange_public_token:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async importTransactions(button) {
    if (!button || !document.body.contains(button)) {
      console.warn("Sync button no longer exists in the DOM");
      return;
    }

    const bankAccountId = button.dataset.bankAccountId;
    const accountOrganizationId = button.dataset.accountOrganizationId;
    const url = `/firm_admin/entities/${accountOrganizationId}/bank_accounts/${bankAccountId}/import_transactions`;

    button.classList.add("disabled");
    const originalText = button.innerHTML;
    button.innerHTML = '<i class="ph-circle-notch ph-spin"></i> Syncing...';

    try {
      const response = await post(url, {
        responseKind: "turbo-stream",
        body: JSON.stringify({ account_organization_id: accountOrganizationId }),
      });

      if (!response.ok) {
        console.error("Unexpected response from import_transactions:", response);
      }
    } catch (error) {
      console.error("Error importing transactions:", error);
    } finally {
      setTimeout(() => {
        if (button && document.body.contains(button)) {
          button.classList.remove("disabled");
          button.innerHTML = originalText;
        }
      }, 2000);
    }
  }

  clearSearch(event) {
    event.preventDefault();
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.value = "";
    }
    const searchResultsFrame = document.getElementById("search_banks_results");
    if (searchResultsFrame) {
      searchResultsFrame.innerHTML = "";
    }
  }
}
