// Custom TailwindCSS modals for confirm dialogs
// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
import Rails from "@rails/ujs";

const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ["a[data-confirm]", "button[data-confirm]", "input[type=submit][data-confirm]"];

const createConfirmModal = (element, confirmCallback) => {
  var id = "confirm-modal-" + String(Math.random()).slice(2, -1);
  var confirm = element.dataset.confirm;
  var confirmDescription = element.dataset.confirmDescription;
  var confirmDestructive = element.dataset.confirmDestructive;
  var confirmDelete = element.dataset.confirmDelete;
  var confirmIcon = element.dataset.confirmIcon;
  var confirmBtnText = element.dataset.confirmBtnText;

  var content = `
    <div class="animated fadeIn fixed inset-0 flex justify-center items-center bg-dark-700 bg-opacity-60" style="background-color: rgba(51, 65, 85, 0.7); z-index: 9999;" data-confirmation-modal>
      <div class="w-11/12 max-w-sm bg-white rounded-sm shadow whitespace-normal">
        <div class="p-6 flex flex-col items-center gap-2">
  `;
  if (confirmDestructive == "yes") {
    content += `<div class="w-12 h-12 rounded-full flex items-center justify-center bg-red-100 text-xl text-red-600"><i class="${confirmIcon}"></i></div>`;
  } else if (confirmDelete == "yes") {
    content += `<div class="w-12 h-12 rounded-full flex items-center justify-center bg-red-100"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><path d="M18.5625 4.8125H3.4375" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.9375 8.9375V14.4375" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.0625 8.9375V14.4375" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.1875 4.8125V17.875C17.1875 18.0573 17.1151 18.2322 16.9861 18.3611C16.8572 18.4901 16.6823 18.5625 16.5 18.5625H5.5C5.31766 18.5625 5.1428 18.4901 5.01386 18.3611C4.88493 18.2322 4.8125 18.0573 4.8125 17.875V4.8125" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.4375 4.8125V3.4375C14.4375 3.07283 14.2926 2.72309 14.0348 2.46523C13.7769 2.20737 13.4272 2.0625 13.0625 2.0625H8.9375C8.57283 2.0625 8.22309 2.20737 7.96523 2.46523C7.70737 2.72309 7.5625 3.07283 7.5625 3.4375V4.8125" stroke="#DC2626" stroke-linecap="round" stroke-linejoin="round"/>
    </svg></div>`;
  } else {
    content += `<div class="w-12 h-12 rounded-full flex items-center justify-center bg-primary-100 text-xl text-primary-600"><i class="${confirmIcon}"></i></div>`;
  }
  content += `
          <h4 class="text-lg text-dark-700 text-medium text-center">${confirm}</h4>
  `;
  if (confirmDescription)
    content += `
          <div class="text-sm text-center">${confirmDescription}</div>
  `;
  content += `
        </div>
          <div class="flex items-center gap-2 px-6 py-3 border-t border-gray-200">
            <button data-behavior="cancel" class="btn btn-white flex-1 flex-shrink-0">Cancel</button>
            <button data-behavior="commit" class="btn ${
              (confirmDestructive || confirmDelete) == "yes" ? "btn-danger" : "btn-primary"
            } flex-1 flex-shrink-0">${confirmBtnText}</button>
          </div>
        </div>
      </div>
    </div>
  `;

  const modalDiv = document.createElement("div");
  modalDiv.innerHTML = content;

  document.body.appendChild(modalDiv);

  const modal = document.body.lastChild;

  element.dataset.confirmModal = `#${id}`;

  modal.addEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      element.removeAttribute("data-confirm-modal");
      modal.remove();
    }
  });

  modal.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
    event.preventDefault();
    element.removeAttribute("data-confirm-modal");
    modal.remove();
  });

  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault();
    event.currentTarget.innerHTML = "Please wait...";

    // setTimeout is required to reflect the innerHTML change
    setTimeout(() => {
      // Allow the confirm to go through
      Rails.confirm = () => {
        return true;
      };

      // Click the link again
      confirmCallback();

      // Remove the confirm attribute and modal
      element.removeAttribute("data-confirm-modal");
      Rails.confirm = old_confirm;

      modal.remove();
    }, 0);
  });

  modal.querySelector("[data-behavior='commit']").focus();
  return modal;
};

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
};

export const handleConfirm = (event, callback) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true;

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target, callback);
    return false;
  }
};

const handleConfirmEvent = (event) => {
  return handleConfirm(event, () => {
    event.target.click();
  });
};

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(", "), "confirm", handleConfirmEvent);
