import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "files", "reset", "upload", "uploadedFiles"];
  static values = { maxFiles: Number };

  connect() {
    this.dataTransfer = new DataTransfer();
  }

  inputTargetConnected(element) {
    element.addEventListener("change", this.showFiles.bind(this));
  }

  inputTargetDisconnected(element) {
    element.removeEventListener("change", this.showFiles.bind(this));
  }

  uploadedFilesTargetConnected() {
    if (this.hasInputTarget && this.inputTarget.files.length > 0) {
      this.reset();
    }
  }

  delete(event) {
    const clickedButton = event.currentTarget;

    for (let i = 0; i < this.dataTransfer.items.length; i++) {
      if (clickedButton.dataset.name === this.convertName(this.dataTransfer.items[i].getAsFile().name)) {
        this.dataTransfer.items.remove(i);
        document.getElementById(clickedButton.dataset.id).remove();

        break;
      }
    }

    this.inputTarget.files = this.dataTransfer.files;
  }

  convertName(name) {
    let newName = name.toLowerCase().split(" ").join("-");
    newName = newName.split(".").join("-");
    newName = newName.split('"').join("-");
    newName = newName.split("'").join("-");

    return newName;
  }

  reset() {
    this.dataTransfer.clearData();
    this.dataTransfer = new DataTransfer();
    this.inputTarget.files = this.dataTransfer.files;
    this.resetTarget.classList.add("hidden");
    this.showFiles();
  }

  showFiles() {
    if (this.maxFilesValue > 0 && this.inputTarget.files.length > this.maxFilesValue) return;

    this.filesTarget.innerHTML = "";
    Array.from(this.inputTarget.files).forEach((file) => {
      this.dataTransfer.items.add(file);
    });
    this.inputTarget.files = this.dataTransfer.files;
    this.printFilesNamesWithEdit();

    if (this.hasUploadTarget) {
      if (this.maxFilesValue > 0 && this.inputTarget.files.length >= this.maxFilesValue) {
        this.uploadTarget.classList.add("hidden");
      } else {
        this.uploadTarget.classList.remove("hidden");
      }
    }
  }

  printFilesNamesWithEdit() {
    if (this.inputTarget.files.length) {
      this.resetTarget.classList.remove("hidden");
    } else {
      this.resetTarget.classList.add("hidden");
    }

    Array.from(this.inputTarget.files).forEach((file, index) => {
      const nameForId = this.convertName(file.name);

      const name = this.getFileNameWithoutExtension(file);
      const extension = this.getExtension(file);

      this.filesTarget.innerHTML += `
        <div id="${nameForId}-${index}" class="flex items-center justify-between border-b border-gray-200 py-3">
          <div class="flex items-center gap-1">
            <i class="ph-file-text text-dark-400 text-md"></i>
            <div class="text-xs text-dark-600" id="${nameForId}-${index}-filename" data-extension="${extension}" data-original-name="${name}">${
        name + "." + extension
      }</div>
          </div>
          <button class="btn-icon" type="button" data-button-type="edit" data-container-id="${nameForId}-${index}" data-action="click->files-multiple-upload#toggleEdit">
            <i class="ph-pencil-simple-line"></i>
          </button>
        </div>
      `;

      this.filesTarget.innerHTML += `
        <div id="${nameForId}-${index}-input-container" class="flex items-center gap-1 border-b border-gray-200 py-3 hidden">
          <input class="form-control form-control-sm" id="${nameForId}-${index}-input" type="text" value="${name}" name="data_room_file[files_names][]">
          <div class="flex items-center gap-1">
            <button class="btn btn-white btn-sm" type="button" data-button-type="cancel" data-container-id="${nameForId}-${index}" data-action="click->files-multiple-upload#toggleEdit">Cancel</button>
            <button class="btn btn-primary btn-sm" type="button" data-button-type="save" data-container-id="${nameForId}-${index}" data-action="click->files-multiple-upload#toggleEdit">Save</button>
          </div>
        </div>
      `;
    });
  }

  toggleEdit(event) {
    const button = event.currentTarget;

    const type = button.dataset.buttonType;
    const containerId = button.dataset.containerId;

    switch (type) {
      case "edit":
        document.getElementById(`${containerId}-input-container`).classList.remove("hidden");
        document.getElementById(`${containerId}`).classList.add("hidden");
        break;
      case "save":
        document.getElementById(`${containerId}-input-container`).classList.add("hidden");
        document.getElementById(`${containerId}`).classList.remove("hidden");
        this.updateFileName(document.getElementById(`${containerId}-input`).value, containerId);
        break;
      case "cancel":
        this.updateFileName(document.getElementById(`${containerId}-input`).dataset.oldValue || "", containerId);

        document.getElementById(`${containerId}`).classList.remove("hidden");
        document.getElementById(`${containerId}-input-container`).classList.add("hidden");
        break;
    }
  }

  updateFileName(newName, id) {
    const fileNameContainer = document.getElementById(`${id}-filename`);
    const extension = fileNameContainer.dataset.extension;

    if (newName.length) {
      fileNameContainer.innerHTML = newName + "." + extension;
      document.getElementById(`${id}-input`).value = newName;
      document.getElementById(`${id}-input`).dataset.oldValue = newName;
    } else {
      document.getElementById(`${id}-input`).value = fileNameContainer.dataset.originalName;
      document.getElementById(`${id}-input`).dataset.oldValue = fileNameContainer.dataset.originalName;
      fileNameContainer.innerHTML = fileNameContainer.dataset.originalName + "." + extension;
    }
  }

  getFileNameWithoutExtension(file) {
    return file.name.replace(/\.[^/.]+$/, "");
  }

  getExtension(file) {
    return file.name.split(".").pop();
  }
}
