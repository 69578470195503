import { Controller } from "stimulus";

export default class extends Controller {
  openMenu() {
    this.element.classList.add("side-menu-opened");
  }

  closeMenu() {
    this.element.classList.remove("side-menu-opened");
  }
}
