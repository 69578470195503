import Trix from "trix";

class CurlyBracketVariablesHandler {
  constructor(element) {
    this.variablesRegex = /\{\{(.*?)\}\}/;
    this.element = element;
    this.editor = element.editor;
    this.formElement = element.closest("form");
    this.installEventHandlers();
  }

  installEventHandlers() {
    if (this.element && this.element.dataset.curlyBracketVariables === "true") {
      this.element.setAttribute("contentEditable", false);
      // Prevent multiple event handlers from being installed or running at the same time.
      // When we do the variable replacement, we don't want to trigger the trix-change event.
      this.runningHandlers = false;
      this.#registerVariables();
      this.#replaceVariables();
      // There is a bug in Trix that sometimes pasting plain text doesn't trigger the re-render of the content.
      // Issue: https://github.com/basecamp/trix/issues/1103
      // PR fix: https://github.com/basecamp/trix/pull/1107/files
      // We need to listen to the trix-paste event to replace the variables.
      this.element.addEventListener("trix-paste", this.#pasteTrixContent.bind(this));
      this.element.addEventListener("trix-change", this.#replaceVariables.bind(this));
      this.element.addEventListener("copy", this.#copyTrixContent.bind(this));
      this.element.setAttribute("contentEditable", true);
    }
  }

  #registerVariables() {
    this.variables = {};
    this.unknownVariableUrl = this.element.dataset.unknownVariableUrl;
    // Local cache to store the loaded variables to avoid multiple requests for the same variable.
    this.loadedVariables = {};
    this.formElement.querySelectorAll("[data-variable]").forEach((element) => {
      if (
        element.dataset.fetchRenderedVariableUrl &&
        element.dataset.fetchRenderedVariableUrl !== "" &&
        element.dataset.variable &&
        element.dataset.variable !== ""
      ) {
        this.variables[element.dataset.variable] = element.dataset.fetchRenderedVariableUrl;
      }
    });

    this.formElement.querySelectorAll("[data-path]").forEach((element) => {
      let root = element.dataset.root || ".";
      let alternativeRoot = element.dataset.alternativeRoot || "";

      if (root && root !== ".") {
        root = `${root}.`;
      } else {
        // FIXME: This is a temporary fix to handle the root variable.
        // Until we migrate all data, we need to duplicate variables within the root.
        this.variables[`prospect.${element.dataset.path}`] = element.dataset.fetchRenderedVariableUrl;
      }
      this.variables[`${root}${element.dataset.path}`] = element.dataset.fetchRenderedVariableUrl;
      if (alternativeRoot && alternativeRoot !== "") {
        this.variables[`${alternativeRoot}.${element.dataset.path}`] = element.dataset.fetchRenderedVariableUrl;
      }
    });
  }

  async #replaceVariables() {
    if (this.runningHandlers) {
      return;
    }
    this.runningHandlers = true;

    const variables_cache = JSON.parse(this.element.dataset.variablesSgidContent || "{}");
    // match all {{variable}} variables in the email body and replace them with an Trix.
    let match = this.variablesRegex.exec(this.editor.getDocument().toString());
    while (match !== null) {
      const variable = match[1];
      let attachment;

      const variableUrl = this.variables[variable];
      let embedUrl;
      if (variableUrl) {
        const formData = new FormData(this.formElement);
        const email_from_actor = formData.get("pipeline_email[email_from_actor]");
        const baseUrl = variableUrl;
        const hasParams = baseUrl.includes("?");
        const separator = hasParams ? "&" : "?";
        embedUrl = `${baseUrl}${separator}email_from_actor=${email_from_actor}`;
        const primaryRecipient = this.formElement.querySelector(".tagify__tag.primary");
        if (primaryRecipient) {
          embedUrl += `&person_id=${primaryRecipient.id}`;
        }
      } else {
        const baseUrl = this.unknownVariableUrl;
        const hasParams = baseUrl.includes("?");
        const separator = hasParams ? "&" : "?";
        embedUrl = `${baseUrl}${separator}variable_name=${variable}`;
      }

      if (
        !variables_cache[variable] &&
        (!this.loadedVariables[variable] || this.loadedVariables[variable].url !== embedUrl)
      ) {
        const ajaxFetchedVariableHtmlString = await this.#fetchRenderedVariable(embedUrl);
        this.loadedVariables[variable] = {
          content: ajaxFetchedVariableHtmlString.content,
          sgid: ajaxFetchedVariableHtmlString.sgid,
          variable_name: ajaxFetchedVariableHtmlString.variable_name,
          url: embedUrl,
        };
      }

      const range = [match.index, match.index + match[0].length];
      this.editor.setSelectedRange(range);
      const attachmentContent = variables_cache[variable] || this.loadedVariables[variable];
      attachment = new Trix.Attachment(attachmentContent);
      this.editor.insertAttachment(attachment);
      match = this.variablesRegex.exec(this.editor.getDocument().toString());
    }

    this.runningHandlers = false;
  }

  #pasteTrixContent(event) {
    const current_range = this.editor.getSelectedRange();
    this.element.setAttribute("contentEditable", false);
    this.#replaceVariables();
    this.element.setAttribute("contentEditable", true);
    this.editor.setSelectedRange(current_range);
  }

  #copyTrixContent(event) {
    const trixDocument = this.editor.getDocument();
    const selectedRange = this.editor.getSelectedRange();

    if (!selectedRange) return;

    const trixDocumentAtRange = trixDocument.getDocumentAtRange(selectedRange);
    const selectedContent = trixDocumentAtRange
      .getPieces()
      .map((piece) => {
        if (piece instanceof Trix.AttachmentPiece) {
          const documentElement = new DOMParser().parseFromString(
            piece.attachment.getContent(),
            "text/html"
          ).documentElement;
          let textContent;
          if (documentElement.querySelector("[id^='variable_value_']")) {
            textContent = documentElement.querySelector("[id^='variable_value_']").textContent.trim();
          } else {
            textContent = documentElement.textContent.trim();
          }
          // if has replaced variable, return the textContent as is(which is the variable value)
          // else return the variable name wrapped in curly brackets(which is the variable name)
          if (documentElement.querySelector("[data-has-replaced-variable='true']")) {
            return textContent;
          } else {
            return `{{${textContent}}}`;
          }
        } else if (piece instanceof Trix.StringPiece) {
          return piece.string;
        }
      })
      .join("");

    event.clipboardData.setData("text/plain", selectedContent);
    event.preventDefault();
  }

  #fetchRenderedVariable(url) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "GET",
        success: (response) => resolve(response),
        error: (response) => reject(response),
      });
    });
  }
}

document.addEventListener("trix-initialize", function (event) {
  new CurlyBracketVariablesHandler(event.target);
});
