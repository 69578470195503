import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String };
  static targets = ["display", "probSlider"];

  connect() {
    this.probSliderTarget.onmousedown = this.registerMouseMove.bind(this);
    this.probSliderTarget.onmouseup = this.submitProb.bind(this);
  }

  changeSlider(event) {
    this.displayTarget.innerHTML = event.target.value;
  }

  registerMouseMove() {
    this.probSliderTarget.onmousemove = this.onChange.bind(this);
  }

  onChange(event) {
    this.displayTarget.innerHTML = event.target.value;
  }

  submitProb(event) {
    Rails.ajax({
      type: "PATCH",
      url: this.urlValue,
      data: `value=${~~event.target.value}`,
    });
  }
}
