import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultSubmit", "channelSubmit"]

  connect() {
    document.addEventListener("channel-mention-added", this.showChannelCountConfirm.bind(this))
    document.addEventListener("trix-attachment-remove", this.hideChannelCountConfirm.bind(this))
  }

  showChannelCountConfirm(e) {
    if (e.detail.count >= 100) {
      this.channelSubmitTarget.dataset.confirm = `This will notify ${e.detail.count} people. Are you sure?`
      this.channelSubmitTarget.value = `${this.channelSubmitTarget.value} (${e.detail.count})`
      this.defaultSubmitTarget.classList.add('hidden')
      this.channelSubmitTarget.classList.remove('hidden')
    } else {
      this.defaultSubmitTarget.value = `${this.defaultSubmitTarget.value} (${e.detail.count})`
    }
  }

  hideChannelCountConfirm(e) {
    if (e.attachment.attachment.attributes.values.is_channel === undefined) return;
    if (this.defaultSubmitTarget.classList.contains('hidden')) {
      this.defaultSubmitTarget.classList.remove('hidden')
      this.channelSubmitTarget.classList.add('hidden')
      this.channelSubmitTarget.value = this.channelSubmitTarget.value.replace(/ \(\d+\)/, '')
    } else {
      this.defaultSubmitTarget.value = this.defaultSubmitTarget.value.replace(/ \(\d+\)/, '')
    }
  }
}
