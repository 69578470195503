import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parent", "child", "arrow", "secondArrow", "link", "toggleAll", "openButton", "closeArrow"];
  static values = { show: Boolean, remember: String };

  connect() {
    if (this.hasArrowTarget && !this.hasToggleAllTarget) {
      this.toggleChildren();
      this.handleToggle();
    }
    if (this.hasOpenButtonTarget) {
      if (!this.openButtonHandler) {
        this.openButtonHandler = this.open.bind(this);
      }
      this.openButtonTarget.addEventListener("click", this.openButtonHandler);
    }
    if (this.hasRememberValue) {
      this.restoreSession();
      this.toggleChildren();
      this.toggleParent();
    }
  }

  disconnect() {
    if (this.hasArrowTarget && this.boundToggleHandler) {
      this.arrowTarget.removeEventListener("click", this.boundToggleHandler);
    }
    if (this.hasCloseArrowTarget && this.boundToggleHandler) {
      this.closeArrowTarget.removeEventListener("click", this.boundToggleHandler);
    }
    if (this.hasSecondArrowTarget && this.boundToggleHandler) {
      this.secondArrowTarget.removeEventListener("click", this.boundToggleHandler);
    }
    if (this.hasOpenButtonTarget && this.openButtonHandler) {
      this.openButtonTarget.removeEventListener("click", this.openButtonHandler);
    }
  }

  handleToggle() {
    if (!this.boundToggleHandler) {
      this.boundToggleHandler = this.toggle.bind(this);
    }
    this.arrowTarget.addEventListener("click", this.boundToggleHandler);
    if (this.hasCloseArrowTarget) {
      this.closeArrowTarget.addEventListener("click", this.boundToggleHandler);
    }
    if (this.hasSecondArrowTarget) {
      this.secondArrowTarget.addEventListener("click", this.boundToggleHandler);
    }
  }

  toggleAll() {
    document.querySelectorAll("turbo-frame[data-controller]").forEach((node) => {
      const context = this.application.getControllerForElementAndIdentifier(node, "collapsible");
      if (context) {
        context.showValue = !this.showValue;
        context.toggleChildren();
      }
    });
    this.showValue = !this.showValue;
    this.arrowTarget.classList.toggle("ph-caret-down-bold", this.showValue);
    this.arrowTarget.classList.toggle("ph-caret-right-bold", !this.showValue);
  }

  toggle(event) {
    event.stopPropagation();
    this.showValue = !this.showValue;
    this.toggleChildren();
    this.toggleParent();

    if (this.hasRememberValue) {
      this.saveSession();
    }
  }

  getParentControllerForIdentifier(identifier) {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === identifier && controller.element.contains(this.element);
    });
  }

  toggleParent() {
    if (this.hasParentTarget) {
      this.parentTarget.classList.toggle("!hidden", this.showValue);
    }
  }

  toggleChildren() {
    for (const node of this.childTargets) {
      if (node.dataset.keepOpen === "true") continue;

      node.classList.toggle("!hidden", !this.showValue);
    }

    if (this.hasArrowTarget) {
      this.arrowTarget.classList.toggle("ph-caret-down-bold", this.showValue);
      this.arrowTarget.classList.toggle("ph-caret-right-bold", !this.showValue);
    }

    if (this.hasCloseArrowTarget && this.showValue) {
      this.closeArrowTarget.classList.remove("hidden");
      this.arrowTarget.classList.add("hidden");
    }
    if (this.hasCloseArrowTarget && !this.showValue) {
      this.arrowTarget.classList.remove("hidden");
      this.closeArrowTarget.classList.add("hidden");
    }
  }

  open() {
    this.showValue = true;
    this.toggleChildren();
    this.showValue = false;
  }

  getChildControllers() {
    this.element.querySelectorAll("div[data-controller]").forEach((node) => {
      this.callChildController(node);
    });
  }

  callChildController(element) {
    const context = this.application.getControllerForElementAndIdentifier(element, "collapsible");
    if (context) context.close();
  }

  saveSession() {
    sessionStorage.setItem(this.rememberValue, JSON.stringify(this.showValue));
  }

  restoreSession() {
    if (!this.rememberValue) {
      return;
    }

    const session = JSON.parse(sessionStorage.getItem(this.rememberValue));

    if (session) {
      this.showValue = session;
    }
  }
}
