import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["button", "menu", "menuItem", "selectedDisplay", "selectedValue"];
  static values = { open: Boolean, default: false };

  connect() {
    super.connect();
  }

  updateForm(event) {
    if (this.hasSelectedDisplayTarget) {
      this.selectedDisplayTarget.innerHTML = event.target.innerHTML;
    }

    if (this.hasSelectedValueTarget) {
      this.selectedValueTarget.value = event.target.value;
    }

    this.openValue = false;
  }
}
