import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "modal", "slides", "previous", "next", "submit", "tabBody", "tabHeader"];

  static values = {
    tabIds: Array,
  };

  connect() {
    if (this.hasSlidesTarget) {
      this.prepareSlides();
    }
    if (this.hasTabHeaderTarget) {
      this.populateTabs();
    }

    document.querySelectorAll("[data-modal='button']").forEach((button) => {
      button.addEventListener("click", () => {
        this.showModal(button.dataset.modalId);
      });
    });

    const params = new URLSearchParams(document.location.search);
    if (params.get("showModal")) {
      this.showModal(params.get("showModal"));
    }
  }

  showModal(modalId, transactionId = null) {
    this.close_modal();
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.classList.remove("hidden");
      modal.classList.remove("none");

      const form = modal.querySelector("form");
      if (form && transactionId) {
        form.setAttribute("data-transaction-id", transactionId);
      }
    }
  }

  prepareSlides() {
    this.currentSlide = 0;
    this.numberOfSlides = this.slidesTarget.children.length;

    for (let i = 1; i < this.numberOfSlides; i++) {
      const slide = this.slidesTarget.children[i];
      slide.classList.add("hidden");
    }

    // hide previous button
    this.previousTarget.classList.add("hidden");
  }

  populateTabs() {
    this.tabBodyTargets.forEach((tabBodyElement, index) => {
      this.tabHeaderTargets[index].onclick = (ev) => {
        this.tabHeaderTargets.forEach((e) => {
          e.classList.remove("active");
        });
        ev.target.classList.add("active");
        this.tabBodyTargets.forEach((e) => e.classList.add("hidden"));
        tabBodyElement.classList.remove("hidden");
      };
    });
  }

  next() {
    if (this.currentSlide + 1 <= this.numberOfSlides - 1) {
      this.currentSlide += 1;
      this.setCurrentSlide(this.currentSlide);
    }

    // if not first slide, show previous button
    if (this.currentSlide > 0) {
      this.previousTarget.classList.remove("hidden");
    }

    // if last slide, show submit and hide next button
    if (this.currentSlide === this.numberOfSlides - 1) {
      this.nextTarget.classList.add("hidden");
      this.submitTarget.classList.remove("hidden");
    }
  }

  nextWithValidation(ele) {
    if (eval(ele.target.dataset.evalFailBoolean)) {
      window.notyf.error(ele.target.dataset.evalFailMessage);
    } else {
      this.next();
    }
  }

  previous() {
    if (this.currentSlide - 1 >= 0) {
      this.currentSlide -= 1;
      this.setCurrentSlide(this.currentSlide);
    }

    // if first slide, show previous button
    if (this.currentSlide < this.numberOfSlides - 1) {
      this.nextTarget.classList.remove("hidden");
      this.submitTarget.classList.add("hidden");
    }

    // if last slide, show submit and hide next button
    if (this.currentSlide === 0) {
      this.previousTarget.classList.add("hidden");
    }
  }

  setCurrentSlide(index) {
    // show current
    this.slidesTarget.children[index]?.classList.remove("hidden");
    // hide prev
    this.slidesTarget.children[index - 1]?.classList.add("hidden");
    // hide next
    this.slidesTarget.children[index + 1]?.classList.add("hidden");
  }

  close() {
    this.element.classList.add("hidden");
  }

  close_modal() {
    this.modalTargets.forEach((target) => {
      target.classList.add("hidden");
    });
  }

  submitAndClose(event) {
    event.preventDefault();
    event.target.submit();
    this.close();
  }
}
