// app/javascript/controllers/deal_memo_ratings_controller.js
import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["ratingsField"];

  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      ghostClass: "bg-gray-300",
      handle: ".sortable__handle",
      onEnd: this.updateRatings.bind(this),
    });
  }

  updateRatings() {
    const items = Array.from(this.element.children);
    const ratings = items.map((item, index) => {
      return {
        text: item.querySelector('.rating-text').textContent.trim(),
        description: item.querySelector('.rating-description').textContent.trim()
      };
    });

    this.ratingsFieldTarget.value = JSON.stringify(ratings);
  }
}
