// app/javascript/controllers/adobe_viewer_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    pdfUrl: String,
    apiKey: String,
    title: String,
    showDownloadPdf: Boolean,
    showPrintPdf: Boolean,
  };

  connect() {
    if (document.getElementById("adobe-viewer-script")) {
      this.initializeAdobeViewer();
    } else {
      this.loadAdobeViewerScript()
        .then(() => {
          document.addEventListener("adobe_dc_view_sdk.ready", this.initializeAdobeViewer.bind(this));
        })
        .catch((error) => {
          console.error("Failed to load the Adobe Viewer script:", error);
        });
    }
  }

  loadAdobeViewerScript() {
    return new Promise((resolve, reject) => {
      if (document.getElementById("adobe-viewer-script")) {
        // Script is already loaded
        window.location.reload();
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = "adobe-viewer-script";
      script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
      script.onload = resolve;
      script.onerror = reject;

      document.head.appendChild(script);
    });
  }

  initializeAdobeViewer() {
    const adobeDCView = new AdobeDC.View({ clientId: this.apiKeyValue, divId: "adobe-dc-view" });

    const previewConfig = {
      showDownloadPDF: this.showDownloadPdfValue === true,
      showZoomControl: true,
      showAnnotationTools: false,
      showPrintPDF: this.showPrintPdfValue === true,
    };
    const decodedUrl = atob(this.pdfUrlValue);
    adobeDCView.previewFile(
      {
        content: { location: { url: decodedUrl } },
        metaData: { fileName: this.titleValue },
      },
      previewConfig
    );
    const eventOptions = {
      enablePDFAnalytics: true,
      enableFilePreviewEvents: true,
      listenOn: [
        AdobeDC.View.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE,
        AdobeDC.View.Enum.PDFAnalyticsEvents.HYPERLINK_OPEN,
      ],
    };

    adobeDCView.registerCallback(
      AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      function (event) {
        if (event.type == "CURRENT_ACTIVE_PAGE" && event.data.pageNumber !== undefined && event.data.pageNumber !== 0) {
          document.dispatchEvent(
            new CustomEvent("pdf-page-change", {
              detail: { pageNumber: event.data.pageNumber },
            })
          );
        } else if (event.type === "HYPERLINK_OPEN") {
          document.dispatchEvent(
            new CustomEvent("pdf-hyperlink-open", {
              detail: { pageNumber: event.data.pageNumber, url: event.data.url },
            })
          );
        }
      },
      eventOptions
    );
  }
}
