import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "toggler",
    "header",
    "form",
    "contextInput",
    "valueInput",
    "nameInput"
  ];

  connect() {
    document.querySelectorAll("[data-data-import--custom-column-modal-target='toggler']").forEach((button) => {
      button.addEventListener("click", (event) => {
        this.open(event);
      });
    });

    this.nameInputTarget.addEventListener("keyup", this.changeValueInputField.bind(this));
    this.nameInputTarget.addEventListener("change", this.changeValueInputField.bind(this));
  }

  open(event) {
    const targetDataset = event.target.dataset;

    // store dropdown element from where the modal was opened.
    this.activeDropdownElement = event.target.closest("[data-controller='data-import--mapping-dropdown']");

    this.resetModalInputsAndElements();

    this.element.classList.remove("hidden");
    this.headerTarget.innerHTML = targetDataset.headerText;
    this.contextInputTarget.value = targetDataset.contextInputVal;
  }

  close() {
    this.resetModalInputsAndElements();
    this.activeDropdownElement = undefined;
    this.element.classList.add("hidden");
  }

  changeValueInputField(event) {
    this.valueInputTarget.value = this.valuify(this.nameInputTarget.value);
  }

  resetModalInputsAndElements() {
    this.formTarget.reset();
    this.valueInputTarget.value = "";
  }

  selectMappingOption(optionKey) {
    if (this.activeDropdownElement) {
      this.activeDropdownElement.dataset["dataImport-MappingDropdownCurrentOptionKeyValue"] = optionKey;
    }
    this.close();
  }

  valuify(value) {
    if (!value) {
      return "";
    }

    return value
      .replace(/[^\w\s]/gi, "")
      .replace(/[\s]/gi, "_")
      .toLowerCase();
  }
}
