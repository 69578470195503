import { Controller } from "stimulus";
import consumer from "~/channels/consumer";

export default class extends Controller {
  static targets = ["typingNotification", "richText", "indicator"];

  connect() {
    const currentUserId = this.element.dataset.currentUserId;
    const postId = this.element.dataset.postId;
    const channelId = this.element.dataset.channelId;

    this.subscription = consumer.subscriptions.create(
      {
        channel: "TypingNotificationsChannel",
        post_id: postId,
        channel_id: channelId,
        user_id: currentUserId,
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (data) => {
          if (data.typing && data.user_id != currentUserId) {
            this.typingNotificationTarget.innerText = `${data.username} is typing...`;
            this.indicatorTarget.classList.remove("hidden");
          } else {
            this.typingNotificationTarget.innerText = "";
            this.indicatorTarget.classList.add("hidden");
          }
        },
      }
    );

    this.typingTimeout = null;
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  onInput() {
    clearTimeout(this.typingTimeout);

    const trixEditor = this.richTextTarget.editor;
    const content = trixEditor.getDocument().toString();

    if (content !== "") {
      this.subscription.perform("send_typing", { typing: true });
      this.typingTimeout = setTimeout(() => {
        this.subscription.perform("send_typing", { typing: false });
      }, 1000);
    } else {
      this.subscription.perform("send_typing", { typing: false });
    }
  }
}
