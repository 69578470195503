import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static values = { url: String };

  mouseleft = false;

  // (hfs 02/16/2024) We aren't using normal tippy tooltips because this controller also gets used from within Trix editors
  // and there are nuances that make it not work there, or make them not save properly in actiontext content
  connect() {
    this.element.addEventListener("mouseenter", this.mouseEnter.bind(this));
    this.element.addEventListener("mouseleave", this.mouseLeave.bind(this));
    this.profileHtml = undefined;
    this.fetchProfileHtml = debounce(this.fetchProfileHtml.bind(this), 200);
    this.preview = document.getElementById(`profile_tooltip`);
    if (!this.preview) this.preview = this.element.parentElement.appendChild(document.createElement("div"));
    this.preview.style.display = "none";
  }

  mouseEnter(event) {
    this.mouseleft = false;
    this.hoverTimeout = setTimeout(() => {
      if (this.profileHtml) {
        this.showPreview(event);
      } else {
        this.fetchProfileHtml(event);
      }
    }, 200);
  }

  fetchProfileHtml(event) {
    if (this.fetchInProgress) return;
    this.fetchInProgress = true;

    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.profileHtml = html;
        if (this.mouseleft === false) this.showPreview(event);
      })
      .catch((error) => {
        console.log(error);
        this.profileHtml = "";
      })
      .finally(() => {
        this.fetchInProgress = false;
      });
  }

  mouseLeave() {
    clearTimeout(this.hoverTimeout);
    this.mouseleft = true;
    if (this.preview) {
      this.preview.style.display = "none";
    }
  }

  showPreview(event) {
    this.preview.innerHTML = this.profileHtml;
    this.preview.style.display = "flex";
    this.positionPreviewNearMouse(event);
  }

  positionPreviewNearMouse(event) {
    this.preview.style.position = "fixed";
    this.preview.style.left = event.clientX + 10 + "px";
    this.preview.style.top = event.clientY + 10 + "px";
    this.preview.style.zIndex = 100;
  }
}
