import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row", "wrapper"];

  connect() {
    this.parsedResponseKeys = JSON.parse(this.data.get("responseKeys"));
    this.parsedComparisonTypes = JSON.parse(this.data.get("comparisonTypes"));
  }

  addComparison(event) {
    event.preventDefault();

    const currentIndex = parseInt(this.wrapperTarget.dataset.currentIndex, 10);
    const newIndex = currentIndex + 1;
    this.wrapperTarget.dataset.currentIndex = newIndex;

    const newRow = this.template(newIndex);

    this.wrapperTarget.insertAdjacentHTML("beforeend", newRow);
    this.refreshComparisonCount();
    this.manageDividers();
  }

  removeRow(event) {
    event.preventDefault();

    event.currentTarget.closest('[data-target="comparisons.row"]').remove();
    this.refreshComparisonCount();
    this.manageDividers();
  }

  refreshComparisonCount() {
    const rows = this.wrapperTarget.querySelectorAll('[data-target="comparisons.row"]');
    this.comparisonCount = rows.length;
  }

  manageDividers() {
    const rows = this.wrapperTarget.querySelectorAll('[data-target="comparisons.row"]');
    const dividers = this.wrapperTarget.querySelectorAll('[data-doc-gen--conditionals-target="divider"]');
    dividers.forEach((divider) => divider.remove()); // Clear all existing dividers

    const currentAndOrType = document.querySelector('input[name="conditional_params[and_or_type]"]:checked').value;
    const label = currentAndOrType == "1" ? "and" : "or";

    // Insert dividers between the comparison rows
    for (let i = 0; i < rows.length - 1; i++) {
      rows[i].insertAdjacentHTML(
        "afterend",
        `
            <div data-doc-gen--conditionals-target="divider" class="relative">
                <span data-doc-gen--conditionals-target="dividerLabel" class="py-0.5 px-1.5 rounded-sm bg-white border border-gray-300 text-xxxs font-semibold uppercase text-dark-700 z-2 absolute left-4 -top-2.5">
                  ${label}
                </span>
                <span class="w-full h-0.5 block border-t border-gray-200 z-1"></span>
            </div>
        `
      );
    }
  }

  handleAndOrTypeChange() {
    this.manageDividers();
  }

  template(index) {
    const responseKeys = this.parsedResponseKeys
      .map((option) => {
        return `<option value="${option[1]}">${option[0]}</option>`;
      })
      .join("");

    const comparisonTypes = this.parsedComparisonTypes
      .map((option) => {
        return `<option value="${option[1]}">${option[0]}</option>`;
      })
      .join("");

    return `
    <div data-target="comparisons.row" class="flex flex-col md:flex-row gap-3 py-4 last:!border-b">
      <div class="flex-1 md:flex-2">
        <select name="conditional_params[comparisons][${index}][response_key]" class="form-control">
          ${responseKeys}
        </select>
      </div>
      <div class="flex-1">
        <select name="conditional_params[comparisons][${index}][comparison_type]" class="form-control">
          ${comparisonTypes}
        </select>
      </div>
      <div class="flex-1">
        <input type="text" name="conditional_params[comparisons][${index}][response_value]" class="form-control">
      </div>
      <div class="flex items-center justify-end">
        <button data-action="doc-gen--conditionals#removeRow" type="button" class="btn-icon btn-icon-danger"><i class="ph-trash"></i></button>
      </div>
    </div>
  `;
  }
}
