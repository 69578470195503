import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    urlPath: String,
  };

  pipelineChanged(e) {
    post(this.urlPathValue, {
      body: { pipeline_id: e.currentTarget.value },
      responseKind: "turbo-stream",
    });
  }
}
