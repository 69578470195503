import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const trainingContentHubPath = urlParams.get("training_content_hub_path");
    if (trainingContentHubPath) {
      const url = `/training_contents?hub_path=${encodeURIComponent(trainingContentHubPath)}`;
      get(url, { responseKind: "turbo-stream" });
      // then clean up training_content_hub_path from the url
      const newUrl = window.location.href.replace(`training_content_hub_path=${trainingContentHubPath}`, "");
      window.history.replaceState({}, document.title, newUrl);
    }
  }
}
