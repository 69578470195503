import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "showButton", "hideButton", "triggerHolder"];

  connect() {
    this.showButtonTarget.onclick = () => this.show();
    this.hideButtonTarget.onclick = () => this.hide();
  }

  show() {
    this.containerTarget.classList.remove("hidden");
    this.triggerHolderTarget.classList.add("hidden");
  }

  hide() {
    this.containerTarget.classList.add("hidden");
    this.triggerHolderTarget.classList.remove("hidden");
  }
}
