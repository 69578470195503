import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["missingEmail"];
  static values = { errorCount: Number };

  missingEmailTargetDisconnected() {
    if (this.errorCountValue == 1) this.element.classList.add("hidden");
  }
}
