import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "whoSelect",
    "whatSelect",
    "counterpartyType",
    "accountColumn",
    "counterpartyId",
    "submitButton",
    "submitButtonText",
    "submitAction",
    "pendingColumn",
    "pipelineStageIdColumn",
    "reportable1099Checkbox",
  ];

  static values = {
    entityId: String,
  };

  connect() {
    if (this.hasSufficientTargets()) {
      this.checkFormValidity();
      this.setInitialCounterparty();
      this.whoSelectTarget.addEventListener("change", this.handleWhoSelectChange);
      this.whatSelectTarget.addEventListener("change", this.handleWhatSelectChange);
    }
    document.addEventListener("newEntityCreated", this.handleNewEntityCreated.bind(this));
  }

  hasSufficientTargets() {
    return (
      this.hasWhoSelectTarget &&
      this.hasWhatSelectTarget &&
      this.hasCounterpartyTypeTarget &&
      this.hasCounterpartyIdTarget &&
      this.hasAccountColumnTarget &&
      this.hasSubmitButtonTarget &&
      this.hasSubmitButtonTextTarget &&
      this.hasSubmitActionTarget &&
      this.hasPendingColumnTarget &&
      this.hasPipelineStageIdColumnTarget &&
      this.hasReportable1099CheckboxTarget
    );
  }

  setInitialCounterparty() {
    // Add safety check at the beginning of the method
    if (!this.hasCounterpartyIdTarget || !this.hasCounterpartyTypeTarget || !this.hasWhoSelectTarget) {
      return;
    }

    const counterpartyId = this.counterpartyIdTarget.value;
    const counterpartyType = this.counterpartyTypeTarget.value;

    if (counterpartyId && counterpartyType) {
      const counterpartyTypeAbbreviation = this.getCounterpartyTypeAbbreviation(counterpartyType);
      const initialValue = `${counterpartyTypeAbbreviation}_${counterpartyId}`;
      this.whoSelectTarget.value = initialValue;

      setTimeout(() => {
        if (this.hasWhoSelectTarget && this.whoSelectTarget.tomSelect) {
          this.whoSelectTarget.tomSelect.setValue(initialValue);
        }
      }, 0);
    }
  }

  disconnect() {
    if (this.hasSufficientTargets()) {
      this.whoSelectTarget.removeEventListener("change", this.handleWhoSelectChange);
      this.whatSelectTarget.removeEventListener("change", this.handleWhatSelectChange);
    }
    document.removeEventListener("newEntityCreated", this.handleNewEntityCreated);
  }

  handleWhoSelectChange = () => {
    this.setCounterparty();
    this.updateWhatOptions();
    this.checkFormValidity();
    this.check1099Eligibility();
  };

  handleWhatSelectChange = () => {
    this.setAccountId();
    this.checkFormValidity();
    this.check1099Eligibility();
  };

  setInitialCounterparty() {
    const counterpartyId = this.counterpartyIdTarget.value;
    const counterpartyType = this.counterpartyTypeTarget.value;

    if (counterpartyId && counterpartyType) {
      const counterpartyTypeAbbreviation = this.getCounterpartyTypeAbbreviation(counterpartyType);
      const initialValue = `${counterpartyTypeAbbreviation}_${counterpartyId}`;
      this.whoSelectTarget.value = initialValue;

      // Wait for the tomSelect instance to be available before setting the value
      setTimeout(() => {
        if (this.whoSelectTarget.tomSelect) {
          this.whoSelectTarget.tomSelect.setValue(initialValue);
        }
      }, 0);
    }
  }

  setCounterparty() {
    if (!this.hasSufficientTargets()) return;
    const selectedValue = this.whoSelectTarget.value;

    if (!selectedValue) {
      return;
    }

    const valueParts = selectedValue.split("_");

    if (valueParts.length === 2) {
      const counterpartyType = valueParts[0];
      const counterpartyId = valueParts[1];

      this.counterpartyIdTarget.value = counterpartyId;
      this.counterpartyTypeTarget.value = this.getCounterpartyTypeClass(counterpartyType);
    } else {
      this.counterpartyIdTarget.value = "";
      this.counterpartyTypeTarget.value = "";
    }
  }

  getCounterpartyTypeAbbreviation(counterpartyType) {
    switch (counterpartyType) {
      case "FirmAdmin::PortfolioCompany":
        return "pc";
      case "FirmAdmin::CapitalAccount":
        return "ca";
      case "Organization":
        return "org";
      case "FirmAdmin::AccountingEntity":
        return "ae";
      default:
        return "";
    }
  }

  getCounterpartyTypeClass(counterpartyType) {
    switch (counterpartyType) {
      case "pc":
        return "FirmAdmin::PortfolioCompany";
      case "ca":
        return "FirmAdmin::CapitalAccount";
      case "org":
        return "Organization";
      case "ae":
        return "FirmAdmin::AccountingEntity";
      default:
        return "";
    }
  }

  setAccountId() {
    if (!this.hasSufficientTargets()) return;

    const selectedOption = this.whatSelectTarget.value;
    const isRequestAdditionalData = selectedOption.startsWith("request_additional_data_");

    if (!isRequestAdditionalData) {
      const accountId = selectedOption;
      const isDebit = this.determineAccountType();
      const accountField = isDebit
        ? "firm_admin_accounting_transaction[firm_admin_account_debit_id]"
        : "firm_admin_accounting_transaction[firm_admin_account_credit_id]";
      this.accountColumnTarget.name = accountField;
      this.accountColumnTarget.value = accountId;
    } else {
      // Set the data_request_action field when a request additional data option is selected
      this.accountColumnTarget.name = "firm_admin_accounting_transaction[data_request_action]";
      this.accountColumnTarget.value = selectedOption;
    }
  }

  determineAccountType() {
    return this.accountColumnTarget.name === "firm_admin_accounting_transaction[firm_admin_account_debit_id]";
  }

  updateSubmitButtonText(buttonText) {
    if (!this.hasSubmitButtonTextTarget) return;

    this.submitButtonTextTarget.textContent = buttonText;
  }

  checkFormValidity() {
    try {
      if (this.hasWhoSelectTarget && this.hasWhatSelectTarget) {
        const isValid = this.whoSelectTarget.value && this.whatSelectTarget.value;
        this.submitButtonTarget.disabled = !isValid;
      }
    } catch (error) {
      console.warn("Form elements not found, skipping validation");
    }
  }

  setDataRequested() {
    const selectedOption = this.whatSelectTarget.value;
    const isRequestAdditionalData = selectedOption.startsWith("request_additional_data_");

    if (isRequestAdditionalData) {
      const pipelineStageId = selectedOption.split("_")[3];
      this.pendingColumnTarget.value = "true";
      this.pipelineStageIdColumnTarget.value = pipelineStageId;
      this.accountColumnTarget.name = "firm_admin_accounting_transaction[data_request_action]";
      this.accountColumnTarget.value = selectedOption;

      const creditIdField = document.querySelector(
        'input[name="firm_admin_accounting_transaction[firm_admin_account_credit_id]"]'
      );
      const debitIdField = document.querySelector(
        'input[name="firm_admin_accounting_transaction[firm_admin_account_debit_id]"]'
      );

      if (creditIdField) {
        creditIdField.value = null;
      }
      if (debitIdField) {
        debitIdField.value = null;
      }
    } else {
      this.pendingColumnTarget.value = "";
      this.pipelineStageIdColumnTarget.value = "";
    }

    this.updateSubmitAction(isRequestAdditionalData);
    this.updateSubmitButtonText(isRequestAdditionalData ? "Send Request" : "Reconcile");
  }

  updateSubmitAction(isRequestAdditionalData) {
    this.submitActionTarget.value = isRequestAdditionalData ? "send_request" : "reconcile";
  }

  updateWhatOptions() {
    const selectedWhoOption = this.whoSelectTarget.value;
    const whatSelect = this.whatSelectTarget.tomSelect;

    if (whatSelect) {
      const optionsData = JSON.parse(this.whatSelectTarget.getAttribute("data-options-data"));
      const requestDataOptions = optionsData.find((group) => group.label === "Request Additional Data")?.options || [];

      if (selectedWhoOption && requestDataOptions.length > 0) {
        const valueParts = selectedWhoOption.split("_");
        if (valueParts.length === 2 || valueParts.length === 3) {
          const counterpartyType = valueParts[0];
          if (counterpartyType === "org" || counterpartyType === "pc" || counterpartyType === "accounting") {
            requestDataOptions.forEach((option) => {
              const existingOption = whatSelect.options[option.value];
              if (!existingOption) {
                whatSelect.addOption(option);
              }
            });
          } else {
            requestDataOptions.forEach((option) => {
              whatSelect.removeOption(option.value);
            });
          }
        } else {
          requestDataOptions.forEach((option) => {
            whatSelect.removeOption(option.value);
          });
        }
      } else {
        requestDataOptions.forEach((option) => {
          whatSelect.removeOption(option.value);
        });
      }

      whatSelect.refreshOptions(false);
    }
  }

  handleSubmit(event) {
    if (event.detail.success) {
      const frame = document.getElementById("pagination");
      if (frame) {
        // Use Turbo's visit method to reload the frame
        Turbo.visit(frame.getAttribute("src"), { frame: "pagination" });
      }
    }
  }

  handleNewEntityCreated(event) {
    const data = event.detail;
    const transactionId = data.transactionId;
    const whatSelect = document.querySelector(
      `[data-transaction-id="${transactionId}"] [data-firm-admin--accounting-transactions--reconcile-target="whatSelect"]`
    ).tomSelect;

    if (whatSelect) {
      Object.values(whatSelect.options)
        .filter((opt) => typeof opt.value === "string" && opt.value.startsWith("request_additional_data_"))
        .forEach((opt) => whatSelect.removeOption(opt.value));

      data.request_data_options.forEach((group) => {
        group.options.forEach((option) => {
          whatSelect.addOption(option);
        });
      });

      whatSelect.refreshOptions(false);
    }
  }

  async check1099Eligibility() {
    const counterpartyType = this.counterpartyTypeTarget.value;
    const counterpartyId = this.counterpartyIdTarget.value;
    const accountId = this.whatSelectTarget.value;

    if (!counterpartyType || !counterpartyId || !accountId) {
      if (this.hasReportable1099CheckboxTarget) {
        this.reportable1099CheckboxTarget.checked = false;
      }
      return;
    }

    try {
      const response = await fetch(
        `/firm_admin/entities/${this.entityIdValue}/accounting_transactions/check_1099_eligibility`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          },
          body: JSON.stringify({
            counterparty_type: counterpartyType,
            counterparty_id: counterpartyId,
            account_id: accountId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (this.hasReportable1099CheckboxTarget) {
          this.reportable1099CheckboxTarget.checked = data.is_eligible;
        }
      }
    } catch (error) {
      console.error("Error checking 1099 eligibility:", error);
      if (this.hasReportable1099CheckboxTarget) {
        this.reportable1099CheckboxTarget.checked = false;
      }
    }
  }

  handle1099Change(event) {
    // We can use this if we need to handle manual changes to the checkbox
  }
}
