import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor", "submitButton", "form"];

  formTargetConnected() {
    this.formTarget.addEventListener("turbo:submit-end", () => {
      this.updateButtonState();
    });
  }

  editorTargetConnected() {
    this.editorTarget.addEventListener("trix-initialize", () => this.updateButtonState());
    this.editorTarget.addEventListener("input", () => this.updateButtonState());
    this.editorTarget.addEventListener("paste", () => {
      setTimeout(() => this.updateButtonState(), 0);
    });
  }

  updateButtonState() {
    if (this.editorHasEmptyContent()) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  formSubmit(event) {
    if (this.editorHasEmptyContent()) {
      event.preventDefault();
      alert("Content cannot be empty.");
    }
  }

  editorHasEmptyContent() {
    const content = this.editorTarget.editor.getDocument().toString().trim();
    return content === "";
  }

  disconnect() {
    this.editorTarget.removeEventListener("input", () => this.updateButtonState());
    this.formTarget.removeEventListener("turbo:submit-end", () => this.updateButtonState());
  }
}
