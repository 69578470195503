import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  updateStages() {
    this.preventModalClose.value = true;
    this.formTarget.requestSubmit();
  }

  get preventModalClose() {
    return document.querySelector("#prevent_modal_close");
  }
}
