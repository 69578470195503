import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const otherController = this.application.getControllerForElementAndIdentifier(
      this.element.closest("[data-controller='data-import--custom-column-modal']"),
      'data-import--custom-column-modal'
    )
    otherController.selectMappingOption(this.element.value);
    this.element.remove();
  }
}
