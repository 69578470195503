import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    requestUrl: String,
    redirectUrl: String,
  };

  connect() {
    const SECONDS_INTERVAL = 5;

    this.interval = setInterval(() => {
      this.doRequest();
    }, SECONDS_INTERVAL * 1000);
  }

  disconnect(){
    clearInterval(this.interval);
  }

  doRequest(){
    Rails.ajax({
      url: this.requestUrlValue,
      type: "GET",
      success: (data) => {
        if(data.redirectURL){
          window.location.href = data.redirectURL;
        } else {
          window.location.href = this.redirectUrlValue;
        }
      }
    })
  }
}
