import { Controller } from "stimulus"

export default class extends Controller {
  static values = { selector: String };

  toggle() {
    const elements = document.querySelectorAll(this.selectorValue);
    elements.forEach(element => {
      element.classList.toggle("hidden");
    });
  }
}
