import { Controller } from "stimulus";
import Tribute from "tributejs";
import Trix from "trix";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["field"];
  static values = { channelId: Number, postId: Number, channelPublic: Boolean };

  connect() {
    this.createProfileTooltipDiv();
    this.getUsersJson();
    this.nonMemberMentions = new Set();
    this.continueSubmissionHandler = this.continueSubmission.bind(this);
    document.addEventListener("mentions:continue-submission", this.continueSubmissionHandler);
  }

  disconnect() {
    if (this.tribute && this.fieldTarget) {
      this.tribute.detach(this.fieldTarget);
    }
    document.removeEventListener("mentions:continue-submission", this.continueSubmissionHandler);
  }

  continueSubmission() {
    this.nonMemberMentions.clear();
    this.element.requestSubmit();
  }

  fieldTargetConnected() {
    this.fieldTarget.addEventListener("trix-initialize", this.initializeEditor.bind(this));
  }

  initializeEditor() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }

  // (hfs 02/16/2024) This div is necessary as a workaround for dynamic content while in a Trix editor area
  // while also working around limits on what can be saved into actiontext content
  createProfileTooltipDiv() {
    const profile_tooltip = this.element.parentElement.appendChild(
      document.createElement("div", { id: "profile_tooltip", style: "display: none;" })
    );
    profile_tooltip.style.display = "none";
    profile_tooltip.id = "profile_tooltip";
  }

  getUsersJson() {
    if (window.mentionsUsersList) {
      this.usersList = window.mentionsUsersList.users;
      this.channelMemberIds = new Set(window.mentionsUsersList.channel_member_ids);
      return;
    }

    fetch(`/mentions.json?channel_id=${this.channelIdValue}&post_id=${this.postIdValue}`)
      .then((response) => response.json())
      .then((data) => {
        window.mentionsUsersList = data;
        this.usersList = data.users;
        this.channelMemberIds = new Set(data.channel_member_ids);
      })
      .catch((error) => {
        this.usersList = [];
      });
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "name",
      values: this.filterUsers.bind(this),
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener("tribute-replaced", this.handleReplaced.bind(this));
    this.fieldTarget.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(event) {
    const tributeContainer = this.tribute.menu;
    if (tributeContainer && tributeContainer.contains(event.target)) {
      event.preventDefault();
      const li = event.target.closest("li");
      if (li) {
        const index = Array.from(li.parentNode.children).indexOf(li);
        this.tribute.selectItemAtIndex(index);
        this.tribute.hideMenu();
      }
    }
  }

  filterUsers(text, callback) {
    if (text.length < 1 || !this.usersList) return;

    const filteredUsers = this.usersList
      .filter((user) =>
        user.name
          .replace(/\(.*\)/, "")
          .toLowerCase()
          .includes(text.toLowerCase())
      )
      .slice(0, 10);
    callback(filteredUsers);
  }

  handleReplaced(e) {
    const mention = e.detail.item.original;

    if (mention.name === "@channel") {
      this.handleChannelMention(mention);
      return;
    }

    if (!this.channelPublicValue && !this.channelMemberIds.has(mention.id)) {
      this.nonMemberMentions.add(mention);
    }

    this.insertMention(mention);
  }

  checkNonMemberMentions(event) {
    if (this.nonMemberMentions.size > 0) {
      event.preventDefault();
      this.showNonMemberModal();
    }
  }

  async showNonMemberModal() {
    const nonMembers = Array.from(this.nonMemberMentions);
    const url = `/base/${this.channelIdValue}/show_add_members`;

    await get(url, {
      query: { mentions: JSON.stringify(nonMembers) },
      responseKind: "turbo-stream",
    });
  }

  handleChannelMention(mention) {
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      is_channel: true,
    });

    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");

    const event = new CustomEvent("channel-mention-added", {
      detail: { count: mention.count },
      bubbles: true,
    });
    document.dispatchEvent(event);
  }

  insertMention(mention) {
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    let tributeLength = endPos - startPos;
    let trixStartPos = position - tributeLength;
    let trixEndPos = position;
    this.editor.setSelectedRange([trixStartPos, trixEndPos]);
    this.editor.deleteInDirection("backward");
  }
}
