import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["googleDocId"];

  toggle(e) {
    const value = e.target.value;
    value ? this.show() : this.hide();
  }

  hide() {
    this.googleDocIdTarget.classList.add("hidden");
    const field = this.googleDocIdTarget.querySelector("input");
    field.required = false;
  }

  show() {
    this.googleDocIdTarget.classList.remove("hidden");
    const field = this.googleDocIdTarget.querySelector("input");
    field.required = true;
  }
}
