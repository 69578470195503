import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fundingRounds", "form"];

  connect() {
    this.slidesEvents();
  }

  showForm() {
    this.fundingRoundsTarget.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
  }

  showFundingRounds() {
    this.fundingRoundsTarget.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
  }

  slidesEvents() {
    document
      .getElementById("next-slide")
      .addEventListener("click", function () {
        document.getElementById("first-slide").classList.add("!hidden");
        document.getElementById("second-slide").classList.remove("!hidden");
      });
  }
}
