import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    this.tabTargets.forEach((tab) => {
      tab.addEventListener("click", this.tabClick);
    });

    this.showDefaultContent();
    this.checkQueryString();
  }

  disconnect() {
    this.tabTargets.forEach((tab) => {
      tab.removeEventListener("click", this.tabClick);
    });
  }

  showDefaultContent() {
    const defaultContent = this.contentTargets.find((content) =>
      content.classList.contains("default")
    );

    if (defaultContent) {
      defaultContent.classList.remove("hidden");
    }
  }

  checkQueryString() {
    const params = new URLSearchParams(window.location.search);
    const tabId = params.get("tab");

    if (tabId) {
      const tab = this.tabTargets.find((tab) => tab.dataset.tabId === tabId);
      if (tab) {
        this.resetAllTabs();
        this.resetAllContents();
        this.setTabActive(tab);
        this.setContentActive(tab);
      }
    }
  }

  tabClick = (event) => {
    const tab = event.currentTarget;
    const isActive = tab.classList.contains("active-state");

    this.resetAllTabs();
    this.resetAllContents();

    if (!isActive) {
      this.setTabActive(tab);
      this.setContentActive(tab);
    } else {
      this.setTabInactive(tab);
      this.showDefaultContent();
    }

    event.preventDefault();
  };

  resetAllTabs() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active-state");
    });
  }

  resetAllContents() {
    this.contentTargets.forEach((content) => {
      content.classList.add("hidden");
    });
  }

  setTabActive(tab) {
    tab.classList.add("active-state");
  }

  setTabInactive(tab) {
    tab.classList.remove("active-state");
  }

  setContentActive(tab) {
    const contentId = tab.dataset.tabId;
    const content = this.contentTargets.find(
      (content) => content.id === contentId
    );

    if (content) {
      content.classList.remove("hidden");
    }
  }
}
