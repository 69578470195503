import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "optionTemplate",
    "optionsContainer",
    "addButton",
    "removeButton",
  ];

  connect() {
    this.prepareOptions();
  }

  prepareOptions() {
    this.setInitialOptions();
    this.setInitialValues();
  }

  setInitialOptions() {
    this.getValues().forEach(() => this.addOption());
  }

  setInitialValues() {
    this.optionsContainerTarget
      .querySelectorAll("input")
      .forEach((input, index) => {
        const value = this.getValues()[index];
        Array.isArray(value) ? input.value = JSON.stringify(value) : input.value = value;
      });
  }

  getValues() {
    return JSON.parse(this.inputTarget.value);
  }

  addOption() {
    this.optionsContainerTarget.insertAdjacentHTML(
      "beforeend",
      this.optionTemplateTarget.innerHTML
    );
  }

  removeOption(e) {
    if (this.checkIfOnlyOneOptionPresent()) return;

    const button = e.target;
    const optionElement = button.closest("[data-element-option]");
    optionElement.remove();
    this.updateInputValue();
    this.updateItemsInputIfExists();
  }

  updateInputValue() {
    const optionsArray = [];
    this.optionsInputs().forEach((input) => {
      if (input.value.length) optionsArray.push(input.value);
    });
    this.inputTarget.value = JSON.stringify(optionsArray);
  }

  optionsInputs() {
    return this.optionsContainerTarget.querySelectorAll("input");
  }

  checkIfOnlyOneOptionPresent() {
    if (this.optionsInputs().length === 1) {
      window.notyf.error("Must have at least one option");
      return true;
    }

    return false;
  }

  updateItemsInputIfExists() {
    const itemsInputController = this.itemsInputController();
    if (itemsInputController) {
      itemsInputController.inputChanged();
    }
  }

  itemsInputController() {
    const parentElement = this.element.closest("[data-controller='doc-gen--items--input-fields--list']");
    const parentController = this.application.getControllerForElementAndIdentifier(parentElement, "doc-gen--items--input-fields--list");
    return parentController;
  }
}
