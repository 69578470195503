// TODO: Delete this
// Ensures only one element is "visually" active.

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { activeClasses: Array };
  static targets = ["node"];

  activate(event) {
    this.nodeTargets.forEach((node) => {
      if (node === event.currentTarget) {
        node.classList.add(...this.activeClassesValue);
      } else {
        node.classList.remove(...this.activeClassesValue);
      }
    });
  }
}
