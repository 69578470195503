import { Controller } from "stimulus";
import { get, put } from "@rails/request.js";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["tippyLoadingTemplate", "input"];
  static values = { input: String };

  connect() {}

  async showTippy(event) {
    const target = event.currentTarget;

    if (target._tippy) {
      target._tippy.show();
      return;
    }

    const hideOnEsc = {
      name: "hideOnEsc",
      defaultValue: true,
      fn({ hide }) {
        function onKeyDown(event) {
          if (event.keyCode === 27) {
            hide();
          }
        }

        return {
          onShow() {
            document.addEventListener("keydown", onKeyDown);
          },
          onHide() {
            document.removeEventListener("keydown", onKeyDown);
          },
        };
      },
    };

    const tippyInstance = tippy(target, {
      appendTo: () => this.element.closest("form")?.parentElement || document.body,
      allowHTML: true,
      interactive: true,
      arrow: false,
      theme: "custom",
      maxWidth: "none",
      offset: [0, 0],
      content: this.tippyLoadingTemplateTarget.innerHTML,
      placement: "bottom-start",
      hideOnClick: true,
      showOnCreate: true,
      trigger: "click",
      plugins: [hideOnEsc],
    });

    const response = await get(target.dataset.url);

    if (response.ok) {
      const body = await response.text;
      tippyInstance?.setContent(body);
    }
  }

  hideTippy(event) {
    const target = event.currentTarget;

    if (target._tippy) {
      target._tippy.hide();
    }
  }
}
