// get from body data-bugsnag-api-key
import Bugsnag from "@bugsnag/js";

document.addEventListener("DOMContentLoaded", function () {
  const bugsnagApikey = import.meta.env.VITE_BUGSNAG_API_KEY;
  let enabledBugsnag = false;
  if (bugsnagApikey && bugsnagApikey !== null && bugsnagApikey.length > 0) {
    // Logs javascript errors to Bugsnag
    Bugsnag.start(bugsnagApikey);
    enabledBugsnag = true;
  }

  document.addEventListener("turbo:frame-missing", function (event) {
    console.log("turbo:frame-missing", event.target.id, event.detail.response.url);

    if (enabledBugsnag) {
      Bugsnag.notify(new Error("turbo:frame-missing"), function (bugsnagEvent) {
        bugsnagEvent.addMetadata("eventDetails", {
          turboframeId: event.target.id,
          responseUrl: event.detail.response.url,
        });
      });
    } else {
      alert(
        `turbo:frame-missing ${event.target.id} ${event.detail.response.url}. Add target="_top" to the turbo-frame if you want to break out of the frame.`
      );
      throw new Error(
        `turbo:frame-missing ${event.target.id} ${event.detail.response.url}. Add target="_top" to the turbo-frame if you want to break out of the frame.`
      );
    }
  });
});
