import { Controller } from "stimulus";
import { get } from "@rails/request.js";
import { debounce } from "~/utils/lodashish";
export default class extends Controller {
  static values = {
    updateUrl: String,
    alreadyCalled: Number,
    remainingToCall: Number,
  };

  static targets = ["percentageToCallHidden", "percentageToCall", "amountToCall"];

  connect() {
    this.initializePercentage();
    this.initializeAmountToCall();
  }

  initializePercentage() {
    const percentage = this.calculatePercentage(this.percentageToCallHiddenTarget.value);
    this.percentageToCallTarget.value = percentage;
  }

  initializeAmountToCall() {
    const percentage = this.calculatePercentage(this.percentageToCallHiddenTarget.value);
    this.amountToCallTarget.value = this.convertPercentageToAmountToCall(percentage);
  }

  percentageToCallChanged(e) {
    const target = e.target;
    const percentage = this.calculatePercentage(target.value);
    this.debouncedUpdateCapitalCallModalRequest(percentage);
  }

  amountToCallChanged(e) {
    const target = e.target;
    const amountToCall = target.value;
    const percentage = this.calculatePercentage(this.convertAmountToCallToPercentage(amountToCall));
    this.debouncedUpdateCapitalCallModalRequest(percentage);
  }

  debouncedUpdateCapitalCallModalRequest = debounce((percentage) => {
    this.updateCapitalCallModalRequest(percentage);
  }, 1000);

  updateCapitalCallModalRequest(percentage) {
    this.setLoader();
    this.updateHiddenPercentageValue(percentage);
    get(
      this.urlWithParams(this.updateUrlValue, {
        param: "firm_admin_capital_call[percentage_to_call]",
        value: percentage,
      }),
      { responseKind: "turbo-stream" }
    );
  }

  setLoader() {
    document.getElementById("capital-call-update-loader").classList.remove("hidden");
  }

  urlWithParams(url, { param, value }) {
    const newUrl = new URL(url);
    newUrl.searchParams.append(param, value);
    return newUrl.toString();
  }

  calculatePercentage(value) {
    return this.validatePercentage(value, this.calculateMaxAllowedPercentage());
  }

  calculateMaxAllowedPercentage() {
    return Number(((this.remainingToCallValue / this.fundTotal()) * 100).toFixed(2));
  }

  validatePercentage(percentage, maxAllowedPercentage = 100) {
    if (percentage > maxAllowedPercentage) return maxAllowedPercentage;
    if (percentage < 0) return 0;
    return percentage;
  }

  convertAmountToCallToPercentage(amountToCall) {
    return Number(((this.stripAndParse(amountToCall) / this.fundTotal()) * 100).toFixed(2));
  }

  convertPercentageToAmountToCall(percentage) {
    return Number(((percentage / 100) * this.fundTotal()).toFixed(0));
  }

  fundTotal() {
    return Number(this.remainingToCallValue + this.alreadyCalledValue);
  }

  updateHiddenPercentageValue(value) {
    this.percentageToCallHiddenTarget.value = value;
  }

  stripAndParse(value) {
    return Number(parseFloat(value.replace(/[^0-9\.]/g, "")).toFixed(2)) || 0;
  }
}
