import { Controller } from "stimulus";
import { post, get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["emailTemplateIdField", "changeTemplateLink", "currentTemplateBtn", "testEmailForm"];
  static values = { changeTemplatePath: String, changeLoadingPath: String };
  primaryPerson = null;

  connect() {}

  updatePrimaryPerson(e) {
    this.primaryPersonId = e.detail.personId;
  }

  async resetTemplate(e) {
    const formData = this.formData(e, true);

    await post(this.changeLoadingPathValue, {
      body: formData,
      responseKind: "turbo-stream",
    });

    await post(this.changeTemplatePathValue, {
      body: formData,
      responseKind: "turbo-stream",
    });

    if (e.target.dataset.emailTemplateId) {
      this.emailTemplateIdFieldTarget.value = e.target.dataset.emailTemplateId;
      this.currentTemplateBtnTarget.innerText = e.target.dataset.emailTemplateName;
    }
  }

  async updateTemplate(e, skipLoading = false) {
    const formData = this.formData(e);

    if (!skipLoading) {
      await post(this.changeLoadingPathValue, {
        responseKind: "turbo-stream",
      });
    }

    await post(this.changeTemplatePathValue, {
      body: formData,
      responseKind: "turbo-stream",
    });

    if (e.target.dataset.emailTemplateId) {
      this.emailTemplateIdFieldTarget.value = e.target.dataset.emailTemplateId;
      this.currentTemplateBtnTarget.innerText = e.target.dataset.emailTemplateName;
    }
  }

  formData(e, removeUpdateFields = false) {
    const form = this.element.closest("form");
    const formData = new FormData(form);

    if (removeUpdateFields) {
      formData.delete("pipeline_email[body]");
      formData.delete("pipeline_email[rich_subject]");
      formData.delete("pipeline_email[generative_enabled]");
      formData.delete("pipeline_email[generative_language]");
      formData.delete("pipeline_email[generative_directive]");
    }

    if (e.target.dataset.emailTemplateId) {
      formData.append("email_template_id", e.target.dataset.emailTemplateId);
    }

    return formData;
  }

  showTestEmailForm(e) {
    e.preventDefault();
    this.testEmailFormTarget.classList.remove("hidden");
  }

  hideTestEmailForm(e) {
    e.preventDefault();
    this.testEmailFormTarget.classList.add("hidden");
  }
}
