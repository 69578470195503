import { Controller } from "stimulus";
import tippy, { followCursor } from "tippy.js";

export default class extends Controller {
  static targets = ["button", "menu", "item", "appendTo", "opener"];

  connect() {
    this.addSubmitFormEventListeners();
  }

  disconnect() {
    this.removeSubmitFormEventListeners();
    this.removeItemClickEventListeners();
    if (this.tippy && this.tippy !== null) {
      this.tippy.destroy();
    }
  }

  openerTargetConnected() {
    this.openerTarget.addEventListener("click", this.show.bind(this));
  }

  itemTargetConnected() {
    this.setItemClickEventListeners();
  }

  buttonTargetConnected() {
    this.setTippy();
  }

  menuTargetConnected() {
    this.setTippy();
  }

  setTippy() {
    if (!this.hasMenuTarget || !this.hasButtonTarget) {
      return;
    }

    this.tippy = tippy(this.buttonTarget, {
      appendTo: () => (this.hasAppendToTarget ? this.appendToTarget : document.body),
      allowHTML: true,
      interactive: true,
      arrow: false,
      theme: "custom",
      maxWidth: "none",
      offset: [0, 0],
      content: this.menuTarget,
      placement: this.menuTarget.dataset.tippyDropdownPlacement || "bottom-start",
      trigger: this.menuTarget.dataset.tippyDropdownTrigger || "click",
      delay: [
        this.menuTarget.dataset.tippyDropdownDelayShow || null,
        this.menuTarget.dataset.tippyDropdownDelayHide || 200,
      ],
      followCursor: this.menuTarget.dataset.tippyDropdownFollowCursor || false,
      plugins: [followCursor],
      onShown(instance) {
        document.addEventListener("turbo:before-fetch-response", (event) => {
          if (event.detail.fetchResponse.response.headers.get("X-Keep-Tippy-Open")) return;

          instance.hide();
        });
      },
    });
  }

  setItemClickEventListeners() {
    this.itemTargets.forEach((item) => {
      item.addEventListener("click", this.hide.bind(this));
    });
  }

  removeItemClickEventListeners() {
    this.itemTargets.forEach((item) => {
      item.removeEventListener("click", this.hide.bind(this));
    });
  }

  addSubmitFormEventListeners() {
    document.addEventListener("turbo:submit-start", this.handleSubmit);
  }

  removeSubmitFormEventListeners() {
    document.removeEventListener("turbo:submit-start", this.handleSubmit);
  }

  hide() {
    if (this.tippy) {
      this.tippy.hide();
    }
  }

  show() {
    if (this.tippy) {
      this.tippy.show();
    }
  }

  handleSubmit = (e) => {
    if (e.detail.formSubmission.submitter?.dataset?.tippyDropdownTarget == "item") this.hide();
  };
}
