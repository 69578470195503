import { Controller } from "stimulus";
import SignaturePad from "signature_pad";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static targets = [
    "typeSelect",
    "typedSignatureContainer",
    "typedSignatureFontContainer",
    "typedSignatureInput",
    "typedSignatureResult",
    "typedSignatureFontRadio",
    "drawnSignatureContainer",
    "drawnSignatureInput",
    "drawnSignatureCanvas",
  ];

  static values = {
    signatureFontsHash: Object,
  };

  initialize() {
    this.canvas = this.drawnSignatureCanvasTarget;
    this.signaturePad = new SignaturePad(this.canvas, {
      minWidth: 2.5,
      maxWidth: 5,
    });
    this.debounceResizeCanvas = debounce(this.setUpDrawnSignature, 300).bind(this);
  }

  connect() {
    this.initialTypedSignatureValue = this.typedSignatureInputTarget.value;
    this.initialDrawnSignatureValue = this.drawnSignatureInputTarget.value;
    this.initialRender = true;

    this.typeChanged();
    this.showTypedSignatureResult();
    this.addDrawFinishEventListener();
    this.addResizeCanvasEventListener();
    this.addDrawStartEventListener();
    this.setUpDrawnSignature();
    this.prePopulateTypedSignatureInput();
  }

  disconnect() {
    this.removeDrawFinishEventListener();
    this.removeDrawStartEventListener();
    this.removeResizeCanvasEventListener();
  }

  setUpDrawnSignature = () => {
    this.resizeCanvas();
    this.updateDrawnImage();
    this.signaturePad.on();
    this.setDrawnSignatureImageValue(this.initialDrawnSignatureValue);
  };

  updateDrawnImage() {
    if (!this.initialDrawnSignatureValue) return;

    this.signaturePad.fromDataURL(this.initialDrawnSignatureValue, {
      ratio: this.getRatio(),
      width: this.canvas.offsetWidth,
      height: this.canvas.offsetHeight,
    });
    this.hideDrawYourSignaturePlaceholder();
  }

  addDrawFinishEventListener() {
    this.signaturePad.addEventListener("endStroke", this.setDrawnSignatureImage);
  }

  removeDrawFinishEventListener() {
    this.signaturePad.removeEventListener("endStroke", this.setDrawnSignatureImage);
  }

  addDrawStartEventListener() {
    this.signaturePad.addEventListener("beginStroke", this.hideDrawYourSignaturePlaceholder);
  }

  removeDrawStartEventListener() {
    this.signaturePad.removeEventListener("beginStroke", this.hideDrawYourSignaturePlaceholder);
  }

  addResizeCanvasEventListener() {
    window.addEventListener("resize", this.debounceResizeCanvas);
  }

  removeResizeCanvasEventListener() {
    window.removeEventListener("resize", this.debounceResizeCanvas);
  }

  setDrawnSignatureImage = () => {
    const data = this.signaturePad.toDataURL();
    this.drawnSignatureInputTarget.value = data;
    this.hideDrawYourSignaturePlaceholder();
  };

  clearDrawnSignature() {
    this.signaturePad.clear();
    this.setDrawnSignatureImage();
    this.drawnSignatureInputTarget.value = null;
    this.showDrawYourSignaturePlaceholder();
  }

  typeChanged() {
    if (this.typeSelectTarget.value === "typed") {
      this.hideDrawnSignatureContainer();
      this.showTypedSignatureContainer();
      this.makeTypedSignatureInputRequired();
    }

    if (this.typeSelectTarget.value === "image") {
      this.hideTypedSignatureContainer();
      this.showDrawnSignatureContainer();
      this.makeDrawnSignatureInputRequired();
    }
  }

  updateEachFontExample() {
    this.typedSignatureFontRadioTargets.forEach((radio) => {
      const font = this.signatureFontsHashValue[radio.value];
      const exampleContainer = document.querySelector("[data-font-result='" + radio.value + "']");
      exampleContainer.style.fontFamily = font;
      exampleContainer.innerHTML = this.typedSignatureInputTarget?.value || "Your Signature";
    });
  }

  showTypedSignatureResult() {
    const result = document.createElement("div");
    result.style.fontFamily = this.getTypedSignatureCurrentFont();
    result.style.fontSize = "40px";
    result.innerHTML = this.typedSignatureInputTarget.value || "Your Signature";
    this.typedSignatureResultTarget.innerHTML = result.outerHTML;
    this.updateEachFontExample();
  }

  prePopulateTypedSignatureInput() {
    if (this.typedSignatureInputTarget.value) return;
    this.typedSignatureInputTarget.value = this.fullNameFromPersonFields();
    this.showTypedSignatureResult();
  }

  fullNameFromPersonFields() {
    const personFieldsForm = document.getElementById("signatory-person-fields");
    const firstName = personFieldsForm.querySelector("[name='doc_gen_signatory[first_name]']")?.value;
    const middleName = personFieldsForm.querySelector("[name='doc_gen_signatory[middle_name]']")?.value;
    const lastName = personFieldsForm.querySelector("[name='doc_gen_signatory[last_name]']")?.value;
    return [firstName, middleName, lastName].filter((name) => name).join(" ");
  }

  toggleFontSelectContainer() {
    this.typedSignatureFontContainerTarget.classList.toggle("hidden");
  }

  showTypedSignatureContainer() {
    this.typedSignatureContainerTarget.classList.remove("hidden");

    if (this.initialRender) {
      this.initialRender = false
    } else {
      this.typedSignatureInputTarget.focus();
    }
  }

  hideTypedSignatureContainer() {
    this.typedSignatureContainerTarget.classList.add("hidden");
  }

  showDrawnSignatureContainer() {
    this.drawnSignatureContainerTarget.classList.remove("hidden");
  }

  hideDrawnSignatureContainer() {
    this.drawnSignatureContainerTarget.classList.add("hidden");
  }

  getTypedSignatureCurrentFont() {
    return this.signatureFontsHashValue[
      this.typedSignatureFontRadioTargets.find((radio) => radio.checked)?.value || "type_1"
    ];
  }

  makeTypedSignatureInputRequired() {
    this.typedSignatureInputTarget.setAttribute("required", "");
    this.drawnSignatureInputTarget.removeAttribute("required");
  }

  makeDrawnSignatureInputRequired() {
    this.drawnSignatureInputTarget.setAttribute("required", "");
    this.typedSignatureInputTarget.removeAttribute("required");
  }

  showDrawYourSignaturePlaceholder() {
    document.getElementById("draw-your-signature-placeholder").classList.remove("hidden");
  }

  hideDrawYourSignaturePlaceholder() {
    document.getElementById("draw-your-signature-placeholder").classList.add("hidden");
  }

  resizeCanvas = () => {
    const wasHidden = this.drawnSignatureContainerTarget.classList.contains("hidden");

    this.showDrawnSignatureContainer();
    const ratio = this.getRatio();
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext("2d").scale(ratio, ratio);
    this.clearDrawnSignature();

    if (wasHidden) this.hideDrawnSignatureContainer();
  };

  getRatio() {
    return Math.max(window.devicePixelRatio || 1, 1);
  }

  setDrawnSignatureImageValue(value) {
    if (value) this.drawnSignatureInputTarget.value = value;
  }
}
