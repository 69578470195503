import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textArea", "jsonEditor"];

  connect() {
    let updatedJson;
    const textarea = this.textAreaTarget;

    const options = {
      onChange: function () {
        try {
          updatedJson = editor.get();
        } catch (err) {
          console.error(err);
        }

        textarea.value = JSON.stringify(updatedJson);
      },
      onError: function (err) {
        alert(err.toString());
      },
      navigationBar: false,
      search: false,
      enableSort: false,
      enableTransform: false,
      mode: "tree",
      modes: ["text", "tree"],
    };

    const editor = new JSONEditor(this.jsonEditorTarget, options);
    this.editor = editor;

    editor.set(JSON.parse(this.textAreaTarget.value));
  }

  setJson(event) {
    this.editor.set(event.detail.content);
    textarea.value = JSON.stringify(updatedJson);
  }
}
