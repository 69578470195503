import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "item"];
  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keyup", this.filterItems.bind(this));
    }
  }

  filterItems() {
    if (this.inputTarget.value == "") {
      this.itemTargets.forEach((item) => {
        item.classList.remove("hidden");
      });
    } else {
      this.itemTargets.forEach((item) => {
        if (
          item.dataset["value"]
            .toLowerCase()
            .includes(this.inputTarget.value.toLowerCase())
        ) {
          item.classList.remove("hidden");
        } else {
          item.classList.add("hidden");
        }
      });
    }
  }
}
