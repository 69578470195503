import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["personId", "submit"];

  connect() {
    this.personIdTarget.addEventListener("change", this.enabledAfterPersonSelected.bind(this));
    this.submitTarget.disabled = true;
  }

  disconnect() {
    this.personIdTarget.removeEventListener("change", this.enabledAfterPersonSelected.bind(this));
  }

  enabledAfterPersonSelected() {
    if (this.personIdTarget.value) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
