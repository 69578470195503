import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const chatAgendaHubPath = urlParams.get("chat_agenda_id");
    if (chatAgendaHubPath) {
      const url = `/chats/new?chat_agenda_id=${encodeURIComponent(chatAgendaHubPath)}`;
      get(url, { responseKind: "turbo-stream" });
      // then clean up chat_agenda_id from the url
      const newUrl = window.location.href.replace(`chat_agenda_id=${chatAgendaHubPath}`, "");
      window.history.replaceState({}, document.title, newUrl);
    }
  }
}
