export const preFillOrganization = (organizationName, organizationId) => {
  document.getElementById("deal-memo-create-modal__title").innerText += " to";
  document.getElementById("deal-memo-create-modal__title_addition").innerText =
    organizationName;
  document.getElementById("deal_memo_organization_id").value = organizationId;
  document
    .getElementById("deal-memo-searchable-container")
    .classList.add("hidden");
  document.getElementById("deal-memo-create-modal").classList.remove("hidden");
};
