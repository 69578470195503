import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = {
    pipelineId: Number,
  };
  static targets = ["stageSelect", "hasAutoEmail"];

  connect() {
    this.checkStageAutoEmail();
  }

  checkStageAutoEmail() {
    const pipelineId = this.pipelineIdValue;
    const stageId = this.stageSelectTarget.value;
    const url = `/pipelines/${pipelineId}/stages/${stageId}/has_auto_email`;

    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      },
      credentials: 'same-origin' // include cookies for authentication
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .catch(error => console.error('Error fetching Turbo Stream:', error));
  }
}
