import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mobileAsideOpenTrigger"];

  connect() {
    this.asideCollapsedBg = document.getElementsByClassName("aside-collapsed-bg")[0];

    if (this.hasMobileAsideOpenTriggerTarget) {
      this.mobileAsideOpenTriggerTarget.addEventListener("click", this.toggleMobileAside.bind(this));
    }
  }

  toggleMobileAside() {
    // aside-collapse should never be applied for mobile
    const appBody = document.getElementsByTagName("body")[0];
    const appRoot = document.getElementsByClassName("app-root")[0];
    appRoot.classList.remove("aside-collapsed");
    appBody.classList.add("overflow-hidden");

    // Use the aside bg for mobile
    if (this.asideCollapsedBg) {
      this.asideCollapsedBg.classList.remove("hidden");

      const aside = document.getElementsByTagName("aside")[0];
      aside.classList.toggle("mobile-hidden");
    }

    document.querySelectorAll("[data-controller='navigation--menu--left-side-bar']").forEach((element) => {
      element.controller.toggleMobileAside();
    });
  }
}
