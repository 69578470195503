import { Controller } from "stimulus";
import "@yaireo/tagify/dist/tagify.css";
import Tagify from "@yaireo/tagify";
export default class extends Controller {
  static values = {
    whitelist: Array,
    autofocus: Boolean,
  };

  connect() {
    this.tagifyObjects = [...this.element.querySelectorAll("input.tagify-input:not(.tagified)")].map((input) =>
      this.createTagify(input)
    );

    if (this.autofocusValue) {
      this.focusContentEditable();
    }
  }

  disconnect() {
    this.element.querySelectorAll("input.tagified").forEach((input) => {
      input.tagify?.destroy();
      input.classList.remove("tagified");
    });

    this.tagifyObjects = [];
  }

  createTagify(input) {
    const tagify = new Tagify(input, {
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(","),
      duplicates: true,
    });
    this.triggerBlurEventToOriginalInput(tagify, input);
    input.classList.add("tagified");

    return tagify;
  }

  triggerBlurEventToOriginalInput(tagify, input) {
    tagify.on("blur", () => input.dispatchEvent(new Event("blur")));
  }

  addTag(event) {
    if (!event?.target?.value) {
      return;
    }

    this.tagifyObjects.forEach((tagify) => {
      tagify.addTags(event.target.value);
    });
  }

  submitForm() {
    // Add a comma to trigger the tagify
    this.element.querySelectorAll("input.tagified").forEach((input) => {
      input.value = `${input.value},`;
    });

    // Let it cook, then submit after tagify has had a chance to update the hidden input
    setTimeout(() => {
      const form = this.element.querySelector("form");
      form.requestSubmit();
    }, 200);
  }

  focusContentEditable() {
    const contentEditableElement = this.element.querySelector("span.tagify__input");
    if (contentEditableElement) {
      contentEditableElement.focus();
    }
  }
}
