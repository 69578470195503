import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "files", "reset", "defaultfolder", "uploadButton", "spinner", "dropzone", "fileBrowse"];
  static values = { folders: Array };

  get previewDocumentsSection() {
    return this.element.querySelector("#preview-documents-section");
  }

  hidePreviewDocumentsSection() {
    if (this.previewDocumentsSection) {
      this.previewDocumentsSection.classList.add("hidden");
    }
  }

  showPreviewDocumentsSection() {
    if (this.previewDocumentsSection) {
      this.previewDocumentsSection.classList.remove("hidden");
    }
  }

  hideUploadedDocumentsSection() {
    const uploadedDocumentsSection = this.element.querySelector("#uploadedFiles");
    if (uploadedDocumentsSection) {
      uploadedDocumentsSection.classList.add("hidden");
    }
  }

  connect() {
    this.inputTarget.addEventListener("change", this.showFiles.bind(this));

    this.element.addEventListener("change", (event) => {
      if (event.target.matches('select[name="folder_select"]')) {
        this.handleSelectChange(event.target);
      }
    });

    this.dataTransfer = new DataTransfer();
    this.filesToUpload = [];
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.showFiles.bind(this));
  }

  onDragOver(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.add("bg-gray-500");
  }

  onDragLeave(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.remove("bg-gray-500");
  }

  onDrop(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.remove("bg-gray-500");

    const dt = new DataTransfer();
    for (let file of event.dataTransfer.files) {
      dt.items.add(file);
    }

    this.inputTarget.files = dt.files;
    this.hideUploadedDocumentsSection();
    this.showFiles();
  }

  delete(event) {
    const clickedButton = event.currentTarget;

    for (let i = 0; i < this.dataTransfer.items.length; i++) {
      if (clickedButton.dataset.name === this.convertName(this.dataTransfer.items[i].getAsFile().name)) {
        this.dataTransfer.items.remove(i);
        document.getElementById(clickedButton.dataset.id).remove();

        break;
      }
    }

    this.inputTarget.files = this.dataTransfer.files;
  }

  convertName(name) {
    let newName = name.toLowerCase().split(" ").join("-");
    newName = newName.split(".").join("-");
    newName = newName.split('"').join("-");
    newName = newName.split("'").join("-");

    return newName;
  }

  reset() {
    this.dataTransfer.clearData();
    this.inputTarget.files = this.dataTransfer.files;

    this.resetTarget.classList.add("hidden");
    this.defaultfolderTarget.classList.add("hidden");
    this.uploadButtonTarget.disabled = true;

    const selectElement = this.defaultfolderTarget.querySelector("select");
    if (selectElement) {
      selectElement.value = "";
    }

    this.showFiles();
    this.spinnerTarget.classList.add("hidden");
    const uploadedDocumentsSection = this.element.querySelector(".uploaded-documents-section");
    if (uploadedDocumentsSection) {
      uploadedDocumentsSection.classList.add("hidden");
    }
    this.hidePreviewDocumentsSection();
  }

  handleSelectChange(selectElement) {
    selectElement.classList.remove("is-invalid");

    const errorMessage = selectElement.nextElementSibling;
    if (errorMessage && errorMessage.classList.contains("validation-error")) {
      errorMessage.remove();
    }
    const selectedFolderId = selectElement.value;
    selectElement.setAttribute("data-folder-id", selectedFolderId);
  }

  async showFiles() {
    this.showPreviewDocumentsSection();
    this.hideUploadedDocumentsSection();
    this.spinnerTarget.classList.remove("hidden");
    this.defaultfolderTarget.classList.add("hidden");
    this.resetTarget.classList.add("hidden");

    this.filesTarget.innerHTML = "";
    let filesToDisplay = [];
    this.filesToUpload = [];
    let filesTooLarge = [];
    let filesDisallowed = [];

    const allowedTypes = [
      "application/zip",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "image/png",
      "image/jpeg",
      "application/ogg",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.presentation",
      "text/csv",
    ];
    const filesArray = Array.from(this.inputTarget.files);
    let allFilenames = [];

    for (const file of filesArray) {
      if (file.size > 20 * 1024 * 1024) {
        filesTooLarge.push(file.name);
        continue;
      }

      if (!allowedTypes.includes(file.type)) {
        filesDisallowed.push(file.name);
        continue;
      }

      if (file.type === "application/zip") {
        const zipContents = await this.inspectZipFile(file);
        zipContents.forEach((zipContent) => {
          filesToDisplay.push({ ...zipContent, isFromZip: true });
          this.filesToUpload.push({ ...zipContent, isFromZip: true });
          allFilenames.push(zipContent.name);
        });
      } else {
        filesToDisplay.push({ name: file.name, file: file });
        this.filesToUpload.push({ file: file });
        allFilenames.push(file.name);
      }
    }
    if (filesTooLarge.length > 0) {
      this.displayFileSizeError(filesTooLarge);
    }

    if (filesDisallowed.length > 0) {
      this.displayFileTypeError(filesDisallowed);
    }

    const foldernames = this.foldersValue.map((folder) => folder[0]);
    if (allFilenames.length > 0) {
      try {
        const matchedFolders = await this.fetchMatchedFolders(allFilenames, foldernames);
        filesToDisplay.forEach((fileDisplay) => {
          const adjustedFilename = fileDisplay.isFromZip ? `zip-${fileDisplay.name}` : fileDisplay.name;
          let folderName = "";
          if (matchedFolders && matchedFolders.length !== 0) {
            const matchedFolder = matchedFolders.find((m) => m.filename === fileDisplay.name);
            folderName = matchedFolder ? matchedFolder.foldername : "";
          }
          const identifier = fileDisplay.isFromZip ? `zip-${fileDisplay.name}` : fileDisplay.name;
          this.printFile(fileDisplay, identifier, folderName);
        });

        if (matchedFolders && matchedFolders.length !== 0) {
          matchedFolders.forEach((matchedFolder) => {
            const folderData = this.foldersValue.find((folder) => folder[0] === matchedFolder.foldername);
            const folderId = folderData ? folderData[1] : "";

            const selectElementFileIndex = document.querySelector(
              `select[data-file-index="${matchedFolder.filename}"]`
            );
            const selectElementIdentifier = document.querySelector(
              `select[data-identifier="zip-${matchedFolder.filename}"]`
            );
            const selectElement = selectElementFileIndex || selectElementIdentifier;

            if (matchedFolder.foldername !== "Not Found" && selectElement && folderId) {
              selectElement.value = folderId;
              selectElement.setAttribute("data-folder-id", folderId);
            }
          });
        }

        this.updateUIAfterFileSelection(filesToDisplay.length);
      } catch (error) {
        console.error("Error fetching matched folders:", error);
      } finally {
        this.spinnerTarget.classList.add("hidden");
      }
    } else {
      this.spinnerTarget.classList.add("hidden");
    }
  }

  async fetchMatchedFolders(filenames, foldernames) {
    try {
      const body = JSON.stringify({ filenames: filenames, foldernames: foldernames });

      const response = await fetch("/data_room/files/match_filenames_to_foldernames", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error in fetchMatchedFolders:", error);
      return [];
    }
  }

  updateUIAfterFileSelection(fileCount) {
    if (fileCount > 1) {
      this.defaultfolderTarget.classList.remove("hidden");
    }
    if (fileCount > 0) {
      this.resetTarget.classList.remove("hidden");
      this.uploadButtonTarget.disabled = false;
    } else {
      this.resetTarget.classList.add("hidden");
      this.defaultfolderTarget.classList.add("hidden");
      this.uploadButtonTarget.disabled = true;
    }
  }

  displayFileSizeError(filesTooLarge) {
    const message = "The following files exceed the 20MB size limit and were not added:\n" + filesTooLarge.join("\n");
    alert(message);
  }

  displayFileTypeError(filesDisallowed) {
    const message = "The following files are not allowed and were not added:\n" + filesDisallowed.join("\n");
    alert(message);
  }

  uploadDocuments(event) {
    event.preventDefault();

    if (!this.validateSelects()) {
      return;
    }

    const formData = this.prepareFormData();
    this.submitDocuments(formData);
  }

  printFile(file, identifier, matchedFolderName = "") {
    const nameForId = this.convertName(file.name);
    const displayName = file.isFromZip ? `${file.name} (from Zip)` : file.name;
    const originalNameAttributeValue = file.isFromZip ? `zip-${file.name}` : file.name;
    const dataAttribute = file.isFromZip ? `data-identifier="${identifier}"` : `data-file-index="${identifier}"`;

    let fileURL;
    if (file.isFromZip) {
      fileURL = file.identifier;
    } else {
      fileURL = URL.createObjectURL(file.file);
    }

    const fileItemHTML = `
    <div id="${nameForId}" class="flex flex-col gap-2 border-b border-gray-200 first:border-t py-3">
      <div class="flex items-center gap-1">
        <i class="ph-file-text text-dark-400 text-md"></i>
        <div class="font-medium text-dark-700 truncate" data-original-name="${originalNameAttributeValue}">
          ${displayName}
        </div>
        <a href="${fileURL}" target="_blank" rel="noopener noreferrer" class="btn-ghost ml-auto">
          <i class="ph-eye"></i>
          Preview
        </a>
      </div>
      <select name="folder_select" ${dataAttribute} data-folder-id="" class="form-control" required></select>
    </div>
    `;

    this.filesTarget.innerHTML += fileItemHTML;
    const selectElement = this.filesTarget.querySelector(`select[${dataAttribute}]`);
    this.populateSelectWithOptions(selectElement, this.foldersValue);

    if (matchedFolderName) {
      Array.from(selectElement.options).forEach((option) => {
        if (option.text === matchedFolderName) {
          selectElement.value = option.value;
        }
      });
    }
  }

  populateSelectWithOptions(selectElement, folders) {
    selectElement.innerHTML = "";

    selectElement.options.add(new Option("Select a folder...", ""));

    folders.forEach(([folderName, folderId]) => {
      const optionElement = new Option(folderName, folderId);
      selectElement.options.add(optionElement);
    });
  }

  updateFileSelects(event) {
    const selectedValue = event.target.value;
    const selectedFolderName = event.target.options[event.target.selectedIndex].text;

    this.element.querySelectorAll('select[name="folder_select"]').forEach((selectElement) => {
      selectElement.value = selectedValue;
      selectElement.setAttribute("data-folder-id", selectedValue);
      selectElement.dispatchEvent(new Event("change"));
    });
  }

  updateFileName(newName, id) {
    const fileNameContainer = document.getElementById(`${id}-filename`);
    const extension = fileNameContainer.dataset.extension;

    if (newName.length) {
      fileNameContainer.innerHTML = newName + "." + extension;
      document.getElementById(`${id}-input`).value = newName;
      document.getElementById(`${id}-input`).dataset.oldValue = newName;
    } else {
      document.getElementById(`${id}-input`).value = fileNameContainer.dataset.originalName;
      document.getElementById(`${id}-input`).dataset.oldValue = fileNameContainer.dataset.originalName;
      fileNameContainer.innerHTML = fileNameContainer.dataset.originalName + "." + extension;
    }
  }

  getFileNameWithoutExtension(file) {
    return file.name.replace(/\.[^/.]+$/, "");
  }

  getExtension(file) {
    return file.name.split(".").pop();
  }

  inspectZipFile(zipFile) {
    const formData = new FormData();
    formData.append("file", zipFile);

    return fetch("/data_room/files/inspect_zip", {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.files) {
          return data.files;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error("Error handling zip file:", error);
        return [];
      });
  }

  validateSelects() {
    const selects = this.element.querySelectorAll('select[name="folder_select"][required]');
    let isValid = true;
    let firstInvalidSelect = null;

    selects.forEach((select) => {
      const existingErrorMessage = select.parentNode.querySelector(".validation-error");
      if (existingErrorMessage) {
        existingErrorMessage.remove();
      }

      if (select.value === "") {
        if (!firstInvalidSelect) {
          firstInvalidSelect = select;
        }
        select.classList.add("is-invalid");

        const errorMessage = document.createElement("span");
        errorMessage.textContent = "Please select a folder";
        errorMessage.className = "validation-error text-red-500 text-sm ml-2";
        select.parentNode.appendChild(errorMessage);

        isValid = false;
      } else {
        select.classList.remove("is-invalid");
      }
    });

    if (firstInvalidSelect) {
      firstInvalidSelect.focus();
    }

    return isValid;
  }

  prepareFormData() {
    const formData = new FormData();
    this.filesToUpload.forEach((item, index) => {
      if (item.file) {
        formData.append("files[]", item.file);
        const fileIdentifier = item.file.name;
        const folderSelect = this.element.querySelector(`select[data-file-index="${fileIdentifier}"]`);
        if (folderSelect) {
          const folderId = folderSelect.getAttribute("data-folder-id");
          if (folderId) {
            formData.append("folder_ids[]", folderId);
          }
        }
      } else if (item.identifier) {
        formData.append("fileIdentifiers[]", item.identifier);
        const folderSelect = this.element.querySelector(`select[data-identifier="zip-${item.name}"]`);

        if (folderSelect) {
          const folderId = folderSelect.getAttribute("data-folder-id");
          if (folderId) {
            formData.append("folder_ids[]", folderId);
          }
        }
      }
    });
    return formData;
  }

  async submitDocuments(formData) {
    try {
      const response = await fetch("capital_accounts/upload_documents", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        throw new Error("Server responded with an error!");
      }

      const data = await response.json();
      if (data) {
        this.showUploadedDocumentsSection(data.files || []);
        window.notyf.success("Document(s) upload successful.");
      }
    } catch (error) {
      console.error("Document(s) upload failed:", error);
      window.notyf.error("Document(s) upload failed. Please contact support.");
    }
  }

  showUploadedDocumentsSection(uploadedFiles) {
    const uploadedDocumentsSection = this.element.querySelector("#uploadedFiles");
    if (uploadedDocumentsSection) {
      uploadedDocumentsSection.classList.remove("hidden");

      const filesContainer = uploadedDocumentsSection.querySelector("#uploadedFiles .panel-body");
      if (filesContainer) {
        filesContainer.innerHTML = "";
        uploadedFiles.forEach((file) => {
          const fileAndFolderContainer = document.createElement("div");
          fileAndFolderContainer.classList.add(
            "flex",
            "items-start",
            "gap-2",
            "py-2",
            "first:pt-0",
            "border-b",
            "border-gray-200",
            "last:border-none"
          );
          fileAndFolderContainer.innerHTML = `
            <i class="ph-paperclip text-mdd text-dark-300"></i>
            <div class="flex flex-col">
              <a href="${file.url}" class="font-medium text-dark-700" target="_blank">${file.name}</a>
              <span>${file.target_folder_hierarchy_path}</span>
            </div>
        `;
          filesContainer.appendChild(fileAndFolderContainer);
        });
      }
      this.hidePreviewDocumentsSection();
      this.uploadButtonTarget.disabled = true;
    }
  }
}
