import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "showElement"];

  showElementTargetConnected(element) {
    element.addEventListener("click", (e) => {
      this.show();
      e.preventDefault();
      e.stopImmediatePropagation();
    });
  }

  show() {
    this.modalTarget.classList.remove("hidden");
  }

  close(event) {
    if (event.type === 'keydown' && event.keyCode !== 27) return;
    
    this.modalTarget.classList.add("hidden");
  }
}
