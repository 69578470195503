import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchInput", "searchButton"];

  connect() {
    this.searchButtonTarget.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      const enterEvent = new KeyboardEvent("keyup", {
        key: "Enter",
        code: "Enter",
        which: 13,
        keyCode: 13,
        bubbles: true,
      });
      this.searchInputTarget.dispatchEvent(enterEvent);
    });
  }
}
