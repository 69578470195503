import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "round",
    "security",
    "investment",
    "amountRaised",
    "valuationCap",
    "pricePerShare",
    "closeDate",
    "pastRoundLabel",
    "currentRoundLabel",
  ];

  connect() {
    this.closeDateTargets.forEach((target) => {
      target.addEventListener("change", this.showHideRoundLabel.bind(this));
    });

    this.securityTarget.addEventListener(
      "change",
      this.enableDisableValueField.bind(this)
    );

    this.populateFields();
    this.showHideRoundLabel();
    this.enableDisableValueField();
  }

  populateFields() {
    const monthField = this.closeDateTargets[1];
    const yearField = this.closeDateTargets[0];

    if (!monthField.value || !yearField.value) {
      yearField.value = new Date().getFullYear();
      monthField.value = new Date().getMonth() + 1;
    }
  }

  showHideRoundLabel() {
    const month = this.closeDateTargets[1].value;
    const year = this.closeDateTargets[0].value;

    if (!month || !year) {
      this.pastRoundLabelTarget.classList.add("hidden");
      this.currentRoundLabelTarget.classList.add("hidden");
      return;
    }

    // Create a date from month and year (using start of next month to act as end of current month for comparison)
    const closeDate = new Date(year, month, 1);

    // If the current date is after closeDate unhide the past round label
    if (new Date() >= closeDate) {
      this.pastRoundLabelTarget.classList.remove("hidden");
      this.currentRoundLabelTarget.classList.add("hidden");
    } else {
      this.pastRoundLabelTarget.classList.add("hidden");
      this.currentRoundLabelTarget.classList.remove("hidden");
    }
  }

  enableDisableValueField() {
    // Always enable amount raised
    this.enableAmountRaised();

    ["safe", "convertible_debt"].includes(this.securityTarget.value)
      ? this.enableValuationCap()
      : this.disableValuationCap();
    ["common", "preferred"].includes(this.securityTarget.value)
      ? this.enablePricePerShare()
      : this.disablePricePerShare();
  }

  disableAmountRaised() {
    this.amountRaisedTarget.disabled = true;
    this.amountRaisedTarget.classList.add("disabled");
  }

  enableAmountRaised() {
    this.amountRaisedTarget.disabled = false;
    this.amountRaisedTarget.classList.remove("disabled");
  }

  disableValuationCap() {
    if (!this.hasValuationCapTarget) {
      return;
    }

    this.valuationCapTarget.disabled = true;
    this.valuationCapTarget.classList.add("disabled");
  }

  enableValuationCap() {
    if (!this.hasValuationCapTarget) {
      return;
    }

    this.valuationCapTarget.disabled = false;
    this.valuationCapTarget.classList.remove("disabled");
  }

  disablePricePerShare() {
    this.pricePerShareTarget.disabled = true;
    this.pricePerShareTarget.classList.add("disabled");
  }

  enablePricePerShare() {
    this.pricePerShareTarget.disabled = false;
    this.pricePerShareTarget.classList.remove("disabled");
  }
}
