// Similar to confirm.js but uses a stimulus controller instead
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="confirm"
export default class extends Controller {
  static values = {
    message: String,
    description: String,
    destructive: Boolean,
    icon: String,
    btnText: String,
  };

  connect() {}

  openModal(event) {
    if (this.confirmModalOpen()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.createConfirmModal(event.target);
  }

  createConfirmModal(element) {
    const modal = document.createElement("div");
    modal.setAttribute("data-controller", "confirm-modal");
    modal.className = "fixed inset-0 flex justify-center items-center bg-dark-700 bg-opacity-60 z-50";
    modal.innerHTML = this.modalContent();

    document.body.appendChild(modal);

    this.element.dataset.clicked = true;

    modal.querySelector("[data-action='confirm-modal#cancel']").addEventListener("click", () => {
      this.closeModal(modal);
    });

    modal.querySelector("[data-action='confirm-modal#commit']").addEventListener("click", () => {
      this.commitAction(element, modal);
    });

    modal.querySelector("[data-behavior='commit']").focus();
  }

  modalContent() {
    const iconHtml = this.destructiveValue
      ? `<div class="w-12 h-12 rounded-full flex items-center justify-center bg-red-100 text-xl text-red-600"><i class="${this.iconValue}"></i></div>`
      : `<div class="w-12 h-12 rounded-full flex items-center justify-center bg-primary-100 text-xl text-primary-600"><i class="${this.iconValue}"></i></div>`;

    return `
      <div class="w-11/12 max-w-sm bg-white rounded-sm shadow whitespace-normal">
        <div class="p-6 flex flex-col items-center gap-2">
          ${iconHtml}
          <h4 class="text-lg text-dark-700 text-medium text-center">${this.messageValue}</h4>
          ${this.descriptionValue ? `<div class="text-sm text-center">${this.descriptionValue}</div>` : ""}
        </div>
        <div class="flex items-center gap-2 px-6 py-3 border-t border-gray-200">
          <button data-action="confirm-modal#cancel" class="btn btn-white flex-1 flex-shrink-0">Cancel</button>
          <button data-behavior="commit" data-action="confirm-modal#commit" class="btn ${
            this.destructiveValue ? "btn-danger" : "btn-primary"
          } flex-1 flex-shrink-0">${this.btnTextValue}</button>
        </div>
      </div>
    `;
  }

  closeModal(modal) {
    this.element.removeAttribute("data-confirm-modal");
    modal.remove();
  }

  commitAction(element, modal) {
    this.closeModal(modal);
    element.click();
  }

  confirmModalOpen() {
    return this.element.dataset.clicked === "true";
  }
}
