import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static values = {
    debounceMilliseconds: Number,
  };
  connect() {
    this.setOriginalValues();
    this.addEventListeners();
  }

  initialize() {
    const debounceMilliseconds = this.debounceMillisecondsValue || 50;
    this.debounceKeyPress = debounce(this.keypress, debounceMilliseconds).bind(this);
    this.debounceSubmitForm = debounce(this.submitForm, debounceMilliseconds).bind(this);
    this.debounceClearErrors = debounce(this.clearErrors, debounceMilliseconds).bind(this);
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener("keypress", this.debounceKeyPress);
    this.element.addEventListener(this.triggerEvent(), this.debounceSubmitForm);
    this.element.addEventListener("keypress", this.debounceClearErrors);
  }

  removeEventListeners() {
    this.element.removeEventListener("keypress", this.debounceKeyPress);
    this.element.removeEventListener(this.triggerEvent(), this.debounceSubmitForm);
    this.element.removeEventListener("keypress", this.debounceClearErrors);
  }

  keypress = (event) => {
    if (event.key === "Enter" && event.target.tagName.toLowerCase() === "input") {
      event.preventDefault();
      event.stopPropagation();
      this.submitForm(event);
    }
  };

  clearErrors = (event) => {
    const field = event.target;

    field.classList.remove("error");
    field.parentElement.querySelector(".form-hint")?.remove();
  };

  submitForm = (event) => {
    const input = event.target;
    const form = input.closest("form");

    if (form && this.valueChanged(input)) {
      if (form.checkValidity()) {
        form.requestSubmit();
      } else {
        if (input.offsetParent === null) return;
        form.reportValidity();
      }
    }
  };

  triggerEvent() {
    return this.element.dataset.autosaveEvent || "blur";
  }

  setOriginalValues() {
    this.element.dataset.originalValue = this.element.value;
  }

  valueChanged() {
    if (this.element.dataset.autosaveBypassOldValueCheck) return true;

    return this.element.value !== this.element.dataset.originalValue;
  }
}
