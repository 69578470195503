import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["star", "input"];
  static values = { initialScore: Number };

  connect() {
    this.addStarClickEventListener();
    this.setInitialScore();
  }

  disconnect() {
    this.removeStarClickEventListener();
  }

  setInitialScore() {
    if (this.initialScoreValue) {
      this.setStars(this.initialScoreValue);
    }
  }

  addStarClickEventListener() {
    this.starTargets.forEach((star) => {
      star.addEventListener("click", () => this.handleStarClick(star));
    });
  }

  removeStarClickEventListener() {
    this.starTargets.forEach((star) => {
      star.removeEventListener("click", () => this.handleStarClick(star));
    });
  }

  handleStarClick = (star) => {
    const score = star.dataset.score;
    this.setStars(score);
  };

  setStars(score) {
    if (score === this.getCurrentScore()) {
      this.disableAllStars();
      this.setCurrentScore("");
    } else {
      this.setStarsClass(score);
      this.setCurrentScore(score);
    }
  }

  setStarsClass(score) {
    this.starTargets.forEach((star) => {
      if (star.dataset.score <= score) {
        this.addClassSelectedStar(star);
      } else {
        this.removeClassSelectedStar(star);
      }
    });
  }

  getCurrentScore() {
    return this.inputTarget.value;
  }

  setCurrentScore(score) {
    this.inputTarget.value = score;
  }

  disableAllStars() {
    this.starTargets.forEach((star) => {
      this.removeClassSelectedStar(star);
    });
  }

  addClassSelectedStar(star) {
    star.classList.add("text-warning-500");
    star.classList.remove("text-gray-200");
  }

  removeClassSelectedStar(star) {
    star.classList.remove("text-warning-500");
    star.classList.add("text-gray-200");
  }
}
