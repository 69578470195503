import { Controller } from "stimulus";
import { Chart } from "chart.js";
import xirr from "xirr";

const POSITIVE_COLOR_TRANSPARENT = 'rgba(38, 151, 255, 0.2)';
const NEGATIVE_COLOR_TRANSPARENT = 'rgba(250, 51, 87, 0.2)';
const POSITIVE_COLOR = 'rgba(38, 151, 255, 0.8)';
const NEGATIVE_COLOR = 'rgba(250, 51, 87, 0.8)';
const BORDER_COLOR = 'rgba(156, 163, 175, 0.8)';

export default class extends Controller {
  static targets = [
    "xirr",
    "lastQuarter",
    "yearlyGraphContainer",
    "quarterlyGraphContainer",
  ];
  static values = {
    data: Array,
    dataLastQuarter: Array,
    yearlyGraphUrl: String,
    quarterlyGraphUrl: String,
  };

  connect() {
    // XIRR
    const xirr = this.calculateXIRR(this.dataValue);
    if (xirr !== undefined) {
      this.xirrTarget.innerHTML = xirr + "%";
    }

    // XIRR Last Quarter
    const xirrQuarter = this.calculateXIRR(this.dataLastQuarterValue);
    if (xirrQuarter !== undefined) {
      this.lastQuarterTarget.innerHTML = xirrQuarter + "%";
    }

    if (xirr !== undefined && xirrQuarter !== undefined) {
      this.xirrTarget.insertAdjacentHTML(
        "afterend",
        this.renderArrow(xirr, xirrQuarter)
      );
    }

    this.setAllGraphs();
  }

  calculateXIRR(data) {
    const xirr_transactions = [];

    if (data.length === 2) {
      const startDate = new Date(data[0][0]);
      const endDate = new Date(data[1][0]);
      const timeDiff = endDate.getTime() - startDate.getTime();
      const daysDiff = timeDiff / (1000 * 3600 * 24);

      // For the first quarter IRR calc (which has 2 elements in the array)
      // If the difference is less than 22 days between the first element date and second
      // We don't append that data, thereby not rendering that initial quarter
      //Preventing the XIRR library from erroring on a failure to converge the IRR
      if (daysDiff < 22) {
        return undefined;
      }
    }

    for (const transaction of data) {
      const when = new Date(transaction[0]);
      const amount = parseFloat(transaction[1]);

      xirr_transactions.push({
        when,
        amount,
      });
    }

    let xirr_result = undefined;

    try {
      xirr_result = parseFloat((xirr(xirr_transactions) * 100).toFixed(2));
    } catch (e) {
      console.log(e);
      xirr_result = 0;
    }

    return xirr_result;
  }

  setAllGraphs() {
    this.fetchDataAndPlotGraph("yearly-irr-graph", this.yearlyGraphUrlValue);
    this.fetchDataAndPlotGraph(
      "quarterly-irr-graph",
      this.quarterlyGraphUrlValue
    );
  }

  fetchDataAndPlotGraph(ctxID, url) {
    Rails.ajax({
      url: url,
      type: "GET",
      success: (response) => {
        this.plotGraph(ctxID, response.data);
      },
      error: (response) => {
        console.log(response);
      },
    });
  }

  renderArrow(xirr, xirrQuarter) {
    const difference = xirr - xirrQuarter;
    let color = "";
    let arrow = "";

    if (difference >= 0) {
      color = "text-primary-600 bg-primary-100";
      arrow = "ph-arrow-up-bold";
    } else {
      color = "text-red-600 bg-red-100";
      arrow = "ph-arrow-down-bold";
    }
    return `
      <div class="h-4 inline-flex items-center justify-center gap-0.5 text-xxxxs ${color} font-medium rounded-full bg-primary-100 px-2">
        <i class="${arrow}"></i>
        ${difference.toFixed(2)}%
      </div>`;
  }

  plotGraph(ctxID, data) {
    const canvas = document.getElementById(ctxID);
    const ctx = canvas.getContext("2d");

    const graphData = [];

    for (const dataset of data) {
      const date = dataset.date;
      const xirr = this.calculateXIRR(dataset.net_irr_data);

      if (xirr !== undefined && xirr !== 0) {
        graphData.push([date, xirr]);
      }
    }

    const loadingContainer = document.getElementById(`${ctxID}-loading`);

    if (graphData.length > 1) {
      canvas.classList.remove("hidden");
      loadingContainer.classList.add("hidden");

      new Chart(ctx, {
        type: "line",
        data: {
          datasets: [{
            fill: true,
            backgroundColor: (context) => this.setGradient(context, graphData),
            pointBorderColor: (context) => this.setPointColor(context),
            pointBackgroundColor: (context) => this.setPointColor(context),
            borderColor: BORDER_COLOR,
            borderWidth: 1,
            data: graphData
          }],
        },
        options: {
          plugins: { legend: { display: false } },
          scales: {
            x: {
              ticks: {
                display: true,
              },
            },
          },
        },
      });
    } else {
      loadingContainer.innerHTML =
        "<div class='flex flex-col flex-1 items-center justify-center border border-dashed border-dark-300 border-opacity-60 rounded-sm p-6 mt-3'><img src='https://d2id8jgnbwyil5.cloudfront.net/hub-illy-investment.svg' class='w-28 h-28'><div class='text-center'><div class='text-dark-600 font-semibold'>Not enough data</div><div class='text-xs'>See historical Net IRR to date.</div></div></div>";
    }
  }

  setPointColor(context) {
    const value = context.parsed.y;
    if (value > 0) {
      return POSITIVE_COLOR;
    } else if (value < 0) {
      return NEGATIVE_COLOR;
    } else {
      return BORDER_COLOR;
    }
  }

  setGradient(context, data) {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }

    const gradient = ctx.createLinearGradient(chartArea.left, chartArea.bottom, chartArea.right, chartArea.bottom);

    for(let i = 0; i < data.length; i++) {
      const value = data[i][1];

      const step = i / (data.length - 1);

      if(value > 0) {
        gradient.addColorStop(step, POSITIVE_COLOR_TRANSPARENT);
      } else if (value < 0) {
        gradient.addColorStop(step, NEGATIVE_COLOR_TRANSPARENT);
      } else {
        gradient.addColorStop(step, 'rgba(250, 250, 250, 0.1)');
      }
    }

    return gradient;
  }

  getStringDate(date) {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
}
