import { Controller } from "stimulus";

export default class extends Controller {
  static values = { errors: Array };

  connect() {
    const erroredPeopleIds = this.errorsValue.map((person) => {
      return person.id;
    });
    this.dispatch("bulkErrors", {
      detail: { peopleIds: erroredPeopleIds },
    });
  }
}
