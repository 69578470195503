import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  static values = { activeClass: String, stopPropagation: Boolean };

  connect() {
    this.activeClassValue = this.activeClassValue || "active";
    this.stopPropagationValue = this.hasStopPropagationValue ? this.stopPropagationValue : true;

    this.tabTargets.forEach((tab) => {
      if (tab.tagName === "INPUT" && tab.type === "hidden") {
        tab.addEventListener("change", this.tabClick);
      } else {
        tab.addEventListener("click", this.tabClick);
      }
    });
  }

  disconnect() {
    this.tabTargets.forEach((tab) => {
      tab.removeEventListener("click", this.tabClick);
    });
  }

  tabClick = (event) => {
    const tab = event.currentTarget;

    this.resetAllTabs();
    this.setTabActive(tab);

    this.resetAllContents();
    this.setContentActive(tab);

    if (this.stopPropagationValue) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  resetAllTabs() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(this.activeClassValue);
    });
  }

  resetAllContents() {
    this.removeAllActive(this.contentTargets, true);
  }

  setTabActive(tab) {
    tab.classList.add(this.activeClassValue);
  }

  setContentActive(tab) {
    const contentId = tab.dataset.tabId;

    const content = document.getElementById(contentId);

    content?.classList.add("active");
    content?.classList.remove("hidden");
  }

  removeAllActive(elements, addHidden = false) {
    elements.forEach((element) => {
      element.classList.remove("active");

      if (addHidden) element.classList.add("hidden");
    });
  }
}
