import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.focusInput();
  }

  disconnect() {}

  focusInput() {
    const element = this.element;
    setTimeout(() => {
      element.focus();
      if (element.type === "text" || element.tagName === "TEXTAREA") {
        element.setSelectionRange(element.value.length, element.value.length);
      }
    }, 500);
  }
}
