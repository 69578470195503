import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    document.addEventListener("slideover:closing", (event) => {
      this.reloadPage(event);
    });
  }

  reloadPage(event) {
    event.preventDefault();
    window.location.reload();
  }
}
