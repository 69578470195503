import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
    variables: Object,
    pacturl: String,
    pipeline: String,
  };
  static targets = [
    "search",
    "template",
    "id",
    "resetButton",
    "pactLink",
    "previewLink",
    "emailLink",
    "copy",
    "copied",
    "searchContainer",
    "low",
    "medium",
    "high",
    "min"
  ];

  connect() {
    this.searchField = this.searchTarget;
    this.searchResultTemplate = this.templateTarget.innerHTML;

    this.setupSearchResults();

    if (this.idTarget.value.length > 0) {
      this.resetButtonTarget.classList.remove("hidden");
      this.searchTarget.disabled = true;
    }

    this.boundSearchEvent = this.search.bind(this);
    this.searchTarget.addEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.addEventListener("focusout", this.closeSearchResultsAfterClickAway.bind(this));
    this.searchTarget.addEventListener("focus", this.unhideSearchResults.bind(this));
    this.copyTarget.addEventListener("click", this.copyPactUrl.bind(this));

    this.lowTarget.addEventListener("keyup", this.updateLMHInPactLinkTarget.bind(this));
    this.mediumTarget.addEventListener("keyup", this.updateLMHInPactLinkTarget.bind(this));
    this.highTarget.addEventListener("keyup", this.updateLMHInPactLinkTarget.bind(this));
    this.minTarget.addEventListener("keyup", this.updateLMHInPactLinkTarget.bind(this));
  }

  disconnect() {
    this.searchTarget.removeEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.removeEventListener("focusout", this.closeSearchResultsAfterClickAway.bind(this));
    this.searchTarget.removeEventListener("focus", this.unhideSearchResults.bind(this));
  }

  updatePreviewLink() {
    this.previewLinkTarget.setAttribute("href", this.pactLinkTarget.innerHTML.trim());
  }

  closeSearchResultsAfterClickAway() {
    setTimeout(() => this.hideSearchResults(), 400);
  }

  createSearchResult(result, forListing = true) {
    let resultContent = this.searchResultTemplate;
    for (const variable in this.variablesValue) {
      resultContent = resultContent.replace(`{{${variable}}}`, result[this.variablesValue[variable]]);
    }

    const resultElement = document.createElement("div");

    let resultIcon =
      '<div class="w-7 h-7 border-2 border-dark-200 rounded-full flex items-center justify-center text-primary-400"><i class="ph-user-bold"></i></div>';

    resultElement.innerHTML = resultIcon + resultContent;

    if (forListing) {
      resultElement.classList.add(
        "flex",
        "items-center",
        "gap-2",
        "px-3",
        "py-2",
        "hover:bg-dark-50",
        "rounded",
        "cursor-pointer"
      );
      resultElement.dataset.id = result.id;
      resultElement.dataset.className = result.class_name;
      resultElement.addEventListener("click", this.selectSearchResultOnClick.bind(this));
    } else {
      resultElement.classList.add("flex-grow", "bg-dark-50", "rounded");
    }

    return resultElement;
  }

  createDisplayElement(selectedResult) {
    const displayElement = document.createElement("div");
    displayElement.classList.add("form-control", "flex", "!p-0.5");

    const editButton = document.createElement("button");
    editButton.type = "button";
    editButton.classList.add(..."flex-shrink-0 btn btn-xs btn-link".split(" "));
    editButton.innerHTML = "edit";
    editButton.addEventListener("click", this.showSearchField.bind(this));

    displayElement.insertAdjacentElement("beforeend", this.createSearchResult(selectedResult, false));

    displayElement.insertAdjacentElement("beforeend", editButton);

    return displayElement;
  }

  hideSearchResults() {
    this.searchResultsContainer.classList.add("hidden");
  }

  unhideSearchResults() {
    if (this.searchField.value.length) {
      this.searchResultsContainer.classList.remove("hidden");
    }
  }

  search(e) {
    const keyModifier = e.keyCode === 16 || e.keyCode === 18;

    const searchString = this.searchField.value.trim();
    const data =
      this.searchField.dataset.queryParam &&
      `search=${this.searchField.dataset.queryParam}&${this.searchField.dataset.queryParam}=${searchString}&exclude_id=${this.searchField.dataset.excludeId}&deal_memo=${this.searchField.dataset.dealMemo}`;

    if (searchString.length > 0 && !keyModifier) {
      Rails.ajax({
        type: "GET",
        url: this.urlValue,
        data: data,
        error: this.showEmptySearchResults.bind(this),
        success: this.showSearchResults.bind(this),
      });
    } else {
      this.hideSearchResults();
    }
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find(
      (result) => result.id == ev.currentTarget.dataset.id && result.class_name === ev.currentTarget.dataset.className
    );

    this.selectSearchResult(selectedResult);
  }

  populateSingleFormField(className, key, value) {
    let element = document.getElementsByName(`${className}[${key}]`)[0];
    if (element) {
      element.addEventListener("keyup", () => {
        document.getElementById("form-edited-warning")?.classList.remove("hidden");
      });
      element.value = value;
    }
  }

  reset() {
    this.resetButtonTarget.classList.add("hidden");
    this.searchTarget.disabled = false;
    this.searchTarget.value = "";
    this.idTarget.value = null;
  }

  selectSearchResult(selectedResult) {
    document.getElementById("form-edited-warning")?.classList.add("hidden");

    if (selectedResult.class_name === "Person") {
      this.pactLinkTarget.innerHTML = `${this.pacturlValue}?pid=${this.pipelineValue}&tar=${selectedResult.obscure_id}`;
    } else {
      this.pactLinkTarget.innerHTML = `${this.pacturlValue}?pid=${this.pipelineValue}&org=${selectedResult.obscure_id}`;
    }

    this.updateLMHInPactLinkTarget();

    this.resetButtonTarget.classList.remove("hidden");
    this.searchTarget.disabled = true;
    this.idTarget.value = selectedResult.id;
    this.searchTarget.value = selectedResult.name;
    this.hideSearchResults();
    this.updatePreviewLink();
    this.updateEmailLink();
  }

  setupSearchResults() {
    this.searchResultsContainer = document.createElement("div");
    this.searchResultsContainer.classList.add(
      ..."hidden absolute flex flex-col divide-dark-100 p-0.5 rounded shadow-lg border border-dark-300 w-full max-h-52 overflow-y-auto scrollbar__style top-full bg-white z-20".split(
        " "
      )
    );

    this.searchContainerTarget.classList.add("relative");
    this.searchContainerTarget.insertAdjacentElement("beforeend", this.searchResultsContainer);
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  showSearchField() {
    this.displayElement.classList.add("hidden");
    this.searchField.classList.remove("hidden");
    this.searchField.value = "";
    this.searchField.focus();
  }

  showSearchResults(results) {
    this.updateSearchResults(results);
    this.unhideSearchResults();
  }

  updateSearchResults(results) {
    this.searchResults = results;
    this.searchResultsContainer.innerHTML = "";
    this.pactLinkTarget.innerHTML = `${this.pacturlValue}?pid=${this.pipelineValue}`;
    this.copyTarget.classList.remove("hidden");
    this.copiedTarget.classList.add("hidden");

    results.forEach((result) => {
      this.searchResultsContainer.insertAdjacentElement("beforeend", this.createSearchResult(result));
    });
  }

  updateLMHInPactLinkTarget() {
    const cleanedInnerHTML = this.pactLinkTarget.innerHTML.replace(/\s+/g, ' ').trim();
    const linkParts = cleanedInnerHTML.split("?");
    const queryStringParts = (linkParts[1] || "").split("&amp;").filter((part) => {
      return !part.startsWith("l=") &&
        !part.startsWith("m=") &&
        !part.startsWith("h=") &&
        !part.startsWith("min=");
    });

    const cleanedLowValue = this.lowTarget.value.replace(/[^\d]/g, '');
    const cleanedMediumValue = this.mediumTarget.value.replace(/[^\d]/g, '');
    const cleanedHighValue = this.highTarget.value.replace(/[^\d]/g, '');
    const cleanedMinValue = this.minTarget.value.replace(/[^\d]/g, '');

    if (cleanedLowValue !== "0" && cleanedLowValue !== "") {
      queryStringParts.push(`l=${cleanedLowValue}`);
    }

    if (cleanedMediumValue !== "0" && cleanedMediumValue !== "") {
      queryStringParts.push(`m=${cleanedMediumValue}`);
    }

    if (cleanedHighValue !== "0" && cleanedHighValue !== "") {
      queryStringParts.push(`h=${cleanedHighValue}`);
    }

    if (cleanedMinValue !== "0" && cleanedMinValue !== "") {
      queryStringParts.push(`min=${cleanedMinValue}`);
    }

    const newUrl = `${this.pacturlValue}?${queryStringParts.join("&")}`;
    this.pactLinkTarget.innerHTML = newUrl;
    this.pactLinkTarget.setAttribute('href', newUrl); // Update href attribute

    this.updatePreviewLink();
    this.updateEmailLink();
  }



  updateEmailLink() {
    const emailLink = document.getElementById("emailLink");
    if (emailLink) {
      const personId = encodeURIComponent(this.idTarget.value);
      const newAddToBodyValue = encodeURIComponent(this.pactLinkTarget.innerHTML);

      let oldHref = emailLink.getAttribute("href");

      let newHref = oldHref.replace(/(add_to_body=).*?(&|$)/, `$1${newAddToBodyValue}$2`);

      if (newHref.includes("person_id=")) {
        newHref = newHref.replace(/(person_id=).*?(&|$)/, `$1${personId}$2`);
      } else {
        newHref += `&person_id=${personId}`;
      }

      emailLink.setAttribute("href", newHref);

      document.addEventListener("turbo:before-cache", () => {
        emailLink.setAttribute("href", oldHref);
      });
    }
  }

  copyPactUrl() {
    const copyInput = document.createElement("input");
    document.body.appendChild(copyInput);
    copyInput.value = this.pactLinkTarget.innerText.trim();
    copyInput.select();
    document.execCommand("copy", false);
    copyInput.remove();

    this.swapCopied()
  }

  swapCopied() {
    this.copiedTarget.classList.remove("hidden");
    this.copyTarget.classList.add("hidden");

    setTimeout(() => {
      this.copiedTarget.classList.add("hidden");
      this.copyTarget.classList.remove("hidden");
    }, 2000);
  }
}
