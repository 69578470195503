import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggler"];

  connect() {
    document.querySelectorAll("[data-data-import--import-errors-info-modal-target='toggler']").forEach((button) => {
      button.addEventListener("click", () => {
        this.open();
      });
    });
  }

  open() {
    this.element.classList.remove("hidden");
  }

  close() {
    this.element.classList.add("hidden");
  }
}
