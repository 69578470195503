import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.addEventListeners();
    this.checkForNonEmptyInputs();
  }

  disconnect() {
    this.removeEventListeners();
  }

  checkForNonEmptyInputs() {
    const nonEmptyInputs = this.inputTargets.filter((input) => {
      return input.value.length > 0;
    });

    if (nonEmptyInputs.length > 0) {
      this.addRequiredToAllInputs();
    } else {
      this.removeRequiredFromAllInputs();
    }
  }

  addEventListeners() {
    this.inputTargets.forEach((input) => {
      input.addEventListener("keyup", this.handleInput);
    });
  }

  removeEventListeners() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener("keyup", this.handleInput);
    });
  }

  handleInput = () => {
    this.checkForNonEmptyInputs();
  };

  addRequiredToAllInputs() {
    this.inputTargets.forEach((input) => {
      input.required = true;
      this.toggleRequiredClassForClosestLabel(input, true);
    });
  }

  removeRequiredFromAllInputs() {
    this.inputTargets.forEach((input) => {
      input.required = false;
      this.toggleRequiredClassForClosestLabel(input, false);
    });
  }

  toggleRequiredClassForClosestLabel(input, required) {
    const label = document.querySelector(`label[for="${input.id}"]`);

    if (required) {
      label.classList.add("required");
    } else {
      label.classList.remove("required");
    }
  }
}
