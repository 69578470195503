import Trix from "trix";
import "~/controllers/trix/variables_handler.js";

document.addEventListener("trix-before-initialize", () => {
  let lang = Trix.config.lang;

  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
  };

  Trix.config.blockAttributes.heading4 = {
    tagName: "h4",
  };

  Trix.config.textAttributes.underline = {
    tagName: "u",
    inheritable: true,
    parser: function (element) {
      return element.style.textDecoration === "underline";
    },
    style: {
      textDecoration: "underline",
    },
  };

  ["Left", "Center", "Right", "Justify"].forEach((alignDirection) => {
    Trix.config.blockAttributes[`textAlign${alignDirection}`] = {
      tagName: `div-text-align-${alignDirection.toLowerCase()}`,
    };
  });

  function trixDefaultToolbarHTML(config = {}) {
    const hideBold = config.hideBold === true;
    const hideItalic = config.hideItalic === true;
    const hideStrike = config.hideStrike === true;
    const hideUnderline = config.hideUnderline === true;
    const hideLink = config.hideLink === true;
    const hideAttachFiles = config.hideAttachFiles === true;
    const hideHeading1 = config.hideHeading1 === true;
    const hideHeading2 = config.hideHeading2 === true;
    const hideQuote = config.hideQuote === true;
    const hideBulletList = config.hideBulletList === true;
    const hideNumberList = config.hideNumberList === true;
    const hideDecreaseNesting = config.hideDecreaseNesting === true;
    const hideIncreaseNesting = config.hideIncreaseNesting === true;
    const hideAlignLeft = config.hideAlignLeft === true;
    const hideAlignCenter = config.hideAlignCenter === true;
    const hideAlignRight = config.hideAlignRight === true;
    const hideAlignJustify = config.hideAlignJustify === true;
    const hideUndo = config.hideUndo === true;
    const hideRedo = config.hideRedo === true;

    const textToolsVisible = !hideBold || !hideItalic || !hideStrike || !hideUnderline || !hideLink || !hideAttachFiles;
    const blockToolsVisible = !hideHeading1 || !hideHeading2 || !hideQuote || !hideBulletList || !hideNumberList;
    const nestingToolsVisible = !hideDecreaseNesting || !hideIncreaseNesting;
    const textAlignmentVisible = !hideAlignLeft || !hideAlignCenter || !hideAlignRight || !hideAlignJustify;
    const historyToolsVisible = !hideUndo || !hideRedo;

    return `
    <div class="trix-button-row">
      ${
        textToolsVisible
          ? `<span class="trix-button-group trix-button-group--text-tools !pl-0" data-trix-button-group="text-tools">
            ${!hideBold ? boldButtonHTML() : ""}
            ${!hideItalic ? italicButtonHTML() : ""}
            ${!hideStrike ? strikeButtonHTML() : ""}
            ${!hideUnderline ? underlineButtonHTML() : ""}
            ${!hideLink ? linkButtonHTML() : ""}
            ${!hideAttachFiles ? attachFilesButtonHTML() : ""}
          </span>`
          : ""
      }

      ${
        blockToolsVisible
          ? `<span class="trix-button-group trix-button-group--block-tools gap-0.5" data-trix-button-group="block-tools">
            ${!hideHeading1 ? heading1ButtonHTML() : ""}
            ${!hideHeading2 ? heading2ButtonHTML() : ""}
            ${!hideQuote ? quoteButtonHTML() : ""}
            ${!hideBulletList ? bulletListButtonHTML() : ""}
            ${!hideNumberList ? numberListButtonHTML() : ""}
          </span>`
          : ""
      }

      ${
        nestingToolsVisible
          ? `<span class="trix-button-group trix-button-group--nesting-tools gap-0.5" data-trix-button-group="nesting-tools">
            ${!hideDecreaseNesting ? decreaseNestingButtonHTML() : ""}
            ${!hideIncreaseNesting ? increaseNestingButtonHTML() : ""}
          </span>`
          : ""
      }

      ${
        textAlignmentVisible
          ? `<span class="trix-button-group trix-button-group--text-alignment" data-trix-button-group="text-alignment">
            ${!hideAlignLeft ? alignLeftButtonHTML() : ""}
            ${!hideAlignCenter ? alignCenterButtonHTML() : ""}
            ${!hideAlignRight ? alignRightButtonHTML() : ""}
            ${!hideAlignJustify ? alignJustifyButtonHTML() : ""}
          </span>`
          : ""
      }

      ${
        historyToolsVisible
          ? `<span class="trix-button-group trix-button-group--history-tools !pr-0 !border-0 !ml-auto" data-trix-button-group="history-tools">
            ${!hideUndo ? undoButtonHTML() : ""}
            ${!hideRedo ? redoButtonHTML() : ""}
          </span>`
          : ""
      }
    </div>

    ${
      !hideLink
        ? `
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" data-trix-input>
            <div class="flex">
              <input type="button" class="btn btn-secondary btn-small mr-1" value="${lang.link}" data-trix-method="setAttribute">
              <input type="button" class="btn btn-tertiary outline btn-small" value="${lang.unlink}" data-trix-method="removeAttribute">
            </div>
          </div>
          <div data-behavior="embed_container">
            <div class="link_to_embed link_to_embed--new">
              Would you like to embed media from this site?
              <input class="btn btn-tertiary outline btn-small ml-3" type="button" data-behavior="embed_url" value="Yes, embed it">
            </div>
          </div>
        </div>
      </div>
      `
        : ""
    }
  `;
  }
  Trix.config.toolbar.getDefaultHTML = trixDefaultToolbarHTML.bind(Trix.config.toolbar);

  class EmbedController {
    constructor(element) {
      this.patterns = undefined;
      this.element = element;
      this.editor = element.editor;
      this.toolbar = element.toolbarElement;
      this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']");
      this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']");
      this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']");

      this.reset();
      this.installEventHandlers();
    }

    installEventHandlers() {
      if (this.hrefElement) {
        this.hrefElement.addEventListener("input", this.didInput.bind(this));
        this.hrefElement.addEventListener("focusin", this.didInput.bind(this));
      }
      if (this.embedElement) {
        this.embedElement.addEventListener("click", this.embed.bind(this));
      }
      this.element.addEventListener("trix-paste", this.didPaste.bind(this));
    }

    didInput(event) {
      let value = event.target.value.trim();

      if (this.patterns === undefined) {
        this.loadPatterns(value);
      } else if (this.match(value)) {
        this.fetch(value);
      } else {
        this.reset();
      }
    }

    didPaste(event) {
      if (this.element.innerHTML.includes("<!--block-->&nbsp;")) {
        this.element.innerHTML = this.element.innerHTML.replace("<!--block-->&nbsp;", "<!--block-->");
      }
    }

    loadPatterns(value) {
      Rails.ajax({
        type: "get",
        url: "/action_text/embeds/patterns.json",
        success: (response) => {
          this.patterns = response.map((pattern) => new RegExp(pattern.source, pattern.options));
          if (this.match(value)) {
            this.fetch(value);
          }
        },
      });
    }

    match(value) {
      return this.patterns && this.patterns.some((regex) => regex.test(value));
    }

    fetch(value) {
      Rails.ajax({
        url: `/action_text/embeds?id=${encodeURIComponent(value)}`,
        type: "post",
        error: this.reset.bind(this),
        success: this.showEmbed.bind(this),
      });
    }

    embed(event) {
      if (!this.currentEmbed) {
        return;
      }

      let attachment = new Trix.Attachment(this.currentEmbed);
      this.editor.insertAttachment(attachment);
      this.element.focus();
    }

    showEmbed(embed) {
      this.currentEmbed = embed;
      if (this.embedContainerElement) {
        this.embedContainerElement.style.display = "block";
      }
    }

    reset() {
      if (this.embedContainerElement) {
        this.embedContainerElement.style.display = "none";
      }
      this.currentEmbed = null;
    }
  }

  class InlineCode {
    constructor(element) {
      this.element = element;
      this.editor = element.editor;
      this.toolbar = element.toolbarElement;

      this.installEventHandlers();
    }

    installEventHandlers() {
      const blockCodeButton = this.toolbar.querySelector("[data-trix-attribute=code]");
      if (blockCodeButton) {
        const inlineCodeButton = blockCodeButton.cloneNode(true);

        inlineCodeButton.hidden = true;
        inlineCodeButton.dataset.trixAttribute = "inlineCode";
        blockCodeButton.insertAdjacentElement("afterend", inlineCodeButton);

        this.element.addEventListener("trix-selection-change", (_) => {
          const type = this.getCodeFormattingType();
          blockCodeButton.hidden = type == "inline";
          inlineCodeButton.hidden = type == "block";
        });
      }
    }

    getCodeFormattingType() {
      if (this.editor.attributeIsActive("code")) return "block";
      if (this.editor.attributeIsActive("inlineCode")) return "inline";

      const range = this.editor.getSelectedRange();
      if (range[0] == range[1]) return "block";

      const text = this.editor.getSelectedDocument().toString().trim();
      return /\n/.test(text) ? "block" : "inline";
    }
  }

  document.addEventListener("trix-initialize", function (event) {
    const editor = event.target;
    const isDisabled =
      editor.hasAttribute("disabled") &&
      editor.getAttribute("disabled") !== "false" &&
      editor.getAttribute("disabled") !== "0" &&
      editor.getAttribute("disabled") !== false;

    if (isDisabled) {
      editor.editor.element.contentEditable = false;
    }

    const { toolbarElement } = event.target;
    const config = {
      hideBold: editor.dataset.hideBold === "true",
      hideItalic: editor.dataset.hideItalic === "true",
      hideStrike: editor.dataset.hideStrike === "true",
      hideUnderline: editor.dataset.hideUnderline === "true",
      hideLink: editor.dataset.hideLink === "true",
      hideAttachFiles: editor.dataset.hideAttachFiles === "true",
      hideHeading1: editor.dataset.hideHeading1 === "true",
      hideHeading2: editor.dataset.hideHeading2 === "true",
      hideQuote: editor.dataset.hideQuote === "true",
      hideBulletList: editor.dataset.hideBulletList === "true",
      hideNumberList: editor.dataset.hideNumberList === "true",
      hideDecreaseNesting: editor.dataset.hideDecreaseNesting === "true",
      hideIncreaseNesting: editor.dataset.hideIncreaseNesting === "true",
      hideAlignLeft: editor.dataset.hideAlignLeft === "true",
      hideAlignCenter: editor.dataset.hideAlignCenter === "true",
      hideAlignRight: editor.dataset.hideAlignRight === "true",
      hideAlignJustify: editor.dataset.hideAlignJustify === "true",
      hideUndo: editor.dataset.hideUndo === "true",
      hideRedo: editor.dataset.hideRedo === "true",
    };

    toolbarElement.innerHTML = Trix.config.toolbar.getDefaultHTML(config);

    new EmbedController(editor);
    new InlineCode(editor);

    const input = toolbarElement.querySelector("input[name=href]");
    if (input) {
      input.type = "text";
    }
  });

  document.addEventListener("trix-file-accept", function (event) {
    const maxFileSize = 1024 * 1024 * 2; // 2MB
    const acceptedTypes = ["image/jpeg", "image/png"];
    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault();
      alert("Only support attachment of jpeg or png files");
    }

    if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert("Only support attachment files upto size 2MB!");
    }
  });

  function boldButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Bold" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M4.49992 8.4376H10.6874C11.4333 8.4376 12.1487 8.73392 12.6762 9.26136C13.2036 9.78881 13.4999 10.5042 13.4999 11.2501C13.4999 11.996 13.2036 12.7114 12.6762 13.2388C12.1487 13.7663 11.4333 14.0626 10.6874 14.0626L4.49951 14.0623V3.37476L9.84367 3.3751C10.515 3.3751 11.1588 3.64178 11.6335 4.11648C12.1082 4.59119 12.3749 5.23502 12.3749 5.90635C12.3749 6.57768 12.1082 7.22151 11.6335 7.69621C11.1588 8.17091 10.515 8.4376 9.84367 8.4376" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function italicButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Italic" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M10.6875 3.9375L7.3125 14.0625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 14.0625H10.125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.875 3.9375H13.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function strikeButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Strike" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 9H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.36477 6.75C5.30666 6.56824 5.27817 6.37831 5.28039 6.1875C5.28039 4.63359 6.82727 3.375 8.99993 3.375C10.6734 3.375 11.9741 4.12031 12.4804 5.16797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.0625 11.8125C5.0625 13.3664 6.82734 14.625 9 14.625C11.1727 14.625 12.9375 13.3664 12.9375 11.8125C12.9375 10.1391 11.4187 9.49219 9.73125 9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function underlineButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="Underline" tabindex="-1" data-controller='tooltip' data-tippy-content="Underline" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M5.25 4.5V9C5.25 10.086 5.83696 11.0783 6.7782 11.5607C7.71944 12.043 8.78056 12.043 9.7218 11.5607C10.663 11.0783 11.25 10.086 11.25 9V4.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 14.25H13.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function linkButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1" data-controller='tooltip' data-tippy-content="Link" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M6.61621 11.3836L11.3834 6.60938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.1952 12.5789L8.20537 14.5687C7.89189 14.8822 7.51974 15.1309 7.11016 15.3005C6.70058 15.4702 6.26159 15.5575 5.81827 15.5575C4.92293 15.5575 4.06426 15.2019 3.43116 14.5687C2.79806 13.9356 2.44238 13.077 2.44238 12.1816C2.44238 11.2863 2.79806 10.4276 3.43116 9.79453L5.421 7.80469" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5789 10.1952L14.5687 8.20537C15.2019 7.57227 15.5575 6.7136 15.5575 5.81827C15.5575 4.92293 15.2019 4.06426 14.5687 3.43116C13.9356 2.79806 13.077 2.44238 12.1816 2.44238C11.2863 2.44238 10.4276 2.79806 9.79453 3.43116L7.80469 5.421" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function attachFilesButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-attach-files" data-trix-attribute="attachFiles" data-trix-action="attachFiles" data-trix-key="i" title="Attach Files" tabindex="-1" data-controller='tooltip' data-tippy-content="Insert an image" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M15.1875 3.375H2.8125C2.50184 3.375 2.25 3.62684 2.25 3.9375V14.0625C2.25 14.3732 2.50184 14.625 2.8125 14.625H15.1875C15.4982 14.625 15.75 14.3732 15.75 14.0625V3.9375C15.75 3.62684 15.4982 3.375 15.1875 3.375Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.25 11.8124L5.78672 8.27572C5.83906 8.22257 5.90145 8.18036 5.97025 8.15156C6.03906 8.12275 6.11291 8.10791 6.1875 8.10791C6.26209 8.10791 6.33594 8.12275 6.40475 8.15156C6.47355 8.18036 6.53594 8.22257 6.58828 8.27572L9.72422 11.4117C9.77656 11.4648 9.83895 11.507 9.90775 11.5358C9.97656 11.5646 10.0504 11.5795 10.125 11.5795C10.1996 11.5795 10.2734 11.5646 10.3422 11.5358C10.4111 11.507 10.4734 11.4648 10.5258 11.4117L11.9742 9.96322C12.0266 9.91007 12.0889 9.86786 12.1578 9.83906C12.2266 9.81025 12.3004 9.79541 12.375 9.79541C12.4496 9.79541 12.5234 9.81025 12.5922 9.83906C12.6611 9.86786 12.7234 9.91007 12.7758 9.96322L15.75 12.9374" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9688 7.98438C11.4951 7.98438 11.9219 7.55765 11.9219 7.03125C11.9219 6.50485 11.4951 6.07812 10.9688 6.07812C10.4424 6.07812 10.0156 6.50485 10.0156 7.03125C10.0156 7.55765 10.4424 7.98438 10.9688 7.98438Z" fill="#64748B" stroke-width="0.5"/>
      </svg>
    </button>
  `;
  }

  function heading1ButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading3" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Title" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 3.9375V12.375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.125 8.15625H2.8125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.125 3.9375V12.375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.7812 8.71875L15.4688 7.59375V14.0625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function heading2ButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-2" data-trix-attribute="heading4" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Subtitle" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 3.9375V12.375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.125 8.15625H2.8125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.125 3.9375V12.375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.6336 8.34611C13.7853 7.98799 14.056 7.69323 14.3999 7.51172C14.7439 7.3302 15.14 7.27307 15.5212 7.34998C15.9024 7.42689 16.2454 7.63312 16.4921 7.93379C16.7388 8.23446 16.874 8.6111 16.875 9.00002C16.8765 9.33603 16.776 9.66458 16.5867 9.94221L13.5 14.0625H16.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function quoteButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Quote" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M7.59375 10.125H2.8125C2.66332 10.125 2.52024 10.0657 2.41475 9.96025C2.30926 9.85476 2.25 9.71168 2.25 9.5625V5.0625C2.25 4.91332 2.30926 4.77024 2.41475 4.66475C2.52024 4.55926 2.66332 4.5 2.8125 4.5H7.03125C7.18043 4.5 7.32351 4.55926 7.429 4.66475C7.53449 4.77024 7.59375 4.91332 7.59375 5.0625V11.25C7.59375 11.9959 7.29743 12.7113 6.76999 13.2387C6.24254 13.7662 5.52717 14.0625 4.78125 14.0625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.75 10.125H10.9688C10.8196 10.125 10.6765 10.0657 10.571 9.96025C10.4655 9.85476 10.4062 9.71168 10.4062 9.5625V5.0625C10.4062 4.91332 10.4655 4.77024 10.571 4.66475C10.6765 4.55926 10.8196 4.5 10.9688 4.5H15.1875C15.3367 4.5 15.4798 4.55926 15.5852 4.66475C15.6907 4.77024 15.75 4.91332 15.75 5.0625V11.25C15.75 11.9959 15.4537 12.7113 14.9262 13.2387C14.3988 13.7662 13.6834 14.0625 12.9375 14.0625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function bulletListButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Bullet List" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M6.1875 4.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.1875 9H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.1875 13.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.09375 9.8125C3.54248 9.8125 3.90625 9.44873 3.90625 9C3.90625 8.55127 3.54248 8.1875 3.09375 8.1875C2.64502 8.1875 2.28125 8.55127 2.28125 9C2.28125 9.44873 2.64502 9.8125 3.09375 9.8125Z" fill="#64748B" stroke-width="0.5"/>
        <path d="M3.09375 5.3125C3.54248 5.3125 3.90625 4.94873 3.90625 4.5C3.90625 4.05127 3.54248 3.6875 3.09375 3.6875C2.64502 3.6875 2.28125 4.05127 2.28125 4.5C2.28125 4.94873 2.64502 5.3125 3.09375 5.3125Z" fill="#64748B" stroke-width="0.5"/>
        <path d="M3.09375 14.3125C3.54248 14.3125 3.90625 13.9487 3.90625 13.5C3.90625 13.0513 3.54248 12.6875 3.09375 12.6875C2.64502 12.6875 2.28125 13.0513 2.28125 13.5C2.28125 13.9487 2.64502 14.3125 3.09375 14.3125Z" fill="#64748B" stroke-width="0.5"/>
      </svg>
    </button>
  `;
  }

  function numberListButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Number List" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M7.3125 9H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.3125 4.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.3125 13.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 4.21875L3.9375 3.65625V7.59375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.88984 10.7298C2.94168 10.6014 3.01999 10.4855 3.11968 10.3894C3.21938 10.2934 3.3382 10.2195 3.46839 10.1725C3.59859 10.1255 3.73723 10.1065 3.87527 10.1167C4.01331 10.1269 4.14764 10.1661 4.2695 10.2318C4.39135 10.2974 4.498 10.3881 4.58246 10.4977C4.66692 10.6074 4.72731 10.7336 4.75968 10.8682C4.79205 11.0028 4.79567 11.1427 4.77031 11.2787C4.74495 11.4148 4.69117 11.544 4.6125 11.6579L2.8125 14.0626H4.78125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function decreaseNestingButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Outdent" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M7.875 9H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.875 4.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 13.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.0625 3.9375L2.25 6.75L5.0625 9.5625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function increaseNestingButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Indent" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M7.875 9H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.875 4.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 13.5H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 3.9375L5.625 6.75L2.8125 9.5625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function alignLeftButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-text-align-left" data-trix-attribute="textAlignLeft" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Align Left" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 4.78125H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 7.59375H11.8125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 10.4062H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 13.2188H11.8125" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function alignCenterButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-text-align-center" data-trix-attribute="textAlignCenter" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Align Center" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 4.78125H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 7.59375H13.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 10.4062H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 13.2188H13.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function alignRightButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-text-align-right" data-trix-attribute="textAlignRight" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Align Right" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 4.78125H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.1875 7.59375H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 10.4062H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.1875 13.2188H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function alignJustifyButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-text-align-justify" data-trix-attribute="textAlignJustify" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Align Justify" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M2.8125 4.78125H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 7.59375H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 10.4062H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.8125 13.2188H15.1875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function undoButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Undo" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M5.61084 7.01025H2.23584V3.63525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.62647 13.3734C5.4916 14.2393 6.5941 14.8291 7.79449 15.0682C8.99489 15.3074 10.2392 15.1852 11.3702 14.717C12.5011 14.2488 13.4677 13.4558 14.1478 12.4381C14.828 11.4205 15.191 10.224 15.191 9C15.191 7.77602 14.828 6.57953 14.1478 5.56189C13.4677 4.54425 12.5011 3.75118 11.3702 3.28302C10.2392 2.81485 8.99489 2.69261 7.79449 2.93176C6.5941 3.17092 5.4916 3.76072 4.62647 4.62657L2.23584 7.01016" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }

  function redoButtonHTML() {
    return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="" tabindex="-1" data-controller='tooltip' data-tippy-content="Redo" data-tippy-delay='0' data-tippy-arrow='true' data-tippy-size='regular' data-tippy-animation='fade'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M12.3892 7.01025H15.7642V3.63525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3736 13.3734C12.5085 14.2393 11.406 14.8291 10.2056 15.0682C9.00517 15.3074 7.76081 15.1852 6.62989 14.717C5.49898 14.2488 4.53233 13.4558 3.85221 12.4381C3.1721 11.4205 2.80908 10.224 2.80908 9C2.80908 7.77602 3.1721 6.57953 3.85221 5.56189C4.53233 4.54425 5.49898 3.75118 6.62989 3.28302C7.76081 2.81485 9.00517 2.69261 10.2056 2.93176C11.406 3.17092 12.5085 3.76072 13.3736 4.62657L15.7642 7.01016" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  `;
  }
});
