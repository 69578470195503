import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["iframe", "modal", "poster"];

  connect() {}

  open(event) {
    const videoUrl = event.currentTarget.getAttribute("data-video-url");
    this.iframeTarget.src = videoUrl;
    this.modalTarget.style.display = "flex";
    if (this.posterTarget) this.posterTarget.style.display = "none";

    this.modalTarget.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  close() {
    this.iframeTarget.src = "";
    if (this.modalTarget) {
      this.modalTarget.style.display = "none";

      this.modalTarget.removeEventListener("click", this.handleOutsideClick.bind(this));
    }
    if (this.posterTarget) this.posterTarget.style.display = "flex";
  }

  handleOutsideClick(event) {
    if (event.target === this.modalTarget) {
      this.close();
    }
  }
}
