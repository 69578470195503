document.addEventListener("turbo:load", () => {
  const tabs = document.querySelector(".default-tabs");

  if (typeof tabs != "undefined" && tabs != null) {
    const tabContent = document.querySelectorAll(".default-tab-content");

    tabs.addEventListener("click", function (e) {
      if (e.target && e.target.nodeName === "DIV") {
        const tabElement = e.target;
        selectTab(tabElement);
      }
    });

    function detectTabAnchor(newLocation) {
      const tabAnchor = newLocation || window.location.hash?.substring(1);
      if (tabAnchor !== null && tabAnchor !== undefined) {
        const tabElement = document.querySelector(`div[data-tab-id='${tabAnchor}'`);
        if (tabElement) {
          selectTab(tabElement);
        }
      }
    }

    function detectTabParam() {
      const tabParam = new URLSearchParams(window.location.search).get("tab");
      if (tabParam !== null && tabParam !== undefined) {
        const tabElement = document.querySelector(`div[data-tab-id='${tabParam}'`);
        if (tabElement) {
          selectTab(tabElement);
        }
      }
    }

    detectTabAnchor();
    detectTabParam();

    // We also add the listener to any elements on the page that have the anchor as a data attribute
    document.querySelectorAll(`[href*="${window.location.pathname}"]`).forEach((element) => {
      if (element.getAttribute("listener") !== "true") {
        element.addEventListener("click", (e) => {
          element.setAttribute("listener", "true");
          const elementTabAnchor = new URL(e.currentTarget.href).hash?.substring(1);
          if (elementTabAnchor !== null || elementTabAnchor !== undefined) {
            const tabElement = document.querySelector(`div[data-tab-id='${elementTabAnchor}'`);
            if (tabElement) {
              const controllerElement = element.closest("[data-controller='navigation--menu--item']");
              if (controllerElement !== null && controllerElement.controller !== undefined) {
                controllerElement.controller.enableActiveLink();
              }
              selectTab(tabElement);
            }
          }
        });
      }
    });

    function selectTab(element) {
      if (element.classList.contains("disabled")) return;

      const tab = document.querySelectorAll(".default-tabs .tab");
      for (var i = 0; i < tab.length; i++) {
        tab[i]?.classList?.remove("active");
      }
      element.classList?.toggle("active");

      for (i = 0; i < tabContent.length; i++) {
        tabContent[i]?.classList?.remove("active");
      }
      const tabId = "#" + element.dataset.tabId;
      document.querySelector(tabId)?.classList.toggle("active");
    }

    if ("onhashchange" in window) {
      // event supported?
      // Forces the event listener to guarantee tab will be selected
      window.onhashchange = function (event) {
        detectTabAnchor(new URL(event.newURL).hash?.substring(1));
      };
    }
  }
});
