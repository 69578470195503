import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    // check if there is a quick_email parameter in the URL, and if so, make a turbo request to quick_email/new?pipeline_id=123
    const urlParams = new URLSearchParams(window.location.search);
    const quickEmailTemplateId = urlParams.get("quick_email_template_id");
    if (quickEmailTemplateId) {
      const pipelineId = urlParams.get("pipeline_id");
      const url = `/quick_email/new?email_template_id=${quickEmailTemplateId}`;
      get(url, { responseKind: "turbo-stream" });
      // then clean up quick_email_template_id from the url
      const newUrl = window.location.href.replace(`quick_email_template_id=${quickEmailTemplateId}`, "");
      window.history.replaceState({}, document.title, newUrl);
    }
  }
}
