import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['block'];

  connect() {
    this.blockTarget.addEventListener('click', (event) => {
      if (event.target.tagName.toLowerCase() !== 'a') {
        Turbo.visit(this.data.get('url'));
      }
    });
  }
}
