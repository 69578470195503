import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "clickToRegenerate",
    "confirmButton",
    "content",
    "documentDataUri",
    "htmlDocument",
  ];

  connect() {
    this.contentTarget.classList.add("hidden");
  }

  prepareAndSubmitDocument(ev) {
    ev.preventDefault();

    if (window.html2pdf == undefined) {
      alert("Something went wrong. Pleae try after sometime.");
      return;
    }

    const opts = {
      margin: [0.75, 0.75, 0.75, 0.75],
      filename: "pact.pdf",
      jsPDF: {
        unit: "in",
        format: "legal",
      },
      pagebreak: {
        mode: "avoid-all",
      },
    };

    this.contentTarget.classList.remove("hidden");
    this.htmlDocumentTarget.value = this.contentTarget.innerHTML;
    html2pdf()
      .set(opts)
      .from(this.contentTarget)
      .toPdf()
      .output("datauristring")
      .then(this.setDocumentDataUriAndSubmit.bind(this));
  }

  setDocumentDataUriAndSubmit(txt) {
    this.contentTarget.classList.add("hidden");
    this.documentDataUriTarget.value = txt;
    Rails.fire(this.element.querySelector("form"), "submit");
  }
}
