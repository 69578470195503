import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["newFolder", "newItem"];

  addNewFolder(ev) {
    ev.preventDefault();
    ev.currentTarget.blur();

    const [parentFolderEle, newChildFormContainer] = this.getParentAndChildForm(
      ev.currentTarget
    );
    newChildFormContainer.innerHTML = this.newFolderTarget.innerHTML;
    newChildFormContainer.querySelector("input.folder_parent_id").value =
      parentFolderEle.dataset.id;
  }

  addNewItem(ev) {
    ev.currentTarget.blur();

    const [parentFolderEle, newChildFormContainer] = this.getParentAndChildForm(
      ev.currentTarget
    );

    const newItemForm = this.newItemTargets.find((ele) => {
      return ev.currentTarget.dataset.type === ele.dataset.type;
    });
    newChildFormContainer.innerHTML = newItemForm.innerHTML.replaceAll(
      "NEW_ITEM_FOLDER_ID",
      parentFolderEle.dataset.id
    );
  }

  removeNewChildForm(ev) {
    ev.preventDefault();

    ev.currentTarget.closest("form").remove();
  }

  getParentAndChildForm(childEle) {
    let parentFolderEle = childEle.closest(".folder");
    parentFolderEle = parentFolderEle || document.getElementById("folders");

    const childFormContainer = parentFolderEle.querySelector(
      ":scope > .new-child-container"
    );
    return [parentFolderEle, childFormContainer];
  }
}
