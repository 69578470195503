import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["vehicleType"];

  connect () {
    this.typeChanged();
  }

  typeChanged() {
    this.hideAllTypeInputs();

    switch (this.vehicleTypeTarget.value) {
      case "ira":
        this.showIraFields();
        break;
      case "individual":
        this.showIndividualFields();
        break;
      case "married_couple":
        this.showIndividualFields();
        break;
      case "joint_tenants":
        this.showIndividualFields();
        break;
      case "entity":
        this.showEntityFields();
        break;
      case "partnership":
        this.showEntityFields();
        break;
      case "corporation":
        this.showEntityFields();
        break;
      case "trust":
        this.showTrustFields();
        break;
    }
  }

  hideAllTypeInputs() {
    this.hideIraFields();
    this.hideIndividualFields();
    this.hideEntityFields();
    this.hideTrustFields();
  }

  hideIraFields() {
    const parentElement = document.getElementById("form-ira-fields");
    parentElement.classList.add("hidden");
    this.disableFields(parentElement);
  }

  hideIndividualFields() {
    const parentElement = document.getElementById("form-individual-fields");
    parentElement.classList.add("hidden");
    this.disableFields(parentElement);
  }

  hideEntityFields() {
    const parentElement = document.getElementById("form-entity-fields");
    parentElement.classList.add("hidden");
    this.disableFields(parentElement);
  }

  hideTrustFields() {
    const parentElement = document.getElementById("form-trust-fields");
    parentElement.classList.add("hidden");
    this.disableFields(parentElement);
  }

  showIraFields() {
    const parentElement = document.getElementById("form-ira-fields");
    parentElement.classList.remove("hidden");
    this.enableFields(parentElement);
  }

  showIndividualFields() {
    const parentElement = document.getElementById("form-individual-fields");
    parentElement.classList.remove("hidden");
    this.enableFields(parentElement);
  }

  showEntityFields() {
    const parentElement = document.getElementById("form-entity-fields");
    parentElement.classList.remove("hidden");
    this.enableFields(parentElement);
  }

  showTrustFields() {
    const parentElement = document.getElementById("form-trust-fields");
    parentElement.classList.remove("hidden");
    this.enableFields(parentElement);
  }

  disableFields(parentElement) {
    const inputs = parentElement.querySelectorAll("input, select, textarea");
    inputs.forEach(input => input.disabled = true);
  }

  enableFields(parentElement) {
    const inputs = parentElement.querySelectorAll("input, select, textarea");
    inputs.forEach(input => input.disabled = false);
  }
}
