import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "otherInput", "otherTextFieldInput"];

  connect() {
    this.toggleDisableOtherInputFromSubmitting();
    this.setInitialDatasetSelected();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.inputTargets.forEach((radio) => {
      radio.addEventListener("click", this.click);
      radio.addEventListener("change", this.otherSelectChange.bind(this));
    });
  }

  removeEventListeners() {
    this.inputTargets.forEach((radio) => {
      radio.removeEventListener("click", this.click);
      radio.removeEventListener("change", this.otherSelectChange.bind(this));
    });
  }

  click = (e) => {
    const radioButton = e.target;

    radioButton.dataset.selected === "true" ? this.unselect(radioButton) : this.select(radioButton);
    radioButton.dispatchEvent(
      new CustomEvent("change", {
        bubbles: true,
        detail: {
          emptyValue: this.noRadioButtonsSelected(),
        },
      })
    );
  };

  unselect(radioButton) {
    this.setinputTargetsUnselected();
    radioButton.checked = false;
    radioButton.dataset.selected = "false";
  }

  select(radioButton) {
    this.setinputTargetsUnselected();
    radioButton.dataset.selected = "true";
  }

  setInitialDatasetSelected() {
    this.inputTargets.forEach((radio) => {
      if (radio.checked) {
        radio.dataset.selected = "true";
      } else {
        radio.dataset.selected = "false";
      }
    });
  }

  setinputTargetsUnselected() {
    this.inputTargets.forEach((radio) => {
      radio.dataset.selected = "false";
    });
  }

  noRadioButtonsSelected() {
    return !Array.from(this.inputTargets).some((radio) => radio.checked);
  }

  otherSelectChange() {
    if (this.hasOtherInputTarget) {
      if(this.otherInputTarget.checked) {
        this.otherTextFieldInputTarget.classList.remove("hidden");
        this.otherTextFieldInputTarget.required = this.requiredValue;
      } else {
        this.otherTextFieldInputTarget.classList.add("hidden");
        this.otherTextFieldInputTarget.required = false;
        this.otherTextFieldInputTarget.value = "";
      }

      this.toggleDisableOtherInputFromSubmitting();
    }
  }

  toggleDisableOtherInputFromSubmitting() {
    if (!this.hasOtherInputTarget) return;

    if (this.otherInputTarget.checked) {
      this.enableOtherTextFieldFromSubmitting();
    } else {
      this.disableOtherTextFieldFromSubmitting();
    }
  }

  disableOtherTextFieldFromSubmitting() {
    this.otherTextFieldInputTarget.disabled = true;
  }

  enableOtherTextFieldFromSubmitting() {
    this.otherTextFieldInputTarget.disabled = false;
  }
}
