import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  async submit(event) {
    event.preventDefault()

    const response = await post(this.element.action, {
      body: new FormData(this.element)
    })

    if (response.ok) {
      this.element.closest('#turbo-modal').innerHTML = ''
      document.dispatchEvent(new CustomEvent('mentions:continue-submission'))
    }
  }

  skip(event) {
    this.element.closest('#turbo-modal').innerHTML = ''
    document.dispatchEvent(new CustomEvent('mentions:continue-submission'))
  }
}
