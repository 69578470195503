import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.checkboxTarget.addEventListener("keypress", this.handleKeypress.bind(this));
  }

  disconnect() {
    this.checkboxTarget.removeEventListener("keypress", this.handleKeypress.bind(this));
  }

  handleKeypress(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      this.checkboxTarget.checked = !this.checkboxTarget.checked;
    }
  }
}
