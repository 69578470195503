import { Controller } from "stimulus";

export default class extends Controller {
  toggle(e) {
    const target = e.target;
    const id = target.dataset.id;

    document.querySelectorAll(`[data-aggregate-id="${id}"]`).forEach((el) => {
      el.classList.toggle("hidden");
    });
  }
}
