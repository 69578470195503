import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["type", "settings"];

  typeChanged() {
    if (this.typeTarget.value === "form_page") {
      this.showSettings();
    } else {
      this.hideSettings();
    }
  }

  showSettings() {
    if (!this.hasSettingsTarget) return;

    this.settingsTarget.classList.remove("hidden");
  }

  hideSettings(){
    if (!this.hasSettingsTarget) return;

    this.settingsTarget.classList.add("hidden");
  }
}
