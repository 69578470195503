import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "emojiInput"];

  connect() {
    this.emojis = Array.from(this.element.querySelectorAll("button[data-emoji]"));
  }

  filter(event) {
    const search = event.target.value.toLowerCase();
    this.emojis.forEach((button) => {
      const aliases = button.getAttribute("title").toLowerCase();
      button.style.display = aliases.includes(search) ? "" : "none";
    });
  }

  select(event) {
    const emoji = event.currentTarget.dataset.emoji;
    this.emojiInputTarget.value = emoji;
    Turbo.navigator.submitForm(this.formTarget);
    this.closePicker();
  }

  closePicker() {
    const emojiTippyInitController = this.application.getControllerForElementAndIdentifier(
      document.querySelector(".decile-base-app-root"),
      "emoji-tippy-init"
    );
    if (emojiTippyInitController && emojiTippyInitController.tippy) {
      emojiTippyInitController.tippy.hide();
    }
  }
}
