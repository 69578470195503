import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {
  static targets = [
    'menu',
    'input',
    'selectedOptionIcon',
    'selectedOptionValue',
    'scrollableOptionsContainer',
    'option',
    'optionGroup'
  ]
  static values = { open: Boolean, currentOptionKey: String }

  optionSelect(event) {
    const mappingKey = event.target.dataset.mappingKey;

    this.removeOptionKeyFromAllDropdowns(mappingKey);
    this.currentOptionKeyValue = mappingKey;
    this.toggle();
  }

  currentOptionKeyValueChanged() {
    const selectedOption = this.optionTargets.find(opt => opt.dataset.mappingKey == this.currentOptionKeyValue);

    if (selectedOption) {
      this.updateDropdownHeader(
        selectedOption?.dataset?.mappingKey,
        selectedOption?.dataset?.displayIconClass,
        selectedOption?.dataset?.displayValue
      );
    } else {
      this.currentOptionKeyValue = ""; // selects Skip this column (do not import) option
    }
  }

  updateDropdownHeader(mappingKey, iconClass, displayText) {
    // update input name
    if (mappingKey) {
      this.inputTarget.name = `mapping[${mappingKey}]`;
    } else {
      this.inputTarget.removeAttribute("name");
    }

    // update dropdown header content here
    this.selectedOptionIconTarget.className = iconClass || '';
    this.selectedOptionValueTarget.innerHTML = displayText;
  }

  removeOptionKeyFromAllDropdowns(mappingKey) {
    document.querySelectorAll("[data-controller='data-import--mapping-dropdown']").forEach((elem) => {
      if (elem.dataset["dataImport-MappingDropdownCurrentOptionKeyValue"] == mappingKey) {
        elem.dataset["dataImport-MappingDropdownCurrentOptionKeyValue"] = "";
      }
    })
  }

  scrollToOptionGroup(event) {
    const optionGroupForEventTarget = this.optionGroupTargets.find(optGroup => optGroup.dataset.groupId == event.target.dataset.optionGroupId);

    if (optionGroupForEventTarget) {
      this.scrollableOptionsContainerTarget.scrollTop = optionGroupForEventTarget.offsetTop;
    }
  }
}
