import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit", "form"];

  connect() {
    if (this.formTarget) {
      const checkboxElements = this.formTarget.getElementsByClassName("checkbox-labels");
      Array.from(checkboxElements).forEach((label) => {
        const input = document.getElementById(label.htmlFor);

        if (input) {
          let wasChecked = false;

          label.addEventListener("mousedown", () => {
            if (input.checked) {
              wasChecked = true;
            } else {
              wasChecked = false;
            }
          });

          label.addEventListener("click", () => {
            if (wasChecked) {
              setTimeout(() => {
                input.checked = false;
                this.checkEmptyInputs();
              }, 2);
            } else {
              this.submitTarget.disabled = false;
            }
          });
        }
      });
    }
  }

  checkEmptyInputs() {
    for (const input of this.formTarget.querySelectorAll("input:not([type=submit]):not([type=hidden])")) {
      if (input.type === "radio") {
        if (input.checked) {
          this.submitTarget.disabled = false;
          return;
        }
      } else {
        if (input.value) {
          this.submitTarget.disabled = false;
          return;
        }
      }
    }
    this.submitTarget.disabled = true;
  }
}
