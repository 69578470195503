import { Controller } from "stimulus";

const INITIAL_SCALE_POSITION = 1;
export default class extends Controller {
  static targets = [
    "top",
    "image",
    "controls",
    "backPageButton",
    "forwardPageButton",
  ];

  connect() {
    if (!this.hasImageTarget) return;

    this.scrollWrapper = document.getElementById("scroll-wrapper");
    this.imgWrapper = document.querySelector("#documentPreviewWrapper");
    this.scale = INITIAL_SCALE_POSITION;

    document.addEventListener("contextmenu", (event) => event.preventDefault());
    const documentImg = this.imageTarget;
    this.img = new Image();
    this.img.addEventListener("load", this.imageOnLoad.bind(this), false);
    this.img.src = documentImg.src;

    this.maxZoom = 4;
    this.minZoom = 1;
    this.scaleStep = 0.4;
    this.panning = false;
    this.start = { x: 0, y: 0 };

    this.imageLoaded = false;

    this.windowHeight = this.getWindowHeight();

    this.imageTarget.addEventListener("mousedown", this.dragStart.bind(this));
    this.imageTarget.addEventListener("mousemove", this.drag.bind(this));
    this.imageTarget.addEventListener("mouseup", this.dragEnd.bind(this));

    // set min height for loader
    this.scrollWrapper.style.height = `${
      window.innerHeight - this.topTarget.clientHeight
    }px`;

    window.onresize = () => {
      this.setWindow();

      if (this.scale === INITIAL_SCALE_POSITION) {
        this.setInitialWidth();
      }
    };

    if (this.hasBackPageButtonTarget && this.hasForwardPageButtonTarget) {
      this.setListenersForNavigationKeys();
    }
  }

  disconnect() {
    if (this.hasBackPageButtonTarget && this.hasForwardPageButtonTarget) {
      this.removeListenersForNavigationKeys();
    }
  }

  getWindowHeight() {
    return window.innerHeight - this.topTarget.clientHeight;
  }

  getProportionalWidth() {
    return (this.getWindowHeight() * this.originalWidth) / this.originalHeight;
  }

  setWindow() {
    this.imgWrapper.style.minHeight = `${
      window.innerHeight - this.topTarget.clientHeight
    }px`;

    const windowWidth = window.innerWidth;

    this.windowHeight = this.getWindowHeight();

    const proportionalWidth = this.getProportionalWidth();

    if (this.scale === INITIAL_SCALE_POSITION) {
      if (windowWidth <= proportionalWidth) {
        this.setWidth(windowWidth);
        return;
      }

      if (windowWidth > proportionalWidth) {
        this.setHeight();
      }
    }
  }

  setHeight() {
    this.imageTarget.style.height = `${
      (window.innerHeight - this.topTarget.clientHeight) * this.scale
    }px`;
    this.scrollWrapper.style.height = `${
      (window.innerHeight - this.topTarget.clientHeight) * this.scale
    }px`;
    this.imageTarget.style.width = `${
      this.getProportionalWidth() * this.scale
    }px`;
  }

  setWidth(width) {
    this.imageTarget.style.width = `${width}px`;
    this.imageTarget.style.height = "auto";
  }

  imageOnLoad() {
    this.imageLoaded = true;

    this.scrollWrapper.style.height = "auto";

    const loader = document.getElementById("loader");

    if (loader) {
      loader.remove();
    }

    this.imageTarget.classList.remove("hidden");

    // original image size
    this.originalWidth = this.img.width;
    this.originalHeight = this.img.height;

    this.setWindow();

    this.setInitialWidth();
  }

  setInitialWidth() {
    // image size when loaded on page
    this.initialHeight = this.imageTarget.clientHeight;

    this.initialWidth = this.imageTarget.clientWidth;
  }

  setTransform() {
    this.imageTarget.style.height = `${this.initialHeight * this.scale}px`;
    this.imageTarget.style.width = `${this.initialWidth * this.scale}px`;
  }

  dragStart(event) {
    if (this.scale !== INITIAL_SCALE_POSITION) {
      this.panning = true;

      this.start = {
        x: event.clientX,
        y: event.clientY,
      };

      this.initialScrollPosX = this.imgWrapper.scrollLeft;
      this.initialScrollPosY = this.imgWrapper.scrollTop;
    }
  }

  drag(event) {
    event.preventDefault();

    if (!this.panning) {
      return;
    }

    const deltaX = event.clientX - this.start.x;
    const deltaY = event.clientY - this.start.y;

    this.imgWrapper.scroll(
      this.initialScrollPosX - deltaX,
      this.initialScrollPosY - deltaY
    );
  }

  dragEnd() {
    this.panning = false;
  }

  toggleGrab(scale) {
    if (scale <= INITIAL_SCALE_POSITION) {
      this.imageTarget.style.cursor = "auto";
    } else {
      this.imageTarget.style.cursor = "grab";
    }
  }

  reset() {
    this.start.x = 0;
    this.start.y = 0;
    this.scale = INITIAL_SCALE_POSITION;
    this.setInitialWidth();
    this.setWindow(window.innerWidth);
  }

  transformAndScrollToNewPosition() {
    const oldWidth = this.imageTarget.clientWidth;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const oldScrollLeft = this.imgWrapper.scrollLeft;
    const oldScrollTop = this.imgWrapper.scrollTop;

    this.setTransform();

    const newWidth = this.imageTarget.clientWidth;

    const scale = newWidth / oldWidth;

    const newScrollPosX =
      (oldScrollLeft + (windowWidth - windowWidth / scale) / 2) * scale;
    const newScrollPosY =
      (oldScrollTop + (windowHeight - windowHeight / scale) / 2) * scale;

    this.imgWrapper.scroll(newScrollPosX, newScrollPosY);
  }

  zoomIn() {
    const newScale = this.scale + this.scaleStep;

    this.toggleGrab(newScale);

    if (this.scale < this.maxZoom) {
      if (this.scale < this.maxZoom && this.scale < newScale) {
        this.scale = newScale;

        this.transformAndScrollToNewPosition();
      }
    }
  }

  zoomOut() {
    const newScale = this.scale - this.scaleStep;

    this.toggleGrab(newScale);

    if (this.scale > INITIAL_SCALE_POSITION) {
      if (this.scale > INITIAL_SCALE_POSITION && this.scale > newScale) {
        this.scale = newScale;

        this.transformAndScrollToNewPosition();

        if (this.scale <= INITIAL_SCALE_POSITION) {
          this.reset();
        }
      }
    }
  }

  setListenersForNavigationKeys() {
    this.boundHandleNavigationKeypress =
      this.handleNavigationKeypress.bind(this);
    document.addEventListener("keydown", this.boundHandleNavigationKeypress);
  }

  handleNavigationKeypress(event) {
    const backPageButtonTarget = this.backPageButtonTarget;
    const forwardPageButtonTarget = this.forwardPageButtonTarget;
    if (event.code === "ArrowLeft" || event.code === "PageUp") {
      backPageButtonTarget.click();
    } else if (event.code === "ArrowRight" || event.code === "PageDown") {
      forwardPageButtonTarget.click();
    }
  }

  removeListenersForNavigationKeys() {
    document.removeEventListener("keydown", this.boundHandleNavigationKeypress);
  }
}
