import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["optionsContainer", "select"];

  static values = {
    valueToShowOptions: String,
  };

  connect() {
    this.showHideSelectOptions();
    this.selectTarget.addEventListener("change", this.showHideSelectOptions.bind(this));
  }

  showHideSelectOptions() {
    if (this.selectTarget.selectedOptions[0].text === this.valueToShowOptionsValue) {
      this.optionsContainerTarget.classList.remove("hidden");
    } else {
      this.optionsContainerTarget.classList.add("hidden");
    }
  }
}
