import { Controller } from "stimulus";
import { debounce, delay } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["search", "sortAsc", "sortDesc", "sortDefault", "filterButton"];
  static values = {
    id: String,
    filter: String,
    groupby: Boolean,
    sortby: Boolean,
    sortdesc: Boolean,
    matchtype: String,
    currentWidth: Number,
    active: Boolean,
    position: Number,
  };

  initialize() {
    this.debounceSearchSubmit = debounce(this.searchSubmit, 600).bind(this);
  }

  connect() {
    delay(this.updateSortDisplay.bind(this), 1000);
  }

  disconnect() {}

  viewController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest("[data-controller='pipelines--pipeline-view']"),
      "pipelines--pipeline-view"
    );
  }

  lazyLoadDropdownController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector("[data-controller='lazy-load-dropdown']"),
      "lazy-load-dropdown"
    );
  }

  hasFilter() {
    return (this.filterValue || "") !== "";
  }

  updateSortDisplay() {
    this.sortAscTarget.classList.add("hidden");
    this.sortDescTarget.classList.add("hidden");
    this.sortDefaultTarget.classList.add("hidden");

    if (!this.sortbyValue) {
      this.sortDefaultTarget.classList.remove("hidden");
      return;
    }

    this.sortdescValue ? this.sortDescTarget.classList.remove("hidden") : this.sortAscTarget.classList.remove("hidden");
  }

  invalidateFilterDropdown() {
    const dropdownController = this.lazyLoadDropdownController();

    if (dropdownController) {
      dropdownController.loadedValue = false;
    }
  }

  searchSubmit(event) {
    if (event.target.value === this.filterValue) {
      return;
    }

    this.filterValue = event.target.value;
    this.viewController().updateUserDisplay({
      pipeline_user_display: {
        cells: [
          {
            id: this.idValue,
            filter: this.filterValue,
            active: this.activeValue,
            position: this.positionValue,
            width: this.currentWidthValue,
          },
        ],
      },
    });
  }

  applyFilter(sortingValue) {
    if (this.filterValue === "") {
      return false;
    }

    if (this.filterValue === "[Empty]" && sortingValue === "") {
      return false;
    }

    if (this.matchtypeValue === "like") {
      return !sortingValue?.toLowerCase().includes(this.filterValue.toLowerCase());
    }

    return this.filterValue !== sortingValue;
  }
}
