import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    defaultBottom: Boolean,
    id: String,
  };

  connect() {
    const controllerElement = this.element;
    const scrollPosition = sessionStorage["containerScrollPosition_" + this.idValue];

    if (scrollPosition != undefined) {
      controllerElement.scrollTop = parseInt(sessionStorage.getItem("containerScrollPosition_" + this.idValue));
    } else if (this.defaultBottomValue) {
      controllerElement.scrollTop = 999999;
    }

    this.intervalRef = setInterval(() => {
      var scrollPosition = controllerElement.scrollTop;
      sessionStorage.setItem("containerScrollPosition_" + this.idValue, scrollPosition);
    }, 1000);
  }
}
