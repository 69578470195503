import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "form", "fileError"];

  connect() {
    if (this.inputTarget) {
      this.inputTarget.addEventListener("change", this.submitForm.bind(this));
    }
  }

  submitForm(event) {
    if (this.formTarget) {
      if (this.fileTooLarge()) return event.preventDefault();
      Rails.fire(this.formTarget, "submit");
    }
  }

  fileTooLarge() {
    if (!this.hasFileErrorTarget) return false;
    const maxSize = parseInt(this.inputTarget.dataset.maxSizeMb);
    if (this.inputTarget.files[0].size > maxSize * 1024 * 1024) {
      this.fileErrorTarget.classList.remove("hidden");
      this.inputTarget.value = null;
      return true;
    }
    this.fileErrorTarget.classList.add("hidden");
    return false;
  }
}
