import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "container"];

  addForm(event) {
    event.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /NEW-FORM-IDENTIFIER/g,
      new Date().getTime()
    );

    this.containerTarget.insertAdjacentHTML("beforeend", content);
  }

  removeForm(event) {
    event.preventDefault();

    const currentForm = event.target.closest(".new-form");
    currentForm.remove();
  }
}
