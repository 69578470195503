import { Controller } from "stimulus";

export default class extends Controller {
  static values = { scrollTargetId: String };

  connect() {
    this.element.onclick = this.scrollToElement.bind(this);
  }

  scrollToElement() {
    const scrollToElement = document.getElementById(this.scrollTargetIdValue);

    setTimeout(() => {
      scrollToElement.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);

    scrollToElement.dataset.scrolled = this.scrolledIdentifier;
  }

  get scrolledIdentifier() {
    return "scrolled";
  }
}
