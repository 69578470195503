import { Controller } from "stimulus";

export default class extends Controller {
  static values = { enabled: Boolean, readonly: Boolean, controller: String, title: String };

  connect() {
    this.element.classList.add("hidden");
    this.addToggleButton();
    this.visualizeToggle();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
    this.toggleButton.remove();
  }

  addToggleButton() {
    this.toggleButton = document.createElement("button");
    const toggleButtonClassNames =
      "flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-7 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200".split(
        " "
      );
    if (this.readonlyValue) {
      toggleButtonClassNames.push("cursor-not-allowed");
      toggleButtonClassNames.push("opacity-50");
    } else {
      toggleButtonClassNames.push("cursor-pointer");
    }
    this.toggleHandle = document.createElement("span");
    const toggleHandleClassNames =
      "translate-x-0 pointer-events-none absolute left-0 top-0 inline-block h-4 w-4 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 z-2".split(
        " "
      );
    this.toggleSlider = document.createElement("span");
    const toggleSliderClassNames =
      "pointer-events-none absolute h-3 w-7 mx-auto rounded-full transition-colors ease-in-out duration-200 z-1".split(
        " "
      );
    this.toggleBg = document.createElement("span");
    const toggleBgClassNames = "pointer-events-none absolute bg-white w-full h-full rounded-md".split(" ");

    this.toggleButton.classList.add(...toggleButtonClassNames);
    this.toggleHandle.classList.add(...toggleHandleClassNames);
    this.toggleSlider.classList.add(...toggleSliderClassNames);
    this.toggleBg.classList.add(...toggleBgClassNames);

    this.toggleButton.appendChild(this.toggleBg);
    this.toggleButton.appendChild(this.toggleSlider);
    this.toggleButton.appendChild(this.toggleHandle);

    if (this.controllerValue) {
      this.toggleButton.dataset.controller = this.controllerValue;
    }

    if (this.titleValue) {
      this.toggleButton.title = this.titleValue;
    }

    this.element.insertAdjacentElement("afterend", this.toggleButton);

    this.toggleButton.onclick = this.toggleEnabledValue.bind(this);
  }

  visualizeToggle() {
    this.element.value = this.enabledValue ? 1 : 0;

    if (this.enabledValue) {
      this.toggleON();
    } else {
      this.toggleOFF();
    }
    this.dispatchChangeEvents();
  }

  visualizeON() {
    this.toggleSlider.classList.add("bg-primary-400");
    this.toggleSlider.classList.remove("bg-gray-300");
    this.toggleHandle.classList.add("translate-x-3");
    this.toggleHandle.classList.remove("translate-x-0");
  }

  visualizeOFF() {
    this.toggleSlider.classList.remove("bg-primary-400");
    this.toggleSlider.classList.add("bg-gray-300");
    this.toggleHandle.classList.remove("translate-x-3");
    this.toggleHandle.classList.add("translate-x-0");
  }

  toggleEnabledValue(ev) {
    ev.preventDefault();

    if (this.readonlyValue) return;

    this.enabledValue = !this.enabledValue;
    this.visualizeToggle();
    this.dispatchChangeEvents();
  }

  addEventListeners() {
    this.element.addEventListener("toggleON", this.toggleON);
    this.element.addEventListener("toggleOFF", this.toggleOFF);
  }

  removeEventListeners() {
    this.element.removeEventListener("toggleON", this.toggleON);
    this.element.removeEventListener("toggleOFF", this.toggleOFF);
  }

  dispatchChangeEvents() {
    this.toggleButton.dispatchEvent(new Event("change"));
    this.element.dispatchEvent(new Event("change"));
  }

  toggleON = () => {
    this.element.value = "1";
    this.enabledValue = true;
    this.visualizeON();
  };

  toggleOFF = () => {
    this.element.value = "0";
    this.enabledValue = false;
    this.visualizeOFF();
  };
}
