import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["subjectableType", "subjectableId"];

  connect() {}

  setSubjectable(event) {
    const selectedOption = event.target.selectedOptions[0];
    const [subjectableType, subjectableId] = selectedOption.value.split(";");

    this.subjectableTypeTarget.value = subjectableType;
    this.subjectableIdTarget.value = subjectableId;
  }
}
