import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static values = {
    url: String,
    allowable: String,
    variables: Object,
    preSelected: Object,
    autoFocus: Boolean,
  };
  static targets = [
    "search",
    "template",
    "templateCursor",
    "selectedId",
    "selectedType",
    "searchContainer",
    "searchReset",
  ];

  initialize() {
    this.search = debounce(this.search, 300).bind(this);
  }

  connect() {
    this.searchField = this.searchTarget;

    this.boundSearchEvent = this.search.bind(this);
    this.searchTarget.addEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.addEventListener("blur", this.closeSearchResultsAfterClickAway.bind(this));
    if (this.autoFocusValue) {
      this.searchTarget.focus();
    }

    if (this.searchTarget.value.length > 0) {
      this.boundSearchEvent({ key: "" });
    }
  }

  disconnect() {
    this.searchTarget.removeEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.removeEventListener("blur", this.closeSearchResultsAfterClickAway.bind(this));
  }

  closeSearchResultsAfterClickAway() {
    setTimeout(() => {
      this.templateTarget.classList.add("hidden");
    }, 400);
  }

  search(e) {
    if (e.key == "Enter") {
      window.notyf.error("Please choose an option from the dropdown to continue");
      return;
    }

    const keyModifier = e.keyCode === 16 || e.keyCode === 18;

    const searchString = this.searchField.value.trim();
    const data =
      this.searchField.dataset.queryParam &&
      `search=${this.searchField.dataset.queryParam}&${this.searchField.dataset.queryParam}=${searchString}`;

    if (searchString.length > 0 && !keyModifier) {
      // show loading
      document.getElementById("loader").classList.remove("hidden");
      Rails.ajax({
        type: "GET",
        url: this.urlValue,
        data: data,
        error: this.showEmptySearchResults.bind(this),
        success: this.showSearchResults.bind(this),
      });
    }
  }

  showSearchResults(results) {
    document.getElementById("loader").classList.add("hidden");

    this.updateSearchResults(results);
    this.templateTarget.classList.remove("hidden");
  }

  updateSearchResults(results) {
    this.searchResults = results;

    this.templateCursorTarget.innerHTML = "";

    results.forEach((result) => {
      this.templateCursorTarget.insertAdjacentElement("beforeend", this.createSearchResult(result));
    });

    this.createNewButtons();
  }

  createNewButtons() {
    const addIcon = '<i class="ph-plus-circle-bold text-md"></i>';

    if (["person_only", "organization_and_person"].includes(this.allowableValue)) {
      const addNewPerson = document.createElement("a");
      addNewPerson.innerHTML = addIcon + "Add Person";
      addNewPerson.dataset.contentLoaderUrlValue = "/pipeline_prospects/new_person";
      addNewPerson.dataset.action = "content-loader#load";
      addNewPerson.className =
        "px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50 border-t border-dark-200";
      addNewPerson.id = "new_person";

      this.templateCursorTarget.insertAdjacentElement("beforeend", addNewPerson);

      addNewPerson.addEventListener("click", this.selectSearchResultOnClick.bind(this));
    }

    if (["organization_only", "organization_and_person"].includes(this.allowableValue)) {
      const addNewOrganization = document.createElement("a");
      addNewOrganization.innerHTML = addIcon + "Add Organization";
      addNewOrganization.dataset.contentLoaderUrlValue = "/pipeline_prospects/new_organization";
      addNewOrganization.dataset.action = "content-loader#load";
      addNewOrganization.className =
        "px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50";
      addNewOrganization.id = "new_org";

      this.templateCursorTarget.insertAdjacentElement("beforeend", addNewOrganization);

      addNewOrganization.addEventListener("click", this.selectSearchResultOnClick.bind(this));
    }
  }

  createSearchResult(result) {
    const resultElement = document.createElement("a");

    resultElement.classList.add(
      "flex",
      "items-center",
      "gap-2",
      "px-3",
      "py-2",
      "hover:bg-dark-50",
      "rounded",
      "cursor-pointer"
    );

    const icon =
      result.class_name === "Person"
        ? "ph-user-bold"
        : result.class_name === "Organization"
        ? "ph-buildings-bold"
        : result.class_name === "Account"
        ? "ph-buildings-bold"
        : result.class_name === "AccountOrganizationFund"
        ? "ph-bank"
        : "ph-user-bold";

    resultElement.innerHTML = `<div class="w-7 h-7 border-2 border-dark-200 rounded-full flex items-center justify-center text-primary-400"><i class="${icon}"></i></div><span>${result.name}</span>`;
    resultElement.dataset.type = result.class_name;
    resultElement.dataset.id = result.id;
    resultElement.dataset.action = "content-loader#load";
    resultElement.classList.add(
      "hover:bg-dark-50",
      "rounded",
      result.class_name === "person" ? "person" : "organization"
    );
    resultElement.addEventListener("click", this.selectSearchResultOnClick.bind(this));

    return resultElement;
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find((result) => result.id == ev.currentTarget.dataset.id);
    const contentLoader = ev.currentTarget.closest("[data-controller='content-loader']");

    if (ev.currentTarget.dataset.type) {
      contentLoader.dataset.contentLoaderUrlValue = `/pipeline_prospects/existing_${ev.currentTarget.dataset.type.toLowerCase()}/${
        ev.currentTarget.dataset.id
      }`;
    } else {
      let prospectName = encodeURIComponent(document.getElementById("prospect-name").value);
      contentLoader.dataset.contentLoaderUrlValue = `${ev.currentTarget.dataset.contentLoaderUrlValue}/?name=${prospectName}`;
    }

    // hide search bar
    this.searchContainerTarget.classList.add("hidden");
    // show prospect form fields
    document.getElementById("prospect-form")?.classList.remove("hidden");
    // show reset button
    if (this.hasSearchResetTarget) {
      this.searchResetTarget.classList.remove("hidden");
    }

    // show form submit buttons
    document.getElementById("form-submit")?.classList.remove("hidden");

    this.selectSearchResult(selectedResult);
  }

  hideSearchResults() {
    this.templateTarget.classList.add("hidden");
  }

  reset() {
    // hide search bar
    this.searchContainerTarget.classList.remove("hidden");
    // show prospect form fields
    document.getElementById("prospect-form")?.classList.add("hidden");
    // show reset button
    if (this.hasSearchResetTarget) {
      this.searchResetTarget.classList.add("hidden");
    }
    // clear replaceable form
    document.getElementById("replaceable-form")?.remove();
    // hide form submit buttons
    document.getElementById("form-submit")?.classList.add("hidden");
  }

  selectSearchResult(selectedResult) {
    document.getElementById("form-edited-warning")?.classList.add("hidden");

    if (selectedResult?.id) this.selectedIdTarget.value = selectedResult.id;
    if (selectedResult?.class_name) this.selectedTypeTarget.value = selectedResult.class_name;

    this.hideSearchResults();
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  showSearchField() {
    this.displayElement.classList.add("hidden");
    this.searchField.classList.remove("hidden");
    this.searchField.value = "";
    this.searchField.focus();
  }
}
