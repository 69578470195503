import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "deleteEmpty"];

  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners(){
    document.addEventListener("turbo:before-fetch-response", this.toggleCheckboxAfterTurboEvent);
  }

  removeEventListeners(){
    document.removeEventListener("turbo:before-fetch-response", this.toggleCheckboxAfterTurboEvent);
  }

  toggleCheckboxAfterTurboEvent = () => {
    const checkbox = this.checkboxTarget;
    const responses = Array.from(this.allEmptyResponses());

    if (responses.length > 0) {
      checkbox.disabled = false;
    } else {
      checkbox.disabled = true;
    }

    checkbox.checked = false;
    this.showOrHideAll(false);
  }

  showOrHideEmptyResponses(event) {
    const checkbox = event.target;

    if (checkbox.checked) {
      this.showOrHideAll(true);
    } else {
      this.showOrHideAll(false);
    }
  };

  showOrHideAll(show) {
    const emptyResponses = this.allEmptyResponses();
    emptyResponses.forEach((response) => this.showOrHideSingleResponse(response, show));
    this.showOrHideDeleteAllButton(show);
  }

  allEmptyResponses() {
    return document.querySelectorAll("[data-empty-response='true']");
  }

  showOrHideSingleResponse(response, show) {
    if (show) {
      response.classList.remove("hidden");
    } else {
      response.classList.add("hidden");
    }
  }

  showOrHideDeleteAllButton(show) {
    if (!this.hasDeleteEmptyTarget) return;

    if (show) {
      this.deleteEmptyTarget.classList.remove("hidden");
    } else {
      this.deleteEmptyTarget.classList.add("hidden");
    }
  }
}
