import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actionsInput", "actionTemplate", "actionsContainer"];
  static values = {
    actions: Array,
    actionTypes: Object,
    editableActions: Array,
  };

  connect() {
    this.setInitialValues();
    this.addChangeEventListeners();
    this.inputChanged();
    this.setActionsNumeration();
  }

  setInitialValues() {
    this.actionsValue.forEach((action) => this.setUpInitialAction(action));
  }

  setUpInitialAction(action) {
    const actionType = this.actionTypesValue[action.action_type];
    this.addAction(actionType, action.action_type, action);

    const actionElement = this.actionsContainerTarget.lastElementChild;
    this.setFields({ actionElement, data: action });
    this.showOrHideAction(actionType, actionElement);

    if (action.to_stage) actionElement.dataset.toStage = action.to_stage;
  }

  triggerCustomEventWhenActionAdded(actionElement = null) {
    document.dispatchEvent(
      new CustomEvent("questionnaireActionsCreated", { bubbles: true, detail: { element: actionElement } })
    );
  }

  showOrHideAction(actionType, actionElement) {
    if (!this.editableActionsValue.includes(actionType)) {
      actionElement.classList.add("hidden");
    }
  }

  setFields({ actionElement, data }) {
    Object.keys(data).forEach((field) => this.setField({ actionElement, field, value: data[field] }));
  }

  setField({ actionElement, field, value }) {
    const input = actionElement.querySelector(`[name=${field}]`);

    if (input) {
      this.resolveField(input, field, value);
    }
  }

  resolveField(input, field, value) {
    if (input.name === "async") {
      setTimeout(() => {
        const event = value === true ? "toggleON" : "toggleOFF";
        input.value = value === true ? "1" : "0";
        input.dispatchEvent(new Event(event));
      }, 20);

      return;
    }

    if (field === "from_stages" && value.length === 1 && value[0] === "*") {
      input.value = "";
    } else {
      input.value = value;
    }
  }

  disconnect() {
    this.removeChangeEventListeners();
  }

  addChangeEventListeners() {
    this.getAllInputs().forEach((input) => input.addEventListener("change", this.inputChanged));
  }

  removeChangeEventListeners() {
    this.getAllInputs().forEach((input) => input.removeEventListener("change", this.inputChanged));
  }

  getAllInputs() {
    return this.element.querySelectorAll("input, select");
  }

  inputChanged = () => {
    setTimeout(() => {
      this.actionsInputTarget.value = JSON.stringify(this.getActionsValues());
    }, 20);
  };

  getActionsValues() {
    return Array.from(this.actionsContainerTarget.children).map((actionElement) => this.getActionValues(actionElement));
  }

  getActionValues(actionElement) {
    const actionType =
      actionElement.querySelector("[name='action_type']").value ||
      actionElement.querySelector("[name='action_type_select']").value;
    const eventType = actionElement.querySelector("[name='event_type']").value;
    const actionFields = this.getFieldsContainer(actionElement).querySelectorAll("input, select");
    let actionValues = {};
    actionFields.forEach((field) => (actionValues[field.name] = field.value));
    actionValues = { ...actionValues, action_type: parseInt(actionType), event_type: parseInt(eventType) };

    return actionValues;
  }

  addActionClick() {
    this.removeChangeEventListeners();
    this.addAction();
    this.addChangeEventListeners();
  }

  addAction(actionType = "move_prospect", actionTypeValue = 0, actionData = {}) {
    this.actionsContainerTarget.insertAdjacentHTML("beforeend", this.actionTemplateTarget.innerHTML);

    const actionElement = this.actionsContainerTarget.lastElementChild;
    actionElement.dataset.actionData = JSON.stringify(actionData);
    this.setTypeFields(this.getFieldsContainer(actionElement), actionType);
    this.setActionTypeValue(actionElement, actionTypeValue);
    this.setActionsNumeration();
    this.triggerCustomEventWhenActionAdded(actionElement);
  }

  setActionTypeValue(actionElement, actionTypeValue) {
    actionElement.querySelector("[name='action_type_select']").value = actionTypeValue;
  }

  removeAction(event) {
    event.target.closest("[data-action-element]").remove();
    this.inputChanged();
    this.setActionsNumeration();
  }

  setActionsNumeration() {
    this.actionsContainerTarget.querySelectorAll("[data-action-element]").forEach((actionElement, index) => {
      actionElement.querySelector("[data-action-number-element]").innerText = index + 1;
    });
  }

  changeType(event) {
    this.removeChangeEventListeners();
    const type = this.actionTypesValue[event.target.value];
    const actionElement = event.target.closest("[data-action-element]");
    actionElement.querySelector("[name='action_type']").value = event.target.value;
    this.setTypeFields(this.getFieldsContainer(actionElement), type);
    this.inputChanged();
    this.addChangeEventListeners();
    this.triggerCustomEventWhenActionAdded(actionElement);
  }

  getFieldsContainer(actionElement) {
    return actionElement.querySelector("[data-fields-container]");
  }

  setTypeFields(actionFieldsElement, type) {
    actionFieldsElement.innerHTML = this.getActionTemplate(type);
  }

  getActionTemplate(actionType) {
    return this.element.querySelector(`[data-action-type="${actionType}"]`).innerHTML;
  }
}
