import { Controller } from "stimulus";


export default class extends Controller {
  static values = {
    scroll: Boolean,
  };

  connect() {
    if (this.scrollValue) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      document.querySelector("[autofocus]")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }
}
