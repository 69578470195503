import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  clear(ev) {
    ev.preventDefault();
    this.inputTarget.value = "";
    ev.currentTarget.blur();
  }
}
