import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nilValue"];

  static values = {
    required: Boolean,
  };

  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListener();
  }

  addEventListeners = () => {
    this.getAllCheckboxes().forEach((checkbox) => {
      checkbox.addEventListener("change", this.hideError);
      checkbox.addEventListener("change", this.setNilValueIfNoCheckboxChecked);
    });
    this.element.closest('form').addEventListener("submit", this.validate);
  };

  removeEventListener = () => {
    this.getAllCheckboxes().forEach((checkbox) => {
      checkbox.removeEventListener("change", this.hideError);
      checkbox.removeEventListener("change", this.setNilValueIfNoCheckboxChecked);
    });
    this.element.closest('form').removeEventListener("submit", this.validate);
  };

  getAllCheckboxes() {
    return this.element.querySelectorAll("input[type='checkbox']");
  }

  validate = (event) => {
    if (this.requiredValue && !this.isAnyCheckboxChecked()) {
      event.preventDefault();
      this.showError();
      setTimeout(() => this.resetFormButton(), 50);
    }
  }

  resetFormButton = () => {
    const event = new CustomEvent("enableTurboFormButtons");
    document.dispatchEvent(event);
  }

  isAnyCheckboxChecked = () => {
    return Array.from(this.getAllCheckboxes()).some((checkbox) => checkbox.checked);
  }

  showError = () => {
    this.element.nextElementSibling.classList.remove("hidden");
  };

  hideError = () => {
    this.element.nextElementSibling.classList.add("hidden");
  }

  setNilValueIfNoCheckboxChecked =() => {
    if (!this.isAnyCheckboxChecked() && !this.requiredValue) {
      this.nilValueTarget.disabled = false;
    } else {
      this.nilValueTarget.disabled = true;
    }
  }
}
