import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static targets = ["form", "input", "reset"];

  connect() {
    if (typeof URLSearchParams !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const search = urlParams.get("search");
      if (search?.length) {
        this.inputTarget.value = search;
        this.search();
      }
    } else {
      const pathWithoutParams = window.location.href.split("?")[0];
      window.history.pushState({}, document.title, pathWithoutParams);
    }
  }

  search = debounce(this.debounceMethod, 500).bind(this);

  debounceMethod() {
    if (this.inputTarget.value.length) {
      this.resetTarget.classList.remove("hidden");
    } else {
      this.resetTarget.classList.add("hidden");
    }

    this.formTarget.requestSubmit();
  }

  reset() {
    this.resetTarget.classList.add("hidden");
    this.inputTarget.value = "";
    this.formTarget.requestSubmit();

    if (typeof URLSearchParams !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.delete("search");
    }
  }
}
