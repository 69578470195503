import { Controller } from "stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js";
export default class extends Controller {
  static values = { url: String, group: String, handle: String, turboStream: Boolean, extraParams: Object };

  connect() {
    this.prepareSortable();
  }

  prepareSortable() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      dataIdAttr: "data-id",
      ghostClass: "bg-gray-300",
      handle: this.handleValue || ".sortable__handle",
      filter: ".sortable__filtered",
      group: this.groupValue || undefined,
      onSort: (event) => {
        this.requestMethod(event);
      },
    });
  }

  requestMethod(event) {
    if (!this.urlValue) return;

    if (this.turboStreamValue) {
      post(this.urlValue, { body: JSON.stringify(this.turboStreamFormData(event)), responseKind: "turbo-stream" });
    } else {
      Rails.ajax({
        url: this.urlValue,
        type: "post",
        data: this.ajaxFormData(event),
      });
    }
  }

  ajaxFormData(event) {
    const data = new FormData();
    data.append("newIndex", event.newIndex);
    data.append("moved_id", event.item.dataset.id);

    if (this.extraParamsValue) {
      Object.keys(this.extraParamsValue).forEach((key) => {
        data.append(key, this.extraParamsValue[key]);
      });
    }

    if (event.item.previousElementSibling) data.append("moved_after_id", event.item.previousElementSibling.dataset.id);
    return data;
  }

  turboStreamFormData(event) {
    let data = {};
    data["newIndex"] = event.newIndex;
    data["moved_id"] = event.item.dataset.id;
    data = { ...data, ...this.extraParamsValue };
    if (event.item.previousElementSibling) data["moved_after_id"] = event.item.previousElementSibling.dataset.id;
    return data;
  }
}
