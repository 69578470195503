import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "valuationTypeContainer",
    "roundSelect",
    "securitySelect",
    "valuationCapDiscountContainer",
    "preMoneyPriceContainer",
    "postMoneyValuationContainer",
    "convertedToContainer",
    "closeDateContainer",
    "closeDateInput",
    "closedRoundLabel",
    "amountRaiseLabel",
    "pricePerShare",
    "conditionalFields",
    "warrantContainer",
    "preMoneyValuation",
    "amountRaised",
    "postMoneyValuation",
    "currencyContainer",
    "valuationTypeSelect",
    "valuationCapContainer",
    "bridgeRoundContainer",
    "amountRaisedContainer",
    "otherTerms",
    "otherTermsLabel",
    "advancedOptions",
    "submitButton",
  ];

  static ROUND_SECURITY_MAP = {
    seed: "safe",
    pre_seed: "safe",
    accelerator: "safe",
    angel: "safe",
    a: "preferred",
    b: "preferred",
    c: "preferred",
    d: "preferred",
    e: "preferred",
    f: "preferred",
    mezzanine: "debt",
  };

  connect() {
    this.securitySelectTarget.addEventListener("change", this.chooseSecurity.bind(this));
    this.roundSelectTarget.addEventListener("change", this.chooseRound.bind(this));

    this.amountRaisedTarget.addEventListener("input", this.toggleCurrencyField.bind(this));
    this.toggleCurrencyField();

    this.valuationTypeSelectTarget.addEventListener("change", this.toggleValuationCap.bind(this));
    this.toggleValuationCap();

    this.securitySelectTarget.addEventListener("change", this.toggleOtherTermsRequired.bind(this));
    this.toggleOtherTermsRequired();

    this.chooseSecurity();
    this.checkFormValidity();
  }

  toggleCurrencyField() {
    const amountRaised = this.parseNumber(this.amountRaisedTarget.value);
    if (amountRaised !== 0) {
      this.showCurrencyField();
    } else {
      this.hideCurrencyField();
    }
  }

  toggleValuationCap() {
    const valuationType = this.valuationTypeSelectTarget.value;
    if (valuationType === "pre_money_valuation" || valuationType === "post_money_valuation") {
      this.showValuationCap();
    } else {
      this.hideValuationCap();
    }
  }

  toggleOtherTermsRequired() {
    const chosenSecurity = this.securitySelectTarget.value;
    if (chosenSecurity === "warrants") {
      this.otherTermsTarget.required = true;
      this.otherTermsLabelTarget.classList.add("required");
      this.openAdvancedOptions();
    } else {
      this.otherTermsTarget.required = false;
      this.otherTermsLabelTarget.classList.remove("required");
      this.closeAdvancedOptions();
    }
  }

  openAdvancedOptions() {
    if (this.hasAdvancedOptionsTarget) {
      const collapsibleController = this.application.getControllerForElementAndIdentifier(
        this.advancedOptionsTarget,
        "collapsible"
      );
      if (collapsibleController) {
        collapsibleController.showValue = true;
        collapsibleController.toggleChildren();
        collapsibleController.toggleParent();
      }
    }
  }

  closeAdvancedOptions() {
    if (this.hasAdvancedOptionsTarget) {
      const collapsibleController = this.application.getControllerForElementAndIdentifier(
        this.advancedOptionsTarget,
        "collapsible"
      );
      if (collapsibleController) {
        collapsibleController.showValue = false;
        collapsibleController.toggleChildren();
        collapsibleController.toggleParent();
      }
    }
  }

  setClosed() {
    if (this.hasCloseDateContainerTarget && this.hasCloseDateInputTarget) {
      this.showCloseDate();
    }
    if (this.hasAmountRaiseLabelTarget) {
      this.setClosedLabels();
    }
  }

  hideAndClearCloseDate() {
    if (this.hasCloseDateContainerTarget && this.hasCloseDateInputTarget) {
      this.closeDateContainerTarget.classList.add("hidden");
      this.closeDateInputTarget.value = "";
    }
  }

  showCloseDate() {
    if (this.hasCloseDateContainerTarget && this.hasCloseDateInputTarget) {
      this.closeDateContainerTarget.classList.remove("hidden");
      const date = new Date();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const day = date.getDate().toString().padStart(2, "0");
      this.closeDateInputTarget.value = `${year}-${month}-${day}`;
    }
  }

  updatePostMoneyValuation() {
    const preMoneyValuation = this.parseNumber(this.preMoneyValuationTarget.value);
    const amountRaised = this.parseNumber(this.amountRaisedTarget.value);

    if (preMoneyValuation > 0 && amountRaised > 0) {
      const postMoneyValuation = preMoneyValuation + amountRaised;
      this.postMoneyValuationTarget.value = this.formatNumber(postMoneyValuation);
    } else {
      this.postMoneyValuationTarget.value = "";
    }
  }

  chooseRound(e) {
    const chosenRound = e.target.value;
    if (!chosenRound) {
      this.setSecurity(null);
      this.checkConditionalFields();
      return;
    }
    this.checkConditionalFields();
    if (this.constructor.ROUND_SECURITY_MAP[chosenRound]) {
      this.setSecurity(this.constructor.ROUND_SECURITY_MAP[chosenRound]);
    }
    this.checkFormValidity();
  }

  setSecurity(security) {
    this.securitySelectTarget.value = security || "debt";
    this.securitySelectTarget.dispatchEvent(new Event("change"));
  }

  chooseSecurity(e) {
    const chosenSecurity = e?.target?.value || this.securitySelectTarget.value;
    if (!chosenSecurity) {
      this.hideConditionalSecurities();
      this.checkConditionalFields();
      return;
    }
    this.checkConditionalFields();
    switch (chosenSecurity) {
      case "debt":
        this.hideConditionalSecurities();
        this.showConvertedTo();
        this.showAmountRaised();
      case "warrants":
        this.hideConditionalSecurities();
        this.showConvertedTo();
        this.showWarrants();
        this.showBridgeRound();
        this.showAmountRaised();
        break;
      case "convertible_debt":
        this.hideConditionalSecurities();
        this.showValuationCapDiscount();
        this.showValuationType();
        this.showConvertedTo();
        this.showBridgeRound();
        this.showAmountRaised();
        break;
      case "safe":
        this.hideConditionalSecurities();
        this.showValuationCapDiscount();
        this.showValuationType();
        this.showConvertedTo();
        this.showBridgeRound();
        this.showAmountRaised();
        break;
      case "common":
        this.hideConditionalSecurities();
        this.showPostMoneyValuation();
        this.showPreMoneyPrice();
        this.showAmountRaised();
        break;
      case "preferred":
        this.hideConditionalSecurities();
        this.showPostMoneyValuation();
        this.showPreMoneyPrice();
        this.showAmountRaised();
        break;
      default:
        this.hideConditionalSecurities();
    }
    this.checkFormValidity();
  }

  checkFormValidity() {
    const roundSelected = this.roundSelectTarget.value !== '';
    const securitySelected = this.securitySelectTarget.value !== '';

    if (roundSelected && securitySelected) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  checkConditionalFields() {
    const chosenRound = this.roundSelectTarget.value;
    const chosenSecurity = this.securitySelectTarget.value;
    if (chosenRound && chosenSecurity) {
      this.showConditionalFields();
    } else {
      this.hideConditionalFields();
    }
  }

  showConditionalFields() {
    if (this.hasConditionalFieldsTarget) {
      this.conditionalFieldsTarget.classList.remove("hidden");
    }
  }

  hideConditionalFields() {
    if (this.hasConditionalFieldsTarget) {
      this.conditionalFieldsTarget.classList.add("hidden");
    }
  }

  setClosedLabels() {
    if (this.hasAmountRaiseLabelTarget) {
      this.amountRaiseLabelTarget.innerText = "Amount raised";
    }
  }
  showValuationCapDiscount() {
    if (this.hasValuationCapDiscountContainerTarget) {
      this.valuationCapDiscountContainerTargets.forEach((target) => {
        target.classList.remove("hidden");
      });
    }
  }

  hideValuationCapDiscount() {
    if (this.hasValuationCapDiscountContainerTarget) {
      this.valuationCapDiscountContainerTargets.forEach((target) => {
        target.classList.add("hidden");
      });
    }
  }
  showValuationType() {
    if (this.hasValuationTypeContainerTarget) this.valuationTypeContainerTarget.classList.remove("hidden");
  }
  hideValuationType() {
    if (this.hasValuationTypeContainerTarget) this.valuationTypeContainerTarget.classList.add("hidden");
  }
  showPostMoneyValuation() {
    if (this.hasPostMoneyValuationContainerTarget) this.postMoneyValuationContainerTarget.classList.remove("hidden");
  }
  hidePostMoneyValuation() {
    if (this.hasPostMoneyValuationContainerTarget) this.postMoneyValuationContainerTarget.classList.add("hidden");
  }
  showConvertedTo() {
    if (this.hasConvertedToContainerTarget) this.convertedToContainerTarget.classList.remove("hidden");
  }
  showWarrants() {
    if (this.hasWarrantContainerTarget) this.warrantContainerTarget.classList.remove("hidden");
  }
  hideConvertedTo() {
    if (this.hasConvertedToContainerTarget) this.convertedToContainerTarget.classList.add("hidden");
  }
  hideWarrants() {
    if (this.hasWarrantContainerTarget) this.warrantContainerTarget.classList.add("hidden");
  }
  showPreMoneyPrice() {
    if (this.hasPreMoneyPriceContainerTarget) this.preMoneyPriceContainerTarget.classList.remove("hidden");
    if (this.hasPricePerShareTarget) this.pricePerShareTarget.required = true;
  }
  hidePreMoneyPrice() {
    if (this.hasPreMoneyPriceContainerTarget) this.preMoneyPriceContainerTarget.classList.add("hidden");
    if (this.hasPricePerShareTarget) this.pricePerShareTarget.required = false;
  }
  showCurrencyField() {
    if (this.hasCurrencyContainerTarget) this.currencyContainerTarget.classList.remove("hidden");
  }

  hideCurrencyField() {
    if (this.hasCurrencyContainerTarget) this.currencyContainerTarget.classList.add("hidden");
  }

  parseNumber(value) {
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }

  formatNumber(value) {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  }

  showValuationCap() {
    if (this.hasValuationCapTarget) this.valuationCapTarget.classList.remove("hidden");
  }

  hideValuationCap() {
    if (this.hasValuationCapTarget) this.valuationCapTarget.classList.add("hidden");
  }

  showAmountRaised() {
    if (this.hasAmountRaisedContainerTarget) this.amountRaisedContainerTarget.classList.remove("hidden");
  }

  hideAmountRaised() {
    if (this.hasAmountRaisedContainerTarget) this.amountRaisedContainerTarget.classList.add("hidden");
  }

  showBridgeRound() {
    if (this.hasBridgeRoundContainerTarget) this.bridgeRoundContainerTarget.classList.remove("hidden");
  }

  hideBridgeRound() {
    if (this.hasBridgeRoundContainerTarget) this.bridgeRoundContainerTarget.classList.add("hidden");
  }

  hideConditionalSecurities() {
    this.hideConvertedTo();
    this.hideValuationType();
    this.hidePreMoneyPrice();
    this.hidePostMoneyValuation();
    this.hideWarrants();
    this.hideValuationCap();
    this.hideAmountRaised();
    this.hideBridgeRound();
  }
}
