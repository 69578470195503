import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropZone", "dropZoneIndicator", "slideover", "richTextToAddLinkTo"];
  static values = {
    targetFolderName: String,
    skipRedirection: Boolean,
  };
  dragCounter = 0;
  timer = null;

  boundDragOver = this.dragOver.bind(this);
  boundDragEnter = this.dragEnter.bind(this);
  boundDragLeave = this.dragLeave.bind(this);
  boundHandleDrop = this.handleDrop.bind(this);

  connect() {
    this.addDragAndDropListeners();
  }

  disconnect() {
    this.removeDragAndDropListeners();
    this.slideoverTarget.classList.add("hidden");
    clearTimeout(this.timer);
  }

  addDragAndDropListeners() {
    document.addEventListener("dragover", this.boundDragOver);
    document.addEventListener("dragenter", this.boundDragEnter);
    document.addEventListener("dragleave", this.boundDragLeave);
    document.addEventListener("drop", this.boundHandleDrop);
  }

  removeDragAndDropListeners() {
    document.removeEventListener("dragover", this.boundDragOver);
    document.removeEventListener("dragenter", this.boundDragEnter);
    document.removeEventListener("dragleave", this.boundDragLeave);
    document.removeEventListener("drop", this.boundHandleDrop);
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }

  dragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }

  dragEnter(event) {
    if (!event.dataTransfer.types.includes("Files")) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.dragCounter++;

    clearTimeout(this.timer);

    if (this.dragCounter === 1) {
      this.slideoverTarget.classList.remove("hidden");
      const slideOverController = this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller="slideover"]'),
        "slideover"
      );

      const folderName = this.targetFolderNameValue || "Unfiled";
      slideOverController.populate(
        "Upload File",
        '<div data-drag-and-drop-target="dropZoneIndicator" id="drop-zone-indicator" class="slideover-drop-zone-indicator"><div>' +
          `<i class="ph-file-arrow-up text-5xl text-primary-400 mb-1"></i><div class="font-medium text-base text-dark-600">Drop a file here to upload to your '${folderName}' folder</div>` +
          "</div></div>",
        [],
        "file"
      );
    }
  }

  dragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter--;

    if (this.dragCounter === 0) {
      this.timer = setTimeout(() => {
        this.slideoverTarget.classList.add("hidden");
      }, 20);
    }
  }

  handleDropTarget(event) {
    const dropTarget = event.target;
    if (!dropTarget.closest(".slideover-pane")) {
      return false;
    }
    return true;
  }

  async handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.handleDropTarget(event)) {
      this.closeSlideover();
      this.resetDragCounter();
      return;
    }

    const files = [...event.dataTransfer.files];

    const progressBarReady = new Promise((resolve, reject) => {
      const checkProgressBar = () => {
        const progressBar = document.getElementById("upload-progress");
        if (progressBar) {
          resolve();
        } else {
          setTimeout(checkProgressBar, 50);
        }
      };
      checkProgressBar();
    });

    await this.progressBarForm(event);
    await progressBarReady;
    const cancelButton = document.getElementById("cancel-upload");
    if (cancelButton) {
      cancelButton.addEventListener("click", () => this.handleCancel(xhr));
    }

    const boundHandleCancel = this.handleCancel.bind(this);
    if (cancelButton) {
      cancelButton.addEventListener("click", () => boundHandleCancel(xhr));
    }

    const xhr = new XMLHttpRequest();
    this.attachProgressListener(xhr);

    let file = files[0];

    if (!this.isValidFile(file)) {
      alert("File size cannot exceed 20 MB.");
      this.closeSlideover();
      this.resetDragCounter();
      return;
    }

    let formData = new FormData();
    formData.append("data_room_file[file]", file);

    const currentUrl = window.location.href;
    const uploadUrl = `/data_room/files/upload_drag_and_drop?current_url=${currentUrl}`;

    xhr.open("POST", uploadUrl, true);
    xhr.setRequestHeader("X-CSRF-Token", this.getMetaValue("csrf-token"));

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        if (response.success) {
          const slideOverController = this.application.getControllerForElementAndIdentifier(
            document.querySelector('[data-controller="slideover"]'),
            "slideover"
          );
          slideOverController.populate("Uploaded File", response.content, [], "file");
          slideOverController.open();
        } else {
          alert("Failed to upload file.");
        }
      } else if (xhr.status === 202) {
        try {
          const payload = JSON.parse(xhr.responseText);
          const folderId = payload.obscure_folder_id;
          const folderPreviewLink = payload.folder_file_preview_link;

          if (this.hasRichTextToAddLinkToTarget) {
            this.addRichTextLink(folderPreviewLink);
            this.slideoverTarget.classList.add("hidden");
          }

          if (!this.skipRedirectionValue) {
            window.location.href = `/data_room/folders/${folderId}`;
          }
        } catch (e) {
          console.log(e);
          alert("Failed to upload file.");
        }
      } else {
        alert("Failed to upload file.");
      }
    };

    xhr.send(formData);

    this.resetDragCounter();
  }

  addRichTextLink(folderPreviewLink) {
    const linkHtml = '<a href="' + folderPreviewLink + '">' + folderPreviewLink + "</a>";
    this.richTextToAddLinkToTarget.editor.insertString(" ");
    this.richTextToAddLinkToTarget.editor.insertHTML(linkHtml);
  }

  resetDragCounter() {
    clearTimeout(this.timer);
    this.dragCounter = 0;
  }

  closeSlideover() {
    this.slideoverTarget.classList.add("hidden");
    const slideOverController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="slideover"]'),
      "slideover"
    );
    slideOverController.close();
  }

  handleDropTarget(event) {
    const dropTarget = event.target;

    if (!dropTarget.closest(".slideover-pane")) {
      this.dragCounter--;

      if (this.dragCounter === 0) {
        this.timer = setTimeout(() => {
          this.slideoverTarget.classList.add("hidden");
        }, 20);
      }
      return false;
    }

    return true;
  }

  isValidFile(file) {
    const MAX_FILE_SIZE_MB = 20;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    return file.size <= MAX_FILE_SIZE_BYTES;
  }

  async progressBarForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter++;
    clearTimeout(this.timer);

    const slideOverController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="slideover"]'),
      "slideover"
    );

    try {
      const response = await fetch("/data_room/files/progress_bar_form");
      if (!response.ok) {
        return;
      }
      const htmlContent = await response.text();
      slideOverController.populate("Progress", htmlContent, [], "file");
    } catch (error) {}
  }

  handleCancel(xhr) {
    xhr.abort();

    const slideOverController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="slideover"]'),
      "slideover"
    );

    slideOverController.populate(
      "Upload Cancelled",
      '<div class="alert alert-error">Upload has been cancelled.</div>',
      [],
      "file"
    );
    slideOverController.open();
  }

  attachProgressListener(xhr) {
    const checkProgressBar = () => {
      const progressBar = document.getElementById("upload-progress");
      const progressText = document.getElementById("upload-progress-text");
      if (progressBar) {
        xhr.upload.addEventListener("progress", function (e) {
          if (e.lengthComputable) {
            const percentComplete = (e.loaded / e.total) * 100;
            progressBar.value = percentComplete;
            progressText.textContent = `${Math.floor(percentComplete)}%`;
          }
        });
      } else {
        setTimeout(checkProgressBar, 50);
      }
    };
    checkProgressBar();
  }
}
