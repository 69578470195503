import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pipelinesWithStages: Object };

  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners() {
    document.addEventListener("questionnaireActionsCreated", this.updateStages);
    this.element.addEventListener("change", this.updateStages);
  }

  removeEventListeners() {
    document.removeEventListener("questionnaireActionsCreated", this.updateStages);
    this.element.removeEventListener("change", this.updateStages);
  }

  updateStages = (event) => {
    const actionElement = event.detail?.element;

    if (actionElement) {
      this.updateStagesForAction(actionElement);
    } else {
      this.allActions().forEach((action) => this.updateStagesForAction(action));
    }
  };

  updateStagesForAction(action) {
    const toStageSelectElement = action.querySelector("[name='to_stage']");
    if (!toStageSelectElement) return;

    const oldSelectedPipelineId = action.dataset.targetPipelineId;
    const newSelectedPipelineId = this.element.value;
    const stages = this.pipelinesWithStagesValue[parseInt(newSelectedPipelineId)] || [];
    this.updateSelectElement(toStageSelectElement, stages);

    if (stages.includes(action.dataset.toStage)) {
      toStageSelectElement.value = action.dataset.toStage;
    }
  }

  updateSelectElement(selectElement, stages) {
    selectElement.innerHTML = "";
    const blankOption = document.createElement("option");
    blankOption.value = "";
    blankOption.text = "Select target stage...";
    selectElement.appendChild(blankOption);

    stages.forEach((stage) => {
      const option = document.createElement("option");
      option.value = stage;
      option.text = stage;
      selectElement.appendChild(option);
    });
  }

  allActions() {
    return document.querySelectorAll("[data-action-element]");
  }
}
