import consumer from "./consumer";

consumer.subscriptions.create("UnreadCountsChannel", {
  received(data) {
    const channelId = data.channel_id;
    const channelType = data.channel_type;
    const channelNavLink = document.querySelector(`#nav_link_base_${channelType}_channel_${channelId}`);

    if (channelNavLink === undefined || channelNavLink == null || channelNavLink.classList.contains("active")) return;

    if (!channelNavLink.classList.contains("decile-base-unread")) {
      channelNavLink.classList.add("decile-base-unread");
    }
  },
});
