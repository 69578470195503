// app/javascript/controllers/pact_content_type_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image", "video"];

  connect() {
    this.toggleContentType();
  }

  toggleContentType() {
    const selectedType = this.element.querySelector("select").value;

    if (selectedType === "image") {
      this.imageTarget.style.display = "block";
      this.videoTarget.style.display = "none";
    } else if (selectedType === "video") {
      this.imageTarget.style.display = "none";
      this.videoTarget.style.display = "block";
    }
  }
}
