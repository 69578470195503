import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static values = {
    questionnaireItemId: String,
  };

  static targets = ["submit", "otherTextFieldInput"];

  initialize() {
    this.debounceNoPageChangeSubmit = debounce(this.noPageChangeSubmit, 50).bind(this);
  }

  connect() {
    if (!this.hasSubmitTarget) return;

    this.form = this.submitTarget?.closest("form");
    this.addSubmitEventListener(this.form);
    this.isSubmitting = false;

    this.submitTargets.forEach((target) => {
      target.addEventListener("change", this.debounceNoPageChangeSubmit);
    });

    document.addEventListener("turbo:before-fetch-request", this.showProgressBar.bind(this));

    Turbo.navigator.delegate.adapter.progressBar.hide();

    const dgrid = document.querySelector("input[name='dgrid']");

    if (dgrid && !window.location.pathname.includes(dgrid.value)) {
      history.replaceState(null, null, this.appendQueryStringToUrl("dgrid", dgrid.value));
    }
  }

  disconnect() {
    if (!this.hasSubmitTarget) return;

    this.removeSubmitEventListener(this.form);

    this.submitTargets.forEach((target) => {
      target.removeEventListener("change", this.debounceNoPageChangeSubmit);
    });

    document.removeEventListener("turbo:before-fetch-request", this.showProgressBar);
  }

  addSubmitEventListener(form) {
    if (form) form.addEventListener('submit', this.submitEventListener);
  }

  removeSubmitEventListener(form) {
    if (form) form.removeEventListener('submit', this.submitEventListener);
  }

  submitEventListener = () => {
    this.isSubmitting = true;
    this.resetIsSubmitting();
  }

  showProgressBar() {
    Turbo.navigator.delegate.adapter.showProgressBar();

    const submitButton = this.form.querySelector("input[type='submit']");

    if (submitButton) {
      submitButton.disabled = true;
    }
  }

  noPageChangeSubmit(event) {
    setTimeout(() => {
      if (this.isSubmitting) return;

      if (!this.form.querySelector("input[name='no_page_change']")) {
        this.addHiddenInputToForm("no_page_change", "true");
        this.addHiddenInputToForm("caused_no_page_change", event.target.name);
      }

      this.addOtherOptionSelectedToSubmitRequest();
      this.addEmptyValueToSubmitRequest(event);

      this.isSubmitting = true;
      Rails.fire(this.form, "submit");

      this.resetIsSubmitting();
    }, 50);
  }

  addOtherOptionSelectedToSubmitRequest() {
    if (!this.hasOtherTextFieldInputTarget) return;
    if (!this.otherTextFieldInputTarget.classList.contains("hidden"))
      this.addHiddenInputToForm("preselect_other_option", this.questionnaireItemIdValue);
  }

  addEmptyValueToSubmitRequest(event) {
    const emptyValue = event.detail?.emptyValue;

    if (emptyValue) {
      this.addHiddenInputToForm(this.questionnaireItemIdValue, "");
    }
  }

  addHiddenInputToForm(name, value) {
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", name);
    hiddenInput.setAttribute("value", value);
    this.form.appendChild(hiddenInput);
  }

  appendQueryStringToUrl(paramName, paramValue) {
    const url = new URL(window.location.href);
    url.searchParams.set(paramName, paramValue);
    return url.toString();
  }

  resetIsSubmitting() {
    setTimeout(() => {
      this.isSubmitting = false;
    }, 5000);
  }
}
