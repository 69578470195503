import { Controller } from "@hotwired/stimulus";
import { safeJSONParse } from "../utils/json_parsing";

export default class extends Controller {
  static targets = [
    "select",
    "allContent",
    "limitedContent",
    "pipelineContent",
    "limitedPeopleList",
    "limitedPeopleHiddenField",
    "pipelineSelect",
    "pipelineStagesSelect",
    "pipelineAllPipelinesAndStages",
    "pipelineCurrentPermittedPipeline",
    "pipelineCurrentPermittedPipelineStages",
  ];

  connect() {
    this.showPermissionType();
  }

  showPermissionType() {
    this.hideAllContent();
    const permissionTypeHandlers = {
      PERMISSIONS_ALL_TYPE: () => this.showContent(this.allContentTarget),
      PERMISSIONS_LIMITED_TYPE: () => this.handleLimitedPermissions(),
      PERMISSIONS_PIPELINE_TYPE: () => this.handlePipelinePermissions(),
    };
    const handler = permissionTypeHandlers[this.selectTarget.value];
    if (handler) handler();
  }

  hideAllContent() {
    this.allContentTarget.classList.add("hidden");
    this.limitedContentTarget.classList.add("hidden");
    this.pipelineContentTarget.classList.add("hidden");
  }

  showContent(contentTarget) {
    contentTarget.classList.remove("hidden");
  }

  handleLimitedPermissions() {
    this.people = safeJSONParse(this.limitedPeopleHiddenFieldTarget.value, []);
    this.renderPeople();
    this.showContent(this.limitedContentTarget);
  }

  handlePipelinePermissions() {
    this.setupPipelineSelect();
    this.showContent(this.pipelineContentTarget);
  }

  setupPipelineSelect() {
    const currentPipelineId =
      this.pipelineCurrentPermittedPipelineTarget.value || this.pipelineSelectTarget.options[0].value;
    this.pipelineSelectTarget.value = currentPipelineId;
    this.showPipelineStages({ target: this.pipelineSelectTarget });
  }

  addPerson(event) {
    const selectedOption = event.target.selectedOptions[0];
    if (!selectedOption.value) return;

    const id = parseInt(selectedOption.value, 10);
    const person = safeJSONParse(event.target.dataset.optionsData).find((item) => item.value === id);
    if (person) {
      this.people.push({ id: person.value, name: person.text, email: person.description });
      this.updateLimitedPeopleHiddenField();
      this.renderPeople();
    }
    event.target.tomSelect.setValue("");
  }

  removePerson(event) {
    const personId = parseInt(event.target.getAttribute("data-person-id"), 10);
    this.people = this.people.filter((person) => person.id !== personId);
    this.updateLimitedPeopleHiddenField();
    this.renderPeople();
  }

  updateLimitedPeopleHiddenField() {
    this.limitedPeopleHiddenFieldTarget.value = JSON.stringify(this.people);
  }

  renderPeople() {
    this.limitedPeopleListTarget.innerHTML = this.people
      .map(
        (person) => `
      <div class="entity-card">
        <div class="flex gap-2 items-center flex-1 truncate">
          <div class="w-8 h-8 border border-dark-200 rounded-full flex items-center justify-center">
            <i class="ph-user-bold text-md text-primary-400"></i>
          </div>
          <div class="flex flex-col">
            <div class="font-medium text-dark-700">${person.name}</div>
            <p class="text-xxs text-dark-400">${person.email}</p>
          </div>
        </div>
        <button class="btn btn-white btn-xs icon-only" data-action="click->data-room-folder-permissions#removePerson">
          <i data-person-id="${person.id}" class="ph-x-bold"></i>
        </button>
      </div>
    `
      )
      .join("");
  }

  showPipelineStages(event) {
    const selectedPipelineId = parseInt(event.target.value, 10);
    if (!selectedPipelineId) return;

    const pipelineStagesData = safeJSONParse(this.pipelineAllPipelinesAndStagesTarget.value, []);
    const pipeline = pipelineStagesData.find((p) => p.id === selectedPipelineId);
    const permittedStages = safeJSONParse(this.pipelineCurrentPermittedPipelineStagesTarget.value, []);
    const permittedStageIds = permittedStages.map((stage) => stage.id);

    this.pipelineStagesSelectTarget.innerHTML = pipeline
      ? pipeline.stages
          .map(
            (stage) => `
      <div class="flex items-center gap-2 py-2">
        <input id="stage-${stage.id}-id" type="checkbox"
               name="data_room_folder[permitted_stages][${stage.id}]"
               class="form-checkbox stage-checkbox"
               ${permittedStageIds.includes(stage.id) ? "checked" : ""}>
        <label for="stage-${stage.id}-id" class="form-label">${stage.name}</label>
      </div>
    `
          )
          .join("")
      : "";
  }
}
