import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nonOther", "other", "defaultOtherValue", "otherValue"];

  connect() {
    this.nonOtherTargets.forEach((target) => {
      target.addEventListener("click", this.hideOtherInput.bind(this));
    });
    this.otherTargets.forEach((target) => {
      target.addEventListener("click", this.showOtherInput.bind(this));
    });
  }

  showOtherInput(e) {
    this.hideAllOtherInputs();
    if (e.target.dataset.customOtherValueIdName) {
      document.getElementById(e.target.dataset.customOtherValueIdName).classList.remove("hidden");
    } else {
      if (this.hasDefaultOtherValueTarget) {
        this.defaultOtherValueTarget.classList.remove("hidden");
      }
    }
  }

  hideOtherInput() {
    this.hideAllOtherInputs();
  }

  hideAllOtherInputs() {
    this.otherValueTargets.forEach((target) => {
      target.classList.add("hidden");
    });
    if (this.hasDefaultOtherValueTarget) {
      this.defaultOtherValueTarget.classList.add("hidden");
    }
  }
}
