// The BulkSelectorController can be used for adding bulk operations to your index views.
// You can add a Select All checkbox, select all in a group checkbox and individual checkboxes for each record and easily grab
// the selected records.
//
// Usage:
//
//   import BulkSelectorController from "controllers/bulk_selector_controller"
//
//   export default class extends BulkSelectorController {
//   }

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "checkbox",
    "selectGroup",
    "selectAll",
    "selectedCount",
    "bulkActionsContainer",
    "bulkActionsForm",
  ];

  static values = { preselected: Array };

  connect() {
    this.selectedGroups = [];
    this.preselect();
    if (this.hasSelectAllTarget) this.change();
  }

  preselect() {
    if (!this.hasPreselectedValue) return;

    this.checkboxTargets.forEach((target) => {
      if (this.preselectedValue.includes(target.dataset.id))
        target.checked = true;
    });
  }

  toggleGroup(event) {
    const group = event.target.dataset.group;
    if (group == "*") {
      this.selectAll();
    } else if (group == "") {
      this.unselectAll();
    } else {
      const exclusiveSelection = event.target.hasAttribute(
        "data-group-exclusive-selection"
      );
      this.selectedGroups.includes(group)
        ? this.unselectGroup(group, exclusiveSelection)
        : this.selectGroup(group, exclusiveSelection);
    }
    this.change();
  }

  selectGroup(group, exclusiveSelection = false) {
    this.checkboxTargets.map((checkbox) => {
      if (exclusiveSelection) {
        checkbox.checked = checkbox.dataset.group == group;
      } else if (checkbox.dataset.group == group) {
        checkbox.checked = true;
      }
    });

    exclusiveSelection
      ? (this.selectedGroups = [group])
      : this.selectedGroups.push(group);
  }

  unselectGroup(group) {
    this.checkboxTargets.map((checkbox) => {
      if (checkbox.dataset.group == group) {
        checkbox.checked = false;
      }
    });

    this.selectedGroups = this.selectedGroups.filter((item) => item !== group);
  }

  // Toggles all checkboxes based upon what is currently checked
  toggleSelectAll(event) {
    !this.allCheckboxesSelected ? this.selectAll() : this.unselectAll();
    this.updateSelectedCount();
    this.showBulkActions();
  }

  // Selects all checkboxes
  selectAll() {
    this.selectAllTarget.checked = true;
    this.selectAllTarget.indeterminate = false;
    this.unselected.forEach((target) => (target.checked = true));
  }

  // Unselects all checkboxes
  unselectAll() {
    this.selectAllTarget.checked = false;
    this.selectAllTarget.indeterminate = false;
    this.selected.forEach((target) => (target.checked = false));
  }

  // Keep track of SelectAll state based upon how many checkboxes are selected
  change(event) {
    if (this.noCheckboxesSelected) {
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = false;
    } else if (this.allCheckboxesSelected) {
      this.selectAllTarget.checked = true;
      this.selectAllTarget.indeterminate = false;
    } else {
      this.selectAllTarget.indeterminate = true;
    }

    this.updateSelectedCount();
    this.showBulkActions();
  }

  updateSelectedCount() {
    if (this.hasSelectedCountTarget)
      this.selectedCountTarget.innerText = this.selected.length;
  }

  showBulkActions() {
    this.bulkActionsContainerTarget.classList.toggle(
      "hidden",
      this.noCheckboxesSelected
    );
  }

  // Returns true if Select All checkbox is checked
  get selectedAll() {
    return this.selectAllTarget.checked;
  }

  // Returns all selected checkboxes
  get selected() {
    return this.checkboxTargets.filter((target) => target.checked);
  }

  // Returns all unselected checkboxes
  get unselected() {
    return this.checkboxTargets.filter((target) => !target.checked);
  }

  // Returns data-id attributes for all selected checkboxes
  get selectedIds() {
    return this.selected.map((target) => target.dataset.id);
  }

  // Returns true if all checkboxes are checked
  get allCheckboxesSelected() {
    return this.checkboxTargets.every((target) => target.checked);
  }

  // Returns true if no checkboxes are checked
  get noCheckboxesSelected() {
    return this.checkboxTargets.every((target) => !target.checked);
  }

  submitBulkActionForm() {
    this.bulkActionsFormTarget.querySelector("[name='ids']").value =
      this.selectedIds;
    Rails.fire(this.bulkActionsFormTarget, "submit");
  }
}
