import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["triggerSelect", "optionsSelect"];
  static values = { selectOptions: String };

  connect() {
    if (this.hasTriggerSelectTarget) {
      this.updateOptions();
      this.triggerSelectTarget.onchange = () => this.updateOptions();
    }
  }

  updateOptions() {
    const selectedOption = this.triggerSelectTarget.value;
    const options = JSON.parse(this.selectOptionsValue);
    const optionsForSelected = options[selectedOption];

    this.optionsSelectTarget.innerHTML = "";

    optionsForSelected.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option[1];
      optionElement.innerText = option[0];
      this.optionsSelectTarget.appendChild(optionElement);
    });

    this.optionsSelectTarget.value = "";
  }
}
