import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "checkbox",
    "submitButton"
  ];

  connect() {
    this.toggleSubmitButtonDisabledState();
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        this.toggleSubmitButtonDisabledState();
      });
    });
  }

  toggleSubmitButtonDisabledState() {
    if (this.hasSubmitButtonTarget) {
      const firstCheckedCheckbox = this.checkboxTargets.find(element => element.checked == true)

      if (firstCheckedCheckbox) {
        this.submitButtonTarget.disabled = false;
      } else {
        this.submitButtonTarget.disabled = true;
      }
    }
  }
}
