import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.add("legal-document");
    //window.addEventListener("resize", this.setFontSize.bind(this));
    //this.setFontSize();
  }

  setFontSize() {
    const pageWidth = this.element.clientWidth;
    // Observation by trail and error:
    //  52.87em of 12pt font is 100% legal doc with 0.75" and 0.75" left and right margins.
    this.element.style.fontSize = `${pageWidth / 52.87}px`;
  }
}
