import { add, Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "bulkActionsContainer",
    "selectAllShownCheckbox",
    "selectAllInDatabaseButton",
    "selectAllInDatabaseInput",
    "showButton",
    "actionInput",
    "actionSelect",
    "mainButton",
    "counter",
    "shown",
    "form",
    "loader",
  ];

  static values = {
    name: String,
  };

  connect() {
    this._selectedObjectCount = 0;

    this.actionSelectTargets.forEach((actionSelect) => {
      actionSelect.addEventListener("click", this.actionSelectClicked);
    });

    document.addEventListener("turbo:submit-start", this.turboSubmitStart);
    document.addEventListener("turbo:before-fetch-request", this.turboSubmitStart);

    document.addEventListener("turbo:submit-end", this.turboSubmitEnd);
    document.addEventListener("turbo:before-fetch-response", this.turboSubmitEnd);
  }

  disconnect() {
    this.actionSelectTargets.forEach((actionSelect) => {
      actionSelect.removeEventListener("click", this.actionSelectClicked);
    });

    document.removeEventListener("turbo:submit-start", this.turboSubmitStart);
    document.removeEventListener("turbo:before-fetch-request", this.turboSubmitStart);

    document.removeEventListener("turbo:submit-end", this.turboSubmitEnd);
    document.removeEventListener("turbo:before-fetch-response", this.turboSubmitEnd);
  }

  /* turbo events */

  turboSubmitStart = (e) => {
    this.loaderTarget.classList.remove("hidden");

    this.disableAllButtons();
  };

  turboSubmitEnd = (e) => {
    if (e?.detail?.fetchResponse?.response?.headers?.get("X-Bulk-Actions-Response") === "true") {
      this.checkboxes().forEach((checkbox) => {
        checkbox.checked = false;
      });
    }

    this.loaderTarget.classList.add("hidden");

    this.setSelectedCount();
    this.setShown();
    this.selectOrUnselectAllShown();
    this.setCounter();
    this.checkIfGroupCheckboxesNeedsToBeChecked();
    this.toggleButtons();
    this.hideEverythingIfThereAreNoCheckboxes();
  };

  /* end turbo events */

  /* click events */

  selectAllInDatabaseClick(e) {
    this.selectAllInDatabaseInputTarget.value = true;
    this.selectAllInDatabaseInputTarget.checked = true;

    if (this.hasSelectAllInDatabaseButtonTarget) {
      this.selectAllInDatabaseButtonTarget.disabled = true;
      this.selectAllInDatabaseButtonTarget.innerHTML = "All Selected";
    }

    this.selectAllShownCheckboxTarget.checked = true;

    this.setCounter();
    this.selectAllShownClick(e);
    this.checkIfGroupCheckboxesNeedsToBeChecked();
    this.toggleButtons();
  }

  selectAllShownClick(e) {
    if (!e.target.checked) {
      this.deselectAllShownClicked(e);
      return;
    }

    this.checkboxes().forEach((checkbox) => {
      checkbox.checked = true;
      this.toggleCheckboxParentClass(checkbox);
    });

    this.checkIfGroupCheckboxesNeedsToBeChecked();
    this.setSelectedCount();
    this.setCounter();
    this.showSelectAllInDatabaseButton();
    this.toggleButtons();
  }

  deselectAllShownClicked() {
    this.selectAllShownCheckboxTarget.checked = false;
    this.checkboxes().forEach((checkbox) => {
      checkbox.checked = false;
      this.toggleCheckboxParentClass(checkbox);
    });

    this.checkIfGroupCheckboxesNeedsToBeChecked();
    this.setSelectedCount();
    this.setCounter();
    this.resetSelectAllInDatabaseButton();
    this.toggleButtons();
  }

  actionSelectClicked = (e) => {
    this.actionInputTarget.value = event.target.dataset.value;
  };

  turboRequestWithIdsClicked = (e) => {
    const button = e.currentTarget;
    let url = new URL(button.dataset.url);

    this.checkedCheckboxes().forEach((checkbox) => url.searchParams.append(`${this.nameValue}[]`, checkbox.value));

    url.searchParams.append("bulk_action", button.dataset.bulkAction);
    url.searchParams.append("all_in_database_selected", this.selectAllInDatabaseInputTarget.value);

    get(url.toString(), { responseKind: "turbo-stream" });
  };

  /* end click events */

  /* change events */

  groupCheckboxChange = (e) => {
    const groupCheckbox = e.target;
    const groupName = groupCheckbox.dataset.groupName;

    this.checkboxes()
      .filter((checkbox) => checkbox.dataset.groupName === groupName)
      .forEach((checkbox) => {
        checkbox.checked = groupCheckbox.checked;
        this.toggleCheckboxParentClass(checkbox);
      });

    this.checkboxChange(e);
  };

  checkboxChange = (e) => {
    this.checkIfGroupCheckboxesNeedsToBeChecked();
    this.toggleCheckboxParentClass(e.target);
    this.setSelectedCount();
    this.setCounter();
    this.selectOrUnselectAllShown();
    this.resetSelectAllInDatabaseButton();
    this.toggleButtons();
  };

  /* end change events */

  /* getters */

  checkboxes() {
    return Array.from(
      document.querySelectorAll(`input[name="${this.nameValue}[]"][data-bulk-actions-element="checkbox"]`)
    );
  }

  groupCheckboxes() {
    return Array.from(document.querySelectorAll(`input[name="group_checkbox"]`));
  }

  checkedCheckboxes() {
    return this.checkboxes().filter((checkbox) => checkbox.checked);
  }

  /* end getters */

  /* setters */

  setShown() {
    if (this.hasShownTarget) this.shownTarget.innerHTML = `${this.checkboxes().length} Shown`;
  }

  setSelectedCount() {
    // Try to find view controller for pipelines to count from
    const viewController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='pipelines--pipeline-view']"),
      "pipelines--pipeline-view"
    );

    if (viewController) {
      this._selectedObjectCount = viewController.checkedRowsCount();
      return;
    }

    this._selectedObjectCount = this.checkedCheckboxes().length;
  }

  setCounter() {
    if (this.selectAllInDatabaseInputTarget.value === "true") {
      this.checkboxes().forEach((checkbox) => {
        checkbox.checked = true;
      });

      this.counterTarget.innerHTML = `<div class="min-w-[1rem] text-center py-0.5 px-1 rounded-sm bg-white bg-opacity-[15%] text-white text-xxxs counter-label">All</div> Selected`;
    } else {
      this.counterTarget.innerHTML = `<div class="min-w-[1rem] text-center py-0.5 px-1 rounded-sm bg-white bg-opacity-[15%] text-white text-xxxs counter-label">${this._selectedObjectCount}</div> Selected`;
    }

    this._selectedObjectCount > 0
      ? this.counterTarget.classList.remove("hidden")
      : this.counterTarget.classList.add("hidden");
  }

  /* end setters */

  hideEverythingIfThereAreNoCheckboxes() {
    if (this.checkboxes().length === 0) {
      this.bulkActionsContainerTarget.classList.add("hidden");
      if (this.hasSelectAllShownCheckboxTarget) this.selectAllShownCheckboxTarget.classList.add("hidden");
    } else {
      this.bulkActionsContainerTarget.classList.remove("hidden");
      if (this.hasSelectAllShownCheckboxTarget) this.selectAllShownCheckboxTarget.classList.remove("hidden");
    }
  }

  checkIfGroupCheckboxesNeedsToBeChecked() {
    if (this.groupCheckboxes().length === 0) return;

    for (const groupCheckbox of this.groupCheckboxes()) {
      const groupName = groupCheckbox.dataset.groupName;
      const checkboxesWithinGroup = this.checkboxes().filter((checkbox) => checkbox.dataset.groupName === groupName);
      const groupingCheckbox = checkboxesWithinGroup.find((checkbox) => checkbox.value.startsWith("grouping"));

      if (checkboxesWithinGroup.length === 0) {
        groupCheckbox.checked = false;
        continue;
      }

      const allChecked = checkboxesWithinGroup.every((checkbox) => checkbox.checked);

      groupCheckbox.checked = allChecked;

      if (groupingCheckbox) {
        groupingCheckbox.checked = allChecked;
      }
    }
  }

  toggleButtons = () => {
    this._selectedObjectCount === 0 ? this.disableAllButtons() : this.enableAllButtons();

    this.mainButtonTargets.forEach((button) => {
      this.enableButtonIfLogic(button);
      this.disableButtonIfLogic(button);
    });

    if (this.hasBulkActionsContainerTarget) {
      this._selectedObjectCount > 0
        ? this.bulkActionsContainerTarget.classList.add("bulk-active")
        : this.bulkActionsContainerTarget.classList.remove("bulk-active");
    }
  };

  enableButtonIfLogic(button) {
    if (this._selectedObjectCount === 0) return;

    const enableIfCounter = button.dataset.enableIfCounter;

    if (!enableIfCounter) return;

    const counterCondition = button.dataset.counterCondition;
    const logicIsTrue = this.resolveCondition(counterCondition, enableIfCounter);

    logicIsTrue ? this.enableButton(button) : this.disableButton(button);
  }

  disableButtonIfLogic(button) {
    if (this._selectedObjectCount === 0) return;

    const disableIfCounter = button.dataset.disableIfCounter;

    if (!disableIfCounter) return;

    const counterCondition = button.dataset.counterCondition;
    const logicIsTrue = this.resolveCondition(counterCondition, disableIfCounter);

    logicIsTrue ? this.disableButton(button) : this.enableButton(button);
  }

  resolveCondition(condition, compareTo) {
    compareTo = parseInt(compareTo);

    switch (condition) {
      case "===":
        return this._selectedObjectCount === compareTo;
      case ">=":
        return this._selectedObjectCount >= compareTo;
      case "<=":
        return this._selectedObjectCount <= compareTo;
      case ">":
        return this._selectedObjectCount > compareTo;
      case "<":
        return this._selectedObjectCount < compareTo;
      default:
        return this._selectedObjectCount === compareTo;
    }
  }

  selectOrUnselectAllShown() {
    if (!this.hasSelectAllShownCheckboxTarget) return;

    const checkboxes = this.checkboxes();

    if (checkboxes.length === 0) return;

    if (checkboxes.every((checkbox) => checkbox.checked)) {
      this.selectAllShownCheckboxTarget.checked = true;
    } else {
      this.selectAllShownCheckboxTarget.checked = false;
    }
  }

  checkForEmptyCheckboxes() {
    for (const checkbox of this.checkboxes()) {
      if (checkbox.checked) return false;
    }

    return true;
  }

  disableAllButtons = () => {
    this.mainButtonTargets.forEach((button) => this.disableButton(button));
  };

  enableAllButtons = () => {
    this.mainButtonTargets.forEach((button) => this.enableButton(button));
  };

  disableButton(button) {
    button.disabled = true;
    button.classList.add("disabled");
  }

  enableButton(button) {
    button.disabled = false;
    button.classList.remove("disabled");
  }

  showSelectAllInDatabaseButton() {
    if (!this.hasSelectAllInDatabaseButtonTarget) return;

    this.selectAllInDatabaseButtonTarget.classList.remove("!hidden");
    this.resetSelectAllInDatabaseButton();
  }

  resetSelectAllInDatabaseButton() {
    if (!this.hasSelectAllInDatabaseButtonTarget) return;

    this.selectAllInDatabaseInputTarget.value = false;
    this.selectAllInDatabaseInputTarget.checked = false;
    this.selectAllInDatabaseButtonTarget.disabled = false;
    this.selectAllInDatabaseButtonTarget.innerHTML = '<i class="ph-selection-all"></i>Select All';
  }

  toggleCheckboxParentClass = (checkbox) => {
    const selectableRow = checkbox.closest(".selectable-row");

    if (selectableRow) {
      if (checkbox.checked) {
        selectableRow.classList.add("selected");
      } else {
        selectableRow.classList.remove("selected");
      }
    }
  };
}
