// Example usage:
// <div data-controller="tippy" data-tippy-content="<b>Hello</b> <i>world</i>"></div>

import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  connect() {
    this.tippy = tippy(this.element, {
      content: this.element.getAttribute('data-tippy-content'),
      allowHTML: true,
      appendTo: () => document.body
    });
  }

  disconnect() {
    this.tippy.destroy();
  }
}
