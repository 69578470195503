import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["organization", "search"];

  static values = {
    url: String,
  };

  connect() {
    const input = this.organizationTarget;

    const descriptor = Object.getOwnPropertyDescriptor(
      Object.getPrototypeOf(input),
      "value"
    );

    const that = this;

    Object.defineProperty(input, "value", {
      set: function (value) {
        that.fetchOrganizationFundingRounds(that, value);
        return descriptor.set.apply(this, arguments);
      },
      get: function () {
        return descriptor.get.apply(this);
      },
    });
  }

  fetchOrganizationFundingRounds(context, value) {
    Rails.ajax({
      type: "GET",
      url: context.urlValue,
      data: `organization_id=${value}`
    })
  }
}
