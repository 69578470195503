import { Controller } from "stimulus"
import Cookies from "js-cookie"
import jstz from "jstz"

export default class extends Controller {
  connect() {
    Cookies.set("browser_time_zone", this.findTimeZone(), {
      expires: 365,
      path: "/"
    })
  }

  findTimeZone() {
    const oldIntl = window.Intl
    try {
      window.Intl = undefined
      const tz = jstz.determine().name()
      window.Intl = oldIntl
      return tz
    } catch (e) {
      // sometimes (on android) you can't override intl
      return jstz.determine().name()
    }
  }
}
