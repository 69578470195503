import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.element.addEventListener('keypress', event => this.handleKeyPress(event));
  }

  handleKeyPress(event) {
    if (event.keyCode === 13 && this.shouldIntercept(event)) {  // 13 == Enter key
      event.preventDefault();
      this.buttonTarget.click();
    }
  }

  // Only intercept if the active element is not a button
  shouldIntercept(event) {
    return !(document.activeElement && document.activeElement.type === 'submit');
  }
}
