import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "firstName",
    "lastName",
    "organizationName",
    "vehicleType",
    "taxIdQuestion",
    "taxIdFieldSet",
    "taxIdNumberText",
    "disregardedEntityType",
    "title",
    "mailingAddress",
    "accreditedInvestorType",
    "subjectToInfoDisclosureType",
    "investAsName",
    "spouseFirstName",
    "spouseLastName",
  ];

  connect() {
    const pathname = window.location.pathname;

    if (pathname.includes("prepare")) {
      this.updateVehicleTypeVisibility()
      this.vehicleTypeTarget.addEventListener(
        "change",
        this.updateVehicleTypeVisibility.bind(this)
      )
    } else {
      if (this.hasDisregardedEntityTypeTarget) {
        if (this.disregardedEntityTypeTarget.value != "") {
          this.updateDisregardedEntityTypeVisibility()
        }
        this.disregardedEntityTypeTarget.addEventListener(
          "change",
          this.updateDisregardedEntityTypeVisibility.bind(this)
        )
      }

      this.taxIdQuestionTarget.addEventListener(
        "change",
        this.updateTaxIdVisibility.bind(this)
      )
      this.mailingAddressTarget.addEventListener(
        "change",
        this.updateMailingAddressVisibility.bind(this)
      )
    }
  }

  //------ prepare --------------------------------------------------
  prepareReview(event) {
    if (this.vehicleTypeTarget.value == "individual") {
      if (this.hasOrganizationNameTarget) {
        this.investAsNameTarget.value = this.organizationNameTarget.value;
      } else if (this.hasFirstNameTarget && this.hasLastNameTarget) {
        this.investAsNameTarget.value =
          this.firstNameTarget.value + " " + this.lastNameTarget.value;
      }

      this.titleTarget.value = "";
    }
  }

  prepareEditInfo(event) {
    event.preventDefault();
    document.getElementById("prepareViewData").classList.add("hidden");
    document.getElementById("prepareEditLink").classList.add("hidden");
    document.getElementById("prepareEditData").classList.remove("hidden");
    document.getElementById("prepareSubmitButton").innerHTML =
      "<span class='filter drop-shadow-text'>Save and Next Step</span>"
  }

  updateVehicleTypeVisibility() {
    if (
      this.vehicleTypeTarget.value == "individual" ||
      this.vehicleTypeTarget.value == ""
    ) {
      this.hideEntityTrustFields()
      this.hideMarriedCoupleFields()

    } else if (this.vehicleTypeTarget.value == 'married_couple') {
      this.hideEntityTrustFields()
      this.showMarriedCoupleFields()
    }
    else {
      this.hideMarriedCoupleFields()
      this.showEntityTrustFields()

      let prepareEntityTypeLabel =
        this.vehicleTypeTarget.selectedOptions[0].innerText
      document.getElementById("prepareEntityType").innerText =
        prepareEntityTypeLabel + " name"

      document.getElementById("prepareTitle").classList.remove("hidden")

    }
  }

  showEntityTrustFields() {
    document.getElementById("prepareEntityName").classList.remove("hidden")

    let prepareEntityTypeLabel =
      this.vehicleTypeTarget.selectedOptions[0].innerText
    document.getElementById("prepareEntityType").innerText =
      prepareEntityTypeLabel + " name"

    document.getElementById("prepareTitle").classList.remove("hidden")
    this.investAsNameTarget.required = true
    this.titleTarget.required = true
  }

  hideEntityTrustFields() {
    document.getElementById("prepareEntityName").classList.add("hidden")
    document.getElementById("prepareTitle").classList.add("hidden")
    this.investAsNameTarget.required = false
    this.titleTarget.required = false
  }

  showMarriedCoupleFields() {
    document.getElementById("prepareSpouseFirstName").classList.remove("hidden")
    document.getElementById("prepareSpouseLastName").classList.remove("hidden")
    this.spouseFirstNameTarget.required = true
    this.spouseLastNameTarget.required = true
  }

  hideMarriedCoupleFields() {
    document.getElementById("prepareSpouseFirstName").classList.add("hidden")
    document.getElementById("prepareSpouseLastName").classList.add("hidden")
    this.spouseFirstNameTarget.required = false
    this.spouseLastNameTarget.required = false
  }

  //------ investor_info --------------------------------------------------

  updateDisregardedEntityTypeVisibility() {
    if (this.disregardedEntityTypeTarget.value == "true") {
      document
        .getElementById("investor:entity:disregarded")
        .classList.remove("hidden")
      this.taxIdNumberTextTarget.innerHTML =
        "Enter your Social Security Number (SSN)"
    } else {
      document
        .getElementById("investor:entity:disregarded")
        .classList.add("hidden")
      this.taxIdNumberTextTarget.innerHTML =
        "Enter your Employer Identification Number (EIN)"
    }
  }

  updateTaxIdVisibility(event) {
    let usYes = this.taxIdQuestionTarget.value;
    if (usYes == "Yes") {
      this.taxIdFieldSetTarget.classList.remove("hidden");
    }
    else {
      this.taxIdFieldSetTarget.classList.add("hidden")
    }
  }

  updateMailingAddressVisibility() {
    let sameAddress = this.mailingAddressTarget.value;
    if (sameAddress == "Yes") {
      document.getElementById("mailing-address").classList.add("hidden");
      document.getElementsByClassName("mailing-address").forEach((e) => {
        e.value = "";
      });
    } else {
      document.getElementById("mailing-address").classList.remove("hidden");
    }
  }
}
