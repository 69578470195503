import { Controller } from "stimulus";
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = ["form", "id", "submit"];

  static values = {
    url: String,
  }

  connect() {
    if (this.hasIdTarget) this.idTarget.addEventListener("change", this.disableOrEnableSubmitButton.bind(this));
  }

  disconnect(){
    if (this.hasIdTarget) this.idTarget.removeEventListener("change", this.disableOrEnableSubmitButton.bind(this));
  }

  collectData() {
    let organizationId = null;
    let personId = null;

    if (this.hasIdTarget) {
      if (this.idTarget.id === "organization_id") {
        organizationId = this.idTarget.value;
      }

      if (this.idTarget.id === "person_id") {
        personId = this.idTarget.value;
      }
    }

    return {
      organization_id: organizationId,
      person_id: personId,
    }
  }

  disableOrEnableSubmitButton(e) {
    const target = e.currentTarget;

    target.value ? this.submitTarget.disabled = false : this.submitTarget.disabled = true;
    target.value ? this.submitTarget.classList.remove('hidden') : this.submitTarget.classList.add('hidden')
  }

  submitForm(e) {
    const target = e.currentTarget;
    const actionType = target.dataset.actionType;
    const organizationId = target.dataset.organizationId;
    const personId = target.dataset.personId;

    let data = { action_type: actionType, ...this.collectData() };

    if (organizationId) data = { ...data, organization_id: organizationId };
    if (personId) data = { ...data, person_id: personId };

    post(this.urlValue, { body: JSON.stringify(data), responseKind: "turbo-stream" })
  }
}
