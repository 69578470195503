import { Controller } from "stimulus";
import Trix from "trix";

export default class extends Controller {
  static targets = ["customSignatureBlockContainer", "customSignatureBlockEnabled", "richTextEditor"];

  static values = { variables: Array };

  connect() {
    this.richTextEditorTarget.addEventListener("trix-initialize", this.trixInitialize);
  }


  disconnect() {
    this.richTextEditorTarget.removeEventListener("trix-initialize", this.trixInitialize);
    this.form.removeEventListener("submit", this.submit.bind(this));
  }

  trixInitialize = () => {
    this.form = this.richTextEditorTarget.closest("form");
    this.editor = this.richTextEditorTarget.editor;
    this.hiddenInput = this.element.querySelector('input[name="doc_gen_signatory[custom_signature_block]"]');
    this.form.addEventListener("submit", this.submit.bind(this));
    this.showCustomSignatureBlockIfInputValuePresent();
    this.transformVariablesToAttachments();
  }

  submit(event) {
    if (this.customSignatureBlockEnabledTarget.value === "0") return;

    if (this.isTrixEditorValueValid(event)) {
      const oldInputValue = this.hiddenInput.value;
      this.replaceAttachmentsWithSignatureVariable();
      setTimeout(() => {
        this.hiddenInput.value = oldInputValue;
        this.editor.loadHTML(oldInputValue);
        this.editor.element.blur();
      }, 50);
    } else {
      setTimeout(() => {
        const event = new Event("enableTurboFormButtons");
        document.dispatchEvent(event);
      }, 50);
    }
  }

  insertAtCursor(event) {
    event.preventDefault();

    const variableName = event.target.dataset.variable;

    if (!variableName) return;

    const attachment = new Trix.Attachment({
      content: this.variableHTML(variableName),
    });

    this.editor.insertAttachment(attachment);
    this.richTextEditorTarget.editor.recordUndoEntry(`Insert ${variableName}`);
  }

  transformVariablesToAttachments() {
    let content = this.hiddenInput.value;

    for (const variable of this.variablesValue) {
      content = content.split(`{{${variable}}}`).join(this.getAttachmentHTML(variable));
    }

    this.editor.loadHTML(content);
    this.editor.element.blur();
  }

  getAttachmentHTML(variable) {
    const attachment = new Trix.Attachment({
      content: this.variableHTML(variable),
    });

    const tempEditor = document.createElement("trix-editor");
    const tempContainer = document.createElement("div");
    tempContainer.classList.add("hidden");
    document.body.appendChild(tempContainer);
    tempContainer.appendChild(tempEditor);
    tempEditor.editor.insertAttachment(attachment);
    const attachmentHTML = tempEditor.innerHTML;
    tempContainer.remove();
    const spanRegex =
      /<span data-trix-cursor-target="left" data-trix-serialize="false">.*?<\/span>[\s\S]*?<span data-trix-cursor-target="right" data-trix-serialize="false">.*?<\/span>/;
    const match = attachmentHTML.match(spanRegex);

    return match[0];
  }

  replaceAttachmentsWithSignatureVariable() {
    const attachments = Array.from(this.editor.getDocument().getAttachments());

    attachments.forEach((attachment) => {
      const range = this.editor.getDocument().getRangeOfAttachment(attachment);
      this.editor.setSelectedRange(range);

      for (const variable of this.variablesValue) {
        if (attachment.attributes.values.content.includes(this.variableHTML(variable))) {
          this.editor.insertString(`{{${variable}}}`);
          break;
        }
      }
    });
  }

  isTrixEditorValueValid(event) {
    const signatureBlock = this.hiddenInput.value;

    if (signatureBlock.trim() === "") {
      event.preventDefault();
      const message = "Please enter valid content for the custom signature block.";
      this.hiddenInput.setCustomValidity(message);
      this.hiddenInput.dispatchEvent(new Event("invalid"));
      window.notyf.error(message);
      return false;
    }

    if (!signatureBlock.includes(this.variableHTML('signature'))) {
      event.preventDefault();
      const message = "Please include the your signature placeholder in the custom signature block.";
      this.hiddenInput.setCustomValidity(message);
      this.hiddenInput.dispatchEvent(new Event("invalid"));
      window.notyf.error(message);
      return false;
    }

    return true;
  }

  variableHTML(variable) {
    return `<span class='bg-primary-500 p-0.5 text-white rounded-sm'>${variable}</span>`;
  }

  toggleCustomSignatureBlock(e) {
    e.target.value === "1" ? this.showCustomSignatureBlock() : this.hideCustomSignatureBlock();
  }

  showCustomSignatureBlock() {
    this.customSignatureBlockContainerTarget.classList.remove("hidden");
  }

  hideCustomSignatureBlock() {
    this.customSignatureBlockContainerTarget.classList.add("hidden");
    this.customSignatureBlockContainerTarget.querySelector("input").value = "";
    this.customSignatureBlockContainerTarget.querySelector("trix-editor").editor.loadHTML("");
  }

  showCustomSignatureBlockIfInputValuePresent() {
    setTimeout(() => {
      if (this.hiddenInput.value.length > 0) {
        this.customSignatureBlockEnabledTarget.dispatchEvent(new Event("toggleON"));
        this.showCustomSignatureBlock();
      }
    }, 50);
  }
}
