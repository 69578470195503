import { Controller } from "stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["questionsField", "categoriesField", "questionInput", "categoryInput",
                    "categoryQuestionList", "newCategoryInput"]

  connect() {
    this.initSortable();
  }

  initSortable() {
    this.initializeSortableForCategories();
    this.initializeSortableForQuestions();
  }

  initializeSortableForCategories() {
    new Sortable(this.categoryQuestionListTarget, {
      handle: '.cursor-move',
      animation: 150,
      onEnd: this.updateCategoryOrder.bind(this)
    });
  }

  initializeSortableForQuestions() {
    document.querySelectorAll('.questions-list').forEach(questionList => {
      new Sortable(questionList, {
        handle: '.cursor-move',
        animation: 150,
        group: 'shared',
        onEnd: this.updateQuestionOrder.bind(this)
      });
    });
  }

  updateCategoryOrder(event) {
    const newOrder = Array.from(this.categoryQuestionListTarget.children)
                          .map(item => item.dataset.categoryId);

    let { categories, questions } = this.parseFields();

    const newCategories = newOrder.map(categoryId =>
      categories.find(cat => cat.id.toString() === categoryId)
    ).filter(cat => cat);

    this.updateFields(JSON.stringify(newCategories), JSON.stringify(questions));
    console.log('Updated order of categories:', newCategories);
  }

  updateQuestionOrder(event) {
    const from = event.target;
    const categoryId = from.dataset.categoryId;
    const newQuestionOrder = Array.from(from.children)
                                  .map(item => item.dataset.questionId);

    let { questions } = this.parseFields();
    questions[categoryId] = newQuestionOrder.map(id =>
      questions[categoryId].find(q => q.id.toString() === id)
    );

    this.questionsFieldTarget.value = JSON.stringify(questions);
    console.log('Updated order of questions in category:', categoryId, questions[categoryId]);
  }

  parseFields() {
    const categories = JSON.parse(this.categoriesFieldTarget.value);
    const questions = JSON.parse(this.questionsFieldTarget.value);
    return { categories, questions };
  }

  updateFields(categories, questions) {
    this.categoriesFieldTarget.value = categories;
    this.questionsFieldTarget.value = questions;
  }

  showNewCategoryField() {
    if (this.categoryInputTarget.value === "0") {
      this.newCategoryInputTarget.style.display = 'block';
    } else {
      this.newCategoryInputTarget.style.display = 'none';
    }
  }

  addCategory(event) {
    event.preventDefault();
    let { categories, questions } = this.parseFields();
    let questionText = this.questionInputTarget.value.trim();
    let categoryId = parseInt(this.categoryInputTarget.value);
    const newCategoryName = this.newCategoryInputTarget.value.trim();

    if (categoryId === 0) {
      if (newCategoryName === "") {
        alert("Please enter a category name.");
        return;
      }
      categoryId = this.getNextCategoryId(categories);
      categories.push({ id: categoryId, name: newCategoryName });
      this.categoryInputTarget.add(new Option(newCategoryName, categoryId, true, true));
      this.newCategoryInputTarget.style.display = 'none';
      this.newCategoryInputTarget.value = '';
    }

    this.addQuestionToCategory(questionText, categoryId, questions);
    this.updateFields(JSON.stringify(categories), JSON.stringify(questions));
    this.renderQuestionsAndCategories();
  }

  getNextCategoryId(categories) {
    return categories.length === 0 ? 1 : Math.max(...categories.map(cat => cat.id)) + 1;
  }

  addQuestionToCategory(questionText, categoryId, questions) {
    if (!questionText) {
      alert("Please enter a question.");
      return;
    }
    if (!questions[categoryId]) {
      questions[categoryId] = [];
    }
    questions[categoryId].push({
      text: questionText,
      id: new Date().getTime() // temporary id, to be reassigned server-side
    });
    this.questionInputTarget.value = '';
  }

  deleteCategory(event) {
    const categoryId = parseInt(event.currentTarget.dataset.categoryId);
    let { categories, questions } = this.parseFields();

    categories = categories.filter(category => category.id !== categoryId);
    delete questions[categoryId];

    this.updateFields(JSON.stringify(categories), JSON.stringify(questions));
    this.renderQuestionsAndCategories();
  }

  deleteQuestion(event) {
    const categoryId = parseInt(event.currentTarget.dataset.categoryId);
    const questionId = parseInt(event.currentTarget.dataset.questionId);
    let { questions } = this.parseFields();

    if (questions[categoryId]) {
      questions[categoryId] = questions[categoryId].filter(question => question.id !== questionId);
    }

    this.questionsFieldTarget.value = JSON.stringify(questions);
    this.renderQuestionsAndCategories();
  }

  renderQuestionsAndCategories() {
    let { questions, categories } = this.parseFields();
    let contentHtml = categories.map(cat => {
      let categoryQuestions = questions[cat.id] || [];
      return this.buildCategoryHtml(cat, categoryQuestions);
    }).join('');

    this.categoryQuestionListTarget.innerHTML = contentHtml;
  }

  buildCategoryHtml(category, categoryQuestions) {
    let questionHtml = categoryQuestions.map(question => `
      <div class="flex gap-2 items-center border-b border-dark-200 py-2 last:border-none" data-question-id="${question.id}">
        <div class="w-6 h-6 flex items-center justify-center text-md text-dark-400 cursor-move flex-shrink-0">
          <i class="ph-dots-six-vertical"></i>
        </div>
        <h3 class="text-dark-700 font-semibold text-sm leading-tight flex-1">${question.text}</h3>
        <button type="button" title="Delete question" data-action="deal-memo-config-question#deleteQuestion" data-category-id="${category.id}" data-question-id="${question.id}" class="text-dark-300 text-md hover:text-red-400 flex items-center justify-center flex-shrink-0 ml-auto">
          <i class="ph-trash"></i>
        </button>
      </div>
    `).join('');

    return `
      <div class="mb-4 last:mb-0" data-category-id="${category.id}">
        <div class="border-b border-dark-200 py-2 flex items-center">
          <div class="w-6 h-6 flex items-center justify-center text-md text-dark-400 cursor-move flex-shrink-0 mr-1.5">
            <i class="ph-dots-six-vertical"></i>
          </div>
          <div class="font-semibold text-dark-400 uppercase text-xxs">
            ${category.name}
          </div>
          <button type="button" title="Delete category" data-action="deal-memo-config-question#deleteCategory" data-category-id="${category.id}" class="text-dark-300 text-md hover:text-red-400 flex items-center justify-center flex-shrink-0 ml-auto">
            <i class="ph-trash"></i>
          </button>
        </div>
        <div class="questions-list">
          ${questionHtml}
        </div>
      </div>
    `;
  }
}
