import { Controller } from "stimulus"
import Cookies from "js-cookie"

const SIDEBAR_SESSION_KEY = "sidebar.session"

export default class extends Controller {
  static targets = [
    "aside",
    "expandable",
    "asideTrigger",
    "mobileAsideCloseTrigger",
    "subExpandable",
    "scrollableNav"
  ]

  initialize() {
    this.scrollingTimeout = null

    const sidebarSession = window.localStorage.getItem(SIDEBAR_SESSION_KEY)
    if (sidebarSession && sidebarSession != "undefined") {
      this.sidebarSession = JSON.parse(sidebarSession)
    } else {
      this.sidebarSession = {}
    }

    if (!this.sidebarSession.expandedMenus) {
      this.sidebarSession.expandedMenus = []
    }

    this.asideCollapsedBg =
      document.getElementsByClassName("aside-collapsed-bg")[0]

    if (this.sidebarSession.asideCollapsed) {
      // Close all expanded menus if aside is collapsed
      this.closeAllExpandables()
    } else {
      // Open the sidebar to full
      this.openAside()
    }
  }

  connect() {
    this.asideTriggerTarget.addEventListener("click", () => {
      this.toggleAside(true)
    })

    this.mobileAsideCloseTriggerTarget.addEventListener(
      "click",
      this.toggleMobileAside.bind(this)
    )

    this.expandableTargets.forEach((expandTarget) => {
      const targetHead =
        expandTarget.getElementsByClassName("nav-block-heading")[0]

      targetHead.addEventListener(
        "click",
        this.toggleExpandable.bind(this, expandTarget)
      )
    })

    this.subExpandableTargets.forEach((subExpandTarget) => {
      subExpandTarget
        .querySelectorAll('.sub-block-head')
        .forEach((targetHead) => {
          targetHead.addEventListener(
            "click",
            this.toggleSubExpandable.bind(this, subExpandTarget)
          )
        })
    })

    this.asideCollapsedBg.addEventListener("click", () => {
      const appRoot = document.getElementsByClassName("app-root")[0]

      if (appRoot.classList.contains("aside-collapsed")) {
        this.toggleExpandable()
      }
    })

    setTimeout(() => {
      this.enableTransitions()
      this.scrollableNavTarget.scrollTop = this.sidebarSession.scrollTop || 0

      this.scrollableNavTarget.addEventListener(
        "scroll",
        this.monitorScrollEvents.bind(this)
      )
    }, 500)
  }

  disconnect() {
    this.scrollableNavTarget.removeEventListener(
      "scroll",
      this.monitorScrollEvents.bind(this)
    )
  }

  enableTransitions() {
    const appRoot = document.getElementsByClassName("app-root")[0]
    appRoot.classList.add("transition-all", "duration-100")
  }

  openAside() {
    const appRoot = document.getElementsByClassName("app-root")[0]
    appRoot.classList.remove("aside-collapsed")
    this.expandMenus()
  }

  closeAside() {
    const appRoot = document.getElementsByClassName("app-root")[0]
    appRoot.classList.add("aside-collapsed")
  }

  toggleAside(shouldSave = false) {
    const appRoot = document.getElementsByClassName("app-root")[0]

    // Toggle aside
    if (appRoot.classList.contains("aside-collapsed")) {
      this.openAside()
    } else {
      this.closeAside()
      this.closeAllExpandables()
    }

    if (!shouldSave) {
      return
    }

    this.sidebarSession.asideCollapsed =
      appRoot.classList.contains("aside-collapsed")
    Cookies.set("aside_collapsed", this.sidebarSession.asideCollapsed)

    //open all the expanded menus from last session
    if (
      !this.sidebarSession.asideCollapsed &&
      this.sidebarSession.expandedMenus.length > 0
    ) {
      this.expandMenus()
    }

    this.saveSession()
  }

  nameForTarget(target) {
    return target?.getElementsByClassName("nav-block-heading")[0].innerText
  }

  expandMenus() {
    this.expandableTargets.forEach((expandTarget) => {
      const menuTitle = this.nameForTarget(expandTarget)
      if (this.sidebarSession.expandedMenus.includes(menuTitle)) {
        expandTarget.classList.add("expanded")
      }
    })
  }

  storeExpandedMenuItem(target) {
    const blockTitle = this.nameForTarget(target)
    const expandedMenus =
      this.sidebarSession.expandedMenus?.filter(
        (menu) => menu.toLowerCase() !== blockTitle?.toLowerCase()
      ) || []

    if (target?.classList.contains("expanded")) {
      expandedMenus.push(blockTitle)
    }

    this.sidebarSession.expandedMenus = expandedMenus
    this.saveSession()
  }

  toggleMobileAside() {
    this.asideCollapsedBg.classList.add("hidden")

    const appBody = document.getElementsByTagName("body")[0]
    const aside = document.getElementsByTagName("aside")[0]
    aside.classList.toggle("mobile-hidden")
    appBody.classList.remove("overflow-hidden")
    this.saveSession()
  }

  toggleExpandable(expandTarget) {
    let shouldShowCollapsedBg = false

    this.expandableTargets.forEach((target) => {
      if (target == expandTarget) {
        expandTarget.classList.toggle("expanded")

        shouldShowCollapsedBg =
          expandTarget.classList.contains("expanded") &&
          this.sidebarSession.asideCollapsed

        if (!this.sidebarSession.asideCollapsed) {
          this.storeExpandedMenuItem(target)
        }
      } else if (this.sidebarSession.asideCollapsed) {
        target.classList.remove("expanded")
      }
    })

    if (shouldShowCollapsedBg) {
      this.asideCollapsedBg.classList.remove("hidden")
    } else {
      this.asideCollapsedBg.classList.add("hidden")
    }

    this.saveSession()
  }

  closeAllExpandables() {
    this.expandableTargets.forEach((target) => {
      target.classList.remove("expanded")
    })

    this.asideCollapsedBg.classList.add("hidden")
  }

  toggleSubExpandable(subExpandTarget) {
    this.subExpandableTargets.forEach((target) => {
      if (target !== subExpandTarget) {
        target.classList.remove("expanded")
      } else {
        target.classList.toggle("expanded")
      }
    })
  }

  saveSession() {
    window.localStorage.setItem(
      SIDEBAR_SESSION_KEY,
      JSON.stringify(this.sidebarSession)
    )
  }

  monitorScrollEvents() {
    if (this.scrollingTimeout) {
      window.clearTimeout(this.scrollingTimeout)
    }

    this.scrollingTimeout = window.setTimeout(() => {
      this.scrollingTimeout = null
      this.sidebarSession.scrollTop = this.scrollableNavTarget.scrollTop
      this.saveSession()
    }, 500)
  }
}
