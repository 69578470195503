import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "firstNameField",
    "lastNameField",
    "submit",
    "cancel",
    "close",
  ];

  connect() {
    this.firstNameFieldTarget.addEventListener(
      "change",
      this.updateExternalFirstNameField.bind(this)
    );
    this.lastNameFieldTarget.addEventListener(
      "change",
      this.updateExternalLasttNameField.bind(this)
    );
    this.submitTarget.addEventListener(
      "click",
      this.submitEmailForm.bind(this)
    );
    this.cancelTarget.addEventListener(
      "click",
      this.submitEmailFormCancel.bind(this)
    );
    this.closeTarget.addEventListener(
      "click",
      this.submitEmailFormCancel.bind(this)
    );
  }

  updateExternalFirstNameField(event) {
    const externalFirstNameField = document.getElementById(
      "quick-email__external-first-name"
    );
    externalFirstNameField.value = event.target.value;
  }

  updateExternalLasttNameField(event) {
    const externalLastNameField = document.getElementById(
      "quick-email__external-last-name"
    );
    externalLastNameField.value = event.target.value;
  }

  submitEmailForm() {
    document.getElementById("quick-email__external-no-popup").value = true;
    document.getElementById("quick-email-form__submit").click();
  }

  submitEmailFormCancel() {
    document.getElementById("quick-email__external-no-popup").value = true;
    document.getElementById("quick-email__ignore-names").value = true;
    document.getElementById("quick-email-form__submit").click();
  }
}
