import { Controller } from "stimulus";

export default class extends Controller {
  static values = { offset: Number };

  connect() {
    this.boundingBox = this.element.getBoundingClientRect();
    this.scrollToElement();
  }

  scrollToElement() {
    if (this.isVisible() || this.wasScrolled()) return;

    setTimeout(() => {
      document.getElementById(this.element.id).scrollIntoView({
        behavior: "smooth",
      });
    }, 200);

    this.element.dataset.scrolled = this.scrolledIdentifier;
  }

  isVisible() {
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    const isAboveScreen = this.boundingBox.bottom < this.offsetValue;
    const isBelowScreen = this.boundingBox.top + this.offsetValue > viewHeight;

    return !isAboveScreen && !isBelowScreen;
  }

  wasScrolled() {
    return this.element.dataset.scrolled === this.scrolledIdentifier;
  }

  get scrolledIdentifier() {
    return "scrolled";
  }
}
