import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["errorText"];
  static values = { step: Number };

  connect() {
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListener();
  }

  addEventListeners = () => {
    this.allFields().forEach((field) => {
      field.addEventListener("invalid", this.showError);
      field.addEventListener("change", this.hideError);
      field.addEventListener("change", this.trimValue);
    });
  };

  removeEventListener = () => {
    this.allFields().forEach((field) => {
      field.removeEventListener("invalid", this.showError);
      field.removeEventListener("change", this.hideError);
      field.removeEventListener("change", this.trimValue);
    });
  };

  allFields() {
    return this.element.querySelectorAll("input, textarea, select, trix-editor");
  }

  showError = (event) => {
    if (this.isNoPageChangeRequest()) return;

    const submitButton = event.target.form.querySelector('button[type="submit"]:focus, input[type="submit"]:focus');

    if ((submitButton && submitButton.hasAttribute("formnovalidate")) || this.isNoPageChangeRequest()) {
      event.preventDefault();
      return;
    }

    const errorMessage = event.target.validationMessage;
    this.setErrorMessage(errorMessage);

    event.target.closest("[data-column-container]").querySelector(".dg-validation-js").classList.remove("hidden");
  };

  hideError = () => {
    this.element.querySelector(".dg-validation-js").classList.add("hidden");
  };

  setErrorMessage(message) {
    this.errorTextTarget.innerHTML = message;
  }

  trimValue = (event) => {
    // Prevents this error:
    // Failed to set the 'value' property on 'HTMLInputElement': This input element accepts a filename, which may only be programmatically set to the empty
    if (event.target.type === "file") return;

    event.target.value = event.target.value.trim();
  };

  isNoPageChangeRequest() {
    const form = this.element.closest("form")

    if(!form) return false;

    const input = form.querySelector("input[name='no_page_change']");

    return input && input.value === "true" ? true : false;
  }
}
