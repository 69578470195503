import Tagify from "@yaireo/tagify";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    enforceWhitelist: Boolean,
    whitelist: Array,
    preselected: Array,
  };

  connect() {
    this.processWhitelist();
    this.tagifyInput();
    this.showTags();
  }

  disconnect() {
    this.tagify = null;
  }

  tagifyInput() {
    const emailPattern = /^((?:.+? ?)<[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+>|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+)$/;
    this.tagify = new Tagify(this.element, {
      pattern: emailPattern,
      delimiters: ",",
      templates: {
        tag: function (tagData) {
          try {
            return `<tag title='${tagData.value}' contenteditable='false' spellcheck="false" class='tagify__tag ${
              tagData.class ? tagData.class : ""
            }' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn'></x>
                        <div>
                            <span class='tagify__tag-text'>${tagData.value}</span>
                        </div>
                    </tag>`;
          } catch (err) {}
        },
      },
      enforceWhitelist: this.enforceWhitelistValue,
      whitelist: this.whitelist,
      dropdown: {
        closeOnSelect: false,
        enabled: 0,
        classname: "extra-properties",
      },
    });

    this.validateRequiredInput();
  }

  showTags() {
    const tagsToAdd = [];
    tagsToAdd.push(...this.addCustomEmailsTags());
    tagsToAdd.push(...this.addInternalUsersTags());
    this.tagify.addTags(tagsToAdd);
  }

  addCustomEmailsTags() {
    const tags = [];
    this.preselectedValue.forEach((val) => val.actor_type == "external_person" && tags.push(this.createTag(val)));
    return tags;
  }

  addInternalUsersTags() {
    return this.whitelist.filter((val) => this.preselectedValue.find((element) => element.actor_id === val.id));
  }

  validateRequiredInput() {
    if (!this.element.required) return;

    this.tagify.on("add", () => this.tagify.DOM.scope.classList.remove("error"));
    this.tagify.on("remove", () => {
      if (this.element.value.length === 0) this.tagify.DOM.scope.classList.add("error");
    });
  }

  processWhitelist() {
    this.whitelist = this.whitelistValue.map((val) => this.createTag(val));
  }

  createTag(val) {
    return {
      value: this.setTagEmail(val),
      id: this.setTagId(val),
    };
  }

  setTagId(val) {
    return val.actor_type === "external_person" ? null : val.id;
  }

  setTagEmail(val) {
    return val.actor_type === "external_person" ? val.email : `${val.name} <${val.email}>`;
  }
}
