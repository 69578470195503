import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["editButton", "staticContainer", "formContainer"];

  connect() {
    if (this.hasEditButtonTarget) {
      this.editButtonTarget.addEventListener("click", this.editView.bind(this));
    }
  }

  editView(e) {
    this.staticContainerTarget.classList.add("hidden");
    this.formContainerTarget.classList.remove("hidden");
  }
}
