import { Controller } from "stimulus";
import GLightbox from "glightbox";

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox({
      selector: ".glightbox",
    });
  }

  disconnect() {
    if (this.lightbox) {
      this.lightbox.destroy();
    }
  }
}
