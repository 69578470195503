import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    periodStart: String,
    periodEnd: String,
  };
  static targets = ["periodType", "periodStart", "periodEnd"];

  connect() {
    this.periodTypeTarget.addEventListener(
      "change",
      this.changePeriodType.bind(this)
    );

    this.changePeriodType({ target: this.periodTypeTarget });
  }

  changePeriodType(event) {
    const periodType = event.target.value;

    this.periodStartTarget.parentElement.classList.add("hidden");
    this.periodEndTarget.parentElement.classList.add("hidden");

    switch (periodType) {
      case "":
        this.setDatesForLast90Days();
        break;
      case "This month":
        this.setDatesForThisMonth();
        break;
      case "This quarter":
        this.setDatesForThisQuarter();
        break;
      case "This financial year":
        this.setDatesForThisFinancialYear(); //
        break;
      case "Last month":
        this.setDatesForLastMonth();
        break;
      case "Last quarter":
        this.setDatesForLastQuarter();
        break;
      case "Last year":
        this.setDatesForLastFinancialYear();
        break;
      case "Month to date":
        this.setDatesForMonthToDate();
        break;
      case "Quarter to date":
        this.setDatesForQuarterToDate(); //
        break;
      case "Year to date":
        this.setDatesForYearToDate();
        break;
      case "End of last month":
        this.setDatesForEndOfLastMonth(); //
        break;
      case "End of last quarter":
        this.setDatesForEndOfLastQuarter(); //
        break;
      case "End of last financial year":
        this.setDatesForEndOfLastFinancialYear(); //
        break;
      default:
        this.setDatesForCustom();
    }
  }

  setDatesForLast90Days() {
    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 90
    );
    const endDate = new Date();

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForThisMonth() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForThisQuarter() {
    const today = new Date();
    const startDate = this.getStartOfQuarter(today);
    const endDate = this.getEndOfQuarter(today);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForThisFinancialYear() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), 0, 1);
    const endDate = new Date(today.getFullYear() + 1, 0, 0);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForLastMonth() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endDate = new Date(today.getFullYear(), today.getMonth(), 0);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForLastQuarter() {
    const aDayInLastQuarter = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3
    );
    const startDate = this.getStartOfQuarter(aDayInLastQuarter);
    const endDate = this.getEndOfQuarter(aDayInLastQuarter);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForLastFinancialYear() {
    const today = new Date();
    const startDate = new Date(today.getFullYear() - 1, 0, 1);
    const endDate = new Date(today.getFullYear(), 0, 0);

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForMonthToDate() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date();

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForQuarterToDate() {
    const today = new Date();
    const startDate = this.getStartOfQuarter(today);
    const endDate = new Date();

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForYearToDate() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), 0, 1);
    const endDate = new Date();

    this.setDatesTargets(startDate, endDate)
  }

  setDatesForCustom() {
    this.periodStartTarget.parentElement.classList.remove("hidden");
    this.periodEndTarget.parentElement.classList.remove("hidden");
  }

  getStartOfQuarter(date = new Date()) {
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    const year = date.getFullYear();

    return new Date(year, (quarter - 1) * 3, 1);
  }

  getEndOfQuarter(date = new Date()) {
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    const year = date.getFullYear();

    return new Date(year, (quarter - 1) * 3 + 3, 0);
  }

  setDatesTargets(startDate, endDate) {
    this.periodStartTarget.value = startDate.toISOString().slice(0, 10);
    this.periodEndTarget.value = endDate.toISOString().slice(0, 10);
  }
}
