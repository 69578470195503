import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["name", "value"];

  nameTargetConnected(element) {
    element.addEventListener("keyup", this.changeValueField.bind(this));

    element.onchange = this.changeValueField.bind(this);
  }

  changeValueField(event) {
    this.valueTarget.value = this.valuify(this.nameTarget.value);
  }

  valuify(value) {
    if (!value) {
      return "";
    }

    return value
      .replace(/[^\w\s]/gi, "")
      .replace(/[\s]/gi, "_")
      .toLowerCase();
  }
}
