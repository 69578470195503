import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "context",
    "name",
    "value",
    "format",
    "description",
    "showInAddNew",
    "pipelineColumnEnabled",
    "linkContainer",
    "formContainer",
    "selectOptions",
  ];

  connect() {
    this.nameTarget.addEventListener("keyup", this.changeValueField.bind(this));
    this.nameTarget.onchange = this.changeValueField.bind(this);
  }

  changeValueField(event) {
    this.valueTarget.value = this.valuify(this.nameTarget.value);
  }

  showForm() {
    this.linkContainerTarget.className = "hidden";
    this.formContainerTarget.classList.remove("hidden");
  }

  hideForm() {
    this.linkContainerTarget.className = "flex items-center justify-between pt-3 border-t border-gray-200";
    this.formContainerTarget.classList.add("hidden");
  }

  saveForm() {
    const data = new FormData();
    data.append("variable_variable[context]", this.contextTarget.value);
    data.append("variable_variable[name]", this.nameTarget.value);
    data.append("variable_variable[value]", this.valueTarget.value);
    data.append("variable_variable[description]", this.descriptionTarget.value);
    data.append("variable_variable[format_id]", this.formatTarget.value);

    // For checkbox fields, find the first element with the same name as the option element, since rails uses a
    // workaround to appease HTML specification. See "Gotcha" https://apidock.com/rails/ActionView/Helpers/FormHelper/check_box
    const showInAddNewTargetValue = document.getElementsByName(this.showInAddNewTarget.name)[1].checked;
    data.append("variable_variable[data][show_in_add_new]", showInAddNewTargetValue == true ? "1" : "0");
    const pipelineColumnEnabledTargetValue = document.getElementsByName(this.pipelineColumnEnabledTarget.name)[1]
      .checked;
    data.append("variable_variable[pipeline_column_enabled]", pipelineColumnEnabledTargetValue);

    this.selectOptionsTargets.forEach((option) => {
      if (!option.parentElement.classList.contains("hidden")) {
        data.append("variable_variable[data][select_options][]", option.value);
      }
    });

    Rails.ajax({
      type: "POST",
      url: "/variable/variables.json",
      data: data,
      error: this.saveError.bind(this),
      success: this.saveSuccess.bind(this),
    });
  }

  saveError(response) {
    console.log("SAVE ERROR");
    console.log(response);
  }

  saveSuccess(response) {
    if (response.result) {
      window.notyf.success(response.message);

      const link = document.getElementsByClassName("slideover-tabs-tab--active")[0];
      Rails.fire(link, "click");
    } else {
      window.notyf.error(response.message);
    }
  }

  valuify(value) {
    if (!value) {
      return "";
    }

    return value
      .replace(/[^\w\s]/gi, "")
      .replace(/[\s]/gi, "_")
      .toLowerCase();
  }
}
