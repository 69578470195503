import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:submit-end", this.handleSubmit);
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit);
  }

  handleSubmit = (e) => {
    this.close();
  };

  close() {
    this.modalTurboFrame().removeAttribute("src");
    this.element.innerHTML = "";
    this.disconnect();
  }

  modalTurboFrame() {
    return document.querySelector("turbo-frame[id='second-turbo-modal']");
  }
}
