import { Controller } from "stimulus";

export default class extends Controller {
  static values = {};
  static targets = [
    "investForm",
    "investedBeforeYes",
    "investedBeforeNo",
    "investedInBlock",
    "accreditedYes",
    "accreditedNo",
    "notAccreditedBlock",
    "submit",
  ];

  connect() {
    this.investedBeforeYesTarget.addEventListener(
      "change",
      this.showInvestedIn.bind(this)
    );

    this.investedBeforeNoTarget.addEventListener(
      "change",
      this.hideInvestedIn.bind(this)
    );

    this.accreditedYesTarget.addEventListener(
      "change",
      this.enableAccreditedSubmit.bind(this)
    );

    this.accreditedNoTarget.addEventListener(
      "change",
      this.disableAccreditedSubmit.bind(this)
    );
  }

  formSubmit(event) {
    event.preventDefault();
    if (!this.accreditedYesTarget.checked) {
      this.disableAccreditedSubmit();
    } else {
      this.investFormTarget.submit();
    }
  }

  showInvestedIn() {
    this.investedInBlockTarget.classList.remove("hidden");
  }

  hideInvestedIn() {
    this.investedInBlockTarget.classList.add("hidden");
  }

  enableAccreditedSubmit() {
    this.submitTarget.removeAttribute("disabled");
    this.notAccreditedBlockTarget.classList.add("hidden");
  }

  disableAccreditedSubmit() {
    this.submitTarget.setAttribute("disabled", "disabled");
    this.notAccreditedBlockTarget.classList.remove("hidden");
  }
}
