import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "search",
    "template",
    "searchContainer",
    "selectedResultContainer",
    "selectedResult",
    "loader",
    "searchResults",
    "newDataRoomForm",
    "reset",
    "dataRoomIdField",
    "newDataRoomNameField",
    "file",
    "fileName",
  ];

  connect() {
    this.searchField = this.searchTarget;
    this.searchResultTemplate = this.templateTarget;

    this.boundSearchEvent = this.search.bind(this);
    this.searchTarget.addEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.addEventListener(
      "blur",
      this.closeSearchResultsAfterClickAway.bind(this)
    );
  }

  disconnect() {
    this.searchTarget.removeEventListener("keyup", this.boundSearchEvent);
    this.searchTarget.removeEventListener(
      "blur",
      this.closeSearchResultsAfterClickAway.bind(this)
    );
  }

  closeSearchResultsAfterClickAway() {
    setTimeout(() => {
      this.searchResultTemplate.classList.add("hidden");
    }, 400);
  }

  search(e) {
    if (e.key == "Enter") {
      window.notyf.error(
        "Please choose an option from the dropdown to continue"
      );
      return;
    }

    const keyModifier =
      e.keyCode === 16 || e.keyCode === 18;

    const searchString = this.searchField.value.trim();
    const data =
      this.searchField.dataset.queryParam &&
      `search=${this.searchField.dataset.queryParam}&${this.searchField.dataset.queryParam}=${searchString}`;

    if (searchString.length > 0 && !keyModifier) {
      // show loading
      this.loaderTarget.classList.remove("hidden");
      Rails.ajax({
        type: "GET",
        url: this.searchTarget.dataset.dataRoomFolderSearchUrlValue,
        data: data,
        error: this.showEmptySearchResults.bind(this),
        success: this.showSearchResults.bind(this),
      });
    } else {
      this.hideSearchResults();
    }
  }

  showSearchResults(results) {
    this.loaderTarget.classList.add("hidden");

    this.updateSearchResults(results);
    this.searchResultTemplate.classList.remove("hidden");
  }

  updateSearchResults(results) {
    this.searchResults = results;

    this.searchResultsTarget.innerHTML = "";

    results.forEach((result) => {
      this.searchResultsTarget.insertAdjacentElement(
        "beforeend",
        this.createSearchResult(result)
      );
    });

    this.createNewButtons();
  }

  createNewButtons() {
    const addIcon = '<i class="ph-plus-circle-bold text-md"></i>';

    const addNewDataRoom = document.createElement("a");
    addNewDataRoom.innerHTML = addIcon + "Create new files";
    addNewDataRoom.className =
      "px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50";
    addNewDataRoom.id = "new_org";

    this.searchResultsTarget.insertAdjacentElement("beforeend", addNewDataRoom);

    addNewDataRoom.addEventListener(
      "click",
      this.selectSearchResultOnClick.bind(this)
    );
    addNewDataRoom.addEventListener(
      "click",
      this.selectSearchResultOnClick.bind(this)
    );
    addNewDataRoom.addEventListener(
      "click",
      this.preFillDataRoomName.bind(this)
    );
  }

  preFillDataRoomName() {
    if (this.hasNewDataRoomNameFieldTarget) {
      this.newDataRoomNameFieldTarget.value = this.searchTarget.value;
    }
  }

  createSearchResult(result) {
    const resultElement = document.createElement("a");

    resultElement.classList.add(
      "flex",
      "items-center",
      "gap-2",
      "px-3",
      "py-2",
      "hover:bg-dark-50",
      "rounded",
      "cursor-pointer"
    );

    const icon = "ph-tray";

    resultElement.innerHTML = `<div class="w-7 h-7 border-2 border-dark-200 rounded-full flex items-center justify-center text-primary-400"><i class="${icon}"></i></div><span>${result.name}</span>`;
    resultElement.dataset.id = result.id;
    resultElement.classList.add("hover:bg-dark-50", "rounded");
    resultElement.addEventListener(
      "click",
      this.selectSearchResultOnClick.bind(this)
    );

    return resultElement;
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find(
      (result) => result.id == ev.currentTarget.dataset.id
    );

    if (selectedResult != undefined) {
      document
        .getElementById("upload-file-tab__selectedResultContainer")
        .classList.remove("hidden");
      this.selectedResultTarget.innerText = selectedResult.name;
    } else {
      this.newDataRoomFormTarget.classList.remove("hidden");
    }

    // hide search bar
    this.searchContainerTarget.classList.add("hidden");
    // show reset button
    this.resetTarget.classList.remove("hidden");

    this.selectSearchResult(selectedResult);
  }

  hideSearchResults() {
    this.searchResultTemplate.classList.add("hidden");
  }

  reset() {
    // show search bar
    this.searchContainerTarget.classList.remove("hidden");
    // hide file upload form fields
    document
      .getElementById("upload-file-tab__selectedResultContainer")
      .classList.add("hidden");
    this.newDataRoomFormTarget.classList.add("hidden");
    // hide reset button
    this.resetTarget.classList.add("hidden");
    // reset other fields
    this.searchTarget.value = "";
    this.fileTarget.value = "";
    this.fileNameTarget.value = "";
  }

  selectSearchResult(selectedResult) {
    if (selectedResult?.id)
      this.dataRoomIdFieldTarget.value = selectedResult.id;

    this.hideSearchResults();
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  showSearchField() {
    this.displayElement.classList.add("hidden");
    this.searchField.classList.remove("hidden");
    this.searchField.value = "";
    this.searchField.focus();
  }
}
