import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static classes = ["collapsed"];

  connect() {
    if (this.element.classList.contains(this.collapsedClass) || this.#getCookie()) {
      this.toggle({ detail: { toggled: true } });
    } else {
      this.toggle({ detail: { toggled: false } });
    }
  }

  disconnect() {}

  toggle({ detail: { toggled } }) {
    if (toggled) {
      this.#addCookie();
      this.element.classList.add(this.collapsedClass);
    } else {
      this.#deleteCookie();
      this.element.classList.remove(this.collapsedClass);
    }
  }

  #addCookie() {
    Cookies.set("aside_collapsed", "true", { path: "/" });
  }

  #deleteCookie() {
    Cookies.remove("aside_collapsed", { path: "/" });
  }

  #getCookie() {
    return Cookies.get("aside_collapsed") === "true";
  }
}
