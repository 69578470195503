import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "input", "container"];

  connect() {
    super.connect();

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("click", this.destroy.bind(this));
    }
  }

  destroy(event) {
    if (this.hasInputTarget) {
      this.inputTarget.value = 1;
    }

    if (this.hasContainerTarget) {
      this.containerTarget.classList.add("hidden");
    }
  }
}
