import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    dataRoomsWithFolders: Object,
  };
  static targets = ["dataRoomSelect", "dataRoomFolderSelect"];

  connect() {
    this.actionData = JSON.parse(this.element.closest("[data-action-element]").dataset.actionData);
    this.setDataRoomFolderSelect();
  }

  dataRoomChanged() {
    this.setDataRoomFolderSelect();
  }

  setDataRoomFolderSelect() {
    this.dataRoomFolderSelectTarget.innerHTML = "";
    this.dataRoomFolderSelectTarget.value = "";
    const currentDataRoomId = parseInt(this.dataRoomSelectTarget.value);

    if (currentDataRoomId) {
      const folders = this.dataRoomsWithFoldersValue[currentDataRoomId] || [];
      this.createFoldersSelectOptions(folders, currentDataRoomId);
    } else {
      this.createFoldersSelectOptions([], "");
    }
  }

  createFoldersSelectOptions(folders, dataRoomId) {
    this.setBlankOption(dataRoomId);

    folders.forEach((folder) => {
      this.setOption(folder);
    });
  }

  setBlankOption(dataRoomId) {
    const blankName = dataRoomId ? "Root folder" : "Not selected...";
    const blankOption = new Option(
      blankName,
      dataRoomId,
      true,
      dataRoomId === parseInt(this.actionData["data_room_folder_id"])
    );
    this.dataRoomFolderSelectTarget.appendChild(blankOption);
  }

  setOption(folder) {
    const option = new Option(
      folder.name,
      folder.id,
      false,
      folder.id === parseInt(this.actionData["data_room_folder_id"])
    );
    this.dataRoomFolderSelectTarget.appendChild(option);
  }
}
