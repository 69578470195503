import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.activeItem = this.itemTargets.find((item) => item.classList.contains("active-state"));
    this.activeClass = this.element.dataset.highlightActiveClass || "active-state";
  }

  select(event) {
    event.preventDefault();

    const newActiveItem = event.currentTarget;

    if (this.activeItem) {
      const inactiveClass = this.activeItem.dataset.highlightInactiveClass || "";
      this.activeItem.classList.remove(...this.activeClass.split(" "));
      this.activeItem.classList.add(...inactiveClass.split(" "));

      const iconDiv = this.activeItem.querySelector(".icon-div");
      if (iconDiv) {
        iconDiv.classList.add("hidden");
      }
    }

    this.activeItem = newActiveItem;
    const inactiveClass = this.activeItem.dataset.highlightInactiveClass || "";
    this.activeItem.classList.add(...this.activeClass.split(" "));
    this.activeItem.classList.remove(...inactiveClass.split(" "));

    //optional active icon when highlight is active on an element
    const iconDiv = this.activeItem.querySelector(".icon-div");
    if (iconDiv) {
      iconDiv.classList.remove("hidden");
    }
  }
}
