import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["values", "activeChannel", "viewAllPosts", "activityFeed"];

  connect() {
    this.loadedTargets = false;
  }

  async loadMenuItems() {
    if (this.activeChannelId === undefined || this.activeChannelId === null || this.activeChannelId === "") {
      if (window.location.pathname === "/base/activity_feeds") {
        this.activityFeedTarget.classList.add("active");
      } else {
        this.viewAllPostsTarget.classList.add("active");
      }
    }

    const response = await post(this.urlValue, {
      body: JSON.stringify({ channels: this.paramsValue, active_channel_id: this.activeChannelId }),
      responseKind: "turbo-stream",
    });

    if (!response.ok) {
      console.log("Error loading menu items");
    }
  }

  valuesTargetConnected(elem) {
    this.urlValue = elem.dataset.loaderUrlValue;
    this.paramsValue = JSON.parse(elem.dataset.loaderParamsValue);
    this.#targetConnected();
  }

  activeChannelTargetConnected(elem) {
    this.activeChannelId = elem.dataset.loaderActiveChannelValue;
    this.#targetConnected();
  }

  #targetConnected() {
    if (!this.loadedTargets) {
      this.loadedTargets = true;
    } else {
      this.loadMenuItems();
    }
  }
}
