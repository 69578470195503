document.addEventListener("turbo:render", () => {
  setDisabledWith();
});

document.addEventListener("DOMContentLoaded", () => {
  setDisabledWith();
});

document.addEventListener("turbo:before-stream-render", () => {
  setTimeout(() => {
    setDisabledWith();
  }, 50);
});

document.addEventListener("turbo:before-fetch-response", () => {
  resetButtons();
});

document.addEventListener("enableTurboFormButtons", () => {
  resetButtons();
});

function setDisabledWith() {
  const buttons = document.querySelectorAll("[data-disable-with]");

  for (const button of buttons) {
    if (button.dataset.disableWithIsSet) continue;

    button.dataset.disableWithIsSet = true;
    button.dataset.disableWithOriginalText = button.value || button.innerHTML;

    const formId = button.dataset.disableWithFormId || "";
    const form = document.getElementById(formId) || button.closest("form");

    if (form && !form.dataset.disableWithIsSet) {
      form.dataset.disableWithIsSet = true;
      form.addEventListener("submit", () => formSubmit(form));
    }
  }
}

function formSubmit(form) {
  const no_page_change = form.querySelector("input[name='no_page_change']");

  if (no_page_change && no_page_change.value === "true") {
    disableButtons();
    return;
  }

  if (form.checkValidity()) {
    disableButtons();
  } else {
    form.reportValidity();
  };
}

function disableButtons() {
  const buttons = document.querySelectorAll("[data-disable-with]");

  buttons.forEach((button) => {
    Rails.disableElement(button);
    button.disabled = true;

    if (button.value) button.value = button.dataset.disableWith;
    if (button.innerHTML) button.innerHTML = button.dataset.disableWith;
  });

}

function resetButtons() {
  const buttons = document.querySelectorAll("[data-disable-with]");

  buttons.forEach((button) => {
    Rails.enableElement(button);
    button.disabled = false;
    const originalText = button.dataset.disableWithOriginalText;

    if (originalText) {
      button.value = button.dataset.disableWithOriginalText;
      button.innerHTML = button.dataset.disableWithOriginalText;
    }
  });
}
