// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
//= require stimulus

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
// application.register('slideover', Slideover)
import Flatpickr from "stimulus-flatpickr";
import { Dropdown, Modal, Tabs, Toggle } from "tailwindcss-stimulus-components";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import GLightboxController from "./glightbox_controller";
import TrixGlightboxController from "./trix_glightbox_controller";

const application = Application.start();
window.application = application;

const controllers = import.meta.glob("./**/*_controller.js", { eager: true });

registerControllers(application, controllers);

application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("toggle", Toggle);
application.register("flatpickr", Flatpickr);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("glightbox", GLightboxController);
application.register("trix-glightbox", TrixGlightboxController);

export { application };
