// It sticks right after header (provide position:sticky)
// <div data-controller="sticky-under-header" data-sticky-under-header-target="element"></div>
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.adjustElementPosition();
    window.addEventListener("resize", this.adjustElementPosition.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.adjustElementPosition.bind(this));
  }

  adjustElementPosition() {
    const header = document.querySelector("header");
    const headerHeight = header.offsetHeight;
    this.elementTarget.style.top = `${headerHeight}px`;
  }
}
