import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["valueInput", "select", "dateInput", "useTodayCheckbox", "input"];

  connect() {
    this.initializeTomSelect();
  }

  selectTargetConnected(element) {
    this.initializeTomSelect();
  }

  inputTargetConnected(element) {
    this.setInitialValue(element);
  }

  initializeTomSelect() {
    if (!this.hasSelectTarget) return;

    const selectTarget = this.selectTarget;
    const options = JSON.parse(selectTarget.dataset.options);
    const groupedOptions = this.groupOptionsByModel(options);
    const initialValue = selectTarget.dataset.initialValue;

    const tomSelectOptions = {
      valueField: "value",
      labelField: "text",
      searchField: ["text"],
      options: options.map((option) => ({
        text: option[0],
        value: option[1],
        model: option[1].split(".")[0],
        fieldType: option[2],
      })),
      optgroups: groupedOptions,
      optgroupField: "model",
      render: {
        optgroup_header: function (data) {
          return `<div class="optgroup-header">${data.label}</div>`;
        },
        option: function (data, escape) {
          return `<div class="option">${escape(data.text.split(": ")[1])}</div>`;
        },
      },
      onChange: (value) => {
        this.toggleInput({ target: { value } });
      },
    };

    if (selectTarget.tomSelect) {
      selectTarget.tomSelect.destroy();
    }

    selectTarget.tomSelect = new TomSelect(selectTarget, tomSelectOptions);

    if (initialValue) {
      selectTarget.tomSelect.setValue(initialValue);
      this.toggleInput({ target: { value: initialValue } });
    }
  }

  groupOptionsByModel(options) {
    return options.reduce((groups, option) => {
      const [, value] = option;
      const [model] = value.split(".");
      if (!groups[model]) {
        groups[model] = { label: model, value: model };
      }
      return groups;
    }, {});
  }

  setInitialValue(inputTarget) {
    const initialValue = inputTarget.dataset.initialValue;
    if (initialValue) {
      inputTarget.value = initialValue;
    }
  }

  toggleInput(event) {
    const selectedValue = event.target.value;
    const options = JSON.parse(this.selectTarget.dataset.options);
    const selectedOption = options.find((option) => option[1] === selectedValue);

    if (selectedOption) {
      const [, , fieldType] = selectedOption;
      this.updateInputField(fieldType);
    }
  }

  updateInputField(fieldType) {
    const container = this.valueInputTarget;
    const currentValue = this.hasInputTarget ? this.inputTarget.value : '';

    if (!container) return;

    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    let newInput;

    switch (fieldType) {
      case "date":
      case "datetime":
        newInput = this.createDateInput(currentValue);
        break;
      case "boolean":
        newInput = this.createBooleanInput(currentValue);
        break;
      case "number":
        newInput = this.createNumberInput(currentValue);
        break;
      default:
        newInput = this.createTextInput(currentValue);
    }

    container.appendChild(newInput);
  }

  createDateInput(value) {
    const wrapper = document.createElement("div");
    wrapper.className = "flex items-center gap-2";

    const dateInput = document.createElement("input");
    dateInput.type = "date";
    dateInput.name = `${this.getBaseName()}[data][value]`;
    dateInput.className = "w-40 form-control set-variable-input";
    dateInput.required = true;
    dateInput.dataset.setVariableTarget = "dateInput";
    dateInput.value = value;

    const checkboxWrapper = document.createElement("div");
    checkboxWrapper.className = "flex items-center gap-1 flex-shrink-0";

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.name = `${this.getBaseName()}[data][use_today]`;
    checkbox.className = "form-checkbox";
    checkbox.dataset.setVariableTarget = "useTodayCheckbox";
    checkbox.dataset.action = "change->set-variable#toggleDateInput";

    const label = document.createElement("label");
    label.textContent = "Use Today's Date";
    label.className = "ml-2 text-sm";

    checkboxWrapper.appendChild(checkbox);
    checkboxWrapper.appendChild(label);

    wrapper.appendChild(dateInput);
    wrapper.appendChild(checkboxWrapper);

    return wrapper;
  }

  createBooleanInput(value) {
    const select = document.createElement("select");
    select.name = `${this.getBaseName()}[data][variable_value]`;
    select.className = "form-control set-variable-input";
    select.dataset.setVariableTarget = "input";

    const options = [
      { value: "", text: "Select a value" },
      { value: "true", text: "True" },
      { value: "false", text: "False" },
    ];

    options.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option.value;
      optionElement.textContent = option.text;
      if (option.value === value) {
        optionElement.selected = true;
      }
      select.appendChild(optionElement);
    });

    return select;
  }

  createNumberInput(value) {
    const input = document.createElement("input");
    input.type = "number";
    input.name = `${this.getBaseName()}[data][variable_value]`;
    input.className = "form-control set-variable-input";
    input.dataset.setVariableTarget = "input";
    input.value = value;
    return input;
  }

  createTextInput(value) {
    const input = document.createElement("input");
    input.type = "text";
    input.name = `${this.getBaseName()}[data][variable_value]`;
    input.className = "form-control set-variable-input";
    input.dataset.setVariableTarget = "input";
    input.value = value;
    return input;
  }

  toggleDateInput() {
    const useToday = this.useTodayCheckboxTarget.checked;
    this.dateInputTarget.disabled = useToday;
    if (useToday) {
      this.dateInputTarget.value = "";
    }
  }

  getBaseName() {
    const selectName = this.selectTarget.name;
    return selectName.substring(0, selectName.lastIndexOf("["));
  }

  disconnect() {
    if (this.hasSelectTarget && this.selectTarget.tomSelect) {
      this.selectTarget.tomSelect.destroy();
    }
  }
}
