import { Dropdown } from "tailwindcss-stimulus-components";
import { get } from "@rails/request.js";

export default class extends Dropdown {
  static targets = ["menu", "button"];
  static values = {
    open: Boolean,
    loadUrl: String,
  };

  connect() {
    super.connect();
    this.loadedValue = false;
  }

  _show(cb) {
    if (!this.loadedValue && this.loadUrlValue) {
      this._loadMenu();
    }

    super._show(cb);
  }

  async _loadMenu() {
    const response = await get(this.loadUrlValue);

    if (response.ok) {
      const body = await response.text;
      this.menuTarget.innerHTML = body;
    }
  }
}
