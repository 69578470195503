import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  toggle() {
    this.menuTarget.classList.toggle("hidden");
  }

  hide() {
    this.menuTarget.classList.add("hidden");
  }
}
