import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "comment"];

  removeForm(event) {
    event.preventDefault();
    this.formTarget.remove();
  }

  formTargetConnected() {
    if (this.hasCommentTarget) {
      this.commentTarget.classList.add("hidden");
    }
  }

  formTargetDisconnected() {
    if (this.hasCommentTarget) {
      this.commentTarget.classList.remove("hidden");
    }
  }
}
