import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label"];

  connect() {
    const input = document.getElementById(this.labelTarget.htmlFor);

    if (input) {
      this.labelTarget.addEventListener("click", (event) => {
        const currentState = input.checked;
        if (currentState) {
          event.preventDefault();
          input.checked = false;
        }
      });
    }
  }
}
