import consumer from "./consumer";

consumer.subscriptions.create("MentionsCountsChannel", {
  received(data) {
    console.log(data);
    const channelId = data.channel_id;
    const channelType = data.channel_type;
    const unreadCountElement = document.querySelector(`#unread_count_base_${channelType}_channel_${channelId}`);
    const channelNavLink = document.querySelector(`#nav_link_base_${channelType}_channel_${channelId}`);

    if (
      unreadCountElement == null ||
      unreadCountElement == undefined ||
      channelNavLink.classList.contains("active") ||
      unreadCountElement.innerText.includes("-1")
    ) {
      return;
    }

    if (unreadCountElement.classList.contains("hidden")) {
      unreadCountElement.innerText = "1";
      unreadCountElement.classList.remove("hidden");
      document.querySelector(`#nav_icon_base_${channelType}_channel_${channelId}`)?.classList?.add("hidden");
    } else {
      const unreadCount = parseInt(unreadCountElement.innerText);
      unreadCountElement.innerText = unreadCount + 1;
    }
  },
});
