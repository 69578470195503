import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["entitiesContainer", "entityTemplate"]

  connect() {
    document.addEventListener('sortable:sorted', this.updateOnSorted);
  }

  disconnect() {
    document.removeEventListener("sortable:sorted", this.updateOnSorted);
  }

  addEntity(e) {
    e.preventDefault();
    const template = document.importNode(this.entityTemplateTarget.content, true);
    const newField = template.querySelector("input");
    const newTagIndex = document.querySelectorAll('input[name="doc_gen_signatory[entities][]"]').length;
    this.setNewIndex(newField, newTagIndex);
    this.entitiesContainerTarget.prepend(template);
  }

  setNewIndex(field, index) {
    field.setAttribute("type", "text");
    field.setAttribute("name", "doc_gen_signatory[entities][]");
    field.setAttribute("id", "doc_gen_signatory_entities_" + index);
  }

  updateOnSorted = (e) => {
    if (e.detail.element === this.entitiesContainerTarget) {
      this.updateIndexes();
    }
  }

  updateIndexes = () => {
    const entities = document.querySelectorAll('input[name="doc_gen_signatory[entities][]"]');
    entities.forEach((entity, index) => {
      entity.setAttribute("id", "doc_gen_signatory_entities_" + index);
    });
  }

  removeEntity(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const entity = target.parentElement;
    entity.remove();
    this.updateIndexes();
  }
}
