import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["emojiPickerContent"];

  emojiPickerContentTargetConnected(element) {
    this.emojiPickerHtml = element.innerHTML;
  }

  showPicker(event) {
    const buttonElement = event.target.closest("button");
    const reactableGid = buttonElement.getAttribute("data-reactable-id");
    const form = document.querySelector("#emoji_picker form");
    form.querySelector('[data-emoji-target="reactableGidInput"]').value = reactableGid;
    const pickerContent = this.emojiPickerContentTarget.innerHTML;

    this.tippy = tippy(buttonElement, {
      content: pickerContent,
      allowHTML: true,
      interactive: true,
      hideOnClick: true,
      trigger: "click",
      theme: "custom",
      appendTo: () => document.body,
    });

    this.tippy.show();
  }
}
