import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const params = new URLSearchParams(document.location.search);
    if (params.get("scrollOnNavigate")) {
      this.scrollElement = document.getElementById(
        params.get("scrollOnNavigate")
      );
      params.delete("scrollOnNavigate");
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + `?${params.toString()}`
      );
      this.boundingBox = this.scrollElement.getBoundingClientRect();
      this.scrollToElement();
    }
  }

  scrollToElement() {
    if (this.isVisible() || this.wasScrolled()) return;

    setTimeout(() => {
      document.getElementById(this.scrollElement.id).scrollIntoView({
        behavior: "smooth",
      });
    }, 200);

    this.scrollElement.dataset.scrolled = this.scrolledIdentifier;
  }

  isVisible() {
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    const isAboveScreen = this.boundingBox.bottom < 0;
    const isBelowScreen = this.boundingBox.top > viewHeight;

    return !isAboveScreen && !isBelowScreen;
  }

  wasScrolled() {
    return this.scrollElement.dataset.scrolled === this.scrolledIdentifier;
  }

  get scrolledIdentifier() {
    return "scrolled";
  }
}
