import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("profile");

    if (userId) {
      window.history.replaceState({}, document.title, window.location.pathname);
      get(`/users/${userId}/profile`, { responseKind: "turbo-stream" });
    }
  }
}
