import { Controller } from "stimulus";

export default class extends Controller {
  static values = { allowBlank: Boolean };

  connect() {
    this.defaultLocales = ["en-US"];

    this.numberField = document.createElement("input");
    this.numberField.type = "number";
    this.numberField.classList.add("hidden");
    this.numberField.setAttribute("min", this.element.getAttribute("min"));
    this.numberField.setAttribute("max", this.element.getAttribute("max"));
    this.numberField.setAttribute("name", this.element.getAttribute("name"));
    this.numberField.value = this.element.value;

    this.currencyField = this.element;
    this.currencyField.removeAttribute("name");
    this.currencyField.type = "text";

    this.currencyField.insertAdjacentElement("afterend", this.numberField);

    this.currencyField.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.currencyField.addEventListener("input", this.update.bind(this));

    this.update({ key: "" });
    this.numberField.addEventListener("invalid", this.duplicateInvalidError);
  }

  disconnect() {
    this.numberField.removeEventListener("invalid", this.duplicateInvalidError);
  }

  duplicateInvalidError = (e) => {
    e.preventDefault();
    this.currencyField.setCustomValidity(e.target.validationMessage);
    this.currencyField.reportValidity();
    this.currencyField.focus();
  };

  numberToCurrency(str) {
    return this.currencyToNumber(str || "").toLocaleString(this.defaultLocales[0]);
  }

  currencyToNumber(str) {
    // This causes decimal numbers to increase 10x
    // return Number(str.replace(/[^\d]/g, ""));

    return Number(this.parseNumber(str));
  }

  update() {
    this.currencyField.setCustomValidity("");

    if (this.allowBlankValue && this.currencyField.value === "") {
      this.currencyField.value = "";
      this.numberField.value = "";
    } else {
      this.currencyField.value = this.numberToCurrency(this.currencyField.value);
      this.numberField.value = this.currencyToNumber(this.currencyField.value);
    }
  }

  parseNumber(value, locales = this.defaultLocales) {
    const example = Intl.NumberFormat(locales).format("1.1");
    const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, "g");
    const cleaned = value.replace(cleanPattern, "");
    const normalized = cleaned.replace(example.charAt(1), ".");

    return parseFloat(normalized) || 0;
  }

  increment() {
    const step = this.currencyField.step || 1;
    const newValue = this.currencyToNumber(this.currencyField.value) + parseFloat(step);
    this.currencyField.value = this.numberToCurrency(newValue.toString());
    this.numberField.value = newValue;
  }

  decrement() {
    const step = this.currencyField.step || 1;
    const newValue = Math.max(this.currencyToNumber(this.currencyField.value) - parseFloat(step), 0);
    this.currencyField.value = this.numberToCurrency(newValue.toString());
    this.numberField.value = newValue;
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      this.increment();
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      this.decrement();
    }
  }
}
