import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "preview",
    "icon",
    "empty",
    "remove",
    "removeInput",
  ];
  static values = { preview: String };

  connect() {
    this.updateView();
    this.bindInput();
    this.bindRemove();
  }

  updateView() {
    if (this.previewValue) this.previewTarget.src = this.previewValue;
    this.previewTarget.classList.toggle("hidden", !this.previewValue);
    this.iconTarget.classList.toggle("hidden", !this.previewValue);
    this.emptyTargets.forEach((target) => {
      target.classList.toggle("hidden", !!this.previewValue);
    });
    if (this.removeTarget)
      this.removeTarget.classList.toggle("hidden", !this.previewValue);
  }

  bindInput() {
    this.inputTarget.onchange = (ev) => {
      const [file] = this.inputTarget.files;
      if (file) {
        this.previewValue = URL.createObjectURL(file);
        if (this.removeInputTarget) this.removeInputTarget.value = "";
        this.updateView();
      }
    };
  }

  bindRemove() {
    if (!this.removeTarget) return;

    this.removeTarget.onclick = (ev) => {
      ev.preventDefault();
      this.inputTarget.value = "";
      this.removeInputTarget.value = "true";
      this.previewValue = "";
      this.updateView();
      this.inputTarget.dispatchEvent(new Event("change"));
    };
  }
}
