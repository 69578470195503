import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["file", "input", "submit", "warning", "drag"];

  static values = {
    formats: Array,
  };

  fileTargetConnected(element) {
    if (!this.fileTarget.files[0]) {
      this.changeSubmitDisabledState(true);
    }

    element.addEventListener("change", () => {
      let file = this.fileTarget.files[0];

      if (file) {
        const lastDot = file.name.lastIndexOf(".");

        const ext = file.name.substring(lastDot + 1);

        if (this.hasWarningTarget) {
          if (!this.formatsValue.includes(`.${ext.toLowerCase()}`)) {
            this.warningTarget.classList.remove("hidden");
          } else {
            this.warningTarget.classList.add("hidden");
          }
        }

        this.changeSubmitDisabledState(false);
      } else {
        if (this.hasWarningTarget) {
          this.warningTarget.classList.add("hidden");
        }

        this.changeSubmitDisabledState(true);
      }

      if (this.hasInputTarget) {
        this.setInputName(file?.name || "");
      }
    });
  }

  changeSubmitDisabledState(disabled) {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = disabled;
    }
  }

  dragTargetConnected(element) {
    element.addEventListener("dragenter", this.handleDragEnter, false);
    element.addEventListener("dragover", this.handleDragEnter, false);
    element.addEventListener("dragleave", this.handleDragLeave, false);
  }

  setInputName(name) {
    if (this.hasInputTarget) {
      let nameWithoutExtension = name.split(".");
      nameWithoutExtension.pop();
      this.inputTarget.value = nameWithoutExtension.join(".");
    }
  }

  handleDragEnter(ev) {
    if (this.hasFileTarget) {
      this.fileTarget.classList.add("highlight");
    }
  }

  handleDragLeave(ev) {
    if (this.hasFileTarget) {
      this.fileTarget.classList.remove("highlight");
    }
  }
}
