import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
    attached: Boolean,
    failed: Boolean
  };

  connect() {
    if (!this.attachedValue && !this.failedValue) {
      const SECONDS_INTERVAL = 5;

      this.interval = setInterval(() => {
        this.checkAvailability();
      }, SECONDS_INTERVAL * 1000);
    }
  }

  disconnect(){
    clearInterval(this.interval);
  }

  checkAvailability(){
    Rails.ajax({
      url: this.urlValue,
      type: "GET",
      success: () => {
        if (document.getElementById('pact-result-ready')){
          clearInterval(this.interval);
        } else {
          location.reload();
        }
      }
    })
  }
}
