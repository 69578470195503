import { Controller } from "stimulus";
import Sortable from "sortablejs";
export default class extends Controller {
  static targets = ["template", "container", "addAssociationButton"];
  static values = {
    sortable: Boolean,
    reverse: Boolean,
    hideAddAssociationButton: Boolean,
  };

  connect() {
    this.prepareSortable();

    if (this.hasContainerTarget) {
      const initialContent = this.containerTarget.innerHTML.replace(/INITIAL_RECORD/g, new Date().getTime());
      this.containerTarget.innerHTML = initialContent;
    }
  }

  addAssociation(event) {
    event.preventDefault();

    if (this.hideAddAssociationButtonValue) {
      this.addAssociationButtonTarget.classList.add("hidden");
    }

    let content;
    let epochTimeIndex = new Date().getTime();

    if (event.target.dataset.customTemplateId) {
      const customTemplate = document.getElementById(
        event.target.dataset.customTemplateId
      );

      content = customTemplate.innerHTML.replace(
        /NEW_RECORD/g, epochTimeIndex
      );
    } else {
      content = this.templateTarget.innerHTML.replace(
        /NEW_RECORD/g, epochTimeIndex
      );
    }

    this.insertElement(content, epochTimeIndex);

    event.currentTarget.blur();
  }

  insertElement(content, index) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const contentNode = doc.body.firstChild;
    const insertPosition = this.reverseValue ? "afterbegin" : "beforeend";

    if (contentNode.dataset !== undefined) {
      contentNode.dataset.childIndex = index;
      this.containerTarget.insertAdjacentElement(insertPosition, contentNode);
    } else {
      this.containerTarget.insertAdjacentHTML(insertPosition, content);
    }
  }

  removeAssociation(event) {
    if (!event.currentTarget.dataset.keepDefault) {
      event.preventDefault();
    }

    if (this.hideAddAssociationButtonValue) {
      this.addAssociationButtonTarget.classList.remove("hidden");
    }

    const currentAssociation = event.target.closest(".nested-fields");
    if (currentAssociation.dataset.newRecord == "true") {
      currentAssociation.remove();
    } else {
      currentAssociation.querySelector("input[name*='_destroy']").value = 1;
      currentAssociation.classList.add("hidden");
    }
  }

  prepareSortable() {
    if (!this.sortableValue) return;

    this.sortable = new Sortable(this.containerTarget, {
      animation: 150,
      ghostClass: "bg-gray-300",
      handle: ".sortable__handle",
      filter: ".sortable__filtered",
      onSort: (ev) => {
        let counter = 1;
        for (const child of ev.item.parentElement.children) {
          if (child.classList.contains("nested-fields")) {
            child.querySelector("input[name*='position']").value = counter;
            counter++;
          }
        }
      },
    });
  }
}
